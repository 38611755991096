import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MaterialRequisitionActionTypes } from "./MaterialRequisition.type";

export function* fetchAllMaterialRequisitions(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/material-requisition?project_id=${action.payload?.project_id}`
    );
    yield put({
      type: MaterialRequisitionActionTypes.FETCH_ALL_MATERIAL_REQUISITION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialRequisitionActionTypes.FETCH_ALL_MATERIAL_REQUISITION_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneMaterialRequisitions(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/material-requisition/${action.payload}`
    );
    yield put({
      type: MaterialRequisitionActionTypes.FETCH_ONE_MATERIAL_REQUISITION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialRequisitionActionTypes.FETCH_ONE_MATERIAL_REQUISITION_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllMaterialRequisitions() {
  yield takeLatest(
    MaterialRequisitionActionTypes.FETCH_ALL_MATERIAL_REQUISITION,
    fetchAllMaterialRequisitions
  );
}

export function* watcherFetchOneMaterialRequisitions() {
  yield takeLatest(
    MaterialRequisitionActionTypes.FETCH_ONE_MATERIAL_REQUISITION,
    fetchOneMaterialRequisitions
  );
}
