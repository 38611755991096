import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ProjectUploadDocumentActionTypes } from "./ProjectUploadDocument.type";

export function* fetchAllProjectUploadDocuments(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/project-upload-document?project_id=${action.payload.project_id}`);
    yield put({
      type: ProjectUploadDocumentActionTypes.FETCH_ALL_PROJECT_UPLOAD_DOCUMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ProjectUploadDocumentActionTypes.FETCH_ALL_PROJECT_UPLOAD_DOCUMENT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneProjectUploadDocuments(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/project-upload-document/${action.payload}`
    );
    yield put({
      type: ProjectUploadDocumentActionTypes.FETCH_ONE_PROJECT_UPLOAD_DOCUMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ProjectUploadDocumentActionTypes.FETCH_ONE_PROJECT_UPLOAD_DOCUMENT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllProjectUploadDocuments() {
  yield takeLatest(ProjectUploadDocumentActionTypes.FETCH_ALL_PROJECT_UPLOAD_DOCUMENT, fetchAllProjectUploadDocuments);
}

export function* watcherFetchOneProjectUploadDocuments() {
  yield takeLatest(ProjectUploadDocumentActionTypes.FETCH_ONE_PROJECT_UPLOAD_DOCUMENT, fetchOneProjectUploadDocuments);
}
