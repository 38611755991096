import { Boq } from "../Boq/Boq.type";
import { Project } from "../Project/Project.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type SubContractComparison = {
  duration: string[];
  status: number[];
  id: any;
  date: string;
  approved_by_id: number;
  project: Project;
  checked_by_id: number;
  prepared_by_id: number;
  project_id: number;
  user_id: number;
  createdAt: string;
  updatedAt: string;
  sub_contract_comparison_prepared_by: User;
  sub_contract_comparison_approved_by: User;
  sub_contract_comparison_checked_by: User;
  sub_contract_comparison_items: SubContractComparisonItem[];
  sub_contract_contractor_comparison_items: SubContractContractorComparisonItem[];
};

export type SubContractComparisonItem = {
  id: any;
  sub_contract_comparison_id: any;
  boq_id: number;
  executed_quantity: number;
  sub_contract_quantity: number;
  difference: number;
  quantity_certified: number;
  user_id: any;
  createdAt: string;
  updatedAt: string;
  boq: Boq;
};

export type SubContractContractorComparisonItem = {
  id: any;
  sub_contract_comparison_id: any;
  boq_id: number;
  current_quantity: number;
  previous_quantity: number;
  user_id: any;
  createdAt: string;
  updatedAt: string;
  boq: Boq;
};

export type SubContractComparisonStateTypes = {
  fetchAll: ApiCallState<SubContractComparison[]>;
  fetchOne: ApiCallState<SubContractComparison | {}>;
};

export const SubContractComparisonActionTypes = {
  FETCH_ALL_SUB_CONTRACT_COMPARISON: "FETCH_ALL_SUB_CONTRACT_COMPARISON",
  FETCH_ALL_SUB_CONTRACT_COMPARISON_RESET:
    "FETCH_ALL_SUB_CONTRACT_COMPARISON_RESET",
  FETCH_ALL_SUB_CONTRACT_COMPARISON_FAILURE:
    "FETCH_ALL_SUB_CONTRACT_COMPARISON_FAILURE",
  FETCH_ALL_SUB_CONTRACT_COMPARISON_SUCCESS:
    "FETCH_ALL_SUB_CONTRACT_COMPARISON_SUCCESS",

  FETCH_ONE_SUB_CONTRACT_COMPARISON: "FETCH_ONE_SUB_CONTRACT_COMPARISON",
  FETCH_ONE_SUB_CONTRACT_COMPARISON_RESET:
    "FETCH_ONE_SUB_CONTRACT_COMPARISON_RESET",
  FETCH_ONE_SUB_CONTRACT_COMPARISON_FAILURE:
    "FETCH_ONE_SUB_CONTRACT_COMPARISON_FAILURE",
  FETCH_ONE_SUB_CONTRACT_COMPARISON_SUCCESS:
    "FETCH_ONE_SUB_CONTRACT_COMPARISON_SUCCESS",
};
