import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { TeriMastawekiaActionTypes } from "./TeriMastawekia.type";

export function* fetchAllTeriMastawekias(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/teri-mastawekia`);
    yield put({
      type: TeriMastawekiaActionTypes.FETCH_ALL_TERI_MASTAWEKIA_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: TeriMastawekiaActionTypes.FETCH_ALL_TERI_MASTAWEKIA_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneTeriMastawekias(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/teri-mastawekia/${action.payload}`
    );
    yield put({
      type: TeriMastawekiaActionTypes.FETCH_ONE_TERI_MASTAWEKIA_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: TeriMastawekiaActionTypes.FETCH_ONE_TERI_MASTAWEKIA_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllTeriMastawekias() {
  yield takeLatest(
    TeriMastawekiaActionTypes.FETCH_ALL_TERI_MASTAWEKIA,
    fetchAllTeriMastawekias
  );
}

export function* watcherFetchOneTeriMastawekias() {
  yield takeLatest(
    TeriMastawekiaActionTypes.FETCH_ONE_TERI_MASTAWEKIA,
    fetchOneTeriMastawekias
  );
}
