import {
  CloseCircleOutlined,
  InboxOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Card,
  Form,
  Input,
  InputNumber,
  Spin,
  Typography,
  Upload,
  Button,
  Divider,
  Tag,
} from "antd";
import { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchAllVacanciesByJobId } from "../../../redux/Vacancy/Vacancy.action";
import { AddEmployeeApplication } from "./employee-application.util";
import moment from "moment";
import { useHistory } from "react-router";
import { NotificationType, Message } from "../../../constants/Constants";
import CompanyLogo from "../../../Images/LogoHorizontal.svg";
import {
  EtRegEx,
  generatePassword,
  generateRandomStr,
} from "../../../utilities/utilities";
import { OpenNotification } from "../../common/Notification/Notification.component";

const EmployeeApplication: FC<any> = ({ vacancies, fetchVacancyByRef }) => {
  const { Title, Paragraph } = Typography;
  const param: { jobId: string } = useParams();
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    const par = param.jobId.split("V")[1];
    const id = par;
    fetchVacancyByRef(id);
    setLoading(false);
  }, []);

  const submitApplication = (vals: any) => {
    setLoading(true);
    const keys: any[] = Object.keys(vals);
    const values: any[] = Object.values(vals);
    const formData = new FormData();
    const rand = generateRandomStr();
    formData.append("vacancy_id", vacancies.payload.id);
    formData.append("rand_key", rand);
    let to_send: any[] = [];
    let file_arr: any[] = [];
    keys.forEach((key, index) => {
      const name = key.split(",")[0];
      const typ = key.split(",")[1];
      if (typ === "file") {
        file_arr.push({
          name: name,
          value: `${rand}-${values[index]?.file.name}`,
        });
      } else {
        to_send.push({ type: typ, name: name, value: values[index] });
      }
      if (typ === "file") formData.append("files", values[index]?.file);
    });
    to_send.push({ type: "file", value: file_arr });
    const fd = to_send
      .filter((value) => Object.keys(value).length !== 0)
      .map((vals: any) => JSON.stringify(vals))
      .join("---");
    formData.append("data", fd);
    AddEmployeeApplication(formData)
      .then((res: any) => {
        setLoading(false);
        history.push("/application/success");
      })
      .catch((error: any) => {
        setLoading(false);
        OpenNotification(
          NotificationType.ERROR,
          "Application failed",
          "Something is wrong, application failed"
        );
      });
  };

  const [form] = Form.useForm();

  const renderApplication = () => {
    if (vacancies.isPending || loading) {
      return (
        <div style={{ alignItems: "center", height: "100vh" }}>
          <Spin />
        </div>
      );
    } else if (
      Object.keys(vacancies.payload).length === 0 ||
      vacancies.payload?.status === "CLOSED"
    ) {
      return (
        <div className="container-fluid" style={{ height: "100vh" }}>
          <div className="mtop-5 col-lg-7 col-sm-9 mx-auto">
            <div className="col-lg-7 col-sm-9 mx-auto mt-0 pt-1">
              <Card>
                <CloseCircleOutlined
                  style={{ color: "red", fontSize: "60px" }}
                  className="mx-auto d-block mb-4"
                />
                <h3 className="text-center">Job Closed</h3>
                <p
                  style={{
                    textAlign: "center",
                  }}
                >
                  Job you selected is closed
                </p>
              </Card>
            </div>
          </div>
        </div>
      );
    } else {
      const formData = vacancies.payload?.formData
        .split("---")
        .map((formDa: string) => JSON.parse(formDa));
      return (
        <div className="mt-4">
          <img
            src={CompanyLogo}
            style={{ height: 40, paddingLeft: 15, marginBottom: 10 }}
          />
          <Card className="vacancy-card">
            <Title level={4}>{vacancies.payload?.title}</Title>
            <p>
              <Title level={5} className="d-inline">
                Job Type:{" "}
              </Title>
              <Tag color="blue">{vacancies.payload?.job_type}</Tag>
            </p>
            <p>
              <Title level={5} className="d-inline">
                Company:{" "}
              </Title>
              Company Name
            </p>
            <Paragraph className="text-justify">
              {vacancies.payload?.description}
            </Paragraph>
            <Divider></Divider>
            <p>
              <Title level={5} className="d-inline">
                Employees needed:{" "}
              </Title>
              {vacancies.payload?.quantity}
            </p>
            <p>
              <Title level={5} className="d-inline">
                Salary:{" "}
              </Title>
              {vacancies.payload?.salary_type === "SET"
                ? vacancies.payload?.salary
                : vacancies.payload?.salary_type}
            </p>
            <p>
              <Title level={5} className="d-inline">
                Location{" "}
              </Title>
              Addis Ababa
            </p>
            <p>
              <Title level={5} className="d-inline">
                Closing Date:{" "}
              </Title>
              <Tag color="red">13-Nov-2021</Tag>
            </p>
          </Card>
          {/* <Divider type="vertical" className="vacancy-divider"></Divider> */}
          <div>
            <Card className="mt-4">
              <Title level={4}>Fill out this form</Title>
              <Form
                layout="vertical"
                form={form}
                onFinish={(values) => submitApplication(values)}
              >
                {formData.map((data: any, index: number) => {
                  switch (data.type) {
                    case "text":
                      return (
                        <div className="col-md-12">
                          <Form.Item
                            required={data.required}
                            label={data.label}
                            name={`${data.label.toLowerCase()},${data.type.toLowerCase()}`}
                            rules={[
                              {
                                required: data.required,
                                message: `Please input ${data.label}`,
                              },
                            ]}
                          >
                            <Input
                              type="text"
                              placeholder={`Enter ${data.label}`}
                            />
                          </Form.Item>
                        </div>
                      );
                    case "number":
                      return (
                        <div className="col-md-12">
                          <Form.Item
                            required={data.required}
                            label={data.label}
                            rules={[
                              {
                                required: data.required,
                                message: `Please input ${data.label}`,
                              },
                            ]}
                            name={`${data.label.toLowerCase()},${data.type.toLowerCase()}`}
                          >
                            <InputNumber placeholder={`Enter ${data.label}`} />
                          </Form.Item>
                        </div>
                      );
                    case "phone":
                      return (
                        <div className="col-md-12">
                          <Form.Item
                            required={data.required}
                            label={data.label}
                            name={`${data.label.toLowerCase()},${data.type.toLowerCase()}`}
                            rules={[
                              {
                                required: data.required,
                                message: `Please input ${data.label}`,
                              },
                              {
                                pattern: EtRegEx,
                                message: "Phone format not correct",
                              },
                            ]}
                          >
                            <Input placeholder={`Enter ${data.label}`} />
                          </Form.Item>
                        </div>
                      );
                    case "email":
                      return (
                        <div className="col-md-12">
                          <Form.Item
                            required={data.required}
                            label={data.label}
                            name={`${data.label.toLowerCase()},${data.type.toLowerCase()}`}
                            rules={[
                              {
                                required: data.required,
                                type: "email",
                                message: `Please input ${data.label}`,
                              },
                            ]}
                          >
                            <Input
                              type="email"
                              placeholder={`Enter ${data.label}`}
                            />
                          </Form.Item>
                        </div>
                      );
                    case "file":
                      return (
                        <div className="col-md-12">
                          {/* <Form.Item label={data.label} required={data.required}> */}
                          <Form.Item
                            label={data.label}
                            name={`${data.label.toLowerCase()},${data.type.toLowerCase()}`}
                            rules={[
                              {
                                required: data.required,
                                message: `Please input ${data.label}`,
                              },
                            ]}
                          >
                            <Upload
                              style={{ width: "100%" }}
                              beforeUpload={() => {
                                // if (file.type !== 'image/png') {
                                //   message.error(`${file.name} is not a png file`);
                                // }
                                // return file.type === 'image/png' ? true : Upload.LIST_IGNORE;
                                return false;
                              }}
                              type="select"
                              multiple={false}
                            >
                              <Button
                                style={{ width: "100%" }}
                                icon={<UploadOutlined />}
                              >
                                Click to upload{" "}
                                {data.fileType === "all"
                                  ? null
                                  : `(${data.fileType})`}
                              </Button>
                            </Upload>
                          </Form.Item>
                        </div>
                      );
                    default:
                      break;
                  }
                })}
                <div
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Button type="primary" block onClick={() => form.submit()}>
                    Apply
                  </Button>
                </div>
              </Form>
            </Card>
          </div>
        </div>
      );
    }
  };

  return <div className="vacancy-wrapper">{renderApplication()}</div>;
};

/**
 * Map State to Props
 *
 * @param state
 */
const mapStateToProps = (state: any) => ({
  vacancies: state.vacancies.fetchByJobId,
});

/**
 * Map Dispatch to Props
 *
 * @param dispatch
 */
const mapDispatchToProps = (dispatch: any) => ({
  fetchVacancyByRef: (param: string) =>
    dispatch(fetchAllVacanciesByJobId(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeApplication);
