import { ApiCallState } from "../Utils";

export type Justification= {
    id: number | null;
    project_id:number | null;
    date:string;
    prepared_by:string;
    checked_by:string;
    approved_by:string;
    is_approved:boolean;
    is_checked:boolean;
    justification_details:JustificationDetail[];
};

export type JustificationDetail= {
    id: number | null;
    justification_id:number | null;
    credible_reason:string;
    description_of_main_work:string;
    unexecuted_amount:number;
    description_of_problems:string;
    remedial_measure:string;
    remark:string;
}

export type JustificationStateTypes = {
  fetchAll: ApiCallState<Justification[]>;
  fetchOne: ApiCallState<Justification | {}>;
};

export const JustificationActionTypes = {
  FETCH_ALL_JUSTIFICATION: "FETCH_ALL_JUSTIFICATION",
  FETCH_ALL_JUSTIFICATION_RESET: "FETCH_ALL_JUSTIFICATION_RESET",
  FETCH_ALL_JUSTIFICATION_FAILURE: "FETCH_ALL_JUSTIFICATION_FAILURE",
  FETCH_ALL_JUSTIFICATION_SUCCESS: "FETCH_ALL_JUSTIFICATION_SUCCESS",

  FETCH_ONE_JUSTIFICATION: "FETCH_ONE_JUSTIFICATION",
  FETCH_ONE_JUSTIFICATION_RESET: "FETCH_ONE_JUSTIFICATION_RESET",
  FETCH_ONE_JUSTIFICATION_FAILURE: "FETCH_ONE_JUSTIFICATION_FAILURE",
  FETCH_ONE_JUSTIFICATION_SUCCESS: "FETCH_ONE_JUSTIFICATION_SUCCESS",
};
