import { ApiCallState } from "../Utils";

export type PriceComparison = {
 id: number,
 date: string,
 price_comparison_items: PriceComparisonItem[]
}

type PriceComparisonItem= {
  id: number;
  description: string;
  unit: string;
  quantity: number;
  rate_description_analysis: string;
  status: string;
  remark: string;
  price_comparison_companies: PriceComparisonCompany[]
};

type PriceComparisonCompany = {
    id?: number,
    name: string;
    rate: number,
}

export type PriceComparisonStateTypes = {
  fetchAll: ApiCallState<PriceComparison[]>;
  fetchOne: ApiCallState<PriceComparison | {}>;
};

export const PriceComparisonActionTypes = {
  FETCH_ALL_PRICE_COMPARISON: "FETCH_ALL_PRICE_COMPARISON",
  FETCH_ALL_PRICE_COMPARISON_RESET: "FETCH_ALL_PRICE_COMPARISON_RESET",
  FETCH_ALL_PRICE_COMPARISON_FAILURE: "FETCH_ALL_PRICE_COMPARISON_FAILURE",
  FETCH_ALL_PRICE_COMPARISON_SUCCESS: "FETCH_ALL_PRICE_COMPARISON_SUCCESS",

  FETCH_ONE_PRICE_COMPARISON: "FETCH_ONE_PRICE_COMPARISON",
  FETCH_ONE_PRICE_COMPARISON_RESET: "FETCH_ONE_PRICE_COMPARISON_RESET",
  FETCH_ONE_PRICE_COMPARISON_FAILURE: "FETCH_ONE_PRICE_COMPARISON_FAILURE",
  FETCH_ONE_PRICE_COMPARISON_SUCCESS: "FETCH_ONE_PRICE_COMPARISON_SUCCESS",
};
