import { ApiCallState } from "../Utils";

export type ActionPlanSubcontractor= {
    id: number | null;
    project_id:number;
    no:number;
    is_title:boolean;
    month_subcontractor_schedule_id:number;
    date:string;
    remark:string;
};

export type ActionPlanSubcontractorStateTypes = {
  fetchAll: ApiCallState<ActionPlanSubcontractor[]>;
  fetchOne: ApiCallState<ActionPlanSubcontractor | {}>;
};

export const ActionPlanSubcontractorActionTypes = {
  FETCH_ALL_ACTION_PLAN_SUBCONTRACTOR: "FETCH_ALL_ACTION_PLAN_SUBCONTRACTOR",
  FETCH_ALL_ACTION_PLAN_SUBCONTRACTOR_RESET: "FETCH_ALL_ACTION_PLAN_SUBCONTRACTOR_RESET",
  FETCH_ALL_ACTION_PLAN_SUBCONTRACTOR_FAILURE: "FETCH_ALL_ACTION_PLAN_SUBCONTRACTOR_FAILURE",
  FETCH_ALL_ACTION_PLAN_SUBCONTRACTOR_SUCCESS: "FETCH_ALL_ACTION_PLAN_SUBCONTRACTOR_SUCCESS",

  FETCH_ONE_ACTION_PLAN_SUBCONTRACTOR: "FETCH_ONE_ACTION_PLAN_SUBCONTRACTOR",
  FETCH_ONE_ACTION_PLAN_SUBCONTRACTOR_RESET: "FETCH_ONE_ACTION_PLAN_SUBCONTRACTOR_RESET",
  FETCH_ONE_ACTION_PLAN_SUBCONTRACTOR_FAILURE: "FETCH_ONE_ACTION_PLAN_SUBCONTRACTOR_FAILURE",
  FETCH_ONE_ACTION_PLAN_SUBCONTRACTOR_SUCCESS: "FETCH_ONE_ACTION_PLAN_SUBCONTRACTOR_SUCCESS",
};
