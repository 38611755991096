import { FinanceDailyReport } from "../FinanceDailyReport/FinanceDailyReport.type";
import { ApiCallState } from "../Utils";

export type DailySummary= {
  id: number;
  remark: string;
  date:string;
  project_id:number;
  daily_summary_items: DailySummaryItem[]
};

export type DailySummaryItem = {
  id: number,
  remark: string,
  finantial_dialy_report: FinanceDailyReport
}

export type DailySummaryStateTypes = {
  fetchAll: ApiCallState<DailySummary[]>;
  fetchOne: ApiCallState<DailySummary | {}>;
};

export const DailySummaryActionTypes = {
  FETCH_ALL_DAILY_SUMMARY: "FETCH_ALL_DAILY_SUMMARY",
  FETCH_ALL_DAILY_SUMMARY_RESET: "FETCH_ALL_DAILY_SUMMARY_RESET",
  FETCH_ALL_DAILY_SUMMARY_FAILURE: "FETCH_ALL_DAILY_SUMMARY_FAILURE",
  FETCH_ALL_DAILY_SUMMARY_SUCCESS: "FETCH_ALL_DAILY_SUMMARY_SUCCESS",

  FETCH_ONE_DAILY_SUMMARY: "FETCH_ONE_DAILY_SUMMARY",
  FETCH_ONE_DAILY_SUMMARY_RESET: "FETCH_ONE_DAILY_SUMMARY_RESET",
  FETCH_ONE_DAILY_SUMMARY_FAILURE: "FETCH_ONE_DAILY_SUMMARY_FAILURE",
  FETCH_ONE_DAILY_SUMMARY_SUCCESS: "FETCH_ONE_DAILY_SUMMARY_SUCCESS",
};
