import {
  WithHoldPaymentStateTypes,
  WithHoldPaymentActionTypes,
} from "./WithHoldPayment.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: WithHoldPaymentStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const WithHoldPaymentReducer = (
  state: WithHoldPaymentStateTypes = INITIAL_STATE,
  action: any
): WithHoldPaymentStateTypes => {
  switch (action.type) {
    case WithHoldPaymentActionTypes.FETCH_ALL_WITH_HOLD_PAYMENT:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case WithHoldPaymentActionTypes.FETCH_ALL_WITH_HOLD_PAYMENT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case WithHoldPaymentActionTypes.FETCH_ALL_WITH_HOLD_PAYMENT_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case WithHoldPaymentActionTypes.FETCH_ALL_WITH_HOLD_PAYMENT_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case WithHoldPaymentActionTypes.FETCH_ONE_WITH_HOLD_PAYMENT:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case WithHoldPaymentActionTypes.FETCH_ONE_WITH_HOLD_PAYMENT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case WithHoldPaymentActionTypes.FETCH_ONE_WITH_HOLD_PAYMENT_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case WithHoldPaymentActionTypes.FETCH_ONE_WITH_HOLD_PAYMENT_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default WithHoldPaymentReducer;
