import { ApiCallState } from "../Utils";
import { Moment } from "moment";

export type EquipmentSchedule = {
  project_id: number | null;
  id: number | null;
  no: string;
  description: string;
  date: Moment;
  rate: number;
  total_required_hr: number;
  total_amount: number;
  remark: string;
  monday: number;
  tuseday: number;
  wednesday: number;
  thursday: number;
  friday: number;
  saturday: number;
  sunday: number;
};

export type EquipmentScheduleStateTypes = {
  fetchAll: ApiCallState<EquipmentSchedule[]>;
  fetchOne: ApiCallState<EquipmentSchedule | {}>;
};

export type EquipmentScheduleActionType = {
    project_id: number;
    date: string;
};

export const EquipmentScheduleActionTypes = {
  FETCH_ALL_EQUIPMENT_SCHEDULE: "FETCH_ALL_EQUIPMENT_SCHEDULE",
  FETCH_ALL_EQUIPMENT_SCHEDULE_RESET: "FETCH_ALL_EQUIPMENT_SCHEDULE_RESET",
  FETCH_ALL_EQUIPMENT_SCHEDULE_FAILURE: "FETCH_ALL_EQUIPMENT_SCHEDULE_FAILURE",
  FETCH_ALL_EQUIPMENT_SCHEDULE_SUCCESS: "FETCH_ALL_EQUIPMENT_SCHEDULE_SUCCESS",

  FETCH_ONE_EQUIPMENT_SCHEDULE: "FETCH_ONE_EQUIPMENT_SCHEDULE",
  FETCH_ONE_EQUIPMENT_SCHEDULE_RESET: "FETCH_ONE_EQUIPMENT_SCHEDULE_RESET",
  FETCH_ONE_EQUIPMENT_SCHEDULE_FAILURE: "FETCH_ONE_EQUIPMENT_SCHEDULE_FAILURE",
  FETCH_ONE_EQUIPMENT_SCHEDULE_SUCCESS: "FETCH_ONE_EQUIPMENT_SCHEDULE_SUCCESS",
};
