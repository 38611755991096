import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { PaymentCertificateAggregateActionTypes } from "./PaymentCertificateAggregate.type";

export function* fetchAllPaymentCertificateAggregates(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/payment_certificate_aggregate?${action.payload?.pid}`);
    yield put({
      type: PaymentCertificateAggregateActionTypes.FETCH_ALL_PAYMENT_CERTIFICATE_AGGREGATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PaymentCertificateAggregateActionTypes.FETCH_ALL_PAYMENT_CERTIFICATE_AGGREGATE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOnePaymentCertificateAggregates(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/payment_certificate_aggregate/${action.payload}`
    );
    yield put({
      type: PaymentCertificateAggregateActionTypes.FETCH_ONE_PAYMENT_CERTIFICATE_AGGREGATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PaymentCertificateAggregateActionTypes.FETCH_ONE_PAYMENT_CERTIFICATE_AGGREGATE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllPaymentCertificateAggregates() {
  yield takeLatest(PaymentCertificateAggregateActionTypes.FETCH_ALL_PAYMENT_CERTIFICATE_AGGREGATE, fetchAllPaymentCertificateAggregates);
}

export function* watcherFetchOnePaymentCertificateAggregates() {
  yield takeLatest(PaymentCertificateAggregateActionTypes.FETCH_ONE_PAYMENT_CERTIFICATE_AGGREGATE, fetchOnePaymentCertificateAggregates);
}
