import { Button, Typography } from "antd";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";

export default function Welcome() {
  const { Title } = Typography;
  const history = useHistory();
  const params: { jobId: string } = useParams();

  return (
    <div
      className="container-fluid"
      style={{
        backgroundColor: "#f5f8fd",
        paddingTop: "80px",
        height: "100vh",
      }}
    >
      <div className="mtop-5 col-lg-7 col-sm-9 mx-auto">
        <div className="col-lg-10 col-sm-9 mx-auto mt-0 pt-1">
          <Title level={3} style={{ textAlign: "center" }}>
            Welcome, Please click the Apply button to apply for the vacancy
          </Title>
          <div style={{ textAlign: "center", paddingTop: "20px" }}>
            <Button
              onClick={() => history.push(`/apply/${params.jobId}`)}
              type="primary"
              size="small"
            >
              Apply
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
