import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { TransferDocumentFormatActionTypes } from "./TransferDocumentFormat.type";

export function* fetchAllTransferDocumentFormats(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/transfer-document-format?project_id=${action.payload.project_id}`);
    yield put({
      type: TransferDocumentFormatActionTypes.FETCH_ALL_TRANSFER_DOCUMENT_FORMAT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: TransferDocumentFormatActionTypes.FETCH_ALL_TRANSFER_DOCUMENT_FORMAT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneTransferDocumentFormats(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/transfer-document-format/detail?project_id=${action.payload.project_id}&id=${action.payload.id}`
    );
    yield put({
      type: TransferDocumentFormatActionTypes.FETCH_ONE_TRANSFER_DOCUMENT_FORMAT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: TransferDocumentFormatActionTypes.FETCH_ONE_TRANSFER_DOCUMENT_FORMAT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllTransferDocumentFormats() {
  yield takeLatest(TransferDocumentFormatActionTypes.FETCH_ALL_TRANSFER_DOCUMENT_FORMAT, fetchAllTransferDocumentFormats);
}

export function* watcherFetchOneTransferDocumentFormats() {
  yield takeLatest(TransferDocumentFormatActionTypes.FETCH_ONE_TRANSFER_DOCUMENT_FORMAT, fetchOneTransferDocumentFormats);
}
