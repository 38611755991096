import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ActionPlanNarrativeActionTypes } from "./ActionPlanNarrative.type";

export function* fetchAllActionPlanNarratives(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/action-plan-narrative?date=${action.payload.date}`);
    yield put({
      type: ActionPlanNarrativeActionTypes.FETCH_ALL_ACTION_PLAN_NARRATIVE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionPlanNarrativeActionTypes.FETCH_ALL_ACTION_PLAN_NARRATIVE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneActionPlanNarratives(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/action-plan-narrative/${action.payload}`
    );
    yield put({
      type: ActionPlanNarrativeActionTypes.FETCH_ONE_ACTION_PLAN_NARRATIVE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionPlanNarrativeActionTypes.FETCH_ONE_ACTION_PLAN_NARRATIVE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllActionPlanNarratives() {
  yield takeLatest(ActionPlanNarrativeActionTypes.FETCH_ALL_ACTION_PLAN_NARRATIVE, fetchAllActionPlanNarratives);
}

export function* watcherFetchOneActionPlanNarratives() {
  yield takeLatest(ActionPlanNarrativeActionTypes.FETCH_ONE_ACTION_PLAN_NARRATIVE, fetchOneActionPlanNarratives);
}
