import { ApiCallState } from "../Utils";
import { Moment } from "moment";

export type DailySurveyingReporting= {
    project_id: number | null;
    id: number | null;
    date:Moment;
    from:string;
    to:string;
    width:number;
    type_of_activity:string;
    status_progress:string;
};

export type DailySurveyingReportingStateTypes = {
  fetchAll: ApiCallState<DailySurveyingReporting[]>;
  fetchOne: ApiCallState<DailySurveyingReporting | {}>;
};

export type DailySurveyingReportingActionType = {
    project_id: number;
    date: string;
};

export const DailySurveyingReportingActionTypes = {
  FETCH_ALL_DAILY_SURVEYING_REPORTING: "FETCH_ALL_DAILY_SURVEYING_REPORTING",
  FETCH_ALL_DAILY_SURVEYING_REPORTING_RESET: "FETCH_ALL_DAILY_SURVEYING_REPORTING_RESET",
  FETCH_ALL_DAILY_SURVEYING_REPORTING_FAILURE: "FETCH_ALL_DAILY_SURVEYING_REPORTING_FAILURE",
  FETCH_ALL_DAILY_SURVEYING_REPORTING_SUCCESS: "FETCH_ALL_DAILY_SURVEYING_REPORTING_SUCCESS",

  FETCH_ONE_DAILY_SURVEYING_REPORTING: "FETCH_ONE_DAILY_SURVEYING_REPORTING",
  FETCH_ONE_DAILY_SURVEYING_REPORTING_RESET: "FETCH_ONE_DAILY_SURVEYING_REPORTING_RESET",
  FETCH_ONE_DAILY_SURVEYING_REPORTING_FAILURE: "FETCH_ONE_DAILY_SURVEYING_REPORTING_FAILURE",
  FETCH_ONE_DAILY_SURVEYING_REPORTING_SUCCESS: "FETCH_ONE_DAILY_SURVEYING_REPORTING_SUCCESS",
};
