import { ApiCallState } from "../Utils";

export type PriceOffer = {
  id: any;
  description: string;
  work_scope: string;
  unit: string;
  quantity: number;
  amount: number;
  unit_price: number;
  project_id: number;
  key: number;
  remark: string;
};

export type PriceOfferStateTypes = {
  fetchAll: ApiCallState<PriceOffer[]>;
  fetchOne: ApiCallState<PriceOffer | {}>;
};

export const PriceOfferActionTypes = {
  FETCH_ALL_PRICE_OFFER: "FETCH_ALL_PRICE_OFFER",
  FETCH_ALL_PRICE_OFFER_RESET: "FETCH_ALL_PRICE_OFFER_RESET",
  FETCH_ALL_PRICE_OFFER_FAILURE: "FETCH_ALL_PRICE_OFFER_FAILURE",
  FETCH_ALL_PRICE_OFFER_SUCCESS: "FETCH_ALL_PRICE_OFFER_SUCCESS",

  FETCH_ONE_PRICE_OFFER: "FETCH_ONE_PRICE_OFFER",
  FETCH_ONE_PRICE_OFFER_RESET: "FETCH_ONE_PRICE_OFFER_RESET",
  FETCH_ONE_PRICE_OFFER_FAILURE: "FETCH_ONE_PRICE_OFFER_FAILURE",
  FETCH_ONE_PRICE_OFFER_SUCCESS: "FETCH_ONE_PRICE_OFFER_SUCCESS",
};
