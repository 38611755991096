import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { DailyActivityActionTypes } from "./DailyActivity.type";

export function* fetchAllDailyActivitys(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/daily-activity`);
    yield put({
      type: DailyActivityActionTypes.FETCH_ALL_DAILY_ACTIVITY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DailyActivityActionTypes.FETCH_ALL_DAILY_ACTIVITY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneDailyActivitys(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/daily-activity/one?date=${action.payload?.date}`
    );
    yield put({
      type: DailyActivityActionTypes.FETCH_ONE_DAILY_ACTIVITY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DailyActivityActionTypes.FETCH_ONE_DAILY_ACTIVITY_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllDailyActivitys() {
  yield takeLatest(DailyActivityActionTypes.FETCH_ALL_DAILY_ACTIVITY, fetchAllDailyActivitys);
}

export function* watcherFetchOneDailyActivitys() {
  yield takeLatest(DailyActivityActionTypes.FETCH_ONE_DAILY_ACTIVITY, fetchOneDailyActivitys);
}
