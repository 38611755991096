import { Department } from "../Department/Department.type";
import { Project } from "../Project/Project.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type CheckList = {
  id: number;
  user_id: number;

  prepared_by_id: number;
  checked_by_id: number;
  certified_by_id: number;
  approved_by_id: number;

  is_checked: boolean;
  is_certified: boolean;
  is_approved: boolean;

  check_list_number: string;
  date: string;

  prepared_by: User;
  checked_by: User;
  certified_by: User;
  approved_by: User;

  check_list_items: CheckListItem[];
};

export type CheckListItem = {
  id: number;
  check_list_id: number;
  department_id: number;
  project_id: number;

  description: string;
  amount: number;
  remark: string;

  approved: boolean;

  department: Department;
  project: Project;
};

export type CheckListStateTypes = {
  fetchAll: ApiCallState<CheckList[]>;
  fetchOne: ApiCallState<CheckList | {}>;
};

export const CheckListActionTypes = {
  FETCH_ALL_CHECK_LIST: "FETCH_ALL_CHECK_LIST",
  FETCH_ALL_CHECK_LIST_RESET: "FETCH_ALL_CHECK_LIST_RESET",
  FETCH_ALL_CHECK_LIST_FAILURE: "FETCH_ALL_CHECK_LIST_FAILURE",
  FETCH_ALL_CHECK_LIST_SUCCESS: "FETCH_ALL_CHECK_LIST_SUCCESS",

  FETCH_ONE_CHECK_LIST: "FETCH_ONE_CHECK_LIST",
  FETCH_ONE_CHECK_LIST_RESET: "FETCH_ONE_CHECK_LIST_RESET",
  FETCH_ONE_CHECK_LIST_FAILURE: "FETCH_ONE_CHECK_LIST_FAILURE",
  FETCH_ONE_CHECK_LIST_SUCCESS: "FETCH_ONE_CHECK_LIST_SUCCESS",
};
