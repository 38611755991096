import { all, call } from "redux-saga/effects";
import {
  watcherFetchAllAccounts,
  watcherFetchOneAccounts,
} from "./Account/Account.saga";
import {
  watcherFetchAllBoq,
  watcherFetchDetailBoq,
  watcherFetchOneBoq,
  watcherFetchSubContractBoq,
} from "./Boq/Boq.saga";
import { watcherFetchAllBoqActivityPlans } from "./BoqActivityPlan/BoqActivityPlan.saga";
import { watcherFetchAllBoqPlans } from "./BoqPlan/BoqPlan.saga";
import { watcherFetchAllConsultants } from "./Consultant/Consultant.saga";
import { watcherFetchAllContractors } from "./Contractor/Contractor.saga";
import { watcherFetchAllCustomers } from "./Customer/Customer.saga";
import { watcherFetchAllDepartments } from "./Department/Department.saga";
import { watcherFetchAllDocuments } from "./Document/Document.saga";
import { watcherFetchEquipmentInventories } from "./EquipmentInventory/EquipmentInventory.saga";
import { watcherFetchAllEquipmentPlans } from "./EquipmentPlan/EquipmentPlan.saga";
import { watcherFetchEquipmentTransfer } from "./EquipmentTransfer/EquipmentTransfer.saga";
import { watcherFetchAllEquipmentUsage } from "./EquipmentUsage/EquipmentUsage.saga";
import { watcherFetchAllEquipmentUsagePlans } from "./EquipmentUsagePlan/EquipmentUsagePlan.saga";
import { watcherFetchAllExpense } from "./Expense/Expense.saga";
import { watcherFetchAllInvoice } from "./Invoice/Invoice.saga";
import { watcherFetchAllLabour } from "./Labour/Labour.saga";
import { watcherFetchAllLabourPlans } from "./LabourPlan/LabourPlan.saga";
import { watcherFetchAllLabourUsage } from "./LabourUsage/LabourUsage.saga";
import { watcherFetchMaterials } from "./Material/Material.saga";
import {
  watcherFetchMaterialInventories,
  watcherFetchMaterialInventoriesAnalysis,
} from "./MaterialInventory/MaterialInventory.saga";
import { watcherFetchAllMaterialPlans } from "./MaterialPlan/MaterialPlan.saga";
import { watcherFetchMaterialTransfer } from "./MaterialTransfer/MaterialTransfer.saga";
import { watcherFetchAllPayment } from "./Payment/Payment.saga";
import { watcherFetchAllPaymentRetention } from "./PaymentRetention/PaymentRetention.saga";
import {
  watcherFetchAllEmployeeAccommodationPlan,
  watcherFetchOneEmployeeAccommodationPlan,
} from "./EmployeeAccommodationPlan/EmployeeAccommodationPlan.saga";

import {
  watcherFetchAllPreProjects,
  watcherFetchAllProjects,
  watcherFetchOnePreProjects,
  watcherFetchOneProjects,
  watcherFetchAllListProjects,
  watcherFetchAllProjectsDetail,
  watcherFetchAllProjectReport,
} from "./Project/Project.saga";
import { watcherFetchAllProjectStaffs } from "./ProjectStaff/ProjectStaff.saga";
import { watcherFetchAllRebar } from "./Rebar/Rebar.saga";
import { watcherFetchAllServices } from "./Service/Service.saga";
import {
  watcherFetchAllStaffs,
  watcherFetchOneStaffs,
  watcherFetchAllStaffDetails,
  watcherFetchAllStaffsAlongTermination,
} from "./Staff/Staff.saga";
import { watcherFetchAllStaffPlans } from "./StaffPlan/StaffPlan.saga";
import {
  watcherFetchAllSubContracts,
  watcherFetchOneSubContracts,
} from "./SubContract/SubContract.saga";
import { watcherFetchAllSubContractPlans } from "./SubContractPlan/SubContractPlan.saga";
import { watcherFetchSupplies } from "./Supplier/Supplier.saga";
import { watcherFetchAllTakeoffs } from "./Takeoff/Takeoff.saga";
import {
  watcherFetchAllUnitBreakdownsPost,
  watcherFetchAllUnitBreakdownsPre,
  watcherFetchOneUnitBreakdownsPre,
} from "./UnitBreakdown/UnitBreakdown.saga";
import { watcherFetchAllUsage } from "./Usage/Usage.saga";
import {
  watcherFetchAllVariation,
  watcherFetchBoqVariation,
  watcherFetchPIDVariation,
} from "./Variation/Variation.saga";
import { watcherFetchAllManpowerOvertime } from "./ManpowerOvertime/ManpowerOvertime.saga";
import { watcherFetchAllSchedules } from "./Schedule/Schedule.saga";
import { watcherFetchAllEmployeeExtras } from "./EmployeeExtra/EmployeeExtra.saga";
import {
  watcherFetchAllUser,
  watcherFetchOneUser,
  watcherFetchLoggedInUser,
} from "./User/User.saga";
import { watcherFetchAllAttendances } from "./Attendance/Attendance.saga";
import { watcherFetchAllEmployeeRequests } from "./EmployeeRequest/EmployeeRequest.saga";
import {
  watcherFetchAllMaterialRequisitions,
  watcherFetchOneMaterialRequisitions,
} from "./MaterialRequisition/MaterialRequisition.saga";
import {
  watcherFetchAllPurchaseRequisitions,
  watcherFetchOnePurchaseRequisitions,
} from "./PurchaseRequisition/PurchaseRequisition.saga";
import {
  watcherFetchAllPurchaseOrders,
  watcherFetchOnePurchaseOrders,
} from "./PurchaseOrder/PurchaseOrder.saga";
import { watcherFetchAllPurchaseBillings } from "./PurchaseBilling/PurchaseBilling.saga";
import { watcherFetchAllGoodReceived } from "./GoodReceived/GoodReceived.saga";
import { watcherFetchAllRenters } from "./Renter/Renter.saga";
import { watcherFetchAllGoodOut } from "./GoodOut/GoodOut.saga";
import { watcherFetchAllGoodReturn } from "./GoodReturn/GoodReturn.saga";
import { watcherFetchEmployeeHistory } from "./EmployeeHistory/EmployeeHistory.saga";
import { watcherFetchAllDocumentWorkOrders } from "./DocumentWorkOrder/DocumentWorkOrder.saga";
import { watcherFetchAllEquipmentWO } from "./EquipmentWO/EquipmentWO.saga";
import { watcherFetchAllPriceEscalations } from "./PriceEscalation/PriceEscalation.saga";
import { watcherFetchAllHRPolicy } from "./HRPolicy/HRPolicy.saga";
import { watcherFetchAllAbsences } from "./Absence/Absence.saga";
import { watcherFetchAllEmployeeDocuments } from "./EmployeeDocument/EmployeeDocument.saga";
import {
  watcherFetchAllLabourPayrolls,
  watcherFetchAllStaffPayrolls,
  watcherFetchAllPayrolls,
  watcherFetchAllPayrollReport,
  watcherFetchAllPayrollStaffHistory,
} from "./Payroll/Payroll.saga";
import { watcherFetchAllClients } from "./Client/Client.saga";
import {
  watcherFetchAllMeetings,
  watcherFetchOneMeetings,
} from "./Meeting/Meeting.saga";
import { watcherFetchAllAllowances } from "./Allowance/Allowance.saga";
import { watcherFetchAllAllowancePolicy } from "./AllowancePolicy/AllowancePolicy.saga";
import {
  watcherFetchAllStandard,
  watcherFetchOneStandard,
} from "./Standard/Standard.saga";
import { watcherFetchAllLog, watcherFetchOneLog } from "./Log/Log.saga";
import {
  watcherFetchAllBoqStandard,
  watcherFetchOneBoqStandard,
} from "./BoqStandard/BoqStandard.saga";
import {
  watcherFetchAllResources,
  watcherFetchOneResources,
} from "./Resource/Resource.sage";
import {
  watcherFetchAllPaymentCertificates,
  watcherFetchOnePaymentCertificates,
} from "./PaymentCertificate/PaymentCertificate.saga";
import {
  watcherFetchAllPaymentCertificateTakeoffs,
  watcherFetchOnePaymentCertificateTakeoffs,
} from "./PaymentCertificateTakoff/PaymentCertificateTakeoff.saga";
import {
  watcherFetchAllPaymentCertificateRebars,
  watcherFetchOnePaymentCertificateRebars,
} from "./PaymentCertificateRebar/PaymentCertificateRebar.saga";
import {
  watcherFetchAllPaymentCertificateRoads,
  watcherFetchOnePaymentCertificateRoads,
} from "./PaymentCertificateRoad/PaymentCertificateRoad.saga";
import {
  watcherFetchAllPaymentCertificateAggregates,
  watcherFetchOnePaymentCertificateAggregates,
} from "./PaymentCertificateAggregate/PaymentCertificateAggregate.saga";

import {
  watcherFetchAllInspectionForm,
  watcherFetchOneInspectionForm,
} from "./InspectionForm/InspectionForm.saga";
import {
  watcherFetchAllInspection,
  watcherFetchOneInspection,
} from "./Inspection/Inspection.saga";
import { watcherFetchAllCastings } from "./Casting/Casting.saga";
import {
  watcherFetchAllMaterialRequestApprovals,
  watcherFetchOneMaterialRequestApprovals,
} from "./MaterialRequestApproval/MaterialRequestApproval.saga";
import {
  watcherFetchAllTestResult,
  watcherFetchOneTestResult,
} from "./TestResult/TestResult.saga";
import {
  watcherFetchAllTestRequest,
  watcherFetchOneTestRequest,
} from "./TestRequest/TestRequest.saga";
import {
  watcherFetchAllSubmittals,
  watcherFetchOneSubmittals,
} from "./Submittal/Submittal.saga";
import { watcherFetchAllSiteDiary } from "./SiteDiary/SiteDiary.saga";
import { watcherFetchAllWeekReport } from "./WeekReport/WeekReport.saga";
import {
  watcherFetchAllQueries,
  watcherFetchOneQueries,
} from "./Query/Query.saga";
import {
  watcherFetchAllMasterSchedules,
  watcherFetchOneMasterSchedules,
} from "./MasterSchedule/MasterSchedule.saga";
import { watcherFetchAllRFIs } from "./RFI/RFI.saga";
import { watcherFetchAllReviewForm } from "./ReviewForm/ReviewForm.saga";
import {
  watcherFetchAllEmployeeReviews,
  watcherFetchOneEmployeeReviews,
} from "./EmployeeReview/EmployeeReview.saga";
import { watcherFetchAllBonuses } from "./Bonus/Bonus.saga";

import {
  watcherFetchAllVacancies,
  watcherFetchVacanciesByAttributes,
  watcherFetchVacanciesByJobId,
} from "./Vacancy/Vacancy.saga";
import { watcherFetchAllApplications } from "./Application/Application.saga";
import { watcherFetchAllMedias } from "./Media/Media.saga";
import { watcherFetchAllSHEs } from "./SHE/SHE.saga";

import { watcherFetchAllBankAccounts } from "./BankAccount/BankAccount.saga";
import {
  watcherFetchAllLetters,
  watcherFetchOneLetters,
} from "./Letter/Letter.saga";

import {
  watcherFetchAllAccountTransactions,
  watcherFetchAllAllAccountTransactions,
} from "./AccountTransaction/AccountTransaction.saga";
import {
  watcherFetchAllRentContracts,
  watcherFetchAllAllRentContracts,
} from "./RentContract/RentContract.saga";
import { watcherFetchAllRentContractProjects } from "./RentContractProject/RentContractProject.saga";

import { watcherFetchAllStoreRequisitions } from "./StoreRequisition/StoreRequisition.saga";
import { watcherFetchAllStoreRequisitionFollowUps } from "./StoreRequisitionFollowUp/StoreRequisitionFollowUp.saga";
import { watcherFetchAllAccidentFollowUps } from "./AccidentFollowUp/AccidentFollowUp.saga";

import {
  watcherFetchAllMaintenanceVouchers,
  watcherFetchMaintenanceVoucherNumber,
} from "./MaintenanceVoucher/MaintenanceVoucher.saga";
import { watcherFetchAllMaintenanceRequests } from "./MaintenanceRequest/MaintenanceRequest.saga";
import { watcherFetchAllServiceVouchers } from "./ServiceVoucher/ServiceVoucher.saga";

import { watcherFetchAllDailyStatusReports } from "./DailyStatusReport/DailyStatusReport.saga";

import { watcherFetchAllInsurances } from "./Insurance/Insurance.saga";

import {
  watcherFetchAllRiskLogs,
  watcherFetchOneRiskLogs,
} from "./RiskLog/RiskLog.saga";
import {
  watcherFetchAllStaffWorks,
  watcherFetchOneStaffWorks,
} from "./StaffWork/StaffWork.saga";
import {
  watcherFetchAllReceivables,
  watcherFetchOneReceivables,
} from "./Receivable/Receivable.saga";

import { watcherFetchAllAttachments } from "./Attachment/Attachment.saga";
import {
  watcherFetchAllPaymentFiles,
  watcherFetchOnePaymentFiles,
} from "./PaymentFile/PaymentFile.saga";

import {
  watcherFetchAllPettyCashes,
  watcherFetchOnePettyCashes,
} from "./PettyCash/PettyCash.saga";

import {
  watcherFetchAllReplenishments,
  watcherFetchOneReplenishments,
} from "./Replenishment/Replenishment.saga";

import {
  watcherFetchAllReplenishmentTransactions,
  watcherFetchOneReplenishmentTransactions,
  watcherFetchAllAllReplenishmentTransactions,
  watcherFetchPreviousReplenishmentTransactions,
} from "./ReplenishTransaction/ReplenishmentTransaction.saga";

import {
  watcherFetchAllReplenishmentRequests,
  watcherFetchOneReplenishmentRequests,
  watcherFetchRequestNumber,
} from "./ReplenishRequest/ReplenishmentRequest.saga";

import {
  watcherFetchAllTestEvaluations,
  watcherFetchOneTestEvaluations,
} from "./TestEvaluation/TestEvaluation.saga";

import {
  watcherFetchAllVariationFiles,
  watcherFetchOneVariationFiles,
} from "./VariationFile/VariationFile.saga";
import {
  watcherFetchAllPriceEscalationFiles,
  watcherFetchOnePriceEscalationFiles,
} from "./PriceEscalationFile/PriceEscalationFile.saga";

import {
  watcherFetchAllCRVPayments,
  watcherFetchOneCRVPayments,
} from "./CRVPayment/CRVPayment.saga";

import {
  watcherFetchAllPostChecks,
  watcherFetchOnePostChecks,
} from "./PostCheck/PostCheck.saga";
import {
  watcherFetchAllSiteBooks,
  watcherFetchOneSiteBooks,
} from "./SiteBook/SiteBook.saga";
import {
  watcherFetchAllMonthlyReports,
  watcherFetchOneMonthlyReports,
} from "./MonthlyReport/MonthlyReport.saga";
import { watcherFetchAllDailyReports } from "./DailyReport/DailyReport.saga";
import {
  watcherFetchAllMaterialUsages,
  watcherFetchOneMaterialUsages,
} from "./MaterialUsage/MaterialUsage.saga";
import { watcherFetchAllPayables } from "./Payable/Payable.saga";
import {
  watcherFetchAllMemos,
  watcherFetchCountMemos,
  watcherFetchOneMemos,
} from "./Memo/Memo.saga";
import { watcherFetchAllBuildings } from "./Building/Building.saga";
import { watcherFetchAllApartments } from "./Apartment/Apartment.saga";
import { watcherFetchAllApartmentExpenses } from "./ApartmentExpense/ApartmentExpense.saga";
import { watcherFetchAllContractSaless } from "./ContractSales/ContractSales.saga";
import { watcherFetchAllEstimatedBuildingCosts } from "./EstimatedBuildingCost/EstimatedBuildingCost.saga";
import {
  watcherFetchAllProformas,
  watcherFetchOneProformas,
} from "./Proforma/Proforma.saga";
import { watcherFetchAllCurrencies } from "./Currency/Currency.saga";
import {
  watcherFetchAllProcurementPlans,
  watcherFetchOneProcurementPlans,
} from "./ProcurementPlan/ProcurementPlan.saga";
import {
  watcherFetchAllWorkSchedules,
  watcherFetchOneWorkSchedules,
} from "./WorkSchedule/WorkSchedule.saga";

import {
  watcherFetchAllMonthWorkSchedules,
  watcherFetchOneMonthWorkSchedules,
} from "./MonthWorkSchedule/MonthWorkSchedule.saga";

import {
  watcherFetchAllEquipmentSchedules,
  watcherFetchOneEquipmentSchedules,
} from "./EquipmentSchedule/EquipmentSchedule.saga";

import {
  watcherFetchAllMaterialSchedules,
  watcherFetchOneMaterialSchedules,
} from "./MaterialSchedule/MaterialSchedule.saga";

import {
  watcherFetchAllManpowerSchedules,
  watcherFetchOneManpowerSchedules,
} from "./ManpowerSchedule/ManpowerSchedule.saga";

import {
  watcherFetchAllSubcontractorSchedules,
  watcherFetchOneSubcontractorSchedules,
} from "./SubcontractorSchedule/SubcontractorSchedule.saga";

import {
  watcherFetchAllActionPlanGenerals,
  watcherFetchOneActionPlanGenerals,
} from "./ActionPlanGeneral/ActionPlanGeneral.saga";

import {
  watcherFetchAllApprovalSchedules,
  watcherFetchOneApprovalSchedules,
} from "./ApprovalSchedule/ApprovalSchedule.saga";

import {
  watcherFetchAllPaymentSummarys,
  watcherFetchOnePaymentSummarys,
} from "./PaymentSummary/PaymentSummary.saga";

import {
  watcherFetchAllWeatherConditions,
  watcherFetchOneWeatherConditions,
} from "./WeatherCondition/WeatherCondition.saga";

import {
  watcherFetchAllDailySurveyings,
  watcherFetchOneDailySurveyings,
} from "./DailySurveying/DailySurveying.saga";

import {
  watcherFetchAllDailySurveyingReportings,
  watcherFetchOneDailySurveyingReportings,
} from "./DailySurveyingReporting/DailySurveyingReporting.saga";

import {
  watcherFetchAllSiteRAFReadings,
  watcherFetchOneSiteRAFReadings,
} from "./SiteRAFReading/SiteRAFReading.saga";

import {
  watcherFetchAllMonthMaterialSchedules,
  watcherFetchOneMonthMaterialSchedules,
} from "./MonthMaterialSchedule/MonthMaterialSchedule.saga";

import {
  watcherFetchAllMonthEquipmentSchedules,
  watcherFetchOneMonthEquipmentSchedules,
} from "./MonthEquipmentSchedule/MonthEquipmentSchedule.saga";

import {
  watcherFetchAllMonthManpowerSchedules,
  watcherFetchOneMonthManpowerSchedules,
} from "./MonthManpowerSchedule/MonthManpowerSchedule.saga";

import {
  watcherFetchAllMonthSubcontractorSchedules,
  watcherFetchOneMonthSubcontractorSchedules,
} from "./MonthSubcontractorSchedule/MonthSubcontractorSchedule.saga";

import {
  watcherFetchAllSubcontractRateApprovals,
  watcherFetchOneSubcontractRateApprovals,
} from "./SubcontractRateApproval/SubcontractRateApproval.saga";

import {
  watcherFetchAllTransferDocumentFormats,
  watcherFetchOneTransferDocumentFormats,
} from "./TransferDocumentFormat/TransferDocumentFormat.saga";

import {
  watcherFetchAllAssignmentRegistrys,
  watcherFetchOneAssignmentRegistrys,
} from "./AssignmentRegistry/AssignmentRegistry.saga";

import {
  watcherFetchAllEquipmentRequests,
  watcherFetchOneEquipmentRequests,
} from "./EquipmentRequest/EquipmentRequest.saga";

import {
  watcherFetchAllEquipmentAndPlantStatuss,
  watcherFetchOneEquipmentAndPlantStatuss,
} from "./EquipmentAndPlantStatus/EquipmentAndPlantStatus.saga";

import {
  watcherFetchAllEquipmentDemobiliations,
  watcherFetchOneEquipmentDemobiliations,
} from "./EquipmentDemobiliation/EquipmentDemobiliation.saga";

import {
  watcherFetchAllPeriodicalEquipments,
  watcherFetchOnePeriodicalEquipments,
} from "./PeriodicalEquipment/PeriodicalEquipment.saga";

import {
  watcherFetchAllEquipmentProductivitys,
  watcherFetchOneEquipmentProductivitys,
} from "./EquipmentProductivity/EquipmentProductivity.saga";

import {
  watcherFetchAllRiskMitigations,
  watcherFetchOneRiskMitigations,
} from "./RiskMitigation/RiskMitigation.saga";

import {
  watcherFetchAllJustifications,
  watcherFetchOneJustifications,
} from "./Justification/Justification.saga";

import {
  watcherFetchAllBriefings,
  watcherFetchOneBriefings,
} from "./Briefing/Briefing.saga";

import {
  watcherFetchAllCriticalIssues,
  watcherFetchOneCriticalIssues,
} from "./CriticalIssue/CriticalIssue.saga";

import {
  watcherFetchAllBudgetRequests,
  watcherFetchOneBudgetRequests,
} from "./BudgetRequest/BudgetRequest.saga";

import {
  watcherFetchAllBudgetShiftingRequests,
  watcherFetchOneBudgetShiftingRequests,
} from "./BudgetShiftingRequest/BudgetShiftingRequest.saga";

import {
  watcherFetchAllIncomeGenerateds,
  watcherFetchOneIncomeGenerateds,
} from "./IncomeGenerated/IncomeGenerated.saga";

import {
  watcherFetchAllBudgetUtilizations,
  watcherFetchOneBudgetUtilizations,
} from "./BudgetUtilization/BudgetUtilization.saga";

import {
  watcherFetchAllFuelRequests,
  watcherFetchOneFuelRequests,
} from "./FuelRequest/FuelRequest.saga";
import {
  watcherFetchAllFinanceDailyReports,
  watcherFetchFinanceDailyReports,
} from "./FinanceDailyReport/FinanceDailyReport.saga";

import {
  watcherFetchAllFuelUtilizations,
  watcherFetchOneFuelUtilizations,
} from "./FuelUtilization/FuelUtilization.saga";
import {
  watcherFetchAllWeeklySummaryReports,
  watcherFetchOneWeeklySummaryReports,
} from "./WeeklySummaryReport/WeeklySummaryReport.saga";

import {
  watcherFetchAllMaterialPurchaseFollowUps,
  watcherFetchOneMaterialPurchaseFollowUps,
} from "./MaterialPurchaseFollowUp/MaterialPurchaseFollowUp.saga";
import {
  watcherFetchAllProjectOperationReports,
  watcherFetchOneProjectOperationReports,
} from "./ProjectOperationReport/ProjectOperationReport.saga";

import {
  watcherFetchAllPriceOffers,
  watcherFetchOnePriceOffers,
} from "./PriceOffer/PriceOffer.saga";

import {
  watcherFetchAllSiteVisitReports,
  watcherFetchOneSiteVisitReports,
} from "./SiteVisitReport/SiteVisitReport.saga";

import {
  watcherFetchAllMaterialRequiredSummarys,
  watcherFetchOneMaterialRequiredSummarys,
} from "./MaterialRequiredSummary/MaterialRequiredSummary.saga";

import {
  watcherFetchAllMaterialUtilizationSummarys,
  watcherFetchOneMaterialUtilizationSummarys,
} from "./MaterialUtilizationSummary/MaterialUtilizationSummary.saga";

import {
  watcherFetchAllLabourUtilizations,
  watcherFetchOneLabourUtilizations,
} from "./LabourUtilization/LabourUtilization.saga";

import {
  watcherFetchAllLabourUtilizationSummarys,
  watcherFetchOneLabourUtilizationSummarys,
} from "./LabourUtilizationSummary/LabourUtilizationSummary.saga";

import {
  watcherFetchAllSiteBookOrders,
  watcherFetchOneSiteBookOrders,
} from "./SiteBookOrder/SiteBookOrder.saga";

import {
  watcherFetchAllPaymentFollowUps,
  watcherFetchOnePaymentFollowUps,
} from "./PaymentFollowUp/PaymentFollowUp.saga";

import { watcherFetchOneDailyActivitys } from "./DailyActivity/DailyActivity.saga";
import {
  watcherFetchOneMonthlySummaryReports,
  watcherFetchAllMonthlySummaryReports,
} from "./MonthlySummaryReport/MonthlySummaryReport.saga";
import {
  watcherFetchAllSupplyFixes,
  watcherFetchOneSupplyFixes,
} from "./SupplyFix/SupplyFix.saga";
import { watcherFetchAllSupplyFixContracts } from "./SupplyFixContract/SupplyFixContract.saga";
import { watcherFetchAllSupplyFixWorkProgress } from "./SupplyFixWorkProgress/SupplyFixWorkProgress.saga";
import {
  watcherFetchAllMonthlyProgressReports,
  watcherFetchOneMonthlyProgressReports,
} from "./MonthlyProgressReport/MonthlyProgressReport.saga";
import { watcherFetchAllInspectionRegistrys } from "./InspectionRegistry/InspectionRegistry.saga";
import {
  watcherFetchAllSubContractorPerformances,
  watcherFetchOneSubContractorPerformances,
} from "./SubContractorPerformance/SubContractorPerformance.saga";

import {
  watcherFetchAllSubcontractorsCommitments,
  watcherFetchOneSubcontractorsCommitments,
} from "./SubcontractorsCommitment/SubcontractorsCommitment.saga";

import {
  watcherFetchAllSubcontractorPaymentHandovers,
  watcherFetchOneSubcontractorPaymentHandovers,
} from "./SubcontractorPaymentHandover/SubcontractorPaymentHandover.saga";
import {
  watcherFetchAllStores,
  watcherFetchOneStores,
} from "./Store/Store.saga";
import { watcherFetchAllSupportLetters } from "./SupportLetter/SupportLetter.saga";
import {
  watcherFetchAllProformaComparisons,
  watcherFetchOneProformaComparisons,
} from "./ProformaComparison/ProformaComparison.saga";

import {
  watcherFetchAllSubContractHandOvers,
  watcherFetchOneSubContractHandOvers,
} from "./SubContractHandOver/SubContractHandOver.saga";
import { watcherFetchAllPriceComparisons } from "./PriceComparison/PriceComparison.saga";
import {
  watcherFetchAllSubContractPVRs,
  watcherFetchOneSubContractPVRs,
} from "./SubContractPVR/SubContractPVR.saga";
import {
  watcherFetchAllSubContractComparisons,
  watcherFetchOneSubContractComparisons,
} from "./SubContractComparison/SubContractComparison.saga";
import { watcherFetchAllDispatchSummarys } from "./DispatchSummary/DispatchSummary.saga";

import { watcherFetchAllSubContractorPayments } from "./SubContractorPayment/SubContractorPayment.saga";
import { watcherFetchAllWeeklyProgressSummarys } from "./WeeklyProgressSummary/WeeklyProgressSummary.saga";

import {
  watcherFetchAllCastingTestReports,
  watcherFetchOneCastingTestReports,
} from "./CastingTestReport/CastingTestReport.saga";
import { watcherFetchOneDailySummarys } from "./DailySummary/DailySummary.saga";
import { watcherFetchAllJustificationSummarys } from "./JustificationSummary/JustificationSummary.saga";
import { watcherFetchOnePlanSummarys } from "./PlanSummary/PlanSummary.saga";

import {
  watcherFetchAllRoadFuelRequests,
  watcherFetchOneRoadFuelRequests,
} from "./RoadFuelRequest/RoadFuelRequest.saga";

import {
  watcherFetchAllProjectIpcFollowUps,
  watcherFetchProjectIpcFollowUps,
} from "./ProjectIpcFollowUp/ProjectIpcFollowUp.saga";
import { watcherFetchAllUncollectedIpcs } from "./UncollectedIpc/UncollectedIpc.saga";
import {
  watcherFetchAllAllLabourUtilizationSummarys,
  watcherFetchOneAllLabourUtilizationSummarys,
} from "./AllLabourUtilizationSummary/AllLabourUtilizationSummary.saga";
import { watcherFetchAllProjectLists } from "./ProjectList/ProjectList.saga";

import {
  watcherFetchAllActionPlanExpenseSummarys,
  watcherFetchOneActionPlanExpenseSummarys,
} from "./ActionPlanExpenseSummary/ActionPlanExpenseSummary.saga";

import {
  watcherFetchAllActionPlanNarratives,
  watcherFetchOneActionPlanNarratives,
} from "./ActionPlanNarrative/ActionPlanNarrative.saga";

import {
  watcherFetchAllActionPlanSubcontractors,
  watcherFetchOneActionPlanSubcontractors,
} from "./ActionPlanSubcontractor/ActionPlanSubcontractor.saga";

import {
  watcherFetchAllActionPlanMaterials,
  watcherFetchOneActionPlanMaterials,
} from "./ActionPlanMaterial/ActionPlanMaterial.saga";
import { watcherFetchAllMasterLists } from "./MasterList/MasterList.saga";
import { watcherFetchAllFuelRequestVouchers } from "./FuelRequestVoucher/FuelRequestVoucher.saga";
import { watcherFetchAllFuelRecievingVouchers } from "./FuelRecievingVoucher/FuelRecievingVoucher.saga";
import { watcherFetchAllFuelIssueVouchers } from "./FuelIssueVoucher/FuelIssueVoucher.saga";
import {
  watcherFetchAllActionPlanEquipments,
  watcherFetchOneActionPlanEquipments,
} from "./ActionPlanEquipment/ActionPlanEquipment.saga";

import {
  watcherFetchAllPayableV2s,
  watcherFetchOnePayableV2s,
} from "./PayableV2/PayableV2.saga";
import {
  watcherFetchAllPayableV2Payments,
  watcherFetchOnePayableV2Payments,
} from "./PayableV2Payment/PayableV2Payment.saga";
import { watcherFetchAllBiddingSchedules } from "./BiddingSchedule/BiddingSchedule.saga";

import { watcherFetchAllTasks, watcherFetchOneTasks } from "./Task/Task.saga";
import {
  watcherFetchAllCheckLists,
  watcherFetchOneCheckLists,
} from "./CheckList/CheckList.saga";

import {
  watcherFetchAllEquipmentDailyOperationReports,
  watcherFetchOneEquipmentDailyOperationReports,
  watcherFetchPreviousEquipmentDailyOperationReports,
  watcherFetchGeneratedEquipmentDailyOperationReports,
} from "./EquipmentDailyOperationReport/EquipmentDailyOperationReport.saga";

import {
  watcherFetchAllEquipmentDailyDownIdleReports,
  watcherFetchOneEquipmentDailyDownIdleReports,
  watcherFetchPreviousEquipmentDailyDownIdleReports,
} from "./EquipmentDailyDownIdleReport/EquipmentDailyDownIdleReport.saga";

import {
  watcherFetchAllGeneralDailyOperationReports,
  watcherFetchGeneratedGeneralDailyOperationReports,
  watcherFetchOneGeneralDailyOperationReports,
  watcherFetchPreviousGeneralDailyOperationReports,
} from "./GeneralDailyOperationReport/GeneralDailyOperationReport.saga";

import {
  watcherFetchAllUploadDocuments,
  watcherFetchOneUploadDocuments,
  watcherFetchUploadDocumentReferenceNumber,
} from "./UploadDocument/UploadDocument.saga";

import {
  watcherFetchAllSharedDocuments,
  watcherFetchAllSharedDocumentUsers,
} from "./SharedDocument/SharedDocument.saga";

import { watcherFetchAllStaffBios } from "./StaffBio/StaffBio.saga";

import {
  watcherFetchAllLoans,
  watcherFetchLoanStatuses,
} from "./Loan/Loan.saga";
import {
  watcherFetchAllLoanExtensions,
  watcherFetchOneLoanExtensions,
} from "./LoanExtension/LoanExtension.saga";

import { watcherFetchAllTravelRegistry } from "./TravelRegistry/TravelRegistry.saga";

import { watcherFetchAllTeriMastawekias } from "./TeriMastawekia/TeriMastawekia.saga";
import { watcherFetchAllMaberetachas } from "./Maberetacha/Maberetacha.saga";
import { watcherFetchAllResignations } from "./Resignation/Resignation.saga";
import { watcherFetchAllWithHoldPayments } from "./WithHoldPayment/WithHoldPayment.saga";

import {
  watcherFetchAllStaffPenalties,
  watcherFetchOneStaffPenalties,
} from "./StaffPenalty/StaffPenalty.saga";

import {
  watcherFetchAllStaffMeals,
  watcherFetchOneStaffMeals,
} from "./StaffMeal/StaffMeal.saga";

import {
  watcherFetchAllFamilyAllotments,
  watcherFetchOneFamilyAllotments,
} from "./FamilyAllotment/FamilyAllotment.saga";

import {
  watcherFetchAllWorkExecuteds,
  watcherFetchOneWorkExecuteds,
} from "./WorkExecuted/WorkExecuted.saga";
import {
  watcherFetchAllWorkerTimeSheets,
  watcherFetchOneWorkerTimeSheets,
} from "./WorkerTimeSheet/WorkerTimeSheet.saga";
import { watcherFetchAllStaffAttendances } from "./StaffAttendance/StaffAttendance.saga";
import {
  watcherFetchAllDailyActivityLogs,
  watcherFetchOneDailyActivityLogs,
} from "./DailyActivityLog/DailyActivityLog.saga";

import {
  watcherFetchAllAdditionBoqs,
  watcherFetchOneAdditionBoqs
} from "./AdditionBoq/AdditionBoq.saga";

import { 
  watcherFetchAllOmissionBoqs,
  watcherFetchOneOmissionBoqs
} from "./OmissionBoq/OmissionBoq.saga";

import {
  watcherFetchAllSupplementaryBoqs,
  watcherFetchOneSupplementaryBoqs
} from "./SupplementaryBoq/SupplementaryBoq.saga";

import {
  watcherFetchAllMasterScheduleUpdateds,
  watcherFetchOneMasterScheduleUpdateds
} from "./MasterScheduleUpdated/MasterScheduleUpdated.saga";

import {
  watcherFetchAllProjectLetters,
  watcherFetchOneProjectLetters
} from "./ProjectLetter/ProjectLetter.saga";

import {
  watcherFetchAllProjectContracts,
  watcherFetchOneProjectContracts
} from "./ProjectContract/ProjectContract.saga";

import {
  watcherFetchAllSubContractRegistrys,
  watcherFetchOneSubContractRegistrys
} from "./SubContractRegistry/SubContractRegistry.saga";

import {
  watcherFetchAllSubContractPaymentUpdates,
  watcherFetchOneSubContractPaymentUpdates
} from "./SubContractPaymentUpdate/SubContractPaymentUpdate.saga";

import {
  watcherFetchAllSiteHandovers,
  watcherFetchOneSiteHandovers
} from "./SiteHandover/SiteHandover.saga";

import {
  watcherFetchAllBenchmarks,
  watcherFetchOneBenchmarks
} from "./Benchmark/Benchmark.saga";

import {
  watcherFetchAllProjectUploadDocuments,
  watcherFetchOneProjectUploadDocuments
} from "./ProjectUploadDocument/ProjectUploadDocument.saga";

import { watcherFetchAllInventories } from "./Inventory/Inventory.saga";

export default function* RootSaga() {
  yield all([
    /**
     * Project Sagas
     */
    call(watcherFetchAllProjects),
    call(watcherFetchOneProjects),
    call(watcherFetchAllListProjects),
    call(watcherFetchAllProjectsDetail),
    call(watcherFetchAllContractors),
    call(watcherFetchAllConsultants),
    call(watcherFetchAllTakeoffs),
    call(watcherFetchAllRebar),
    call(watcherFetchAllBoq),
    call(watcherFetchOneBoq),
    call(watcherFetchAllBoqPlans),
    call(watcherFetchAllBoqActivityPlans),
    call(watcherFetchAllSubContractPlans),
    call(watcherFetchMaterials),
    call(watcherFetchSupplies),
    call(watcherFetchMaterialInventories),
    call(watcherFetchMaterialTransfer),
    call(watcherFetchAllUsage),
    call(watcherFetchEquipmentInventories),
    call(watcherFetchEquipmentTransfer),
    call(watcherFetchAllEquipmentUsage),
    call(watcherFetchAllLabourUsage),
    call(watcherFetchAllLabour),
    call(watcherFetchAllEquipmentPlans),
    call(watcherFetchAllEquipmentUsagePlans),
    call(watcherFetchAllMaterialPlans),
    call(watcherFetchAllLabourPlans),
    call(watcherFetchDetailBoq),
    call(watcherFetchSubContractBoq),
    call(watcherFetchAllDepartments),
    call(watcherFetchAllStaffs),
    call(watcherFetchAllStaffDetails),
    call(watcherFetchAllStaffsAlongTermination),
    call(watcherFetchAllStaffPlans),
    call(watcherFetchAllAccounts),
    call(watcherFetchAllCustomers),
    call(watcherFetchAllServices),
    call(watcherFetchAllUnitBreakdownsPost),
    call(watcherFetchAllUnitBreakdownsPre),
    call(watcherFetchOneUnitBreakdownsPre),
    call(watcherFetchAllVariation),
    call(watcherFetchBoqVariation),
    call(watcherFetchAllSubContracts),
    call(watcherFetchOneSubContracts),
    call(watcherFetchAllExpense),
    call(watcherFetchAllPayment),
    call(watcherFetchAllInvoice),
    call(watcherFetchAllPaymentRetention),
    call(watcherFetchOneAccounts),
    call(watcherFetchAllDocuments),
    call(watcherFetchAllProjectStaffs),
    call(watcherFetchAllManpowerOvertime),
    call(watcherFetchAllStaffPayrolls),
    call(watcherFetchAllLabourPayrolls),
    call(watcherFetchAllSchedules),
    call(watcherFetchAllPayrolls),
    call(watcherFetchAllEmployeeExtras),
    call(watcherFetchAllStaffPayrolls),
    call(watcherFetchOneUser),
    call(watcherFetchAllAttendances),
    call(watcherFetchAllEmployeeRequests),
    call(watcherFetchAllMaterialRequisitions),
    call(watcherFetchAllPurchaseRequisitions),
    call(watcherFetchAllPurchaseOrders),
    call(watcherFetchOnePurchaseOrders),
    call(watcherFetchAllPurchaseBillings),
    call(watcherFetchAllGoodReceived),
    call(watcherFetchAllRenters),
    call(watcherFetchAllGoodOut),
    call(watcherFetchAllGoodReturn),
    call(watcherFetchEmployeeHistory),
    call(watcherFetchAllDocumentWorkOrders),
    call(watcherFetchAllEquipmentWO),
    call(watcherFetchAllPriceEscalations),
    call(watcherFetchAllHRPolicy),
    call(watcherFetchAllAbsences),
    call(watcherFetchOneStaffs),
    call(watcherFetchAllEmployeeDocuments),
    call(watcherFetchAllClients),
    call(watcherFetchAllAllowances),
    call(watcherFetchAllAllowancePolicy),
    call(watcherFetchAllPreProjects),
    call(watcherFetchOnePreProjects),
    call(watcherFetchAllStandard),
    call(watcherFetchOneStandard),
    call(watcherFetchAllUser),
    call(watcherFetchOneUser),
    call(watcherFetchAllLog),
    call(watcherFetchOneLog),
    call(watcherFetchAllMeetings),
    call(watcherFetchOneMeetings),
    call(watcherFetchAllEmployeeAccommodationPlan),
    call(watcherFetchOneEmployeeAccommodationPlan),
    call(watcherFetchAllBoqStandard),
    call(watcherFetchOneBoqStandard),
    call(watcherFetchAllResources),
    call(watcherFetchOneResources),
    call(watcherFetchAllPaymentCertificates),
    call(watcherFetchOnePaymentCertificates),
    call(watcherFetchPIDVariation),
    call(watcherFetchAllPaymentCertificateTakeoffs),
    call(watcherFetchOnePaymentCertificateTakeoffs),
    call(watcherFetchAllPaymentCertificateRebars),
    call(watcherFetchOnePaymentCertificateRebars),
    call(watcherFetchAllPaymentCertificateRoads),
    call(watcherFetchOnePaymentCertificateRoads),
    call(watcherFetchAllPaymentCertificateAggregates),
    call(watcherFetchOnePaymentCertificateAggregates),
    call(watcherFetchAllInspectionForm),
    call(watcherFetchOneInspectionForm),
    call(watcherFetchAllInspection),
    call(watcherFetchOneInspection),
    call(watcherFetchAllCastings),
    call(watcherFetchAllTestResult),
    call(watcherFetchOneTestResult),
    call(watcherFetchAllTestRequest),
    call(watcherFetchOneTestRequest),
    call(watcherFetchAllSubmittals),
    call(watcherFetchOneSubmittals),
    call(watcherFetchAllMaterialRequestApprovals),
    call(watcherFetchOneMaterialRequestApprovals),
    call(watcherFetchAllSiteDiary),
    call(watcherFetchAllWeekReport),
    call(watcherFetchAllQueries),
    call(watcherFetchOneQueries),
    call(watcherFetchAllMasterSchedules),
    call(watcherFetchOneMasterSchedules),
    call(watcherFetchAllRFIs),
    call(watcherFetchAllReviewForm),
    call(watcherFetchAllEmployeeReviews),
    call(watcherFetchOneEmployeeReviews),
    call(watcherFetchAllBonuses),
    call(watcherFetchAllVacancies),
    call(watcherFetchAllApplications),
    call(watcherFetchVacanciesByAttributes),
    call(watcherFetchVacanciesByJobId),
    call(watcherFetchAllPayrollReport),
    call(watcherFetchAllPayrollStaffHistory),
    call(watcherFetchAllMedias),
    call(watcherFetchAllSHEs),
    call(watcherFetchAllSharedDocuments),
    call(watcherFetchAllSharedDocumentUsers),
    call(watcherFetchAllBankAccounts),
    call(watcherFetchAllLetters),
    call(watcherFetchOneLetters),
    call(watcherFetchAllAccountTransactions),
    call(watcherFetchAllAllAccountTransactions),
    call(watcherFetchAllRentContracts),
    call(watcherFetchAllAllRentContracts),
    call(watcherFetchAllRentContractProjects),
    call(watcherFetchAllStoreRequisitions),
    call(watcherFetchAllStoreRequisitionFollowUps),
    call(watcherFetchAllAccidentFollowUps),
    call(watcherFetchAllMaintenanceRequests),
    call(watcherFetchAllMaintenanceVouchers),
    call(watcherFetchMaintenanceVoucherNumber),
    call(watcherFetchAllServiceVouchers),
    call(watcherFetchAllDailyStatusReports),
    call(watcherFetchAllInsurances),
    call(watcherFetchAllRiskLogs),
    call(watcherFetchOneRiskLogs),
    call(watcherFetchAllStaffWorks),
    call(watcherFetchOneStaffWorks),
    call(watcherFetchAllReceivables),
    call(watcherFetchOneReceivables),
    call(watcherFetchAllAttachments),
    call(watcherFetchMaterialInventoriesAnalysis),
    call(watcherFetchAllPettyCashes),
    call(watcherFetchOnePettyCashes),
    call(watcherFetchAllReplenishments),
    call(watcherFetchOneReplenishments),
    call(watcherFetchAllReplenishmentTransactions),
    call(watcherFetchOneReplenishmentTransactions),
    call(watcherFetchAllAllReplenishmentTransactions),
    call(watcherFetchPreviousReplenishmentTransactions),
    call(watcherFetchAllReplenishmentRequests),
    call(watcherFetchOneReplenishmentRequests),
    call(watcherFetchRequestNumber),
    call(watcherFetchAllTestEvaluations),
    call(watcherFetchOneTestEvaluations),
    call(watcherFetchAllPaymentFiles),
    call(watcherFetchOnePaymentFiles),
    call(watcherFetchAllVariationFiles),
    call(watcherFetchOneVariationFiles),
    call(watcherFetchAllPriceEscalationFiles),
    call(watcherFetchOnePriceEscalationFiles),
    call(watcherFetchAllCRVPayments),
    call(watcherFetchOneCRVPayments),
    call(watcherFetchAllPostChecks),
    call(watcherFetchOnePostChecks),
    call(watcherFetchAllSiteBooks),
    call(watcherFetchOneSiteBooks),
    call(watcherFetchAllMonthlyReports),
    call(watcherFetchOneMonthlyReports),
    call(watcherFetchAllDailyReports),
    call(watcherFetchAllMaterialUsages),
    call(watcherFetchOneMaterialUsages),
    call(watcherFetchAllPayables),
    call(watcherFetchAllMemos),
    call(watcherFetchOneMemos),
    call(watcherFetchCountMemos),
    call(watcherFetchAllBuildings),
    call(watcherFetchAllApartments),
    call(watcherFetchAllApartmentExpenses),
    call(watcherFetchAllContractSaless),
    call(watcherFetchAllEstimatedBuildingCosts),
    call(watcherFetchOneMaterialRequisitions),
    call(watcherFetchOnePurchaseRequisitions),
    call(watcherFetchAllProformas),
    call(watcherFetchOneProformas),
    call(watcherFetchLoggedInUser),
    call(watcherFetchAllCurrencies),
    call(watcherFetchAllProcurementPlans),
    call(watcherFetchOneProcurementPlans),
    call(watcherFetchAllWorkSchedules),
    call(watcherFetchOneWorkSchedules),
    call(watcherFetchAllMonthWorkSchedules),
    call(watcherFetchOneMonthWorkSchedules),
    call(watcherFetchAllEquipmentSchedules),
    call(watcherFetchOneEquipmentSchedules),
    call(watcherFetchAllMaterialSchedules),
    call(watcherFetchOneMaterialSchedules),
    call(watcherFetchAllManpowerSchedules),
    call(watcherFetchOneManpowerSchedules),
    call(watcherFetchAllSubcontractorSchedules),
    call(watcherFetchOneSubcontractorSchedules),
    call(watcherFetchOneActionPlanGenerals),
    call(watcherFetchAllActionPlanGenerals),
    call(watcherFetchAllApprovalSchedules),
    call(watcherFetchOneApprovalSchedules),
    call(watcherFetchAllPaymentSummarys),
    call(watcherFetchOnePaymentSummarys),
    call(watcherFetchAllWeatherConditions),
    call(watcherFetchOneWeatherConditions),
    call(watcherFetchAllDailySurveyings),
    call(watcherFetchOneDailySurveyings),
    call(watcherFetchAllDailySurveyingReportings),
    call(watcherFetchOneDailySurveyingReportings),
    call(watcherFetchAllSiteRAFReadings),
    call(watcherFetchOneSiteRAFReadings),
    call(watcherFetchAllMonthMaterialSchedules),
    call(watcherFetchOneMonthMaterialSchedules),
    call(watcherFetchAllMonthEquipmentSchedules),
    call(watcherFetchOneMonthEquipmentSchedules),
    call(watcherFetchAllMonthManpowerSchedules),
    call(watcherFetchOneMonthManpowerSchedules),
    call(watcherFetchAllMonthSubcontractorSchedules),
    call(watcherFetchOneMonthSubcontractorSchedules),
    call(watcherFetchAllSubcontractRateApprovals),
    call(watcherFetchOneSubcontractRateApprovals),
    call(watcherFetchAllTransferDocumentFormats),
    call(watcherFetchOneTransferDocumentFormats),
    call(watcherFetchAllAssignmentRegistrys),
    call(watcherFetchOneAssignmentRegistrys),
    call(watcherFetchAllEquipmentRequests),
    call(watcherFetchOneEquipmentRequests),
    call(watcherFetchAllEquipmentAndPlantStatuss),
    call(watcherFetchOneEquipmentAndPlantStatuss),
    call(watcherFetchAllEquipmentDemobiliations),
    call(watcherFetchOneEquipmentDemobiliations),
    call(watcherFetchAllPeriodicalEquipments),
    call(watcherFetchOnePeriodicalEquipments),
    call(watcherFetchAllEquipmentProductivitys),
    call(watcherFetchOneEquipmentProductivitys),
    call(watcherFetchAllRiskMitigations),
    call(watcherFetchOneRiskMitigations),
    call(watcherFetchAllJustifications),
    call(watcherFetchOneJustifications),
    call(watcherFetchAllBriefings),
    call(watcherFetchOneBriefings),
    call(watcherFetchAllCriticalIssues),
    call(watcherFetchOneCriticalIssues),
    call(watcherFetchAllBudgetRequests),
    call(watcherFetchOneBudgetRequests),
    call(watcherFetchAllBudgetShiftingRequests),
    call(watcherFetchOneBudgetShiftingRequests),
    call(watcherFetchAllIncomeGenerateds),
    call(watcherFetchOneIncomeGenerateds),
    call(watcherFetchAllBudgetUtilizations),
    call(watcherFetchOneBudgetUtilizations),
    call(watcherFetchAllFuelRequests),
    call(watcherFetchOneFuelRequests),
    call(watcherFetchAllFuelUtilizations),
    call(watcherFetchOneFuelUtilizations),
    call(watcherFetchAllFinanceDailyReports),
    call(watcherFetchAllMaterialPurchaseFollowUps),
    call(watcherFetchOneMaterialPurchaseFollowUps),
    call(watcherFetchAllWeeklySummaryReports),
    call(watcherFetchOneWeeklySummaryReports),
    call(watcherFetchAllPriceOffers),
    call(watcherFetchOnePriceOffers),
    call(watcherFetchAllSiteVisitReports),
    call(watcherFetchOneSiteVisitReports),
    call(watcherFetchOneDailyActivitys),
    call(watcherFetchOneMonthlySummaryReports),
    call(watcherFetchAllSupplyFixes),
    call(watcherFetchOneSupplyFixes),
    call(watcherFetchAllSupplyFixContracts),
    call(watcherFetchAllSupplyFixWorkProgress),
    call(watcherFetchAllMaterialRequiredSummarys),
    call(watcherFetchOneMaterialRequiredSummarys),
    call(watcherFetchAllMaterialUtilizationSummarys),
    call(watcherFetchOneMaterialUtilizationSummarys),
    call(watcherFetchAllProjectOperationReports),
    call(watcherFetchOneProjectOperationReports),
    call(watcherFetchAllLabourUtilizations),
    call(watcherFetchOneLabourUtilizations),
    call(watcherFetchAllLabourUtilizationSummarys),
    call(watcherFetchOneLabourUtilizationSummarys),
    call(watcherFetchAllSiteBookOrders),
    call(watcherFetchOneSiteBookOrders),
    call(watcherFetchAllPaymentFollowUps),
    call(watcherFetchOnePaymentFollowUps),
    call(watcherFetchAllMonthlySummaryReports),
    call(watcherFetchAllMonthlyProgressReports),
    call(watcherFetchOneMonthlyProgressReports),
    call(watcherFetchAllInspectionRegistrys),
    call(watcherFetchAllSubContractorPerformances),
    call(watcherFetchOneSubContractorPerformances),
    call(watcherFetchAllSubcontractorsCommitments),
    call(watcherFetchOneSubcontractorsCommitments),
    call(watcherFetchAllSubcontractorPaymentHandovers),
    call(watcherFetchOneSubcontractorPaymentHandovers),
    call(watcherFetchAllStores),
    call(watcherFetchOneStores),
    call(watcherFetchAllSupportLetters),
    call(watcherFetchAllProformaComparisons),
    call(watcherFetchOneProformaComparisons),
    call(watcherFetchAllSubContractHandOvers),
    call(watcherFetchOneSubContractHandOvers),
    call(watcherFetchAllPriceComparisons),
    call(watcherFetchAllSubContractPVRs),
    call(watcherFetchOneSubContractPVRs),
    call(watcherFetchAllDispatchSummarys),
    call(watcherFetchAllSubContractComparisons),
    call(watcherFetchOneSubContractComparisons),
    call(watcherFetchAllSubContractorPayments),
    call(watcherFetchAllWeeklyProgressSummarys),
    call(watcherFetchAllCastingTestReports),
    call(watcherFetchOneCastingTestReports),
    call(watcherFetchAllRoadFuelRequests),
    call(watcherFetchOneRoadFuelRequests),
    call(watcherFetchOneDailySummarys),
    call(watcherFetchFinanceDailyReports),
    call(watcherFetchAllAllLabourUtilizationSummarys),
    call(watcherFetchOneAllLabourUtilizationSummarys),
    call(watcherFetchAllJustificationSummarys),
    call(watcherFetchOnePlanSummarys),
    call(watcherFetchAllActionPlanExpenseSummarys),
    call(watcherFetchOneActionPlanExpenseSummarys),
    call(watcherFetchAllProjectIpcFollowUps),
    call(watcherFetchAllUncollectedIpcs),
    call(watcherFetchAllProjectReport),
    call(watcherFetchProjectIpcFollowUps),
    call(watcherFetchAllActionPlanNarratives),
    call(watcherFetchOneActionPlanNarratives),
    call(watcherFetchAllActionPlanSubcontractors),
    call(watcherFetchOneActionPlanSubcontractors),
    call(watcherFetchAllActionPlanMaterials),
    call(watcherFetchOneActionPlanMaterials),
    call(watcherFetchAllProjectLists),
    call(watcherFetchAllMasterLists),
    call(watcherFetchAllFuelRequestVouchers),
    call(watcherFetchAllFuelRecievingVouchers),
    call(watcherFetchAllFuelIssueVouchers),
    call(watcherFetchAllActionPlanEquipments),
    call(watcherFetchOneActionPlanEquipments),
    call(watcherFetchAllPayableV2s),
    call(watcherFetchOnePayableV2s),
    call(watcherFetchAllPayableV2Payments),
    call(watcherFetchOnePayableV2Payments),
    call(watcherFetchAllTasks),
    call(watcherFetchOneTasks),
    call(watcherFetchAllBiddingSchedules),
    call(watcherFetchAllCheckLists),
    call(watcherFetchOneCheckLists),
    call(watcherFetchAllEquipmentDailyOperationReports),
    call(watcherFetchOneEquipmentDailyOperationReports),
    call(watcherFetchPreviousEquipmentDailyOperationReports),
    call(watcherFetchGeneratedEquipmentDailyOperationReports),
    call(watcherFetchAllEquipmentDailyDownIdleReports),
    call(watcherFetchOneEquipmentDailyDownIdleReports),
    call(watcherFetchPreviousEquipmentDailyDownIdleReports),
    call(watcherFetchAllGeneralDailyOperationReports),
    call(watcherFetchGeneratedGeneralDailyOperationReports),
    call(watcherFetchOneGeneralDailyOperationReports),
    call(watcherFetchPreviousGeneralDailyOperationReports),
    call(watcherFetchAllUploadDocuments),
    call(watcherFetchOneUploadDocuments),
    call(watcherFetchUploadDocumentReferenceNumber),
    call(watcherFetchAllStaffBios),
    call(watcherFetchAllLoans),
    call(watcherFetchLoanStatuses),
    call(watcherFetchAllLoanExtensions),
    call(watcherFetchOneLoanExtensions),
    call(watcherFetchAllTravelRegistry),
    call(watcherFetchAllTeriMastawekias),
    call(watcherFetchAllMaberetachas),
    call(watcherFetchAllResignations),
    call(watcherFetchAllWithHoldPayments),
    call(watcherFetchAllStaffPenalties),
    call(watcherFetchOneStaffPenalties),
    call(watcherFetchAllStaffMeals),
    call(watcherFetchOneStaffMeals),
    call(watcherFetchAllFamilyAllotments),
    call(watcherFetchOneFamilyAllotments),
    call(watcherFetchAllWorkExecuteds),
    call(watcherFetchOneWorkExecuteds),
    call(watcherFetchAllWorkerTimeSheets),
    call(watcherFetchOneWorkerTimeSheets),
    call(watcherFetchAllStaffAttendances),
    call(watcherFetchAllDailyActivityLogs),
    call(watcherFetchOneDailyActivityLogs),
    call(watcherFetchAllAdditionBoqs),
    call(watcherFetchOneAdditionBoqs),
    call(watcherFetchAllOmissionBoqs),
    call(watcherFetchOneOmissionBoqs),
    call(watcherFetchAllSupplementaryBoqs),
    call(watcherFetchOneSupplementaryBoqs),
    call(watcherFetchAllMasterScheduleUpdateds),
    call(watcherFetchOneMasterScheduleUpdateds),
    call(watcherFetchAllProjectLetters),
    call(watcherFetchOneProjectLetters),
    call(watcherFetchAllProjectContracts),
    call(watcherFetchOneProjectContracts),
    call(watcherFetchAllSubContractRegistrys),
    call(watcherFetchOneSubContractRegistrys),
    call(watcherFetchAllSubContractPaymentUpdates),
    call(watcherFetchOneSubContractPaymentUpdates),
    call(watcherFetchAllSiteHandovers),
    call(watcherFetchOneSiteHandovers),
    call(watcherFetchAllBenchmarks),
    call(watcherFetchOneBenchmarks),
    call(watcherFetchAllProjectUploadDocuments),
    call(watcherFetchOneProjectUploadDocuments),
    call(watcherFetchAllInventories),
  ]);
}
