import { ProformaItem } from "../Proforma/Proforma.type";
import { ApiCallState } from "../Utils";

export type ProformaComparison = {
  id: number;
  no: number;
  purchase_requisition_id: number;
  date: string;
  proforma_comparison_items: ProformaComparisonItem[];
};

export type ProformaComparisonItem = {
  comparison_type: any;
  remark: string;
  expire_date: string;
  proforma_item: ProformaItem;
};

export type ProformaComparisonStateTypes = {
  fetchAll: ApiCallState<ProformaComparison[]>;
  fetchOne: ApiCallState<ProformaComparison | {}>;
};

export const ProformaComparisonActionTypes = {
  FETCH_ALL_PROFORMA_COMPARISON: "FETCH_ALL_PROFORMA_COMPARISON",
  FETCH_ALL_PROFORMA_COMPARISON_RESET: "FETCH_ALL_PROFORMA_COMPARISON_RESET",
  FETCH_ALL_PROFORMA_COMPARISON_FAILURE:
    "FETCH_ALL_PROFORMA_COMPARISON_FAILURE",
  FETCH_ALL_PROFORMA_COMPARISON_SUCCESS:
    "FETCH_ALL_PROFORMA_COMPARISON_SUCCESS",

  FETCH_ONE_PROFORMA_COMPARISON: "FETCH_ONE_PROFORMA_COMPARISON",
  FETCH_ONE_PROFORMA_COMPARISON_RESET: "FETCH_ONE_PROFORMA_COMPARISON_RESET",
  FETCH_ONE_PROFORMA_COMPARISON_FAILURE:
    "FETCH_ONE_PROFORMA_COMPARISON_FAILURE",
  FETCH_ONE_PROFORMA_COMPARISON_SUCCESS:
    "FETCH_ONE_PROFORMA_COMPARISON_SUCCESS",
};
