import { ApiCallState } from "../Utils";
import { Moment } from "moment";

export type TransferDocumentFormat= {
    project_id: number | null;
    id: number | null;
    date: Moment;
    file_transferd_by:string;
    file_received_by:string;
    status:string;
    is_received:boolean;
    transfer_document_details:TransferDocumentDetail[];
};

export type TransferDocumentDetail={
    transfer_document_id: number | null;
    id: number | null;
    description:string;
    soft_copy:boolean;
    hard_copy:boolean;
    pending_issues:string;
    remark:string;
}

export type TransferDocumentFormatActionType = {
    project_id: number;
};

export type TransferDocumentFormatOneActionType = {
  project_id: number;
  id:number;
}

export type TransferDocumentFormatStateTypes = {
  fetchAll: ApiCallState<TransferDocumentFormat[]>;
  fetchOne: ApiCallState<TransferDocumentFormat | {}>;
};

export const TransferDocumentFormatActionTypes = {
  FETCH_ALL_TRANSFER_DOCUMENT_FORMAT: "FETCH_ALL_TRANSFER_DOCUMENT_FORMAT",
  FETCH_ALL_TRANSFER_DOCUMENT_FORMAT_RESET: "FETCH_ALL_TRANSFER_DOCUMENT_FORMAT_RESET",
  FETCH_ALL_TRANSFER_DOCUMENT_FORMAT_FAILURE: "FETCH_ALL_TRANSFER_DOCUMENT_FORMAT_FAILURE",
  FETCH_ALL_TRANSFER_DOCUMENT_FORMAT_SUCCESS: "FETCH_ALL_TRANSFER_DOCUMENT_FORMAT_SUCCESS",

  FETCH_ONE_TRANSFER_DOCUMENT_FORMAT: "FETCH_ONE_TRANSFER_DOCUMENT_FORMAT",
  FETCH_ONE_TRANSFER_DOCUMENT_FORMAT_RESET: "FETCH_ONE_TRANSFER_DOCUMENT_FORMAT_RESET",
  FETCH_ONE_TRANSFER_DOCUMENT_FORMAT_FAILURE: "FETCH_ONE_TRANSFER_DOCUMENT_FORMAT_FAILURE",
  FETCH_ONE_TRANSFER_DOCUMENT_FORMAT_SUCCESS: "FETCH_ONE_TRANSFER_DOCUMENT_FORMAT_SUCCESS",
};
