import { ApiCallState } from "../Utils";

export type ProjectUploadDocument= {
  id: number;
  project_id: number;
  reference_number: string;
  is_public: boolean;
  type: string;
  remark: string;
  name: string;
  url: string;
  size: number;
  date: Date;
  user_id: number;
};

export type ProjectUploadDocumentStateTypes = {
  fetchAll: ApiCallState<ProjectUploadDocument[]>;
  fetchOne: ApiCallState<ProjectUploadDocument | {}>;
};

export const ProjectUploadDocumentActionTypes = {
  FETCH_ALL_PROJECT_UPLOAD_DOCUMENT: "FETCH_ALL_PROJECT_UPLOAD_DOCUMENT",
  FETCH_ALL_PROJECT_UPLOAD_DOCUMENT_RESET: "FETCH_ALL_PROJECT_UPLOAD_DOCUMENT_RESET",
  FETCH_ALL_PROJECT_UPLOAD_DOCUMENT_FAILURE: "FETCH_ALL_PROJECT_UPLOAD_DOCUMENT_FAILURE",
  FETCH_ALL_PROJECT_UPLOAD_DOCUMENT_SUCCESS: "FETCH_ALL_PROJECT_UPLOAD_DOCUMENT_SUCCESS",

  FETCH_ONE_PROJECT_UPLOAD_DOCUMENT: "FETCH_ONE_PROJECT_UPLOAD_DOCUMENT",
  FETCH_ONE_PROJECT_UPLOAD_DOCUMENT_RESET: "FETCH_ONE_PROJECT_UPLOAD_DOCUMENT_RESET",
  FETCH_ONE_PROJECT_UPLOAD_DOCUMENT_FAILURE: "FETCH_ONE_PROJECT_UPLOAD_DOCUMENT_FAILURE",
  FETCH_ONE_PROJECT_UPLOAD_DOCUMENT_SUCCESS: "FETCH_ONE_PROJECT_UPLOAD_DOCUMENT_SUCCESS",
};
