import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { PurchaseBillingActionTypes } from "./PurchaseBilling.type";

export function* fetchAllPurchaseBillings(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/purchase-billing?project_id=${action.payload?.project_id}`);
    yield put({
      type: PurchaseBillingActionTypes.FETCH_ALL_PURCHASE_BILLING_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PurchaseBillingActionTypes.FETCH_ALL_PURCHASE_BILLING_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllPurchaseBillings() {
  yield takeLatest(
    PurchaseBillingActionTypes.FETCH_ALL_PURCHASE_BILLING,
    fetchAllPurchaseBillings
  );
}
