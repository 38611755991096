import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { PlanSummaryActionTypes } from "./PlanSummary.type";

export function* fetchAllPlanSummarys(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/plan-summary`);
    yield put({
      type: PlanSummaryActionTypes.FETCH_ALL_PLAN_SUMMARY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PlanSummaryActionTypes.FETCH_ALL_PLAN_SUMMARY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOnePlanSummarys(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/plan-summary/one?date=${action.payload?.date}&project_id=${action.payload?.project_id}`
    );
    yield put({
      type: PlanSummaryActionTypes.FETCH_ONE_PLAN_SUMMARY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PlanSummaryActionTypes.FETCH_ONE_PLAN_SUMMARY_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllPlanSummarys() {
  yield takeLatest(PlanSummaryActionTypes.FETCH_ALL_PLAN_SUMMARY, fetchAllPlanSummarys);
}

export function* watcherFetchOnePlanSummarys() {
  yield takeLatest(PlanSummaryActionTypes.FETCH_ONE_PLAN_SUMMARY, fetchOnePlanSummarys);
}
