import { SiteBookOrderStateTypes, SiteBookOrderActionTypes } from "./SiteBookOrder.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: SiteBookOrderStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const SiteBookOrderReducer = (
  state: SiteBookOrderStateTypes = INITIAL_STATE,
  action: any
): SiteBookOrderStateTypes => {
  switch (action.type) {
    case SiteBookOrderActionTypes.FETCH_ALL_SITE_BOOK_ORDER:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case SiteBookOrderActionTypes.FETCH_ALL_SITE_BOOK_ORDER_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case SiteBookOrderActionTypes.FETCH_ALL_SITE_BOOK_ORDER_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case SiteBookOrderActionTypes.FETCH_ALL_SITE_BOOK_ORDER_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case SiteBookOrderActionTypes.FETCH_ONE_SITE_BOOK_ORDER:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case SiteBookOrderActionTypes.FETCH_ONE_SITE_BOOK_ORDER_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case SiteBookOrderActionTypes.FETCH_ONE_SITE_BOOK_ORDER_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case SiteBookOrderActionTypes.FETCH_ONE_SITE_BOOK_ORDER_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default SiteBookOrderReducer;
