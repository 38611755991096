import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { SubcontractorPaymentHandoverActionTypes } from "./SubcontractorPaymentHandover.type";

export function* fetchAllSubcontractorPaymentHandovers(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/subcontractor-payment-handover?project_id=${action.payload.project_id}`);
    yield put({
      type: SubcontractorPaymentHandoverActionTypes.FETCH_ALL_SUBCONTRACTOR_PAYMENT_HANDOVER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SubcontractorPaymentHandoverActionTypes.FETCH_ALL_SUBCONTRACTOR_PAYMENT_HANDOVER_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneSubcontractorPaymentHandovers(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/subcontractor-payment-handover/${action.payload}`
    );
    yield put({
      type: SubcontractorPaymentHandoverActionTypes.FETCH_ONE_SUBCONTRACTOR_PAYMENT_HANDOVER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SubcontractorPaymentHandoverActionTypes.FETCH_ONE_SUBCONTRACTOR_PAYMENT_HANDOVER_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllSubcontractorPaymentHandovers() {
  yield takeLatest(SubcontractorPaymentHandoverActionTypes.FETCH_ALL_SUBCONTRACTOR_PAYMENT_HANDOVER, fetchAllSubcontractorPaymentHandovers);
}

export function* watcherFetchOneSubcontractorPaymentHandovers() {
  yield takeLatest(SubcontractorPaymentHandoverActionTypes.FETCH_ONE_SUBCONTRACTOR_PAYMENT_HANDOVER, fetchOneSubcontractorPaymentHandovers);
}
