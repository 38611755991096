import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ReplenishmentRequestActionTypes } from "./ReplenishmentRequest.type";

export function* fetchAllReplenishmentRequests(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/replenishment-request`);
    yield put({
      type: ReplenishmentRequestActionTypes.FETCH_ALL_REPLENISHMENT_REQUEST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ReplenishmentRequestActionTypes.FETCH_ALL_REPLENISHMENT_REQUEST_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneReplenishmentRequests(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/replenishment-request/${action.payload}`
    );
    yield put({
      type: ReplenishmentRequestActionTypes.FETCH_ONE_REPLENISHMENT_REQUEST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ReplenishmentRequestActionTypes.FETCH_ONE_REPLENISHMENT_REQUEST_FAILURE,
      payload: error,
    });
  }
}

export function* fetchRequestNumber(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/replenishment-request/request-number`
    );
    yield put({
      type: ReplenishmentRequestActionTypes.FETCH_REPLENISHMENT_REQUEST_NUMBER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ReplenishmentRequestActionTypes.FETCH_REPLENISHMENT_REQUEST_NUMBER_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllReplenishmentRequests() {
  yield takeLatest(
    ReplenishmentRequestActionTypes.FETCH_ALL_REPLENISHMENT_REQUEST,
    fetchAllReplenishmentRequests
  );
}

export function* watcherFetchOneReplenishmentRequests() {
  yield takeLatest(
    ReplenishmentRequestActionTypes.FETCH_ONE_REPLENISHMENT_REQUEST,
    fetchOneReplenishmentRequests
  );
}
export function* watcherFetchRequestNumber() {
  yield takeLatest(
    ReplenishmentRequestActionTypes.FETCH_REPLENISHMENT_REQUEST_NUMBER,
    fetchRequestNumber
  );
}
