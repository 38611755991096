import { ApiCallState } from "../Utils";

export type StaffBio = {
  id: number;
  user_id: number;
  staff_id: number;
  department_id: string;

  first_name: string;
  middle_name: string;
  last_name: string;

  region: string;
  city: string;
  sub_city: string;
  house_number: string;

  telephone_number: string;
  phone_number: string;
  section: string;

  birth_date: string;
  marital_status: string;

  mother_full_name: string;
  brother_full_name: string;
  sister_full_name: string;
  spouse_full_name: string;
  uncle_full_name: string;
  aunt_full_name: string;

  nature_of_recruitment: string;
  job_description: string;
  recruitment_date: string;
  gross_wage: number;

  employee_bio_children: EmployeeBioChildren[];
  employee_bio_educations: EmployeeBioEducation[];
  employee_bio_experiences: EmployeeBioExperience[];
};

export type EmployeeBioChildren = {
  id: number;
  employee_bio_id: number;

  full_name: string;
  date_of_birth: string;
};

export type EmployeeBioEducation = {
  id: number;
  employee_bio_id: number;

  education_type: string;
  level: string;
  end_year: string;
};

export type EmployeeBioExperience = {
  id: number;
  employee_bio_id: number;

  post: string;
  start_date: string;
  end_date: string;
  institution: string;
};

export type StaffBioStateTypes = {
  fetchAll: ApiCallState<StaffBio[]>;
  fetchOne: ApiCallState<StaffBio | {}>;
};

export const StaffBioActionTypes = {
  FETCH_ALL_STAFF_BIO: "FETCH_ALL_STAFF_BIO",
  FETCH_ALL_STAFF_BIO_RESET: "FETCH_ALL_STAFF_BIO_RESET",
  FETCH_ALL_STAFF_BIO_FAILURE: "FETCH_ALL_STAFF_BIO_FAILURE",
  FETCH_ALL_STAFF_BIO_SUCCESS: "FETCH_ALL_STAFF_BIO_SUCCESS",

  FETCH_ONE_STAFF_BIO: "FETCH_ONE_STAFF_BIO",
  FETCH_ONE_STAFF_BIO_RESET: "FETCH_ONE_STAFF_BIO_RESET",
  FETCH_ONE_STAFF_BIO_FAILURE: "FETCH_ONE_STAFF_BIO_FAILURE",
  FETCH_ONE_STAFF_BIO_SUCCESS: "FETCH_ONE_STAFF_BIO_SUCCESS",
};
