import {
  WorkExecutedStateTypes,
  WorkExecutedActionTypes,
} from "./WorkExecuted.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: WorkExecutedStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const WorkExecutedReducer = (
  state: WorkExecutedStateTypes = INITIAL_STATE,
  action: any
): WorkExecutedStateTypes => {
  switch (action.type) {
    case WorkExecutedActionTypes.FETCH_ALL_WORK_EXECUTED:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case WorkExecutedActionTypes.FETCH_ALL_WORK_EXECUTED_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case WorkExecutedActionTypes.FETCH_ALL_WORK_EXECUTED_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case WorkExecutedActionTypes.FETCH_ALL_WORK_EXECUTED_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case WorkExecutedActionTypes.FETCH_ONE_WORK_EXECUTED:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case WorkExecutedActionTypes.FETCH_ONE_WORK_EXECUTED_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case WorkExecutedActionTypes.FETCH_ONE_WORK_EXECUTED_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case WorkExecutedActionTypes.FETCH_ONE_WORK_EXECUTED_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default WorkExecutedReducer;
