import { ApiCallState } from "../Utils";

export type PeriodicalEquipment= {
    project_id: number | null;
    id: number | null;
    date: string;
    reporting_period:string;
    periodical_no:string;
    total_functional:number;
    total_down:number;
    periodical_equipment_details:PeriodicalEquipmentDetail[];
};

export type PeriodicalEquipmentDetail ={
  id: number | null;
  periodical_equipment_id: number | null;
  equipment:string;
  plate_no:string;
  company:string;
  functional:boolean;
  down:boolean;
  reason:string;
  remark:boolean;
}

export type PeriodicalEquipmentStateTypes = {
  fetchAll: ApiCallState<PeriodicalEquipment[]>;
  fetchOne: ApiCallState<PeriodicalEquipment | {}>;
};

export type PeriodicalEquipmentActionType = {
    project_id: number;
    date: string;
};

export const PeriodicalEquipmentActionTypes = {
  FETCH_ALL_PERIODICAL_EQUIPMENT: "FETCH_ALL_PERIODICAL_EQUIPMENT",
  FETCH_ALL_PERIODICAL_EQUIPMENT_RESET: "FETCH_ALL_PERIODICAL_EQUIPMENT_RESET",
  FETCH_ALL_PERIODICAL_EQUIPMENT_FAILURE: "FETCH_ALL_PERIODICAL_EQUIPMENT_FAILURE",
  FETCH_ALL_PERIODICAL_EQUIPMENT_SUCCESS: "FETCH_ALL_PERIODICAL_EQUIPMENT_SUCCESS",

  FETCH_ONE_PERIODICAL_EQUIPMENT: "FETCH_ONE_PERIODICAL_EQUIPMENT",
  FETCH_ONE_PERIODICAL_EQUIPMENT_RESET: "FETCH_ONE_PERIODICAL_EQUIPMENT_RESET",
  FETCH_ONE_PERIODICAL_EQUIPMENT_FAILURE: "FETCH_ONE_PERIODICAL_EQUIPMENT_FAILURE",
  FETCH_ONE_PERIODICAL_EQUIPMENT_SUCCESS: "FETCH_ONE_PERIODICAL_EQUIPMENT_SUCCESS",
};
