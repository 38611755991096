import { MaterialPurchaseFollowUpStateTypes, MaterialPurchaseFollowUpActionTypes } from "./MaterialPurchaseFollowUp.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: MaterialPurchaseFollowUpStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const MaterialPurchaseFollowUpReducer = (
  state: MaterialPurchaseFollowUpStateTypes = INITIAL_STATE,
  action: any
): MaterialPurchaseFollowUpStateTypes => {
  switch (action.type) {
    case MaterialPurchaseFollowUpActionTypes.FETCH_ALL_MATERIAL_PURCHASE_FOLLOW_UP:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case MaterialPurchaseFollowUpActionTypes.FETCH_ALL_MATERIAL_PURCHASE_FOLLOW_UP_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case MaterialPurchaseFollowUpActionTypes.FETCH_ALL_MATERIAL_PURCHASE_FOLLOW_UP_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MaterialPurchaseFollowUpActionTypes.FETCH_ALL_MATERIAL_PURCHASE_FOLLOW_UP_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case MaterialPurchaseFollowUpActionTypes.FETCH_ONE_MATERIAL_PURCHASE_FOLLOW_UP:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case MaterialPurchaseFollowUpActionTypes.FETCH_ONE_MATERIAL_PURCHASE_FOLLOW_UP_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case MaterialPurchaseFollowUpActionTypes.FETCH_ONE_MATERIAL_PURCHASE_FOLLOW_UP_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MaterialPurchaseFollowUpActionTypes.FETCH_ONE_MATERIAL_PURCHASE_FOLLOW_UP_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default MaterialPurchaseFollowUpReducer;
