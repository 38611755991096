import { Moment } from "moment";
import { ApiCallState } from "../Utils";

export type MaterialUtilizationSummary= {
    id: number | null;
    project_id:number | null;
    date:Moment;
    utilized_no:string;
    material_utilization_summary_details:MaterialUtilizationSummaryDetail[];
    material_utilization_check_details:MaterialUtilizationCheckDetail[];
};

export type MaterialUtilizationSummaryDetail ={
    id: number | null;
    material_required_summary_id:number | null;
    item_no:string;
    description:string;
    unit:string;
    quantity:number;
    cement_required:number;
    sand_required:number;
    aggregate_required:number;
    stone_required:number;
    hcp:number;
    brick:number;
    remark:string;
}

export type MaterialUtilizationCheckDetail ={
    id: number | null;
    material_required_summary_id:number | null;
    description:string;
    unit:string;
    delivered_quantity:number;
    consumed_quantity:number;
    stock_balance:number;
    difference:number;
    remark:string;
}

export type MaterialUtilizationSummaryStateTypes = {
  fetchAll: ApiCallState<MaterialUtilizationSummary[]>;
  fetchOne: ApiCallState<MaterialUtilizationSummary | {}>;
};

export const MaterialUtilizationSummaryActionTypes = {
  FETCH_ALL_MATERIAL_UTILIZATION_SUMMARY: "FETCH_ALL_MATERIAL_UTILIZATION_SUMMARY",
  FETCH_ALL_MATERIAL_UTILIZATION_SUMMARY_RESET: "FETCH_ALL_MATERIAL_UTILIZATION_SUMMARY_RESET",
  FETCH_ALL_MATERIAL_UTILIZATION_SUMMARY_FAILURE: "FETCH_ALL_MATERIAL_UTILIZATION_SUMMARY_FAILURE",
  FETCH_ALL_MATERIAL_UTILIZATION_SUMMARY_SUCCESS: "FETCH_ALL_MATERIAL_UTILIZATION_SUMMARY_SUCCESS",

  FETCH_ONE_MATERIAL_UTILIZATION_SUMMARY: "FETCH_ONE_MATERIAL_UTILIZATION_SUMMARY",
  FETCH_ONE_MATERIAL_UTILIZATION_SUMMARY_RESET: "FETCH_ONE_MATERIAL_UTILIZATION_SUMMARY_RESET",
  FETCH_ONE_MATERIAL_UTILIZATION_SUMMARY_FAILURE: "FETCH_ONE_MATERIAL_UTILIZATION_SUMMARY_FAILURE",
  FETCH_ONE_MATERIAL_UTILIZATION_SUMMARY_SUCCESS: "FETCH_ONE_MATERIAL_UTILIZATION_SUMMARY_SUCCESS",
};
