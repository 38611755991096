import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";
import { API_BASE_URI } from "../ApiCall";
import { GeneralDailyOperationReportActionTypes } from "./GeneralDailyOperationReport.type";

export function* fetchAllGeneralDailyOperationReports(action: any): any {
  try {
    let query = "";

    if (Object.keys(action.payload).length > 0) {
      query = Object.keys(action.payload)
        .map((key) => key + "=" + action.payload[key])
        .join("&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/general-daily-operation-report?${query}`
    );
    yield put({
      type: GeneralDailyOperationReportActionTypes.FETCH_ALL_GENERAL_DAILY_OPERATION_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: GeneralDailyOperationReportActionTypes.FETCH_ALL_GENERAL_DAILY_OPERATION_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneGeneralDailyOperationReports(action: any): any {
  try {
    let query = "";

    if (Object.keys(action.payload).length > 0) {
      query = Object.keys(action.payload)
        .map((key) => key + "=" + action.payload[key])
        .join("&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/general-daily-operation-report/one?${query}`
    );
    yield put({
      type: GeneralDailyOperationReportActionTypes.FETCH_ONE_GENERAL_DAILY_OPERATION_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: GeneralDailyOperationReportActionTypes.FETCH_ONE_GENERAL_DAILY_OPERATION_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchPreviousGeneralDailyOperationReports(action: any): any {
  try {
    let query = "";

    if (Object.keys(action.payload).length > 0) {
      query = Object.keys(action.payload)
        .map((key) => key + "=" + action.payload[key])
        .join("&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/general-daily-operation-report/previous?${query}`
    );
    yield put({
      type: GeneralDailyOperationReportActionTypes.FETCH_PREVIOUS_GENERAL_DAILY_OPERATION_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: GeneralDailyOperationReportActionTypes.FETCH_PREVIOUS_GENERAL_DAILY_OPERATION_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchGeneratedGeneralDailyOperationReports(action: any): any {
  try {
    let query = "";

    if (Object.keys(action.payload).length > 0) {
      query = Object.keys(action.payload)
        .map((key) => key + "=" + action.payload[key])
        .join("&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/general-daily-operation-report/report?${query}`
    );
    yield put({
      type: GeneralDailyOperationReportActionTypes.FETCH_GENERATED_GENERAL_DAILY_OPERATION_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: GeneralDailyOperationReportActionTypes.FETCH_GENERATED_GENERAL_DAILY_OPERATION_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllGeneralDailyOperationReports() {
  yield takeLatest(
    GeneralDailyOperationReportActionTypes.FETCH_ALL_GENERAL_DAILY_OPERATION_REPORT,
    fetchAllGeneralDailyOperationReports
  );
}

export function* watcherFetchOneGeneralDailyOperationReports() {
  yield takeLatest(
    GeneralDailyOperationReportActionTypes.FETCH_ONE_GENERAL_DAILY_OPERATION_REPORT,
    fetchOneGeneralDailyOperationReports
  );
}

export function* watcherFetchPreviousGeneralDailyOperationReports() {
  yield takeLatest(
    GeneralDailyOperationReportActionTypes.FETCH_PREVIOUS_GENERAL_DAILY_OPERATION_REPORT,
    fetchPreviousGeneralDailyOperationReports
  );
}

export function* watcherFetchGeneratedGeneralDailyOperationReports() {
  yield takeLatest(
    GeneralDailyOperationReportActionTypes.FETCH_GENERATED_GENERAL_DAILY_OPERATION_REPORT,
    fetchGeneratedGeneralDailyOperationReports
  );
}
