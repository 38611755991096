import { ApiCallState } from "../Utils";
import { Document } from "../Document/Document.type";

export type ProjectLetter= {
  id: number;
  project_id: number;
  date: string;
  subject: string;
  type: string;
  reference_number: string;
  from: string;
  to: string;
  user_id: number;
  document: Document;
  share_project_letters: ShareProjectLetterTypes[];
  project_letter_remarks: ProjectLetterRemark[];
  users: {
    id: number;
    user_project_letter: {
      id: number;
      user_id: number;
      letter_id: number;
      last_seen: string;
    };
  }[];
};

export type ProjectLetterRemark = {
    id: number | null;
    project_letter_id: number | null;
    date: string;
    remarked_by: number;
    remark: string;
    createdAt: string;
    document: Document;
  };
  
  export type ShareProjectLetterTypes = {
    id: number;
    project_letter_id: number;
    user_id: number;
    remark: string;
  };

export type ProjectLetterStateTypes = {
  fetchAll: ApiCallState<ProjectLetter[]>;
  fetchOne: ApiCallState<ProjectLetter | {}>;
};

export const ProjectLetterActionTypes = {
  FETCH_ALL_PROJECT_LETTER: "FETCH_ALL_PROJECT_LETTER",
  FETCH_ALL_PROJECT_LETTER_RESET: "FETCH_ALL_PROJECT_LETTER_RESET",
  FETCH_ALL_PROJECT_LETTER_FAILURE: "FETCH_ALL_PROJECT_LETTER_FAILURE",
  FETCH_ALL_PROJECT_LETTER_SUCCESS: "FETCH_ALL_PROJECT_LETTER_SUCCESS",

  FETCH_ONE_PROJECT_LETTER: "FETCH_ONE_PROJECT_LETTER",
  FETCH_ONE_PROJECT_LETTER_RESET: "FETCH_ONE_PROJECT_LETTER_RESET",
  FETCH_ONE_PROJECT_LETTER_FAILURE: "FETCH_ONE_PROJECT_LETTER_FAILURE",
  FETCH_ONE_PROJECT_LETTER_SUCCESS: "FETCH_ONE_PROJECT_LETTER_SUCCESS",
};
