import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { CRVPaymentActionTypes } from "./CRVPayment.type";

export function* fetchAllCRVPayments(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }

    const response = yield axios.get(`${API_BASE_URI}/crv_payment?${query}`);
    yield put({
      type: CRVPaymentActionTypes.FETCH_ALL_CRV_PAYMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: CRVPaymentActionTypes.FETCH_ALL_CRV_PAYMENT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneCRVPayments(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/crv_payment/${action.payload}`
    );
    yield put({
      type: CRVPaymentActionTypes.FETCH_ONE_CRV_PAYMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: CRVPaymentActionTypes.FETCH_ONE_CRV_PAYMENT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllCRVPayments() {
  yield takeLatest(
    CRVPaymentActionTypes.FETCH_ALL_CRV_PAYMENT,
    fetchAllCRVPayments
  );
}

export function* watcherFetchOneCRVPayments() {
  yield takeLatest(
    CRVPaymentActionTypes.FETCH_ONE_CRV_PAYMENT,
    fetchOneCRVPayments
  );
}
