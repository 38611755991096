import { ApiCallState } from "../Utils";

export type MonthlySummaryReport= {
  id: number;
  date: string;
  monthly_summary_claims: MonthlySummaryClaim[],
  monthly_summary_variations: MonthlySummaryVariation[],
  monthly_summary_executed_works: MonthlySummaryExecutedWorks[],
  monthly_summary_general_notes: MonthlySummaryGeneralNotes[],
  monthly_summary_problem_encountereds: MonthlySummaryProblemEncounters[],
  monthly_summary_work_forecasts: MonthlySummaryWorkForecast[],
  monthly_summary_sub_contracts: MonthlySummarySubContract[],
  monthly_summary_productions: MonthlySummaryProduction[],
  monthly_summary_expense_sub_contracts: MonthlySummaryExpenseSubContract[],
  monthly_summary_expense_materials: MonthlySummaryExpenseMaterial[],
  monthly_summary_expense_indirects: MonthlySummaryExpenseIndirect[],
  monthly_summary_expense_equipments: MonthlySummaryExpenseEquipment[],
  monthly_summary_expense_direct_labours: MonthlySummaryExpenseDirectLabour[],
  monthly_summary_man_powers: MonthlySummaryManPower[]
};

export type MonthlySummaryManPower = {
  id?: number;
  description: string;
  monthly_summary_man_power_items: MonthlySummaryManPowerItems[],
  isEdited?: boolean;
  isDeleted?:boolean;
}

export type MonthlySummaryManPowerItems = {
  id?:number;
  month: number;
  quantity: number;
  isEdited?: boolean;
  isDeleted?:boolean;
}

export type MonthlySummaryExpenseDirectLabour= {
  id?: number;
  description: string;
  expense: number;
  remark: string;
  isEdited?: boolean;
  isDeleted?:boolean;
}

export type MonthlySummaryExpenseEquipment= {
  id?: number;
  description: string;
  expense: number;
  remark: string;
  isEdited?: boolean;
  isDeleted?:boolean;
}

export type MonthlySummaryExpenseIndirect= {
  id?: number;
  description: string;
  expense: number;
  remark: string;
  isEdited?: boolean;
  isDeleted?:boolean;
}

export type MonthlySummaryExpenseMaterial = {
  id?: number;
  description: string;
  expense: number;
  remark: string;
  isEdited?: boolean;
  isDeleted?:boolean;
}

export type MonthlySummaryExpenseSubContract = {
  id?: number;
  description: string;
  expense: number;
  remark: string;
  isEdited?: boolean;
  isDeleted?:boolean;
}

export type MonthlySummaryProduction = {
  id?: number;
  type:string;
  unit:string;
  demand:number;
  rate:number;
  produced:number;
  consumed:number;
  remaining_amount: number;
  remark: string;
  isEdited?: boolean;
  isDeleted?:boolean;
}

export type MonthlySummarySubContract = {
  id?: number;
  item_no:string;
  description: string;
  sub_contractor: string;
  unit: string;
  quantity: number;
  rate: number;
  amount: number;
  remark: string;
  isEdited?: boolean;
  isDeleted?:boolean;
}

export type MonthlySummaryWorkForecast = {
  id?: number;
  description: string;
  unit: string;
  unit_rate: number;
  total_contract_amount: number;
  planned_next_month: number;
  remark: string;
  isEdited?: boolean;
  isDeleted?:boolean;
}

export type MonthlySummaryProblemEncounters = {
    id?: number;
    problem_encountered: string;
    accountable_for_problem: string;
    problem_impact_on_progress: string;
    proposed_solution: string;
    isEdited?: boolean;
  isDeleted?:boolean;
}

export type MonthlySummaryGeneralNotes=  {
    id?: number;
    description: string;
    isEdited?: boolean;
  isDeleted?:boolean;
}

export type MonthlySummaryExecutedWorks = {
    id?: number;
    description: string;
    unit_rate: number;
    previous_quantity: number;
    previous_amount: number;
    to_date_quantity: number;
    current_planned_quantity: number;
    current_executed_quantity: number;
    current_planned_amount: number;
    current_executed_amount: number;
    percent_execution: number;
    to_date_amount: number;
    isEdited?: boolean;
  isDeleted?:boolean;
}

export type MonthlySummaryVariation = {
    id?: number;
    description: string;
    ordered_date: string;
    amount_approved: number;
    amount_not_approved: number;
    time_extension: number;
    remark: string;
    isEdited?: boolean;
  isDeleted?:boolean;
}

export type MonthlySummaryClaim = {
    id?: number;
    description: string;
    time_extension_requested: number;
    time_extension_approved: number;
    financial_requested: number;
    financial_approved: number;
    remark: string;
    isEdited?: boolean;
  isDeleted?:boolean;
}

export type MonthlySummaryReportStateTypes = {
  fetchAll: ApiCallState<MonthlySummaryReport[]>;
  fetchOne: ApiCallState<MonthlySummaryReport | {}>;
};

export const MonthlySummaryReportActionTypes = {
  FETCH_ALL_MONTHLY_SUMMARY_REPORT: "FETCH_ALL_MONTHLY_SUMMARY_REPORT",
  FETCH_ALL_MONTHLY_SUMMARY_REPORT_RESET: "FETCH_ALL_MONTHLY_SUMMARY_REPORT_RESET",
  FETCH_ALL_MONTHLY_SUMMARY_REPORT_FAILURE: "FETCH_ALL_MONTHLY_SUMMARY_REPORT_FAILURE",
  FETCH_ALL_MONTHLY_SUMMARY_REPORT_SUCCESS: "FETCH_ALL_MONTHLY_SUMMARY_REPORT_SUCCESS",

  FETCH_ONE_MONTHLY_SUMMARY_REPORT: "FETCH_ONE_MONTHLY_SUMMARY_REPORT",
  FETCH_ONE_MONTHLY_SUMMARY_REPORT_RESET: "FETCH_ONE_MONTHLY_SUMMARY_REPORT_RESET",
  FETCH_ONE_MONTHLY_SUMMARY_REPORT_FAILURE: "FETCH_ONE_MONTHLY_SUMMARY_REPORT_FAILURE",
  FETCH_ONE_MONTHLY_SUMMARY_REPORT_SUCCESS: "FETCH_ONE_MONTHLY_SUMMARY_REPORT_SUCCESS",
};
