import {
  ReplenishmentTransactionStateTypes,
  ReplenishmentTransactionActionTypes,
} from "./ReplenishmentTransaction.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: ReplenishmentTransactionStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchAllAll: resetApiCallState([]),
  fetchPrevious: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const ReplenishTransactionReducer = (
  state: ReplenishmentTransactionStateTypes = INITIAL_STATE,
  action: any
): ReplenishmentTransactionStateTypes => {
  switch (action.type) {
    case ReplenishmentTransactionActionTypes.FETCH_ALL_REPLENISHMENT_TRANSACTION:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case ReplenishmentTransactionActionTypes.FETCH_ALL_REPLENISHMENT_TRANSACTION_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ReplenishmentTransactionActionTypes.FETCH_ALL_REPLENISHMENT_TRANSACTION_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ReplenishmentTransactionActionTypes.FETCH_ALL_REPLENISHMENT_TRANSACTION_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case ReplenishmentTransactionActionTypes.FETCH_PREVIOUS_REPLENISHMENT_TRANSACTION:
      return {
        ...state,
        fetchPrevious: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case ReplenishmentTransactionActionTypes.FETCH_PREVIOUS_REPLENISHMENT_TRANSACTION_RESET:
      return {
        ...state,
        fetchPrevious: resetApiCallState([]),
      };
    case ReplenishmentTransactionActionTypes.FETCH_PREVIOUS_REPLENISHMENT_TRANSACTION_FAILURE:
      return {
        ...state,
        fetchPrevious: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ReplenishmentTransactionActionTypes.FETCH_PREVIOUS_REPLENISHMENT_TRANSACTION_SUCCESS:
      return {
        ...state,
        fetchPrevious: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case ReplenishmentTransactionActionTypes.FETCH_ALL_ALL_REPLENISHMENT_TRANSACTION:
      return {
        ...state,
        fetchAllAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case ReplenishmentTransactionActionTypes.FETCH_ALL_ALL_REPLENISHMENT_TRANSACTION_RESET:
      return {
        ...state,
        fetchAllAll: resetApiCallState([]),
      };
    case ReplenishmentTransactionActionTypes.FETCH_ALL_ALL_REPLENISHMENT_TRANSACTION_FAILURE:
      return {
        ...state,
        fetchAllAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ReplenishmentTransactionActionTypes.FETCH_ALL_ALL_REPLENISHMENT_TRANSACTION_SUCCESS:
      return {
        ...state,
        fetchAllAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case ReplenishmentTransactionActionTypes.FETCH_ONE_REPLENISHMENT_TRANSACTION:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case ReplenishmentTransactionActionTypes.FETCH_ONE_REPLENISHMENT_TRANSACTION_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ReplenishmentTransactionActionTypes.FETCH_ONE_REPLENISHMENT_TRANSACTION_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ReplenishmentTransactionActionTypes.FETCH_ONE_REPLENISHMENT_TRANSACTION_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default ReplenishTransactionReducer;
