import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { RentContractProjectActionTypes } from "./RentContractProject.type";

export function* fetchAllRentContractProjects(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/contract-project/`);
    yield put({
      type: RentContractProjectActionTypes.FETCH_ALL_RENT_CONTRACT_PROJECT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: RentContractProjectActionTypes.FETCH_ALL_RENT_CONTRACT_PROJECT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneRentContractProjects(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/contract-project/${action.payload}`
    );
    yield put({
      type: RentContractProjectActionTypes.FETCH_ONE_RENT_CONTRACT_PROJECT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: RentContractProjectActionTypes.FETCH_ONE_RENT_CONTRACT_PROJECT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllRentContractProjects() {
  yield takeLatest(
    RentContractProjectActionTypes.FETCH_ALL_RENT_CONTRACT_PROJECT,
    fetchAllRentContractProjects
  );
}

export function* watcherFetchOneRentContractProjects() {
  yield takeLatest(
    RentContractProjectActionTypes.FETCH_ONE_RENT_CONTRACT_PROJECT,
    fetchOneRentContractProjects
  );
}
