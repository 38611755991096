import { ActionPlanExpenseSummaryStateTypes, ActionPlanExpenseSummaryActionTypes } from "./ActionPlanExpenseSummary.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: ActionPlanExpenseSummaryStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const ActionPlanExpenseSummaryReducer = (
  state: ActionPlanExpenseSummaryStateTypes = INITIAL_STATE,
  action: any
): ActionPlanExpenseSummaryStateTypes => {
  switch (action.type) {
    case ActionPlanExpenseSummaryActionTypes.FETCH_ALL_ACTION_PLAN_EXPENSE_SUMMARY:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case ActionPlanExpenseSummaryActionTypes.FETCH_ALL_ACTION_PLAN_EXPENSE_SUMMARY_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ActionPlanExpenseSummaryActionTypes.FETCH_ALL_ACTION_PLAN_EXPENSE_SUMMARY_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ActionPlanExpenseSummaryActionTypes.FETCH_ALL_ACTION_PLAN_EXPENSE_SUMMARY_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case ActionPlanExpenseSummaryActionTypes.FETCH_ONE_ACTION_PLAN_EXPENSE_SUMMARY:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case ActionPlanExpenseSummaryActionTypes.FETCH_ONE_ACTION_PLAN_EXPENSE_SUMMARY_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ActionPlanExpenseSummaryActionTypes.FETCH_ONE_ACTION_PLAN_EXPENSE_SUMMARY_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ActionPlanExpenseSummaryActionTypes.FETCH_ONE_ACTION_PLAN_EXPENSE_SUMMARY_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default ActionPlanExpenseSummaryReducer;
