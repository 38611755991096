import { ApiCallState } from "../Utils";
import { Moment } from "moment";

export type EquipmentDemobiliation= {
    id: number | null;
    project_id: number | null;
    date:Moment;
    report_no:string;
    to:string;
    from:string;
    note:string;
    reported_by:string;
    approved_by:string;
    is_approved:boolean;
    equipment_demobiliation_details:EquipmentDemobiliationDetail[];
};
export type EquipmentDemobiliationDetail= {
    id: number | null;
    equipment_demobiliation_id:number | null;
    type_of_equipment:string;
    reason:string;
    reporting_date:Moment;
    demobiliation_date:Moment;
    request_for_replacement:string;
}

export type EquipmentDemobiliationStateTypes = {
  fetchAll: ApiCallState<EquipmentDemobiliation[]>;
  fetchOne: ApiCallState<EquipmentDemobiliation | {}>;
};

export const EquipmentDemobiliationActionTypes = {
  FETCH_ALL_EQUIPMENT_DEMOBILIATION: "FETCH_ALL_EQUIPMENT_DEMOBILIATION",
  FETCH_ALL_EQUIPMENT_DEMOBILIATION_RESET: "FETCH_ALL_EQUIPMENT_DEMOBILIATION_RESET",
  FETCH_ALL_EQUIPMENT_DEMOBILIATION_FAILURE: "FETCH_ALL_EQUIPMENT_DEMOBILIATION_FAILURE",
  FETCH_ALL_EQUIPMENT_DEMOBILIATION_SUCCESS: "FETCH_ALL_EQUIPMENT_DEMOBILIATION_SUCCESS",

  FETCH_ONE_EQUIPMENT_DEMOBILIATION: "FETCH_ONE_EQUIPMENT_DEMOBILIATION",
  FETCH_ONE_EQUIPMENT_DEMOBILIATION_RESET: "FETCH_ONE_EQUIPMENT_DEMOBILIATION_RESET",
  FETCH_ONE_EQUIPMENT_DEMOBILIATION_FAILURE: "FETCH_ONE_EQUIPMENT_DEMOBILIATION_FAILURE",
  FETCH_ONE_EQUIPMENT_DEMOBILIATION_SUCCESS: "FETCH_ONE_EQUIPMENT_DEMOBILIATION_SUCCESS",
};
