import { ApiCallState } from "../Utils";
import { Moment } from "moment";

export type ApprovalSchedule= {
    project_id: number | null;
    id: number | null;
    date:Moment;
    approval_no:string;
    approval_schedule_details:ApprovalScheduleDetail[];
};

export type ApprovalScheduleDetail= {
  id: number | null;
  approval_schedule_id: number | null;
  material_description:string;
  unit:string;
  quantity:number;
  material_submission_date:Moment;
  status:string;
  remark:string;
  category:string;
}

export type ApprovalScheduleStateTypes = {
  fetchAll: ApiCallState<ApprovalSchedule[]>;
  fetchOne: ApiCallState<ApprovalSchedule | {}>;
};

export type ApprovalSchedulActionType = {
    project_id: number;
};

export const ApprovalScheduleActionTypes = {
  FETCH_ALL_APPROVAL_SCHEDULE: "FETCH_ALL_APPROVAL_SCHEDULE",
  FETCH_ALL_APPROVAL_SCHEDULE_RESET: "FETCH_ALL_APPROVAL_SCHEDULE_RESET",
  FETCH_ALL_APPROVAL_SCHEDULE_FAILURE: "FETCH_ALL_APPROVAL_SCHEDULE_FAILURE",
  FETCH_ALL_APPROVAL_SCHEDULE_SUCCESS: "FETCH_ALL_APPROVAL_SCHEDULE_SUCCESS",

  FETCH_ONE_APPROVAL_SCHEDULE: "FETCH_ONE_APPROVAL_SCHEDULE",
  FETCH_ONE_APPROVAL_SCHEDULE_RESET: "FETCH_ONE_APPROVAL_SCHEDULE_RESET",
  FETCH_ONE_APPROVAL_SCHEDULE_FAILURE: "FETCH_ONE_APPROVAL_SCHEDULE_FAILURE",
  FETCH_ONE_APPROVAL_SCHEDULE_SUCCESS: "FETCH_ONE_APPROVAL_SCHEDULE_SUCCESS",
};
