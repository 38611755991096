import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { DailyActivityLogActionTypes } from "./DailyActivityLog.type";

export function* fetchAllDailyActivityLogs(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/daily-activity-log?project_id=${action.payload.project_id}&date=${action.payload.date}`
    );
    yield put({
      type: DailyActivityLogActionTypes.FETCH_ALL_DAILY_ACTIVITY_LOG_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DailyActivityLogActionTypes.FETCH_ALL_DAILY_ACTIVITY_LOG_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneDailyActivityLogs(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/daily-activity-log/${action.payload}`
    );
    yield put({
      type: DailyActivityLogActionTypes.FETCH_ONE_DAILY_ACTIVITY_LOG_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DailyActivityLogActionTypes.FETCH_ONE_DAILY_ACTIVITY_LOG_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllDailyActivityLogs() {
  yield takeLatest(
    DailyActivityLogActionTypes.FETCH_ALL_DAILY_ACTIVITY_LOG,
    fetchAllDailyActivityLogs
  );
}

export function* watcherFetchOneDailyActivityLogs() {
  yield takeLatest(
    DailyActivityLogActionTypes.FETCH_ONE_DAILY_ACTIVITY_LOG,
    fetchOneDailyActivityLogs
  );
}
