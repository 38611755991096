import { ApiCallState } from "../Utils";
import { Moment } from "moment";

export type SiteVisitReport= {
    id: number | null;
    project_id:number | null;
    date:Moment;
    report:string;
    prepared_by:string;
    url: string;
    size: number;
};

export type SiteVisitReportStateTypes = {
  fetchAll: ApiCallState<SiteVisitReport[]>;
  fetchOne: ApiCallState<SiteVisitReport | {}>;
};

export const SiteVisitReportActionTypes = {
  FETCH_ALL_SITE_VISIT_REPORT: "FETCH_ALL_SITE_VISIT_REPORT",
  FETCH_ALL_SITE_VISIT_REPORT_RESET: "FETCH_ALL_SITE_VISIT_REPORT_RESET",
  FETCH_ALL_SITE_VISIT_REPORT_FAILURE: "FETCH_ALL_SITE_VISIT_REPORT_FAILURE",
  FETCH_ALL_SITE_VISIT_REPORT_SUCCESS: "FETCH_ALL_SITE_VISIT_REPORT_SUCCESS",

  FETCH_ONE_SITE_VISIT_REPORT: "FETCH_ONE_SITE_VISIT_REPORT",
  FETCH_ONE_SITE_VISIT_REPORT_RESET: "FETCH_ONE_SITE_VISIT_REPORT_RESET",
  FETCH_ONE_SITE_VISIT_REPORT_FAILURE: "FETCH_ONE_SITE_VISIT_REPORT_FAILURE",
  FETCH_ONE_SITE_VISIT_REPORT_SUCCESS: "FETCH_ONE_SITE_VISIT_REPORT_SUCCESS",
};
