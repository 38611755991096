import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { PeriodicalEquipmentActionTypes } from "./PeriodicalEquipment.type";

export function* fetchAllPeriodicalEquipments(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/periodical-equipment?project_id=${action.payload.project_id}`);
    yield put({
      type: PeriodicalEquipmentActionTypes.FETCH_ALL_PERIODICAL_EQUIPMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PeriodicalEquipmentActionTypes.FETCH_ALL_PERIODICAL_EQUIPMENT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOnePeriodicalEquipments(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/periodical-equipment/${action.payload}`
    );
    yield put({
      type: PeriodicalEquipmentActionTypes.FETCH_ONE_PERIODICAL_EQUIPMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PeriodicalEquipmentActionTypes.FETCH_ONE_PERIODICAL_EQUIPMENT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllPeriodicalEquipments() {
  yield takeLatest(PeriodicalEquipmentActionTypes.FETCH_ALL_PERIODICAL_EQUIPMENT, fetchAllPeriodicalEquipments);
}

export function* watcherFetchOnePeriodicalEquipments() {
  yield takeLatest(PeriodicalEquipmentActionTypes.FETCH_ONE_PERIODICAL_EQUIPMENT, fetchOnePeriodicalEquipments);
}
