import axios from "axios";
import moment from "moment";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { SubcontractorScheduleActionTypes } from "./SubcontractorSchedule.type";

export function* fetchAllSubcontractorSchedules(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/subcontractor-schedule?project_id=${action.payload.project_id}&date=${moment(action.payload?.date).endOf("week").format("DD-MM-YYYY")}`);
    yield put({
      type: SubcontractorScheduleActionTypes.FETCH_ALL_SUBCONTRACTOR_SCHEDULE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SubcontractorScheduleActionTypes.FETCH_ALL_SUBCONTRACTOR_SCHEDULE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneSubcontractorSchedules(action: any): any {
  try {
    const response = yield axios.get(
      `API_BASE_URI/${action.payload}`
    );
    yield put({
      type: SubcontractorScheduleActionTypes.FETCH_ONE_SUBCONTRACTOR_SCHEDULE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SubcontractorScheduleActionTypes.FETCH_ONE_SUBCONTRACTOR_SCHEDULE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllSubcontractorSchedules() {
  yield takeLatest(SubcontractorScheduleActionTypes.FETCH_ALL_SUBCONTRACTOR_SCHEDULE, fetchAllSubcontractorSchedules);
}

export function* watcherFetchOneSubcontractorSchedules() {
  yield takeLatest(SubcontractorScheduleActionTypes.FETCH_ONE_SUBCONTRACTOR_SCHEDULE, fetchOneSubcontractorSchedules);
}
