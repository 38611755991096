import {
  TeriMastawekiaStateTypes,
  TeriMastawekiaActionTypes,
} from "./TeriMastawekia.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: TeriMastawekiaStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const TeriMastawekiaReducer = (
  state: TeriMastawekiaStateTypes = INITIAL_STATE,
  action: any
): TeriMastawekiaStateTypes => {
  switch (action.type) {
    case TeriMastawekiaActionTypes.FETCH_ALL_TERI_MASTAWEKIA:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case TeriMastawekiaActionTypes.FETCH_ALL_TERI_MASTAWEKIA_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case TeriMastawekiaActionTypes.FETCH_ALL_TERI_MASTAWEKIA_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case TeriMastawekiaActionTypes.FETCH_ALL_TERI_MASTAWEKIA_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case TeriMastawekiaActionTypes.FETCH_ONE_TERI_MASTAWEKIA:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case TeriMastawekiaActionTypes.FETCH_ONE_TERI_MASTAWEKIA_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case TeriMastawekiaActionTypes.FETCH_ONE_TERI_MASTAWEKIA_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case TeriMastawekiaActionTypes.FETCH_ONE_TERI_MASTAWEKIA_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default TeriMastawekiaReducer;
