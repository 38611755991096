import { DailyActivityStateTypes, DailyActivityActionTypes } from "./DailyActivity.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: DailyActivityStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const DailyActivityReducer = (
  state: DailyActivityStateTypes = INITIAL_STATE,
  action: any
): DailyActivityStateTypes => {
  switch (action.type) {
    case DailyActivityActionTypes.FETCH_ALL_DAILY_ACTIVITY:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case DailyActivityActionTypes.FETCH_ALL_DAILY_ACTIVITY_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case DailyActivityActionTypes.FETCH_ALL_DAILY_ACTIVITY_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case DailyActivityActionTypes.FETCH_ALL_DAILY_ACTIVITY_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case DailyActivityActionTypes.FETCH_ONE_DAILY_ACTIVITY:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case DailyActivityActionTypes.FETCH_ONE_DAILY_ACTIVITY_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case DailyActivityActionTypes.FETCH_ONE_DAILY_ACTIVITY_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case DailyActivityActionTypes.FETCH_ONE_DAILY_ACTIVITY_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default DailyActivityReducer;
