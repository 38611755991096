import { ApiCallState } from "../Utils";

export type ActionPlanMaterial= {
  id: number | null;
  material_id:number;
  date:string;
  remark:string;
};

export type ActionPlanMaterialStateTypes = {
  fetchAll: ApiCallState<ActionPlanMaterial[]>;
  fetchOne: ApiCallState<ActionPlanMaterial | {}>;
};

export const ActionPlanMaterialActionTypes = {
  FETCH_ALL_ACTION_PLAN_MATERIAL: "FETCH_ALL_ACTION_PLAN_MATERIAL",
  FETCH_ALL_ACTION_PLAN_MATERIAL_RESET: "FETCH_ALL_ACTION_PLAN_MATERIAL_RESET",
  FETCH_ALL_ACTION_PLAN_MATERIAL_FAILURE: "FETCH_ALL_ACTION_PLAN_MATERIAL_FAILURE",
  FETCH_ALL_ACTION_PLAN_MATERIAL_SUCCESS: "FETCH_ALL_ACTION_PLAN_MATERIAL_SUCCESS",

  FETCH_ONE_ACTION_PLAN_MATERIAL: "FETCH_ONE_ACTION_PLAN_MATERIAL",
  FETCH_ONE_ACTION_PLAN_MATERIAL_RESET: "FETCH_ONE_ACTION_PLAN_MATERIAL_RESET",
  FETCH_ONE_ACTION_PLAN_MATERIAL_FAILURE: "FETCH_ONE_ACTION_PLAN_MATERIAL_FAILURE",
  FETCH_ONE_ACTION_PLAN_MATERIAL_SUCCESS: "FETCH_ONE_ACTION_PLAN_MATERIAL_SUCCESS",
};
