import {
  SubContractPVRStateTypes,
  SubContractPVRActionTypes,
} from "./SubContractPVR.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: SubContractPVRStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const SubContractPVRReducer = (
  state: SubContractPVRStateTypes = INITIAL_STATE,
  action: any
): SubContractPVRStateTypes => {
  switch (action.type) {
    case SubContractPVRActionTypes.FETCH_ALL_SUB_CONTRACT_PVR:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case SubContractPVRActionTypes.FETCH_ALL_SUB_CONTRACT_PVR_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case SubContractPVRActionTypes.FETCH_ALL_SUB_CONTRACT_PVR_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case SubContractPVRActionTypes.FETCH_ALL_SUB_CONTRACT_PVR_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case SubContractPVRActionTypes.FETCH_ONE_SUB_CONTRACT_PVR:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case SubContractPVRActionTypes.FETCH_ONE_SUB_CONTRACT_PVR_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case SubContractPVRActionTypes.FETCH_ONE_SUB_CONTRACT_PVR_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case SubContractPVRActionTypes.FETCH_ONE_SUB_CONTRACT_PVR_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default SubContractPVRReducer;
