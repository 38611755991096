import { ApiCallState } from "../Utils";

export type ProjectIpcFollowUp= {
  id?: number;
  date: string;
  type_of_payment: string;
  payment_no: number;
  contract_amount: number;
  to_date_net_ipc: number;
  to_date_gross_executed: number;
  ipc_percentage_accomplished: number;
  net_ipc_amount: number;
  gross_ipc_amount: number;
  status: string;
  to_Date_total_certified_payment: number;
  remaining_amount_from_contract: number;
  advance_repaid: number;
  advance_to_be_repaid: number;
  total_retention: number;
  project_id: number
};

export type ProjectIpcFollowUpStateTypes = {
  fetchAll: ApiCallState<ProjectIpcFollowUp[]>;
  fetchAllDates: ApiCallState<ProjectIpcFollowUp[]>;
};

export const ProjectIpcFollowUpActionTypes = {
  FETCH_ALL_PROJECT_IPC_FOLLOW_UP: "FETCH_ALL_PROJECT_IPC_FOLLOW_UP",
  FETCH_ALL_PROJECT_IPC_FOLLOW_UP_RESET: "FETCH_ALL_PROJECT_IPC_FOLLOW_UP_RESET",
  FETCH_ALL_PROJECT_IPC_FOLLOW_UP_FAILURE: "FETCH_ALL_PROJECT_IPC_FOLLOW_UP_FAILURE",
  FETCH_ALL_PROJECT_IPC_FOLLOW_UP_SUCCESS: "FETCH_ALL_PROJECT_IPC_FOLLOW_UP_SUCCESS",

  FETCH_PROJECT_IPC_FOLLOW_UP: "FETCH_PROJECT_IPC_FOLLOW_UP",
  FETCH_PROJECT_IPC_FOLLOW_UP_RESET: "FETCH_PROJECT_IPC_FOLLOW_UP_RESET",
  FETCH_PROJECT_IPC_FOLLOW_UP_FAILURE: "FETCH_PROJECT_IPC_FOLLOW_UP_FAILURE",
  FETCH_PROJECT_IPC_FOLLOW_UP_SUCCESS: "FETCH_PROJECT_IPC_FOLLOW_UP_SUCCESS",
};
