import { ApiCallState } from "../Utils";

export type SubContractorPayment= {
  id: number;
  date: string;
  is_approved: boolean;
  is_checked: boolean;
  is_certified: boolean;
  approved_by_id: number;
  checked_by_id: number;
  certified_by_id: number;
  prepared_by_id: number;
  sub_contractor_payment_items: SubContractorPaymentItems[]
};

export type SubContractorPaymentItems = {
  id: number;
  net_payment: number;
  vat15: number;
  tot2: number;
  tot10: number;
  sub_contractor_name: string;
  type_of_work: string;
}

export type SubContractorPaymentStateTypes = {
  fetchAll: ApiCallState<SubContractorPayment[]>;
  fetchOne: ApiCallState<SubContractorPayment | {}>;
};

export const SubContractorPaymentActionTypes = {
  FETCH_ALL_SUB_CONTRACTOR_PAYMENT: "FETCH_ALL_SUB_CONTRACTOR_PAYMENT",
  FETCH_ALL_SUB_CONTRACTOR_PAYMENT_RESET: "FETCH_ALL_SUB_CONTRACTOR_PAYMENT_RESET",
  FETCH_ALL_SUB_CONTRACTOR_PAYMENT_FAILURE: "FETCH_ALL_SUB_CONTRACTOR_PAYMENT_FAILURE",
  FETCH_ALL_SUB_CONTRACTOR_PAYMENT_SUCCESS: "FETCH_ALL_SUB_CONTRACTOR_PAYMENT_SUCCESS",

  FETCH_ONE_SUB_CONTRACTOR_PAYMENT: "FETCH_ONE_SUB_CONTRACTOR_PAYMENT",
  FETCH_ONE_SUB_CONTRACTOR_PAYMENT_RESET: "FETCH_ONE_SUB_CONTRACTOR_PAYMENT_RESET",
  FETCH_ONE_SUB_CONTRACTOR_PAYMENT_FAILURE: "FETCH_ONE_SUB_CONTRACTOR_PAYMENT_FAILURE",
  FETCH_ONE_SUB_CONTRACTOR_PAYMENT_SUCCESS: "FETCH_ONE_SUB_CONTRACTOR_PAYMENT_SUCCESS",
};
