import { ApiCallState } from "../Utils";

export type ActionPlanEquipment= {
  id: number | null;
  material_id:number;
  project_id:number;
  date:string;
  rental:number;
  owned:number;
  remark:number;
};

export type ActionPlanEquipmentStateTypes = {
  fetchAll: ApiCallState<ActionPlanEquipment[]>;
  fetchOne: ApiCallState<ActionPlanEquipment | {}>;
};

export const ActionPlanEquipmentActionTypes = {
  FETCH_ALL_ACTION_PLAN_EQUIPMENT: "FETCH_ALL_ACTION_PLAN_EQUIPMENT",
  FETCH_ALL_ACTION_PLAN_EQUIPMENT_RESET: "FETCH_ALL_ACTION_PLAN_EQUIPMENT_RESET",
  FETCH_ALL_ACTION_PLAN_EQUIPMENT_FAILURE: "FETCH_ALL_ACTION_PLAN_EQUIPMENT_FAILURE",
  FETCH_ALL_ACTION_PLAN_EQUIPMENT_SUCCESS: "FETCH_ALL_ACTION_PLAN_EQUIPMENT_SUCCESS",

  FETCH_ONE_ACTION_PLAN_EQUIPMENT: "FETCH_ONE_ACTION_PLAN_EQUIPMENT",
  FETCH_ONE_ACTION_PLAN_EQUIPMENT_RESET: "FETCH_ONE_ACTION_PLAN_EQUIPMENT_RESET",
  FETCH_ONE_ACTION_PLAN_EQUIPMENT_FAILURE: "FETCH_ONE_ACTION_PLAN_EQUIPMENT_FAILURE",
  FETCH_ONE_ACTION_PLAN_EQUIPMENT_SUCCESS: "FETCH_ONE_ACTION_PLAN_EQUIPMENT_SUCCESS",
};
