import { ApiCallState } from "../Utils";
import { Moment } from "moment";

export type IncomeGenerated= {
    project_id: number | null;
    id: number | null;
    date:Moment;
    description_of_work:string;
    unit:string;
    quantity:number;
    rate:number;
    amount:number;
    schedule_of_work:string;
    remark:string;
};

export type IncomeGeneratedStateTypes = {
  fetchAll: ApiCallState<IncomeGenerated[]>;
  fetchOne: ApiCallState<IncomeGenerated | {}>;
};

export const IncomeGeneratedActionTypes = {
  FETCH_ALL_INCOME_GENERATED: "FETCH_ALL_INCOME_GENERATED",
  FETCH_ALL_INCOME_GENERATED_RESET: "FETCH_ALL_INCOME_GENERATED_RESET",
  FETCH_ALL_INCOME_GENERATED_FAILURE: "FETCH_ALL_INCOME_GENERATED_FAILURE",
  FETCH_ALL_INCOME_GENERATED_SUCCESS: "FETCH_ALL_INCOME_GENERATED_SUCCESS",

  FETCH_ONE_INCOME_GENERATED: "FETCH_ONE_INCOME_GENERATED",
  FETCH_ONE_INCOME_GENERATED_RESET: "FETCH_ONE_INCOME_GENERATED_RESET",
  FETCH_ONE_INCOME_GENERATED_FAILURE: "FETCH_ONE_INCOME_GENERATED_FAILURE",
  FETCH_ONE_INCOME_GENERATED_SUCCESS: "FETCH_ONE_INCOME_GENERATED_SUCCESS",
};
