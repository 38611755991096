import { Boq } from "../Boq/Boq.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type SubContractPVR = {
  duration: string[];
  status: number[];
  id: any;
  date: string;
  approved_by_id: number;
  checked_by_id: number;
  prepared_by_id: number;
  project_id: number;
  user_id: number;
  createdAt: string;
  updatedAt: string;
  sub_contract_pvr_prepared_by: User;
  sub_contract_pvr_approved_by: User;
  sub_contract_pvr_checked_by: User;
  sub_contract_pvr_items: SubContractPVRItem[];
};

export type SubContractPVRItem = {
  id: any;
  sub_contract_pvr_id: any;
  boq_id: number;
  executed_quantity: number;
  sub_contract_quantity: number;
  difference: number;
  quantity_certified: number;
  user_id: any;
  createdAt: string;
  updatedAt: string;
  boq: Boq;
};

export type SubContractPVRStateTypes = {
  fetchAll: ApiCallState<SubContractPVR[]>;
  fetchOne: ApiCallState<SubContractPVR | {}>;
};

export const SubContractPVRActionTypes = {
  FETCH_ALL_SUB_CONTRACT_PVR: "FETCH_ALL_SUB_CONTRACT_PVR",
  FETCH_ALL_SUB_CONTRACT_PVR_RESET: "FETCH_ALL_SUB_CONTRACT_PVR_RESET",
  FETCH_ALL_SUB_CONTRACT_PVR_FAILURE: "FETCH_ALL_SUB_CONTRACT_PVR_FAILURE",
  FETCH_ALL_SUB_CONTRACT_PVR_SUCCESS: "FETCH_ALL_SUB_CONTRACT_PVR_SUCCESS",

  FETCH_ONE_SUB_CONTRACT_PVR: "FETCH_ONE_SUB_CONTRACT_PVR",
  FETCH_ONE_SUB_CONTRACT_PVR_RESET: "FETCH_ONE_SUB_CONTRACT_PVR_RESET",
  FETCH_ONE_SUB_CONTRACT_PVR_FAILURE: "FETCH_ONE_SUB_CONTRACT_PVR_FAILURE",
  FETCH_ONE_SUB_CONTRACT_PVR_SUCCESS: "FETCH_ONE_SUB_CONTRACT_PVR_SUCCESS",
};
