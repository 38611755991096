import { Takeoff } from "../Takeoff/Takeoff.type";
import { ApiCallState } from "../Utils";

export type PaymentCertificateTakeoff= {
  id: number;
  pid:number
  takeoff_id: number,
  timesing: number,
  length: number,
  width: number,
  height: number,
  total: number,
  approval: number,
  remark: string,
  take_off:Takeoff
};

export type PaymentCertificateTakeoffStateTypes = {
  fetchAll: ApiCallState<PaymentCertificateTakeoff[]>;
  fetchOne: ApiCallState<PaymentCertificateTakeoff | {}>;
};

export const PaymentCertificateTakeoffActionTypes = {
  FETCH_ALL_PAYMENT_CERTIFICATE_TAKEOFF: "FETCH_ALL_PAYMENT_CERTIFICATE_TAKEOFF",
  FETCH_ALL_PAYMENT_CERTIFICATE_TAKEOFF_RESET: "FETCH_ALL_PAYMENT_CERTIFICATE_TAKEOFF_RESET",
  FETCH_ALL_PAYMENT_CERTIFICATE_TAKEOFF_FAILURE: "FETCH_ALL_PAYMENT_CERTIFICATE_TAKEOFF_FAILURE",
  FETCH_ALL_PAYMENT_CERTIFICATE_TAKEOFF_SUCCESS: "FETCH_ALL_PAYMENT_CERTIFICATE_TAKEOFF_SUCCESS",

  FETCH_ONE_PAYMENT_CERTIFICATE_TAKEOFF: "FETCH_ONE_PAYMENT_CERTIFICATE_TAKEOFF",
  FETCH_ONE_PAYMENT_CERTIFICATE_TAKEOFF_RESET: "FETCH_ONE_PAYMENT_CERTIFICATE_TAKEOFF_RESET",
  FETCH_ONE_PAYMENT_CERTIFICATE_TAKEOFF_FAILURE: "FETCH_ONE_PAYMENT_CERTIFICATE_TAKEOFF_FAILURE",
  FETCH_ONE_PAYMENT_CERTIFICATE_TAKEOFF_SUCCESS: "FETCH_ONE_PAYMENT_CERTIFICATE_TAKEOFF_SUCCESS",
};
