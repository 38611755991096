import { ApiCallState } from "../Utils";
import { Moment } from "moment";

export type ActionPlanGeneral= {
    project_id: number | null;
    id: number | null;
    date: Moment;
    time_extension:string;
    effective_date:string;
    time_elapsed:number;
    revised_amount:number;
    action_plan:number;
    expense_plan:number;
    gross_profit:number;
    gross_value:number;
    action_plan_details:ActionPlanDetail[];
    action_plan_comments:ActionPlanComment[];
    action_plan_criticals:ActionPlanCritical[];
};

export type ActionPlanDetail={
    action_plan_general_id: number | null;
    id: number | null;
    item_no:string;
    task_name:string;
    contract_amount:number;
    revised_amount:number;
    excuted_amount:number;
    remaing_amount:number;
    ratio:number;
    critical_issue:string;
    resposible_person:string;
    due_date:Moment;
}

export type ActionPlanComment={
  action_plan_general_id: number | null;
  id: number | null;
  comment:string;
}

export type ActionPlanCritical={
  action_plan_general_id: number | null;
  id: number | null;
  list_activity:string;
  current_status:string;
  action_taken:string;
  resposible_person:string;
  due_date:Moment;
}



export type ActionPlanGeneralStateTypes = {
  fetchAll: ApiCallState<ActionPlanGeneral[]>;
  fetchOne: ApiCallState<ActionPlanGeneral | {}>;
};

export type ActionPlanGeneralActionType = {
    project_id: number;
    date: string;
};

export const ActionPlanGeneralActionTypes = {
  FETCH_ALL_ACTION_PLAN_GENERAL: "FETCH_ALL_ACTION_PLAN_GENERAL",
  FETCH_ALL_ACTION_PLAN_GENERAL_RESET: "FETCH_ALL_ACTION_PLAN_GENERAL_RESET",
  FETCH_ALL_ACTION_PLAN_GENERAL_FAILURE: "FETCH_ALL_ACTION_PLAN_GENERAL_FAILURE",
  FETCH_ALL_ACTION_PLAN_GENERAL_SUCCESS: "FETCH_ALL_ACTION_PLAN_GENERAL_SUCCESS",

  FETCH_ONE_ACTION_PLAN_GENERAL: "FETCH_ONE_ACTION_PLAN_GENERAL",
  FETCH_ONE_ACTION_PLAN_GENERAL_RESET: "FETCH_ONE_ACTION_PLAN_GENERAL_RESET",
  FETCH_ONE_ACTION_PLAN_GENERAL_FAILURE: "FETCH_ONE_ACTION_PLAN_GENERAL_FAILURE",
  FETCH_ONE_ACTION_PLAN_GENERAL_SUCCESS: "FETCH_ONE_ACTION_PLAN_GENERAL_SUCCESS",
};
