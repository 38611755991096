import axios from "axios";
import { isNil } from "lodash";
import { takeLatest, put } from "redux-saga/effects";
import { ContractStatus } from "../../constants/Constants";

import { API_BASE_URI } from "../ApiCall";
import { RentContractActionTypes } from "./RentContract.type";

export function* fetchAllRentContracts(action: any): any {
  try {
    let query = "";

    if (isNil(action.payload)) {
      action.payload = {};
    }

    action.payload.status = ContractStatus.ACTIVE;

    if (action?.payload && Object.keys(action?.payload).length > 0) {
      query = Object.keys(action.payload)
        .map((key) => `${key}=${action.payload[key]}`)
        .join("&");
    }

    const response = yield axios.get(`${API_BASE_URI}/contract?${query}`);
    yield put({
      type: RentContractActionTypes.FETCH_ALL_RENT_CONTRACT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: RentContractActionTypes.FETCH_ALL_RENT_CONTRACT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchAllAllRentContracts(action: any): any {
  try {
    let query = "";

    if (action?.payload && Object.keys(action?.payload).length > 0) {
      query = Object.keys(action.payload)
        .map((key) => `${key}=${action.payload[key]}`)
        .join("&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/contract/detailed?${query}`
    );
    yield put({
      type: RentContractActionTypes.FETCH_ALL_ALL_RENT_CONTRACT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: RentContractActionTypes.FETCH_ALL_ALL_RENT_CONTRACT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllRentContracts() {
  yield takeLatest(
    RentContractActionTypes.FETCH_ALL_RENT_CONTRACT,
    fetchAllRentContracts
  );
}

export function* watcherFetchAllAllRentContracts() {
  yield takeLatest(
    RentContractActionTypes.FETCH_ALL_ALL_RENT_CONTRACT,
    fetchAllAllRentContracts
  );
}
