import { Contractor } from "../Contractor/Contractor.type";
import { Consultant } from "../Consultant/Consultant.type";
import { ApiCallState } from "../Utils";
import { Boq, init_boq } from "../Boq/Boq.type";
import { ProjectStaff } from "../ProjectStaff/ProjectStaff.type";
import { Client } from "../Client/Client.type";
import { PaymentFile } from "../PaymentFile/PaymentFile.type";
import { FinanceDailyReport } from "../FinanceDailyReport/FinanceDailyReport.type";
import { WorkSchedule } from "../WorkSchedule/WorkSchedule.type";
import { MonthlyProgressReport } from "../MonthlyProgressReport/MonthlyProgressReport.type";
import { MonthWorkSchedule } from "../MonthWorkSchedule/MonthWorkSchedule.type";
import { WeeklySummaryReport } from "../WeeklySummaryReport/WeeklySummaryReport.type";
import { ProjectList } from "../ProjectList/ProjectList.type";
export type Project = {
  id: any;
  name: string;
  type: string;
  project_type: "sub-contract" | "pre-contract" | "post-contract";
  basement_size: number;
  floor_size: number;
  road_size: number;
  budget: number;
  country: string;
  region: string;
  address: string;
  manager_name: string;
  commencement_date?: any;
  completion_date?: any;
  status: number;
  road_interval: number | null;
  custom_size: string;
  project_start: string;
  project_end: string;
  contract_type?: string;
  client?: Client;
  boqs: Boq[];
  overall: Overall;
  contractor?: Contractor;
  consultant?: Consultant;
  project_payment: ProjectPayment;
  payment_certificates: PaymentCertificate[];
  payment_files: PaymentFile[];
  project_staff?: ProjectStaff;
  sub_contract_project?: Project;
  month_work_schedules?: MonthWorkSchedule[];
  annual_progress_reports?: AnnualProgressReport[];
  finantial_dialy_reports?: FinanceDailyReport[];
  demo?: boolean;
  project_id: any;
  work_schedules?: WorkSchedule[];
  project_reports?: ProjectReport[];
  monthly_progress_reports?: MonthlyProgressReport[];
  weekly_summary_reports?: WeeklySummaryReport[]
  // material_schedules?: MaterialSchedule[];
  // equipment_schedules?: EquipmentSchedule[];
  // manpower_schedules?: ManpowerSchedule[];
  // subcontractor_schedules?: SubcontractorSchedule[];
  project_list?: ProjectList
};

export type AnnualProgressReport = {
  id: number;
  year: number;
  revised_contract_period: number;
  approved_variation_amount: number;
  remaining_contract_period: number;
  total_elapsed_date: number;
  price_adjustment: number;
  to_date_executed_amount: number;
  month_year_progress: number;
  remark: string;
  project_id: number;
};

export type ProjectReport = {
  id: number;
  planned: number;
  executed: number;
  direct_man_power_cost: number;
  sub_contract_cost: number;
  material_cost: number;
  fuel_lubricant_cost: number;
  equipment_cost: number;
  indirect_man_power: number;
  indirect_expense: number;
  to_date_verified: number;
  net_ipc: number;
  remark: string;
  date: string;
  project_id: number;
};

export type Store = {
  id: number;
  name: string;
};

export const init_project: Project = {
  project_id: null,
  id: null,
  name: "",
  type: "",
  project_type: "post-contract",
  payment_certificates: [],
  basement_size: 2,
  floor_size: 5,
  road_size: 0,
  budget: 0,
  country: "",
  region: "",
  address: "",
  manager_name: "",
  commencement_date: null,
  completion_date: null,
  status: 0,
  road_interval: null,
  custom_size: "",
  project_start: "",
  project_end: "",
  contract_type: "",
  client: {
    id: null,
    name: "",
    city: "",
    address: "",
    email: "",
    phone_number: "",
  },
  overall: {
    project_id: null,
    planned: 0,
    performed: 0,
    paid: 0,
    expense: 0,
    profit: 0,
    variation: 0,
    payment_waiting: 0,
    material_expense: 0,
    labour_expense: 0,
    supply_fix_expense: 0,
    equipment_expense: 0,
    fuel_expense: 0,
    supply_expense: 0,
    sub_contract_labour: 0,
    contract_amount: 0,
    current_performance: 0,
  },
  boqs: [init_boq],
  project_payment: {
    project_id: null,
    advance_payment: 0,
    rebate: 0,
    retention: 0,
    material_in_site: 0,
    penalty: 0,
    advance_percent: 0,
    overhead: 0,
    price_adjustment: 0,
    price_escalation: 0,
    retention_date: new Date(),
  },
  contractor: {
    id: null,
    name: "",
    city: "",
    address: "",
    email: "",
    phone_number: "",
    general_manager: "",
    type: "",
  },
  consultant: {
    id: null,
    name: "",
    city: "",
    address: "",
    email: "",
    phone_number: "",
    general_manager: "",
    type: "",
    key: null,
  },

  payment_files: [],
};

export type Overall = {
  project_id: number | null;
  planned: number;
  contract_amount: number;
  performed: number;
  payment_waiting: number;
  paid: number;
  expense: number;
  profit: number;
  variation: number;
  material_expense: number;
  labour_expense: number;
  supply_fix_expense: number;
  equipment_expense: number;
  fuel_expense: number;
  supply_expense: number;
  sub_contract_labour: number;
  current_performance: number;
};

export type ProjectPayment = {
  project_id: number | null;
  advance_payment: number;
  rebate: number;
  retention: number;
  material_in_site: number;
  penalty: number;
  advance_percent: number;
  price_adjustment: number;
  price_escalation: number;
  overhead: number;
  retention_date: Date;
};

export type PaymentCertificate = {
  id: any;
  project_id: number | null;
  amount: number;
  repayment: number;
  approved: number;
  requested_date: any;
  response_date: any;
  updated: number | null;
  document_id: number | null;
  retention: number;
  rebate: number;
};

export type Variation = {
  key: number;
  id: number;
  boq_id: number;
  quantity: number;
};

export type ProjectStateTypes = {
  fetchList: ApiCallState<Project[]>;
  fetchAll: ApiCallState<Project[]>;
  fetchAllDetail: ApiCallState<Project[]>;
  fetchOne: ApiCallState<Project>;
  fetchAllPreContract: ApiCallState<Project[]>;
  fetchOnePreContract: ApiCallState<Project>;
  fetchAllReport: ApiCallState<Project[]>;
};

export const ProjectActionTypes = {
  FETCH_ALL_PROJECT: "FETCH_ALL_PROJECT",
  FETCH_ALL_PROJECT_RESET: "FETCH_ALL_PROJECT_RESET",
  FETCH_ALL_PROJECT_FAILURE: "FETCH_ALL_PROJECT_FAILURE",
  FETCH_ALL_PROJECT_SUCCESS: "FETCH_ALL_PROJECT_SUCCESS",

  FETCH_ALL_PROJECT_DETIAL: "FETCH_ALL_PROJECT_DETIAL",
  FETCH_ALL_PROJECT_DETIAL_RESET: "FETCH_ALL_PROJECT_DETIAL_RESET",
  FETCH_ALL_PROJECT_DETIAL_FAILURE: "FETCH_ALL_PROJECT_DETIAL_FAILURE",
  FETCH_ALL_PROJECT_DETIAL_SUCCESS: "FETCH_ALL_PROJECT_DETIAL_SUCCESS",

  FETCH_ALL_PROJECT_LIST: "FETCH_ALL_PROJECT_LIST",
  FETCH_ALL_PROJECT_LIST_RESET: "FETCH_ALL_PROJECT_LIST_RESET",
  FETCH_ALL_PROJECT_LIST_FAILURE: "FETCH_ALL_PROJECT_LIST_FAILURE",
  FETCH_ALL_PROJECT_LIST_SUCCESS: "FETCH_ALL_PROJECT_LIST_SUCCESS",

  FETCH_ONE_PROJECT: "FETCH_ONE_PROJECT",
  FETCH_ONE_PROJECT_RESET: "FETCH_ONE_PROJECT_RESET",
  FETCH_ONE_PROJECT_FAILURE: "FETCH_ONE_PROJECT_FAILURE",
  FETCH_ONE_PROJECT_SUCCESS: "FETCH_ONE_PROJECT_SUCCESS",

  FETCH_ALL_PRE_PROJECT: "FETCH_ALL_PRE_PROJECT",
  FETCH_ALL_PRE_PROJECT_RESET: "FETCH_ALL_PRE_PROJECT_RESET",
  FETCH_ALL_PRE_PROJECT_FAILURE: "FETCH_ALL_PRE_PROJECT_FAILURE",
  FETCH_ALL_PRE_PROJECT_SUCCESS: "FETCH_ALL_PRE_PROJECT_SUCCESS",

  FETCH_ONE_PRE_PROJECT: "FETCH_ONE_PRE_PROJECT",
  FETCH_ONE_PRE_PROJECT_RESET: "FETCH_ONE_PRE_PROJECT_RESET",
  FETCH_ONE_PRE_PROJECT_FAILURE: "FETCH_ONE_PRE_PROJECT_FAILURE",
  FETCH_ONE_PRE_PROJECT_SUCCESS: "FETCH_ONE_PRE_PROJECT_SUCCESS",

  FETCH_ALL_PROJECT_REPORT: "FETCH_ALL_PROJECT_REPORT",
  FETCH_ALL_PROJECT_REPORT_RESET: "FETCH_ALL_PROJECT_REPORT_RESET",
  FETCH_ALL_PROJECT_REPORT_FAILURE: "FETCH_ALL_PROJECT_REPORT_FAILURE",
  FETCH_ALL_PROJECT_REPORT_SUCCESS: "FETCH_ALL_PROJECT_REPORT_SUCCESS",
};
