import { DailySurveyingReportingStateTypes, DailySurveyingReportingActionTypes } from "./DailySurveyingReporting.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: DailySurveyingReportingStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const DailySurveyingReportingReducer = (
  state: DailySurveyingReportingStateTypes = INITIAL_STATE,
  action: any
): DailySurveyingReportingStateTypes => {
  switch (action.type) {
    case DailySurveyingReportingActionTypes.FETCH_ALL_DAILY_SURVEYING_REPORTING:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case DailySurveyingReportingActionTypes.FETCH_ALL_DAILY_SURVEYING_REPORTING_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case DailySurveyingReportingActionTypes.FETCH_ALL_DAILY_SURVEYING_REPORTING_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case DailySurveyingReportingActionTypes.FETCH_ALL_DAILY_SURVEYING_REPORTING_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case DailySurveyingReportingActionTypes.FETCH_ONE_DAILY_SURVEYING_REPORTING:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case DailySurveyingReportingActionTypes.FETCH_ONE_DAILY_SURVEYING_REPORTING_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case DailySurveyingReportingActionTypes.FETCH_ONE_DAILY_SURVEYING_REPORTING_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case DailySurveyingReportingActionTypes.FETCH_ONE_DAILY_SURVEYING_REPORTING_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default DailySurveyingReportingReducer;
