import { Replenishment } from "../Replenishment/Replenishment.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type PettyCash = {
  id: number;
  user_id: number;
  employee_id: number;
  current_replenishment_id: number;

  amount: number;
  current_amount: number;
  replenishment_level: number;
  date: Date;

  employee: User;
  current_replenishment: Replenishment;

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type PettyCashStateTypes = {
  fetchAll: ApiCallState<PettyCash[]>;
  fetchOne: ApiCallState<PettyCash | {}>;
};

export const PettyCashActionTypes = {
  FETCH_ALL_PETTY_CASH: "FETCH_ALL_PETTY_CASH",
  FETCH_ALL_PETTY_CASH_RESET: "FETCH_ALL_PETTY_CASH_RESET",
  FETCH_ALL_PETTY_CASH_FAILURE: "FETCH_ALL_PETTY_CASH_FAILURE",
  FETCH_ALL_PETTY_CASH_SUCCESS: "FETCH_ALL_PETTY_CASH_SUCCESS",

  FETCH_ONE_PETTY_CASH: "FETCH_ONE_PETTY_CASH",
  FETCH_ONE_PETTY_CASH_RESET: "FETCH_ONE_PETTY_CASH_RESET",
  FETCH_ONE_PETTY_CASH_FAILURE: "FETCH_ONE_PETTY_CASH_FAILURE",
  FETCH_ONE_PETTY_CASH_SUCCESS: "FETCH_ONE_PETTY_CASH_SUCCESS",
};
