import { Document } from "../../../redux/Document/Document.type";
import { last } from "lodash";
export type DocumentViewerPropType = {
  document: { url: string };
};

export const getFileType = (url: string) => {
  const split = url.split(".");
  return last(split);
};
