import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ProformaActionTypes } from "./Proforma.type";

export function* fetchAllProformas(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/proforma?project_id=${action.payload?.project_id}&purchase_requisition_id=${action.payload?.purchase_requisition_id}`
    );
    yield put({
      type: ProformaActionTypes.FETCH_ALL_PROFORMA_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ProformaActionTypes.FETCH_ALL_PROFORMA_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneProformas(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/proforma/${action.payload}`
    );
    yield put({
      type: ProformaActionTypes.FETCH_ONE_PROFORMA_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ProformaActionTypes.FETCH_ONE_PROFORMA_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllProformas() {
  yield takeLatest(ProformaActionTypes.FETCH_ALL_PROFORMA, fetchAllProformas);
}

export function* watcherFetchOneProformas() {
  yield takeLatest(ProformaActionTypes.FETCH_ONE_PROFORMA, fetchOneProformas);
}
