import { ApiCallState } from "../Utils";
import { Moment } from "moment";

export type FuelRequest= {
    id: number | null;
    project_id:number | null;
    week:Moment;
    date_range:Moment;
    request_no:string;
    total_gasoil:number;
    total_benzene:number;
    fuel_request_details:FuelRequestDetail[];
};

export type FuelRequestDetail= {
    id: number | null;
    fuel_request_id:number | null;
    type_of_equipment:string;
    plate_no:string;
    fuel_type:string;
    per_hour:number;
    per_kilometer:number;
    total_planned_working:number;
    total_planned_fuel:number;
    planned_type_of_work:string;
    remark:string;  
}

export type FuelRequestStateTypes = {
  fetchAll: ApiCallState<FuelRequest[]>;
  fetchOne: ApiCallState<FuelRequest | {}>;
};

export const FuelRequestActionTypes = {
  FETCH_ALL_FUEL_REQUEST: "FETCH_ALL_FUEL_REQUEST",
  FETCH_ALL_FUEL_REQUEST_RESET: "FETCH_ALL_FUEL_REQUEST_RESET",
  FETCH_ALL_FUEL_REQUEST_FAILURE: "FETCH_ALL_FUEL_REQUEST_FAILURE",
  FETCH_ALL_FUEL_REQUEST_SUCCESS: "FETCH_ALL_FUEL_REQUEST_SUCCESS",

  FETCH_ONE_FUEL_REQUEST: "FETCH_ONE_FUEL_REQUEST",
  FETCH_ONE_FUEL_REQUEST_RESET: "FETCH_ONE_FUEL_REQUEST_RESET",
  FETCH_ONE_FUEL_REQUEST_FAILURE: "FETCH_ONE_FUEL_REQUEST_FAILURE",
  FETCH_ONE_FUEL_REQUEST_SUCCESS: "FETCH_ONE_FUEL_REQUEST_SUCCESS",
};
