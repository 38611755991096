import { ApiCallState } from "../Utils";
import { Moment } from "moment";

export type ManpowerSchedule= {
    project_id: number | null;
    id: number | null;
    no: number;
    description: string;
    date: Moment;
    rate: number;
    total_amount:number;
    remark:string;
    monday: number;
    tuseday: number;
    wednesday: number;
    thursday: number;
    friday: number;
    saturday: number;
    sunday: number;
};

export type ManpowerScheduleStateTypes = {
  fetchAll: ApiCallState<ManpowerSchedule[]>;
  fetchOne: ApiCallState<ManpowerSchedule | {}>;
};

export type ManpowerScheduleActionType = {
    project_id: number;
    date: string;
};

export const ManpowerScheduleActionTypes = {
  FETCH_ALL_MANPOWER_SCHEDULE: "FETCH_ALL_MANPOWER_SCHEDULE",
  FETCH_ALL_MANPOWER_SCHEDULE_RESET: "FETCH_ALL_MANPOWER_SCHEDULE_RESET",
  FETCH_ALL_MANPOWER_SCHEDULE_FAILURE: "FETCH_ALL_MANPOWER_SCHEDULE_FAILURE",
  FETCH_ALL_MANPOWER_SCHEDULE_SUCCESS: "FETCH_ALL_MANPOWER_SCHEDULE_SUCCESS",

  FETCH_ONE_MANPOWER_SCHEDULE: "FETCH_ONE_MANPOWER_SCHEDULE",
  FETCH_ONE_MANPOWER_SCHEDULE_RESET: "FETCH_ONE_MANPOWER_SCHEDULE_RESET",
  FETCH_ONE_MANPOWER_SCHEDULE_FAILURE: "FETCH_ONE_MANPOWER_SCHEDULE_FAILURE",
  FETCH_ONE_MANPOWER_SCHEDULE_SUCCESS: "FETCH_ONE_MANPOWER_SCHEDULE_SUCCESS",
};
