import { ApiCallState } from "../Utils";
import { Moment } from "moment";

export type MonthWorkSchedule= {
    project_id: number | null;
    id: number | null;
    location: string | null;
    task_name: string;
    item_no: string;
    unit: string;
    planned_quantity: number;
    unit_price: number;
    planned_amount: number;
    date: Moment;
    remark:string;
    week1:boolean;
    week2:boolean;
    week3:boolean;
    week4:boolean;
};

export type MonthWorkScheduleStateTypes = {
  fetchAll: ApiCallState<MonthWorkSchedule[]>;
  fetchOne: ApiCallState<MonthWorkSchedule | {}>;
};
export type MonthWorkScheduleActionType = {
    project_id: number;
    date: string;
};

export const MonthWorkScheduleActionTypes = {
  FETCH_ALL_MONTH_WORK_SCHEDULE: "FETCH_ALL_MONTH_WORK_SCHEDULE",
  FETCH_ALL_MONTH_WORK_SCHEDULE_RESET: "FETCH_ALL_MONTH_WORK_SCHEDULE_RESET",
  FETCH_ALL_MONTH_WORK_SCHEDULE_FAILURE: "FETCH_ALL_MONTH_WORK_SCHEDULE_FAILURE",
  FETCH_ALL_MONTH_WORK_SCHEDULE_SUCCESS: "FETCH_ALL_MONTH_WORK_SCHEDULE_SUCCESS",

  FETCH_ONE_MONTH_WORK_SCHEDULE: "FETCH_ONE_MONTH_WORK_SCHEDULE",
  FETCH_ONE_MONTH_WORK_SCHEDULE_RESET: "FETCH_ONE_MONTH_WORK_SCHEDULE_RESET",
  FETCH_ONE_MONTH_WORK_SCHEDULE_FAILURE: "FETCH_ONE_MONTH_WORK_SCHEDULE_FAILURE",
  FETCH_ONE_MONTH_WORK_SCHEDULE_SUCCESS: "FETCH_ONE_MONTH_WORK_SCHEDULE_SUCCESS",
};
