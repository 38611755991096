import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MonthWorkScheduleActionTypes } from "./MonthWorkSchedule.type";

export function* fetchAllMonthWorkSchedules(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/month-work-schedule?project_id=${action.payload.project_id}&date=${action.payload.date}`);
    yield put({
      type: MonthWorkScheduleActionTypes.FETCH_ALL_MONTH_WORK_SCHEDULE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MonthWorkScheduleActionTypes.FETCH_ALL_MONTH_WORK_SCHEDULE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneMonthWorkSchedules(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/month-work-schedul/${action.payload}`
    );
    yield put({
      type: MonthWorkScheduleActionTypes.FETCH_ONE_MONTH_WORK_SCHEDULE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MonthWorkScheduleActionTypes.FETCH_ONE_MONTH_WORK_SCHEDULE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllMonthWorkSchedules() {
  yield takeLatest(MonthWorkScheduleActionTypes.FETCH_ALL_MONTH_WORK_SCHEDULE, fetchAllMonthWorkSchedules);
}

export function* watcherFetchOneMonthWorkSchedules() {
  yield takeLatest(MonthWorkScheduleActionTypes.FETCH_ONE_MONTH_WORK_SCHEDULE, fetchOneMonthWorkSchedules);
}
