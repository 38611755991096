import { Project } from "../Project/Project.type";
import { ApiCallState } from "../Utils";

export type PaymentCertificate= {
  id: number;
  project_id:number
  amount:number
  repayment:number
  rebate:number
  approved:number
  requested_date:Date
  response_date:Date
  executed:number
  variation:number
  price_escalation:number
  project:Project
  advance_status:boolean
  variation_executed:number
};

export type PaymentCertificateStateTypes = {
  fetchAll: ApiCallState<PaymentCertificate[]>;
  fetchOne: ApiCallState<PaymentCertificate | {}>;
};

export const PaymentCertificateActionTypes = {
  FETCH_ALL_PAYMENT_CERTIFICATE: "FETCH_ALL_PAYMENT_CERTIFICATE",
  FETCH_ALL_PAYMENT_CERTIFICATE_RESET: "FETCH_ALL_PAYMENT_CERTIFICATE_RESET",
  FETCH_ALL_PAYMENT_CERTIFICATE_FAILURE: "FETCH_ALL_PAYMENT_CERTIFICATE_FAILURE",
  FETCH_ALL_PAYMENT_CERTIFICATE_SUCCESS: "FETCH_ALL_PAYMENT_CERTIFICATE_SUCCESS",

  FETCH_ONE_PAYMENT_CERTIFICATE: "FETCH_ONE_PAYMENT_CERTIFICATE",
  FETCH_ONE_PAYMENT_CERTIFICATE_RESET: "FETCH_ONE_PAYMENT_CERTIFICATE_RESET",
  FETCH_ONE_PAYMENT_CERTIFICATE_FAILURE: "FETCH_ONE_PAYMENT_CERTIFICATE_FAILURE",
  FETCH_ONE_PAYMENT_CERTIFICATE_SUCCESS: "FETCH_ONE_PAYMENT_CERTIFICATE_SUCCESS",
};
