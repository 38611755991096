import { Staff } from "../Staff/Staff.type";
import { ApiCallState } from "../Utils";

export type Maberetacha = {
  id: number;
  staff_id: number;

  from_date: string;
  to_date: string;
  amount: number;
  remark: string;

  staff: Staff;
};

export type MaberetachaStateTypes = {
  fetchAll: ApiCallState<Maberetacha[]>;
  fetchOne: ApiCallState<Maberetacha | {}>;
};

export const MaberetachaActionTypes = {
  FETCH_ALL_MABERETACHA: "FETCH_ALL_MABERETACHA",
  FETCH_ALL_MABERETACHA_RESET: "FETCH_ALL_MABERETACHA_RESET",
  FETCH_ALL_MABERETACHA_FAILURE: "FETCH_ALL_MABERETACHA_FAILURE",
  FETCH_ALL_MABERETACHA_SUCCESS: "FETCH_ALL_MABERETACHA_SUCCESS",

  FETCH_ONE_MABERETACHA: "FETCH_ONE_MABERETACHA",
  FETCH_ONE_MABERETACHA_RESET: "FETCH_ONE_MABERETACHA_RESET",
  FETCH_ONE_MABERETACHA_FAILURE: "FETCH_ONE_MABERETACHA_FAILURE",
  FETCH_ONE_MABERETACHA_SUCCESS: "FETCH_ONE_MABERETACHA_SUCCESS",
};
