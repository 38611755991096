import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { StaffAttendanceActionTypes } from "./StaffAttendance.type";

export function* fetchAllStaffAttendances(action: any): any {
  try {
    let keys: any[] = Object.keys(action.payload);

    let assigns: any[] = keys.map(
      (key: any) => `${key}=${action.payload[key]}`
    );
    assigns.push("scope=staff");
    let query: string = assigns.join("&");

    const response = yield axios.get(`${API_BASE_URI}/attendance?${query}`);
    yield put({
      type: StaffAttendanceActionTypes.FETCH_ALL_STAFF_ATTENDANCE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: StaffAttendanceActionTypes.FETCH_ALL_STAFF_ATTENDANCE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllStaffAttendances() {
  yield takeLatest(
    StaffAttendanceActionTypes.FETCH_ALL_STAFF_ATTENDANCE,
    fetchAllStaffAttendances
  );
}
