import { ApiCallState } from "../Utils";

export type AllLabourUtilizationSummary= {
  id: number | null;
  reporting_period:string;
  description:string;
  unit:string;
  payroll:number;
  measurable:number;
  non_measurable:number;
  total:number;
  loss:number;
  profit:number;
  remark:string;
};

export type AllLabourUtilizationSummaryStateTypes = {
  fetchAll: ApiCallState<AllLabourUtilizationSummary[]>;
  fetchOne: ApiCallState<AllLabourUtilizationSummary | {}>;
};

export const AllLabourUtilizationSummaryActionTypes = {
  FETCH_ALL_ALL_LABOUR_UTILIZATION_SUMMARY: "FETCH_ALL_ALL_LABOUR_UTILIZATION_SUMMARY",
  FETCH_ALL_ALL_LABOUR_UTILIZATION_SUMMARY_RESET: "FETCH_ALL_ALL_LABOUR_UTILIZATION_SUMMARY_RESET",
  FETCH_ALL_ALL_LABOUR_UTILIZATION_SUMMARY_FAILURE: "FETCH_ALL_ALL_LABOUR_UTILIZATION_SUMMARY_FAILURE",
  FETCH_ALL_ALL_LABOUR_UTILIZATION_SUMMARY_SUCCESS: "FETCH_ALL_ALL_LABOUR_UTILIZATION_SUMMARY_SUCCESS",

  FETCH_ONE_ALL_LABOUR_UTILIZATION_SUMMARY: "FETCH_ONE_ALL_LABOUR_UTILIZATION_SUMMARY",
  FETCH_ONE_ALL_LABOUR_UTILIZATION_SUMMARY_RESET: "FETCH_ONE_ALL_LABOUR_UTILIZATION_SUMMARY_RESET",
  FETCH_ONE_ALL_LABOUR_UTILIZATION_SUMMARY_FAILURE: "FETCH_ONE_ALL_LABOUR_UTILIZATION_SUMMARY_FAILURE",
  FETCH_ONE_ALL_LABOUR_UTILIZATION_SUMMARY_SUCCESS: "FETCH_ONE_ALL_LABOUR_UTILIZATION_SUMMARY_SUCCESS",
};
