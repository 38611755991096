import { ApiCallState } from "../Utils";
import { Moment } from "moment";


export type MonthManpowerSchedule= {
    id: number | null;
    project_id:number;
    no: number;
    description: string;
    date: Moment;
    rate: number;
    total_amount:number;
    remark:string;
    week1: number;
    week2: number;
    week3: number;
    week4: number;
};

export type MonthManpowerScheduleStateTypes = {
  fetchAll: ApiCallState<MonthManpowerSchedule[]>;
  fetchOne: ApiCallState<MonthManpowerSchedule | {}>;
};

export type MonthManpowerScheduleActionType = {
    project_id: number;
    date: string;
};

export const MonthManpowerScheduleActionTypes = {
  FETCH_ALL_MONTH_MANPOWER_SCHEDULE: "FETCH_ALL_MONTH_MANPOWER_SCHEDULE",
  FETCH_ALL_MONTH_MANPOWER_SCHEDULE_RESET: "FETCH_ALL_MONTH_MANPOWER_SCHEDULE_RESET",
  FETCH_ALL_MONTH_MANPOWER_SCHEDULE_FAILURE: "FETCH_ALL_MONTH_MANPOWER_SCHEDULE_FAILURE",
  FETCH_ALL_MONTH_MANPOWER_SCHEDULE_SUCCESS: "FETCH_ALL_MONTH_MANPOWER_SCHEDULE_SUCCESS",

  FETCH_ONE_MONTH_MANPOWER_SCHEDULE: "FETCH_ONE_MONTH_MANPOWER_SCHEDULE",
  FETCH_ONE_MONTH_MANPOWER_SCHEDULE_RESET: "FETCH_ONE_MONTH_MANPOWER_SCHEDULE_RESET",
  FETCH_ONE_MONTH_MANPOWER_SCHEDULE_FAILURE: "FETCH_ONE_MONTH_MANPOWER_SCHEDULE_FAILURE",
  FETCH_ONE_MONTH_MANPOWER_SCHEDULE_SUCCESS: "FETCH_ONE_MONTH_MANPOWER_SCHEDULE_SUCCESS",
};
