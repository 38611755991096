import { SubcontractorPaymentHandoverStateTypes, SubcontractorPaymentHandoverActionTypes } from "./SubcontractorPaymentHandover.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: SubcontractorPaymentHandoverStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const SubcontractorPaymentHandoverReducer = (
  state: SubcontractorPaymentHandoverStateTypes = INITIAL_STATE,
  action: any
): SubcontractorPaymentHandoverStateTypes => {
  switch (action.type) {
    case SubcontractorPaymentHandoverActionTypes.FETCH_ALL_SUBCONTRACTOR_PAYMENT_HANDOVER:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case SubcontractorPaymentHandoverActionTypes.FETCH_ALL_SUBCONTRACTOR_PAYMENT_HANDOVER_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case SubcontractorPaymentHandoverActionTypes.FETCH_ALL_SUBCONTRACTOR_PAYMENT_HANDOVER_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case SubcontractorPaymentHandoverActionTypes.FETCH_ALL_SUBCONTRACTOR_PAYMENT_HANDOVER_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case SubcontractorPaymentHandoverActionTypes.FETCH_ONE_SUBCONTRACTOR_PAYMENT_HANDOVER:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case SubcontractorPaymentHandoverActionTypes.FETCH_ONE_SUBCONTRACTOR_PAYMENT_HANDOVER_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case SubcontractorPaymentHandoverActionTypes.FETCH_ONE_SUBCONTRACTOR_PAYMENT_HANDOVER_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case SubcontractorPaymentHandoverActionTypes.FETCH_ONE_SUBCONTRACTOR_PAYMENT_HANDOVER_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default SubcontractorPaymentHandoverReducer;
