import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { RoadFuelRequestActionTypes } from "./RoadFuelRequest.type";

export function* fetchAllRoadFuelRequests(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/road-fuel-request?project_id=${action.payload.project_id}`);
    yield put({
      type: RoadFuelRequestActionTypes.FETCH_ALL_ROAD_FUEL_REQUEST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: RoadFuelRequestActionTypes.FETCH_ALL_ROAD_FUEL_REQUEST_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneRoadFuelRequests(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/road-fuel-request/${action.payload}`
    );
    yield put({
      type: RoadFuelRequestActionTypes.FETCH_ONE_ROAD_FUEL_REQUEST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: RoadFuelRequestActionTypes.FETCH_ONE_ROAD_FUEL_REQUEST_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllRoadFuelRequests() {
  yield takeLatest(RoadFuelRequestActionTypes.FETCH_ALL_ROAD_FUEL_REQUEST, fetchAllRoadFuelRequests);
}

export function* watcherFetchOneRoadFuelRequests() {
  yield takeLatest(RoadFuelRequestActionTypes.FETCH_ONE_ROAD_FUEL_REQUEST, fetchOneRoadFuelRequests);
}
