import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { LabourUtilizationActionTypes } from "./LabourUtilization.type";

export function* fetchAllLabourUtilizations(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/labour-utilization?project_id=${action.payload.project_id}`);
    yield put({
      type: LabourUtilizationActionTypes.FETCH_ALL_LABOUR_UTILIZATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: LabourUtilizationActionTypes.FETCH_ALL_LABOUR_UTILIZATION_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneLabourUtilizations(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/labour-utilization/${action.payload}`
    );
    yield put({
      type: LabourUtilizationActionTypes.FETCH_ONE_LABOUR_UTILIZATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: LabourUtilizationActionTypes.FETCH_ONE_LABOUR_UTILIZATION_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllLabourUtilizations() {
  yield takeLatest(LabourUtilizationActionTypes.FETCH_ALL_LABOUR_UTILIZATION, fetchAllLabourUtilizations);
}

export function* watcherFetchOneLabourUtilizations() {
  yield takeLatest(LabourUtilizationActionTypes.FETCH_ONE_LABOUR_UTILIZATION, fetchOneLabourUtilizations);
}
