import { ApiCallState } from "../Utils";
import { Staff } from "../Staff/Staff.type";

export type TeriMastawekia = {
  id: number;
  user_id: number;
  staff_id: number;
  date: string;
  remark: string;

  staff: Staff;
};

export type TeriMastawekiaStateTypes = {
  fetchAll: ApiCallState<TeriMastawekia[]>;
  fetchOne: ApiCallState<TeriMastawekia | {}>;
};

export const TeriMastawekiaActionTypes = {
  FETCH_ALL_TERI_MASTAWEKIA: "FETCH_ALL_TERI_MASTAWEKIA",
  FETCH_ALL_TERI_MASTAWEKIA_RESET: "FETCH_ALL_TERI_MASTAWEKIA_RESET",
  FETCH_ALL_TERI_MASTAWEKIA_FAILURE: "FETCH_ALL_TERI_MASTAWEKIA_FAILURE",
  FETCH_ALL_TERI_MASTAWEKIA_SUCCESS: "FETCH_ALL_TERI_MASTAWEKIA_SUCCESS",

  FETCH_ONE_TERI_MASTAWEKIA: "FETCH_ONE_TERI_MASTAWEKIA",
  FETCH_ONE_TERI_MASTAWEKIA_RESET: "FETCH_ONE_TERI_MASTAWEKIA_RESET",
  FETCH_ONE_TERI_MASTAWEKIA_FAILURE: "FETCH_ONE_TERI_MASTAWEKIA_FAILURE",
  FETCH_ONE_TERI_MASTAWEKIA_SUCCESS: "FETCH_ONE_TERI_MASTAWEKIA_SUCCESS",
};
