import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import AccountReducer from "./Account/Account.reducer";
import AttendanceReducer from "./Attendance/Attendance.reducer";
import BoqReducer from "./Boq/Boq.reducer";
import BoqActivityPlanReducer from "./BoqActivityPlan/BoqActivityPlan.reducer";
import BoqPlanReducer from "./BoqPlan/BoqPlan.reducer";
import ConsultantReducer from "./Consultant/Consultant.reducer";
import ContractReducer from "./Contract/Contract.reducer";
import ContractorReducer from "./Contractor/Contractor.reducer";
import CustomerReducer from "./Customer/Customer.reducer";
import DepartmentReducer from "./Department/Department.reducer";
import DocumentReducer from "./Document/Document.reducer";
import EmployeeExtraReducer from "./EmployeeExtra/EmployeeExtra.reducer";
import EmployeeRequestReducer from "./EmployeeRequest/EmployeeRequest.reducer";
import EquipmentInventoryReducer from "./EquipmentInventory/EquipmentInventory.reducer";
import EquipmentPlanReducer from "./EquipmentPlan/EquipmentPlan.reducer";
import EquipmentTransferReducer from "./EquipmentTransfer/EquipmentTransfer.reducer";
import EquipmentUsageReducer from "./EquipmentUsage/EquipmentUsage.reducer";
import EquipmentUsagePlanReducer from "./EquipmentUsagePlan/EquipmentUsagePlan.reducer";
import ExpenseReducer from "./Expense/Expense.reducer";
import InvoiceReducer from "./Invoice/Invoice.reducer";
import LabourReducer from "./Labour/Labour.reducer";
import LabourPlanReducer from "./LabourPlan/LabourPlan.reducer";
import LabourUsageReducer from "./LabourUsage/LabourUsage.reducer";
import ManpowerOvertimeReducer from "./ManpowerOvertime/ManpowerOvertime.reducer";
import MaterialReducer from "./Material/Material.reducer";
import MaterialInventoryReducer from "./MaterialInventory/MaterialInventory.reducer";
import MaterialPlanReducer from "./MaterialPlan/MaterialPlan.reducer";
import MaterialTransferReducer from "./MaterialTransfer/MaterialTransfer.reducer";
import PaymentReducer from "./Payment/Payment.reducer";
import PaymentRetentionReducer from "./PaymentRetention/PaymentRetention.reducer";
import PayrollReducer from "./Payroll/Payroll.reducer";

import ProjectReducer from "./Project/Project.reducer";
import ProjectStaffReducer from "./ProjectStaff/ProjectStaff.reducer";
import RebarReducer from "./Rebar/Rebar.reducer";
import ScheduleReducer from "./Schedule/Schedule.reducer";
import ServiceReducer from "./Service/Service.reducer";
import StaffReducer from "./Staff/Staff.reducer";
import StaffPlanReducer from "./StaffPlan/StaffPlan.reducer";
import SubContractReducer from "./SubContract/SubContract.reducer";
import SubContractPlanReducer from "./SubContractPlan/SubContractPlan.reducer";
import SupplierReducer from "./Supplier/Supplier.reducer";
import TakeoffReducer from "./Takeoff/Takeoff.reducer";
import TourReducer from "./Tour/Tour.reducer";
import UnitBreakdownReducer from "./UnitBreakdown/UnitBreakdown.reducer";
import UsageReducer from "./Usage/Usage.reducer";
import UserReducer from "./User/User.reducer";
import VariationReducer from "./Variation/Variation.reducer";
import IdReducer from "./ID/Id.reducer";
import MaterialRequisitionReducer from "./MaterialRequisition/MaterialRequisition.reducer";
import PurchaseRequisitionReducer from "./PurchaseRequisition/PurchaseRequisition.reducer";
import PurchaseOrderReducer from "./PurchaseOrder/PurchaseOrder.reducer";
import PurchaseBillingReducer from "./PurchaseBilling/PurchaseBilling.reducer";
import GoodReceivedReducer from "./GoodReceived/GoodReceived.reducer";
import RenterReducer from "./Renter/Renter.reducer";
import GoodOutReducer from "./GoodOut/GoodOut.reducer";
import GoodReturnReducer from "./GoodReturn/GoodReturn.reducer";
import EmployeeHistoryReducer from "./EmployeeHistory/EmployeeHistory.reducer";
import DocumentWorkOrderReducer from "./DocumentWorkOrder/DocumentWorkOrder.reducer";
import EquipmentWOReducer from "./EquipmentWO/EquipmentWO.reducer";
import PriceEscalationReducer from "./PriceEscalation/PriceEscalation.reducer";
import HRPolicyReducer from "./HRPolicy/HRPolicy.reducer";
import AbsenceReducer from "./Absence/Absence.reducer";
import EmployeeDocumentReducer from "./EmployeeDocument/EmployeeDocument.reducer";
import LogReducer from "./Log/Log.reducer";
import ClientReducer from "./Client/Client.reducer";
import EmployeeAccommodationPlanReducer from "./EmployeeAccommodationPlan/EmployeeAccommodationPlan.reducer";
import AllowanceReducer from "./Allowance/Allowance.reducer";
import AllowancePolicyReducer from "./AllowancePolicy/AllowancePolicy.reducer";
import StandardReducer from "./Standard/Standard.reducer";
import BoqStandardReducer from "./BoqStandard/BoqStandard.reducer";
import ResourceReducer from "./Resource/Resource.reducer";
import PaymentCertificateReducer from "./PaymentCertificate/PaymentCertificate.reducer";
import PaymentCertificateTakeoffReducer from "./PaymentCertificateTakoff/PaymentCertificateTakeoff.reducer";
import PaymentCertificateRebarReducer from "./PaymentCertificateRebar/PaymentCertificateRebar.reducer";
import PaymentCertificateAggregateReducer from "./PaymentCertificateAggregate/PaymentCertificateAggregate.reducer";
import PaymentCertificateRoadReducer from "./PaymentCertificateRoad/PaymentCertificateRoad.reducer";
import InspectionFormReducer from "./InspectionForm/InspectionForm.reducer";
import InspectionReducer from "./Inspection/Inspection.reducer";
import CastingReducer from "./Casting/Casting.reducer";
import TestResultReducer from "./TestResult/TestResult.reducer";
import TestRequestReducer from "./TestRequest/TestRequest.reducer";
import SubmittalReducer from "./Submittal/Submittal.reducer";
import MaterialRequestApprovalReducer from "./MaterialRequestApproval/MaterialRequestApproval.reducer";
import SiteDiaryReducer from "./SiteDiary/SiteDiary.reducer";
import WeekReportReducer from "./WeekReport/WeekReport.reducer";
import MeetingReducer from "./Meeting/Meeting.reducer";
import QueryReducer from "./Query/Query.reducer";
import MasterScheduleReducer from "./MasterSchedule/MasterSchedule.reducer";
import RFIReducer from "./RFI/RFI.reducer";
import StaffAttendanceReducer from "./StaffAttendance/StaffAttendance.reducer";
import EmployeeUserReducer from "./EmployeeUser/EmployeeUser.reducer";
import ReviewFormReducer from "./ReviewForm/ReviewForm.reducer";
import EmployeeReviewReducer from "./EmployeeReview/EmployeeReview.reducer";
import BonusReducer from "./Bonus/Bonus.reducer";
import VacancyReducer from "./Vacancy/vacancy.reducer";
import ApplicationReducer from "./Application/Application.reducer";
import MediaReducer from "./Media/Media.reducer";
import SHEReducer from "./SHE/SHE.reducer";

import SharedDocumentReducer from "./SharedDocument/SharedDocument.reducer";
import BankAccountReducer from "./BankAccount/BankAccount.reducer";
import LetterReducer from "./Letter/Letter.reducer";
import AccountTransactionReducer from "./AccountTransaction/AccountTransaction.reducer";
import RentContractReducer from "./RentContract/RentContract.reducer";
import RentContractProjectReducer from "./RentContractProject/RentContractProject.reducer";

import StoreRequisitionReducer from "./StoreRequisition/StoreRequisition.reducer";
import StoreRequisitionFollowUpReducer from "./StoreRequisitionFollowUp/StoreRequisitionFollowUp.reducer";
import AccidentFollowUpReducer from "./AccidentFollowUp/AccidentFollowUp.reducer";

import MaintenanceRequestReducer from "./MaintenanceRequest/MaintenanceRequest.reducer";
import MaintenanceVoucherReducer from "./MaintenanceVoucher/MaintenanceVoucher.reducer";
import ServiceVoucherReducer from "./ServiceVoucher/ServiceVoucher.reducer";
import DailyStatusReportReducer from "./DailyStatusReport/DailyStatusReport.reducer";
import InsuranceReducer from "./Insurance/Insurance.reducer";

import RiskLogReducer from "./RiskLog/RiskLog.reducer";
import StaffWorkReducer from "./StaffWork/StaffWork.reducer";
import ReceivableReducer from "./Receivable/Receivable.reducer";
import AttachmentReducer from "./Attachment/Attachment.reducer";
import PettyCashReducer from "./PettyCash/PettyCash.reducer";
import ReplenishmentReducer from "./Replenishment/Replenishment.reducer";
import ReplenishmentTransactionReducer from "./ReplenishTransaction/ReplenishmentTransaction.reducer";
import ReplenishmentRequestReducer from "./ReplenishRequest/ReplenishmentRequest.reducer";
import PaymentFileReducer from "./PaymentFile/PaymentFile.reducer";

import TestEvaluationReducer from "./TestEvaluation/TestEvaluation.reducer";
import VariationFileReducer from "./VariationFile/VariationFile.reducer";
import PriceEscalationFileReducer from "./PriceEscalationFile/PriceEscalationFile.reducer";
import CRVPaymentReducer from "./CRVPayment/CRVPayment.reducer";
import PostCheckReducer from "./PostCheck/PostCheck.reducer";
import SiteBookReducer from "./SiteBook/SiteBook.reducer";
import MonthlyReportReducer from "./MonthlyReport/MonthlyReport.reducer";
import DailyReportReducer from "./DailyReport/DailyReport.reducer";
import MaterialUsageReducer from "./MaterialUsage/MaterialUsage.reducer";
import PayableReducer from "./Payable/Payable.reducer";
import MemoReducer from "./Memo/Memo.reducer";
import BuildingReducer from "./Building/Building.reducer";
import ApartmentReducer from "./Apartment/Apartment.reducer";
import ApartmentExpenseReducer from "./ApartmentExpense/ApartmentExpense.reducer";
import ContractSalesReducer from "./ContractSales/ContractSales.reducer";
import EstimatedBuildingCostReducer from "./EstimatedBuildingCost/EstimatedBuildingCost.reducer";
import ProformaReducer from "./Proforma/Proforma.reducer";
import CurrencyReducer from "./Currency/Currency.reducer";
import ProcurementPlanReducer from "./ProcurementPlan/ProcurementPlan.reducer";
import WorkScheduleReducer from "./WorkSchedule/WorkSchedule.reducer";
import MonthWorkScheduleReducer from "./MonthWorkSchedule/MonthWorkSchedule.reducer";
import EquipmentScheduleReduce from "./EquipmentSchedule/EquipmentSchedule.reducer";
import ManpowerScheduleReduce from "./ManpowerSchedule/ManpowerSchedule.reducer";
import SubcontractorScheduleReducer from "./SubcontractorSchedule/SubcontractorSchedule.reducer";
import MaterialScheduleReducer from "./MaterialSchedule/MaterialSchedule.reducer";
import MonthMaterialScheduleReducer from "./MonthMaterialSchedule/MonthMaterialSchedule.reducer";
import ActionPlanGeneralReducer from "./ActionPlanGeneral/ActionPlanGeneral.reducer";
import ApprovalScheduleReducer from "./ApprovalSchedule/ApprovalSchedule.reducer";
import PaymentSummaryReducer from "./PaymentSummary/PaymentSummary.reducer";
import WeatherConditionReducer from "./WeatherCondition/WeatherCondition.reducer";
import DailySurveyingReducer from "./DailySurveying/DailySurveying.reducer";
import DailySurveyingReportingReducer from "./DailySurveyingReporting/DailySurveyingReporting.reducer";
import SiteRAFReadingReducer from "./SiteRAFReading/SiteRAFReading.reducer";
import MonthEquipmentScheduleReducer from "./MonthEquipmentSchedule/MonthEquipmentSchedule.reducer";
import MonthManpowerScheduleReducer from "./MonthManpowerSchedule/MonthManpowerSchedule.reducer";
import MonthSubcontractorScheduleReducer from "./MonthSubcontractorSchedule/MonthSubcontractorSchedule.reducer";
import SubcontractRateApprovalReducer from "./SubcontractRateApproval/SubcontractRateApproval.reducer";
import TransferDocumentFormatReducer from "./TransferDocumentFormat/TransferDocumentFormat.reducer";
import AssignmentRegistryReducer from "./AssignmentRegistry/AssignmentRegistry.reducer";
import EquipmentRequestReducer from "./EquipmentRequest/EquipmentRequest.reducer";
import EquipmentAndPlantStatusReducer from "./EquipmentAndPlantStatus/EquipmentAndPlantStatus.reducer";
import EquipmentDemobiliationReducer from "./EquipmentDemobiliation/EquipmentDemobiliation.reducer";
import PeriodicalEquipmentReducer from "./PeriodicalEquipment/PeriodicalEquipment.reducer";
import EquipmentProductivityReducer from "./EquipmentProductivity/EquipmentProductivity.reducer";
import RiskMitigationReducer from "./RiskMitigation/RiskMitigation.reducer";
import JustificationReducer from "./Justification/Justification.reducer";
import BriefingReducer from "./Briefing/Briefing.reducer";
import CriticalIssueReducer from "./CriticalIssue/CriticalIssue.reducer";
import BudgetRequestReducer from "./BudgetRequest/BudgetRequest.reducer";
import BudgetShiftingRequestReducer from "./BudgetShiftingRequest/BudgetShiftingRequest.reducer";
import IncomeGeneratedReducer from "./IncomeGenerated/IncomeGenerated.reducer";
import BudgetUtilizationReducer from "./BudgetUtilization/BudgetUtilization.reducer";
import FuelRequestReducer from "./FuelRequest/FuelRequest.reducer";
import FuelUtilizationReducer from "./FuelUtilization/FuelUtilization.reducer";
import FinanceDailyReportReducer from "./FinanceDailyReport/FinanceDailyReport.reducer";
import MaterialPurchaseFollowUpReducer from "./MaterialPurchaseFollowUp/MaterialPurchaseFollowUp.reducer";
import WeeklySummaryReportReducer from "./WeeklySummaryReport/WeeklySummaryReport.reducer";
import PriceOfferReducer from "./PriceOffer/PriceOffer.reducer";
import SiteVisitReportReducer from "./SiteVisitReport/SiteVisitReport.reducer";
import DailyActivityReducer from "./DailyActivity/DailyActivity.reducer";
import MonthlySummaryReportReducer from "./MonthlySummaryReport/MonthlySummaryReport.reducer";
import MaterialRequiredSummaryReducer from "./MaterialRequiredSummary/MaterialRequiredSummary.reducer";
import MaterialUtilizationSummaryReducer from "./MaterialUtilizationSummary/MaterialUtilizationSummary.reducer";
import SupplyFixReducer from "./SupplyFix/SupplyFix.reducer";
import SupplyFixWorkProgressReducer from "./SupplyFixWorkProgress/SupplyFixWorkProgress.reducer";
import SupplyFixContractReducer from "./SupplyFixContract/SupplyFixContract.reducer";
import ProjectOperationReportReducer from "./ProjectOperationReport/ProjectOperationReport.reducer";
import LabourUtilizationReducer from "./LabourUtilization/LabourUtilization.reducer";
import LabourUtilizationSummaryReducer from "./LabourUtilizationSummary/LabourUtilizationSummary.reducer";
import MonthlyProgressReportReducer from "./MonthlyProgressReport/MonthlyProgressReport.reducer";
import InspectionRegistryReducer from "./InspectionRegistry/InspectionRegistry.reducer";
import SubContractorPerformanceReducer from "./SubContractorPerformance/SubContractorPerformance.reducer";
import SiteBookOrderReducer from "./SiteBookOrder/SiteBookOrder.reducer";
import PaymentFollowUpReducer from "./PaymentFollowUp/PaymentFollowUp.reducer";
import SubcontractorsCommitmentReducer from "./SubcontractorsCommitment/SubcontractorsCommitment.reducer";
import SubcontractorPaymentHandoverReducer from "./SubcontractorPaymentHandover/SubcontractorPaymentHandover.reducer";
import StoreReducer from "./Store/Store.reducer";
import ProformaComparisonReducer from "./ProformaComparison/ProformaComparison.reducer";
import SupportLetterReducer from "./SupportLetter/SupportLetter.reducer";
import SubContractPVRReducer from "./SubContractPVR/SubContractPVR.reducer";
import SubContractHandOverReducer from "./SubContractHandOver/SubContractHandOver.reducer";
import DispatchSummaryReducer from "./DispatchSummary/DispatchSummary.reducer";
import SubContractComparisonReducer from "./SubContractComparison/SubContractComparison.reducer";
import PriceComparisonReducer from "./PriceComparison/PriceComparison.reducer";
import SubContractorPaymentReducer from "./SubContractorPayment/SubContractorPayment.reducer";
import WeeklyProgressSummaryReducer from "./WeeklyProgressSummary/WeeklyProgressSummary.reducer";
import CastingTestReportReducer from "./CastingTestReport/CastingTestReport.reducer";
import RoadFuelRequestReducer from "./RoadFuelRequest/RoadFuelRequest.reducer";
import DailySummaryReducer from "./DailySummary/DailySummary.reducer";
import AllLabourUtilizationSummaryReducer from "./AllLabourUtilizationSummary/AllLabourUtilizationSummary.reducer";
import JustificationSummaryReducer from "./JustificationSummary/JustificationSummary.reducer";
import PlanSummaryReducer from "./PlanSummary/PlanSummary.reducer";
import ActionPlanExpenseSummaryReducer from "./ActionPlanExpenseSummary/ActionPlanExpenseSummary.reducer";
import ProjectIpcFollowUpReducer from "./ProjectIpcFollowUp/ProjectIpcFollowUp.reducer";
import UncollectedIpcReducer from "./UncollectedIpc/UncollectedIpc.reducer";
import ActionPlanNarrativeReducer from "./ActionPlanNarrative/ActionPlanNarrative.reducer";
import ActionPlanSubcontractorReducer from "./ActionPlanSubcontractor/ActionPlanSubcontractor.reducer";
import ActionPlanMaterialReducer from "./ActionPlanMaterial/ActionPlanMaterial.reducer";
import ProjectListReducer from "./ProjectList/ProjectList.reducer";
import MasterListReducer from "./MasterList/MasterList.reducer";
import FuelRequestVoucherReducer from "./FuelRequestVoucher/FuelRequestVoucher.reducer";
import FuelRecievingVoucherReducer from "./FuelRecievingVoucher/FuelRecievingVoucher.reducer";
import FuelIssueVoucherReducer from "./FuelIssueVoucher/FuelIssueVoucher.reducer";
import ActionPlanEquipmentReducer from "./ActionPlanEquipment/ActionPlanEquipment.reducer";
import PayableV2Reducer from "./PayableV2/PayableV2.reducer";
import PaymentV2PaymentReducer from "./PayableV2Payment/PayableV2Payment.reducer";
import CheckListReducer from "./CheckList/CheckList.reducer";
import TaskReducer from "./Task/Task.reducer";
import BiddingScheduleReducer from "./BiddingSchedule/BiddingSchedule.reducer";
import EquipmentDailyOperationReportReducer from "./EquipmentDailyOperationReport/EquipmentDailyOperationReport.reducer";
import EquipmentDailyDownIdleReportReducer from "./EquipmentDailyDownIdleReport/EquipmentDailyDownIdleReport.reducer";
import GeneralDailyOperationReportReducer from "./GeneralDailyOperationReport/GeneralDailyOperationReport.reducer";
import UploadDocumentReducer from "./UploadDocument/UploadDocument.reducer";
import StaffBioReducer from "./StaffBio/StaffBio.reducer";
import LoanReducer from "./Loan/Loan.reducer";
import LoanExtensionReducer from "./LoanExtension/LoanExtension.reducer";
import TravelRegistryReducer from "./TravelRegistry/TravelRegistry.reducer";
import TeriMastawekiaReducer from "./TeriMastawekia/TeriMastawekia.reducer";
import MaberetachaReducer from "./Maberetacha/Maberetacha.reducer";
import ResignationReducer from "./Resignation/Resignation.reducer";
import WithHoldPaymentReducer from "./WithHoldPayment/WithHoldPayment.reducer";
import StaffPenaltyReducer from "./StaffPenalty/StaffPenalty.reducer";
import StaffMealReducer from "./StaffMeal/StaffMeal.reducer";
import FamilyAllotmentReducer from "./FamilyAllotment/FamilyAllotment.reducer";
import WorkerTimeSheetReducer from "./WorkerTimeSheet/WorkerTimeSheet.reducer";
import WorkExecutedReducer from "./WorkExecuted/WorkExecuted.reducer";
import DailyActivityLogReducer from "./DailyActivityLog/DailyActivityLog.reducer";

import AdditionBoqReducer from "./AdditionBoq/AdditionBoq.reducer";
import OmissionBoqReducer from "./OmissionBoq/OmissionBoq.reducer";
import SupplementaryBoqReducer from "./SupplementaryBoq/SupplementaryBoq.reducer";
import MasterScheduleUpdatedReducer from "./MasterScheduleUpdated/MasterScheduleUpdated.reducer";
import ProjectLetterReducer from "./ProjectLetter/ProjectLetter.reducer";
import ProjectContractReducer from "./ProjectContract/ProjectContract.reducer";
import SubContractRegistryReducer from "./SubContractRegistry/SubContractRegistry.reducer";
import SubContractPaymentUpdateReducer from "./SubContractPaymentUpdate/SubContractPaymentUpdate.reducer";
import SiteHandoverReducer from "./SiteHandover/SiteHandover.reducer";
import BenchmarkReducer from "./Benchmark/Benchmark.reducer";
import ProjectUploadDocumentReducer from "./ProjectUploadDocument/ProjectUploadDocument.reducer";
import InventoryReducer from "./Inventory/Inventory.reducer";


const PersistConfig = {
  key: "root",
  storage,
  whitelist: [],
};

const RootReducer = combineReducers({
  project: ProjectReducer,
  contractor: ContractorReducer,
  consultant: ConsultantReducer,
  takeoff: TakeoffReducer,
  rebar: RebarReducer,
  boq: BoqReducer,
  boq_plan: BoqPlanReducer,
  boq_activity_plan: BoqActivityPlanReducer,
  sub_contract_plan: SubContractPlanReducer,
  material: MaterialReducer,
  supplier: SupplierReducer,
  material_inventory: MaterialInventoryReducer,
  material_transfer: MaterialTransferReducer,
  equipment_inventory: EquipmentInventoryReducer,
  equipment_transfer: EquipmentTransferReducer,
  usage: UsageReducer,
  labour: LabourReducer,
  equipment_usage: EquipmentUsageReducer,
  labour_usage: LabourUsageReducer,
  equipment_plan: EquipmentPlanReducer,
  equipment_usage_plan: EquipmentUsagePlanReducer,
  material_plan: MaterialPlanReducer,
  labour_plan: LabourPlanReducer,
  department: DepartmentReducer,
  staff: StaffReducer,
  staff_plan: StaffPlanReducer,
  account: AccountReducer,
  customer: CustomerReducer,
  service: ServiceReducer,
  unit_breakdown: UnitBreakdownReducer,
  variation: VariationReducer,
  sub_contract: SubContractReducer,
  invoice: InvoiceReducer,
  expense: ExpenseReducer,
  payment: PaymentReducer,
  payment_retention: PaymentRetentionReducer,
  document: DocumentReducer,
  project_staff: ProjectStaffReducer,
  manpower_overtime: ManpowerOvertimeReducer,
  payroll: PayrollReducer,
  tour: TourReducer,
  schedule: ScheduleReducer,
  employee_extra: EmployeeExtraReducer,
  user: UserReducer,
  attendance: AttendanceReducer,
  contract: ContractReducer,
  employee_request: EmployeeRequestReducer,
  id: IdReducer,
  material_requisition: MaterialRequisitionReducer,
  purchase_requisition: PurchaseRequisitionReducer,
  purchase_order: PurchaseOrderReducer,
  purchase_billing: PurchaseBillingReducer,
  good_received: GoodReceivedReducer,
  renter: RenterReducer,
  good_out: GoodOutReducer,
  good_return: GoodReturnReducer,
  employee_history: EmployeeHistoryReducer,
  document_work_order: DocumentWorkOrderReducer,
  equipment_work_order: EquipmentWOReducer,
  price_escalation: PriceEscalationReducer,
  hr_policy: HRPolicyReducer,
  absence: AbsenceReducer,
  employee_document: EmployeeDocumentReducer,
  employee_accommodation_plan: EmployeeAccommodationPlanReducer,
  client: ClientReducer,
  allowance: AllowanceReducer,
  allowance_policy: AllowancePolicyReducer,
  standard: StandardReducer,
  log: LogReducer,
  boq_standard: BoqStandardReducer,
  resource: ResourceReducer,
  payment_certificate: PaymentCertificateReducer,
  payment_certificate_takeoff: PaymentCertificateTakeoffReducer,
  payment_certificate_rebar: PaymentCertificateRebarReducer,
  payment_certificate_road: PaymentCertificateRoadReducer,
  payment_certificate_aggregate: PaymentCertificateAggregateReducer,
  inspection_form: InspectionFormReducer,
  inspection: InspectionReducer,
  casting: CastingReducer,
  submittal: SubmittalReducer,
  material_request_approval: MaterialRequestApprovalReducer,
  test_result: TestResultReducer,
  test_request: TestRequestReducer,
  meeting: MeetingReducer,
  site_diary: SiteDiaryReducer,
  week_report: WeekReportReducer,
  query: QueryReducer,
  master_schedule: MasterScheduleReducer,
  rfi: RFIReducer,
  staff_attendance: StaffAttendanceReducer,
  employee_user: EmployeeUserReducer,
  review_form: ReviewFormReducer,
  employee_review: EmployeeReviewReducer,
  bonus: BonusReducer,
  vacancies: VacancyReducer,
  applications: ApplicationReducer,
  media: MediaReducer,
  she: SHEReducer,
  sharedDocument: SharedDocumentReducer,
  bankAccount: BankAccountReducer,
  letter: LetterReducer,
  accountTransaction: AccountTransactionReducer,
  rentContract: RentContractReducer,
  rent_contract_project: RentContractProjectReducer,
  store_requisition: StoreRequisitionReducer,
  store_requisition_follow_up: StoreRequisitionFollowUpReducer,
  accident_follow_up: AccidentFollowUpReducer,
  maintenance_request: MaintenanceRequestReducer,
  maintenance_voucher: MaintenanceVoucherReducer,
  service_voucher: ServiceVoucherReducer,
  daily_status_report: DailyStatusReportReducer,
  insurance: InsuranceReducer,
  risk_log: RiskLogReducer,
  staff_work: StaffWorkReducer,
  receivable: ReceivableReducer,
  attachment: AttachmentReducer,
  petty_cash: PettyCashReducer,
  replenishment: ReplenishmentReducer,
  replenishment_transaction: ReplenishmentTransactionReducer,
  replenishment_request: ReplenishmentRequestReducer,
  test_evaluation: TestEvaluationReducer,
  payment_file: PaymentFileReducer,
  variation_file: VariationFileReducer,
  price_escalation_file: PriceEscalationFileReducer,
  crv_payment: CRVPaymentReducer,
  post_check: PostCheckReducer,
  site_book: SiteBookReducer,
  monthly_report: MonthlyReportReducer,
  daily_report: DailyReportReducer,
  material_usage: MaterialUsageReducer,
  payable: PayableReducer,
  memo: MemoReducer,
  building: BuildingReducer,
  apartment: ApartmentReducer,
  apartment_expense: ApartmentExpenseReducer,
  contract_sales: ContractSalesReducer,
  estimated_building_cost: EstimatedBuildingCostReducer,
  proforma: ProformaReducer,
  currency: CurrencyReducer,
  procurement_plan: ProcurementPlanReducer,
  work_schedule: WorkScheduleReducer,
  month_work_schedule: MonthWorkScheduleReducer,
  equipment_schedule: EquipmentScheduleReduce,
  manpower_schedule: ManpowerScheduleReduce,
  subcontractor_schedule: SubcontractorScheduleReducer,
  material_schedule: MaterialScheduleReducer,
  action_plan_general: ActionPlanGeneralReducer,
  approval_schedule: ApprovalScheduleReducer,
  payment_summary: PaymentSummaryReducer,
  weather_condition: WeatherConditionReducer,
  daily_surveying: DailySurveyingReducer,
  daily_surveying_reporting: DailySurveyingReportingReducer,
  site_raf_reading: SiteRAFReadingReducer,
  month_material_schedule: MonthMaterialScheduleReducer,
  month_equipment_schedule: MonthEquipmentScheduleReducer,
  month_manpower_schedule: MonthManpowerScheduleReducer,
  month_subcontractor_schedule: MonthSubcontractorScheduleReducer,
  subcontract_rate_approval: SubcontractRateApprovalReducer,
  transfer_document_format: TransferDocumentFormatReducer,
  assignment_registry: AssignmentRegistryReducer,
  equipment_request: EquipmentRequestReducer,
  equipment_and_plant_status: EquipmentAndPlantStatusReducer,
  equipment_demobiliation: EquipmentDemobiliationReducer,
  periodical_equipment: PeriodicalEquipmentReducer,
  equipment_productivity: EquipmentProductivityReducer,
  risk_mitigation: RiskMitigationReducer,
  justification: JustificationReducer,
  briefing: BriefingReducer,
  critical_issue: CriticalIssueReducer,
  budget_request: BudgetRequestReducer,
  budget_shifting_request: BudgetShiftingRequestReducer,
  income_generated: IncomeGeneratedReducer,
  budget_utilization: BudgetUtilizationReducer,
  fuel_request: FuelRequestReducer,
  fuel_utilization: FuelUtilizationReducer,
  finance_daily_report: FinanceDailyReportReducer,
  price_offer: PriceOfferReducer,
  material_purchase_follow_up: MaterialPurchaseFollowUpReducer,
  weekly_summary_report: WeeklySummaryReportReducer,
  site_visit_report: SiteVisitReportReducer,
  daily_activity: DailyActivityReducer,
  monthly_summary_report: MonthlySummaryReportReducer,
  material_required_summary: MaterialRequiredSummaryReducer,
  material_utilization_summary: MaterialUtilizationSummaryReducer,
  project_operation_report: ProjectOperationReportReducer,
  supply_fix: SupplyFixReducer,
  supply_fix_work_progress: SupplyFixWorkProgressReducer,
  supply_fix_contract: SupplyFixContractReducer,
  labour_utilization: LabourUtilizationReducer,
  labour_utilization_summary: LabourUtilizationSummaryReducer,
  site_book_order: SiteBookOrderReducer,
  payment_follow_up: PaymentFollowUpReducer,
  monthly_progress_report: MonthlyProgressReportReducer,
  inspection_registry: InspectionRegistryReducer,
  sub_contractor_performance: SubContractorPerformanceReducer,
  subcontractors_commitment: SubcontractorsCommitmentReducer,
  subcontractor_payment_handover: SubcontractorPaymentHandoverReducer,
  store: StoreReducer,
  support_letter: SupportLetterReducer,
  proforma_comparison: ProformaComparisonReducer,
  sub_contract_handover: SubContractHandOverReducer,
  price_comparison: PriceComparisonReducer,
  sub_contract_pvr: SubContractPVRReducer,
  dispatch_summary: DispatchSummaryReducer,
  sub_contract_comparison: SubContractComparisonReducer,
  sub_contractor_payment: SubContractorPaymentReducer,
  weekly_progress_summary: WeeklyProgressSummaryReducer,
  casting_test_report: CastingTestReportReducer,
  daily_summary: DailySummaryReducer,
  justification_summary: JustificationSummaryReducer,
  plan_summary: PlanSummaryReducer,
  road_fuel_request: RoadFuelRequestReducer,
  project_ipc_follow_up: ProjectIpcFollowUpReducer,
  uncollected_ipc: UncollectedIpcReducer,
  all_labour_utilization_summary: AllLabourUtilizationSummaryReducer,
  project_list: ProjectListReducer,
  action_plan_expense_summary: ActionPlanExpenseSummaryReducer,
  action_plan_narrative: ActionPlanNarrativeReducer,
  action_plan_subcontractor: ActionPlanSubcontractorReducer,
  action_plan_material: ActionPlanMaterialReducer,
  master_list: MasterListReducer,
  fuel_request_voucher: FuelRequestVoucherReducer,
  fuel_recieving_voucher: FuelRecievingVoucherReducer,
  fuel_issue_voucher: FuelIssueVoucherReducer,
  action_plan_equipment: ActionPlanEquipmentReducer,
  payable_v2: PayableV2Reducer,
  payable_v2_payment: PaymentV2PaymentReducer,
  check_list: CheckListReducer,
  task: TaskReducer,
  bidding_schedule: BiddingScheduleReducer,
  equipment_daily_operation_report: EquipmentDailyOperationReportReducer,
  equipment_daily_breakdown_idle_report: EquipmentDailyDownIdleReportReducer,
  general_daily_operation_report: GeneralDailyOperationReportReducer,
  upload_document: UploadDocumentReducer,
  staff_bio: StaffBioReducer,
  loan: LoanReducer,
  loan_extension: LoanExtensionReducer,
  travel_registry: TravelRegistryReducer,
  teri_mastawekia: TeriMastawekiaReducer,
  maberetacha: MaberetachaReducer,
  resignation: ResignationReducer,
  with_hold_payment: WithHoldPaymentReducer,
  staff_penalty: StaffPenaltyReducer,
  staff_meal: StaffMealReducer,
  family_allotment: FamilyAllotmentReducer,
  worker_time_sheet: WorkerTimeSheetReducer,
  work_executed: WorkExecutedReducer,
  daily_activity_log: DailyActivityLogReducer,
  addition_boq:AdditionBoqReducer,
  omission_boq:OmissionBoqReducer,
  supplementary_boq:SupplementaryBoqReducer,
  master_schedule_updated:MasterScheduleUpdatedReducer,
  project_letter:ProjectLetterReducer,
  project_contract:ProjectContractReducer,
  sub_contract_registry:SubContractRegistryReducer,
  sub_contract_payment_update:SubContractPaymentUpdateReducer,
  site_handover:SiteHandoverReducer,
  benchmark:BenchmarkReducer,
  project_upload_document:ProjectUploadDocumentReducer,
  inventory: InventoryReducer,
});

export default persistReducer(PersistConfig, RootReducer);
