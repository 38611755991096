import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { SubContractPVRActionTypes } from "./SubContractPVR.type";

export function* fetchAllSubContractPVRs(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/sub-contract-pvr?project_id=${action.payload.project_id}`
    );
    yield put({
      type: SubContractPVRActionTypes.FETCH_ALL_SUB_CONTRACT_PVR_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SubContractPVRActionTypes.FETCH_ALL_SUB_CONTRACT_PVR_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneSubContractPVRs(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/sub-contract-pvr/${action.payload}`
    );
    yield put({
      type: SubContractPVRActionTypes.FETCH_ONE_SUB_CONTRACT_PVR_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SubContractPVRActionTypes.FETCH_ONE_SUB_CONTRACT_PVR_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllSubContractPVRs() {
  yield takeLatest(
    SubContractPVRActionTypes.FETCH_ALL_SUB_CONTRACT_PVR,
    fetchAllSubContractPVRs
  );
}

export function* watcherFetchOneSubContractPVRs() {
  yield takeLatest(
    SubContractPVRActionTypes.FETCH_ONE_SUB_CONTRACT_PVR,
    fetchOneSubContractPVRs
  );
}
