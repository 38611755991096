import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MonthManpowerScheduleActionTypes } from "./MonthManpowerSchedule.type";

export function* fetchAllMonthManpowerSchedules(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/month-manpower-schedule?project_id=${action.payload.project_id}&date=${action.payload.date}`);
    yield put({
      type: MonthManpowerScheduleActionTypes.FETCH_ALL_MONTH_MANPOWER_SCHEDULE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MonthManpowerScheduleActionTypes.FETCH_ALL_MONTH_MANPOWER_SCHEDULE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneMonthManpowerSchedules(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/month-manpower-schedule/${action.payload}`
    );
    yield put({
      type: MonthManpowerScheduleActionTypes.FETCH_ONE_MONTH_MANPOWER_SCHEDULE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MonthManpowerScheduleActionTypes.FETCH_ONE_MONTH_MANPOWER_SCHEDULE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllMonthManpowerSchedules() {
  yield takeLatest(MonthManpowerScheduleActionTypes.FETCH_ALL_MONTH_MANPOWER_SCHEDULE, fetchAllMonthManpowerSchedules);
}

export function* watcherFetchOneMonthManpowerSchedules() {
  yield takeLatest(MonthManpowerScheduleActionTypes.FETCH_ONE_MONTH_MANPOWER_SCHEDULE, fetchOneMonthManpowerSchedules);
}
