import { ApiCallState } from "../Utils";

export type EquipmentProductivity= {
    project_id: number | null;
    id: number | null;
    productive_no:string;
    date:string;
    reporting_period:string;
    equipment_productivity_details:EquipmentProductivityDetail[];
};

export type EquipmentProductivityDetail ={
    id: number | null;
    equipment_productivity_id: number | null;
    equipment_name:string;
    type_of_activity:string;
    planned_output:number;
    planned_hour:number;
    work_executed:number;
    actual_hour:number;
    justification:string;
    remark:string;
}


export type EquipmentProductivityStateTypes = {
  fetchAll: ApiCallState<EquipmentProductivity[]>;
  fetchOne: ApiCallState<EquipmentProductivity | {}>;
};

export type EquipmentProductivityActionType = {
    project_id: number;
    date: string;
};

export const EquipmentProductivityActionTypes = {
  FETCH_ALL_EQUIPMENT_PRODUCTIVITY: "FETCH_ALL_EQUIPMENT_PRODUCTIVITY",
  FETCH_ALL_EQUIPMENT_PRODUCTIVITY_RESET: "FETCH_ALL_EQUIPMENT_PRODUCTIVITY_RESET",
  FETCH_ALL_EQUIPMENT_PRODUCTIVITY_FAILURE: "FETCH_ALL_EQUIPMENT_PRODUCTIVITY_FAILURE",
  FETCH_ALL_EQUIPMENT_PRODUCTIVITY_SUCCESS: "FETCH_ALL_EQUIPMENT_PRODUCTIVITY_SUCCESS",

  FETCH_ONE_EQUIPMENT_PRODUCTIVITY: "FETCH_ONE_EQUIPMENT_PRODUCTIVITY",
  FETCH_ONE_EQUIPMENT_PRODUCTIVITY_RESET: "FETCH_ONE_EQUIPMENT_PRODUCTIVITY_RESET",
  FETCH_ONE_EQUIPMENT_PRODUCTIVITY_FAILURE: "FETCH_ONE_EQUIPMENT_PRODUCTIVITY_FAILURE",
  FETCH_ONE_EQUIPMENT_PRODUCTIVITY_SUCCESS: "FETCH_ONE_EQUIPMENT_PRODUCTIVITY_SUCCESS",
};
