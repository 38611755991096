import { ApiCallState } from "../Utils";

export type RiskMitigation= {
    project_id: number | null;
    id: number | null;
    status:string;
    risk_category:string;
    risk_event:string;
    cause:string;
    effect:string;
    threat_or_opportunity:string;
    primary_objective:string;
    probability:string;
    impact:string;
    risk_response_plan:string;
    monitor_and_control:string;
    risk_matrix_x:number;
    risk_matrix_y:number;
};

export type RiskMitigationStateTypes = {
  fetchAll: ApiCallState<RiskMitigation[]>;
  fetchOne: ApiCallState<RiskMitigation | {}>;
};

export type RiskMitigationActionType = {
    project_id: number;
};

export const RiskMitigationActionTypes = {
  FETCH_ALL_RISK_MITIGATION: "FETCH_ALL_RISK_MITIGATION",
  FETCH_ALL_RISK_MITIGATION_RESET: "FETCH_ALL_RISK_MITIGATION_RESET",
  FETCH_ALL_RISK_MITIGATION_FAILURE: "FETCH_ALL_RISK_MITIGATION_FAILURE",
  FETCH_ALL_RISK_MITIGATION_SUCCESS: "FETCH_ALL_RISK_MITIGATION_SUCCESS",

  FETCH_ONE_RISK_MITIGATION: "FETCH_ONE_RISK_MITIGATION",
  FETCH_ONE_RISK_MITIGATION_RESET: "FETCH_ONE_RISK_MITIGATION_RESET",
  FETCH_ONE_RISK_MITIGATION_FAILURE: "FETCH_ONE_RISK_MITIGATION_FAILURE",
  FETCH_ONE_RISK_MITIGATION_SUCCESS: "FETCH_ONE_RISK_MITIGATION_SUCCESS",
};
