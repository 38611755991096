import { Moment } from "moment";
import { ApiCallState } from "../Utils";

export type Task= {
  id: number | null;
  project_name:string;
  task_no:string;
  task_name:string;
  due_date:Moment;
  date:Moment;
  assigned_to:string;
  created_by:number;
  task_details:TaskDetail[];
  task_remarks: TaskRemark[];
  users: {
    id: number;
    "user_task": {
      id: number;
      user_id: number;
      task_id: number;
      last_seen: string;
    };
  }[];
};

export type TaskDetail ={
    id: number | null;
    task_id:number;
    check:boolean;
    description:string;
    remark:string;
    checked_date:string;
}

export type TaskRemark ={
  id:number | null;
  task_id:number | null;
  date:string;
  remarked_by:number;
  url:string;
  size:number;
  remark:string;
  createdAt:string;
}

export type TaskStateTypes = {
  fetchAll: ApiCallState<Task[]>;
  fetchOne: ApiCallState<Task | {}>;
};

export const TaskActionTypes = {
  FETCH_ALL_TASK: "FETCH_ALL_TASK",
  FETCH_ALL_TASK_RESET: "FETCH_ALL_TASK_RESET",
  FETCH_ALL_TASK_FAILURE: "FETCH_ALL_TASK_FAILURE",
  FETCH_ALL_TASK_SUCCESS: "FETCH_ALL_TASK_SUCCESS",

  FETCH_ONE_TASK: "FETCH_ONE_TASK",
  FETCH_ONE_TASK_RESET: "FETCH_ONE_TASK_RESET",
  FETCH_ONE_TASK_FAILURE: "FETCH_ONE_TASK_FAILURE",
  FETCH_ONE_TASK_SUCCESS: "FETCH_ONE_TASK_SUCCESS",
};
