import { SubcontractorsCommitmentStateTypes, SubcontractorsCommitmentActionTypes } from "./SubcontractorsCommitment.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: SubcontractorsCommitmentStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const SubcontractorsCommitmentReducer = (
  state: SubcontractorsCommitmentStateTypes = INITIAL_STATE,
  action: any
): SubcontractorsCommitmentStateTypes => {
  switch (action.type) {
    case SubcontractorsCommitmentActionTypes.FETCH_ALL_SUBCONTRACTORS_COMMITMENT:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case SubcontractorsCommitmentActionTypes.FETCH_ALL_SUBCONTRACTORS_COMMITMENT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case SubcontractorsCommitmentActionTypes.FETCH_ALL_SUBCONTRACTORS_COMMITMENT_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case SubcontractorsCommitmentActionTypes.FETCH_ALL_SUBCONTRACTORS_COMMITMENT_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case SubcontractorsCommitmentActionTypes.FETCH_ONE_SUBCONTRACTORS_COMMITMENT:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case SubcontractorsCommitmentActionTypes.FETCH_ONE_SUBCONTRACTORS_COMMITMENT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case SubcontractorsCommitmentActionTypes.FETCH_ONE_SUBCONTRACTORS_COMMITMENT_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case SubcontractorsCommitmentActionTypes.FETCH_ONE_SUBCONTRACTORS_COMMITMENT_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default SubcontractorsCommitmentReducer;
