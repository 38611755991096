import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MaterialRequiredSummaryActionTypes } from "./MaterialRequiredSummary.type";

export function* fetchAllMaterialRequiredSummarys(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/material-required-summary?project_id=${action.payload.project_id}`);
    yield put({
      type: MaterialRequiredSummaryActionTypes.FETCH_ALL_MATERIAL_REQUIRED_SUMMARY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialRequiredSummaryActionTypes.FETCH_ALL_MATERIAL_REQUIRED_SUMMARY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneMaterialRequiredSummarys(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/material-required-summary/${action.payload}`
    );
    yield put({
      type: MaterialRequiredSummaryActionTypes.FETCH_ONE_MATERIAL_REQUIRED_SUMMARY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialRequiredSummaryActionTypes.FETCH_ONE_MATERIAL_REQUIRED_SUMMARY_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllMaterialRequiredSummarys() {
  yield takeLatest(MaterialRequiredSummaryActionTypes.FETCH_ALL_MATERIAL_REQUIRED_SUMMARY, fetchAllMaterialRequiredSummarys);
}

export function* watcherFetchOneMaterialRequiredSummarys() {
  yield takeLatest(MaterialRequiredSummaryActionTypes.FETCH_ONE_MATERIAL_REQUIRED_SUMMARY, fetchOneMaterialRequiredSummarys);
}
