import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ActionPlanExpenseSummaryActionTypes } from "./ActionPlanExpenseSummary.type";

export function* fetchAllActionPlanExpenseSummarys(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/action-plan-expense-summary?date=${action.payload.date}`);
    yield put({
      type: ActionPlanExpenseSummaryActionTypes.FETCH_ALL_ACTION_PLAN_EXPENSE_SUMMARY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionPlanExpenseSummaryActionTypes.FETCH_ALL_ACTION_PLAN_EXPENSE_SUMMARY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneActionPlanExpenseSummarys(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/action-plan-expense-summary/${action.payload}`
    );
    yield put({
      type: ActionPlanExpenseSummaryActionTypes.FETCH_ONE_ACTION_PLAN_EXPENSE_SUMMARY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionPlanExpenseSummaryActionTypes.FETCH_ONE_ACTION_PLAN_EXPENSE_SUMMARY_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllActionPlanExpenseSummarys() {
  yield takeLatest(ActionPlanExpenseSummaryActionTypes.FETCH_ALL_ACTION_PLAN_EXPENSE_SUMMARY, fetchAllActionPlanExpenseSummarys);
}

export function* watcherFetchOneActionPlanExpenseSummarys() {
  yield takeLatest(ActionPlanExpenseSummaryActionTypes.FETCH_ONE_ACTION_PLAN_EXPENSE_SUMMARY, fetchOneActionPlanExpenseSummarys);
}
