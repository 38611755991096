import axios from "axios";
import moment from "moment";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { EquipmentScheduleActionTypes } from "./EquipmentSchedule.type";

export function* fetchAllEquipmentSchedules(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/equipment-schedule?project_id=${action.payload.project_id}&date=${moment(action.payload?.date).endOf("week").format("DD-MM-YYYY")}`);
    yield put({
      type: EquipmentScheduleActionTypes.FETCH_ALL_EQUIPMENT_SCHEDULE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EquipmentScheduleActionTypes.FETCH_ALL_EQUIPMENT_SCHEDULE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneEquipmentSchedules(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/equipment_schedule/${action.payload}`
    );
    yield put({
      type: EquipmentScheduleActionTypes.FETCH_ONE_EQUIPMENT_SCHEDULE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EquipmentScheduleActionTypes.FETCH_ONE_EQUIPMENT_SCHEDULE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllEquipmentSchedules() {
  yield takeLatest(EquipmentScheduleActionTypes.FETCH_ALL_EQUIPMENT_SCHEDULE, fetchAllEquipmentSchedules);
}

export function* watcherFetchOneEquipmentSchedules() {
  yield takeLatest(EquipmentScheduleActionTypes.FETCH_ONE_EQUIPMENT_SCHEDULE, fetchOneEquipmentSchedules);
}
