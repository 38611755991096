import { ApiCallState } from "../Utils";
import { Moment } from "moment";

export type MonthMaterialSchedule= {
    project_id: number | null;
    id: number | null;
    material_id:number;
    total_material_delivered:number;
    month_material_delivery:number;
    date:Moment;
    rate:number;
    amount:number;
    remark:string;
};

export type MonthMaterialScheduleStateTypes = {
  fetchAll: ApiCallState<MonthMaterialSchedule[]>;
  fetchOne: ApiCallState<MonthMaterialSchedule | {}>;
};

export type MonthMaterialScheduleActionType = {
    project_id: number;
    date: string;
};

export const MonthMaterialScheduleActionTypes = {
  FETCH_ALL_MONTH_MATERIAL_SCHEDULE: "FETCH_ALL_MONTH_MATERIAL_SCHEDULE",
  FETCH_ALL_MONTH_MATERIAL_SCHEDULE_RESET: "FETCH_ALL_MONTH_MATERIAL_SCHEDULE_RESET",
  FETCH_ALL_MONTH_MATERIAL_SCHEDULE_FAILURE: "FETCH_ALL_MONTH_MATERIAL_SCHEDULE_FAILURE",
  FETCH_ALL_MONTH_MATERIAL_SCHEDULE_SUCCESS: "FETCH_ALL_MONTH_MATERIAL_SCHEDULE_SUCCESS",

  FETCH_ONE_MONTH_MATERIAL_SCHEDULE: "FETCH_ONE_MONTH_MATERIAL_SCHEDULE",
  FETCH_ONE_MONTH_MATERIAL_SCHEDULE_RESET: "FETCH_ONE_MONTH_MATERIAL_SCHEDULE_RESET",
  FETCH_ONE_MONTH_MATERIAL_SCHEDULE_FAILURE: "FETCH_ONE_MONTH_MATERIAL_SCHEDULE_FAILURE",
  FETCH_ONE_MONTH_MATERIAL_SCHEDULE_SUCCESS: "FETCH_ONE_MONTH_MATERIAL_SCHEDULE_SUCCESS",
};
