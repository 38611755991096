import { Department } from "../Department/Department.type";
import { Material } from "../Material/Material.type";
import { Project } from "../Project/Project.type";
import { Supplier } from "../Supplier/Supplier.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type PurchaseOrder = {
  id: number;
  purchase_requisition_id: number;
  requested_to: string;
  department_id?: number;
  project_id?: number;
  project?: Project;
  department?: Department;
  supplier: Supplier;
  type: string;
  currency: string;
  date: string;
  remark: string;
  purchaser: string;
  shipment: string;
  payment_method: string;
  partial_shipment: string;
  trans_shipment: string;
  delivery_place: number;
  port_of_loading: string;
  port_of_destination: string;
  freight_charge: number;
  document_receiving_bank: string;
  purchase_order_items: PurchaseOrderItem[];
  po_prepared_by: User | null;
  po_checked_by: User | null;
  po_authorized_by: User | null;
  supplier_id: number;
  is_done: boolean;
  is_approved: boolean;
  is_checked: boolean;
  with_hold: boolean;
  approvers: number[];
  checkers: number[];
  tax: number;
};

export type PurchaseOrderItem = {
  material_id: number;
  material: Material;
  quantity: number;
  received: number;
  unit_price: number;
  unit: string;
  total: number;
  key: number;
  purchase_item_id: number;
  purchase_requisition_id: number;
  id: number;
};

export type PurchaseOrderStateTypes = {
  fetchAll: ApiCallState<PurchaseOrder[]>;
  fetchOne: ApiCallState<PurchaseOrder | {}>;
};

export const PurchaseOrderActionTypes = {
  FETCH_ALL_PURCHASE_ORDER: "FETCH_ALL_PURCHASE_ORDER",
  FETCH_ALL_PURCHASE_ORDER_RESET: "FETCH_ALL_PURCHASE_ORDER_RESET",
  FETCH_ALL_PURCHASE_ORDER_FAILURE: "FETCH_ALL_PURCHASE_ORDER_FAILURE",
  FETCH_ALL_PURCHASE_ORDER_SUCCESS: "FETCH_ALL_PURCHASE_ORDER_SUCCESS",

  FETCH_ONE_PURCHASE_ORDER: "FETCH_ONE_PURCHASE_ORDER",
  FETCH_ONE_PURCHASE_ORDER_RESET: "FETCH_ONE_PURCHASE_ORDER_RESET",
  FETCH_ONE_PURCHASE_ORDER_FAILURE: "FETCH_ONE_PURCHASE_ORDER_FAILURE",
  FETCH_ONE_PURCHASE_ORDER_SUCCESS: "FETCH_ONE_PURCHASE_ORDER_SUCCESS",
};
