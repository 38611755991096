import { ActionPlanMaterialStateTypes, ActionPlanMaterialActionTypes } from "./ActionPlanMaterial.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: ActionPlanMaterialStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const ActionPlanMaterialReducer = (
  state: ActionPlanMaterialStateTypes = INITIAL_STATE,
  action: any
): ActionPlanMaterialStateTypes => {
  switch (action.type) {
    case ActionPlanMaterialActionTypes.FETCH_ALL_ACTION_PLAN_MATERIAL:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case ActionPlanMaterialActionTypes.FETCH_ALL_ACTION_PLAN_MATERIAL_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ActionPlanMaterialActionTypes.FETCH_ALL_ACTION_PLAN_MATERIAL_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ActionPlanMaterialActionTypes.FETCH_ALL_ACTION_PLAN_MATERIAL_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case ActionPlanMaterialActionTypes.FETCH_ONE_ACTION_PLAN_MATERIAL:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case ActionPlanMaterialActionTypes.FETCH_ONE_ACTION_PLAN_MATERIAL_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ActionPlanMaterialActionTypes.FETCH_ONE_ACTION_PLAN_MATERIAL_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ActionPlanMaterialActionTypes.FETCH_ONE_ACTION_PLAN_MATERIAL_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default ActionPlanMaterialReducer;
