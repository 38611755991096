import { ActionPlanEquipmentStateTypes, ActionPlanEquipmentActionTypes } from "./ActionPlanEquipment.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: ActionPlanEquipmentStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const ActionPlanEquipmentReducer = (
  state: ActionPlanEquipmentStateTypes = INITIAL_STATE,
  action: any
): ActionPlanEquipmentStateTypes => {
  switch (action.type) {
    case ActionPlanEquipmentActionTypes.FETCH_ALL_ACTION_PLAN_EQUIPMENT:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case ActionPlanEquipmentActionTypes.FETCH_ALL_ACTION_PLAN_EQUIPMENT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ActionPlanEquipmentActionTypes.FETCH_ALL_ACTION_PLAN_EQUIPMENT_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ActionPlanEquipmentActionTypes.FETCH_ALL_ACTION_PLAN_EQUIPMENT_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case ActionPlanEquipmentActionTypes.FETCH_ONE_ACTION_PLAN_EQUIPMENT:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case ActionPlanEquipmentActionTypes.FETCH_ONE_ACTION_PLAN_EQUIPMENT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ActionPlanEquipmentActionTypes.FETCH_ONE_ACTION_PLAN_EQUIPMENT_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ActionPlanEquipmentActionTypes.FETCH_ONE_ACTION_PLAN_EQUIPMENT_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default ActionPlanEquipmentReducer;
