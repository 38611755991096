import { SupplyFixContract } from "../SupplyFixContract/SupplyFixContract.type";
import { SupplyFixWorkProgress } from "../SupplyFixWorkProgress/SupplyFixWorkProgress.type";
import { ApiCallState } from "../Utils";

export type SupplyFix = {
  id: number;
  sub_contractor: string;
  type_of_work: string;
  project_id: number;
  start_date: string;
  end_date: string
  supply_fix_contracts: SupplyFixContract[],
  supply_fix_work_progresses: SupplyFixWorkProgress[]
};

export type SupplyFixStateTypes = {
  fetchAll: ApiCallState<SupplyFix[]>;
  fetchOne: ApiCallState<SupplyFix | {}>;
};

export const SupplyFixActionTypes = {
  FETCH_ALL_SUPPLY_FIX: "FETCH_ALL_SUPPLY_FIX",
  FETCH_ALL_SUPPLY_FIX_RESET: "FETCH_ALL_SUPPLY_FIX_RESET",
  FETCH_ALL_SUPPLY_FIX_FAILURE: "FETCH_ALL_SUPPLY_FIX_FAILURE",
  FETCH_ALL_SUPPLY_FIX_SUCCESS: "FETCH_ALL_SUPPLY_FIX_SUCCESS",

  FETCH_ONE_SUPPLY_FIX: "FETCH_ONE_SUPPLY_FIX",
  FETCH_ONE_SUPPLY_FIX_RESET: "FETCH_ONE_SUPPLY_FIX_RESET",
  FETCH_ONE_SUPPLY_FIX_FAILURE: "FETCH_ONE_SUPPLY_FIX_FAILURE",
  FETCH_ONE_SUPPLY_FIX_SUCCESS: "FETCH_ONE_SUPPLY_FIX_SUCCESS",
};
