import { ApiCallState } from "../Utils";

export type ActionPlanExpenseSummary= {
  id: number | null;
  date:string;
  project_id:number;
  planned_fuel_and_lubricant_expense:number;
  planned_indirect_labour_expense:number;
  planned_indirect_expense:number;
  total_planned_profit:number;
  total_planned_profit_percetage:number;
  remark:string;
};

export type ActionPlanExpenseSummaryStateTypes = {
  fetchAll: ApiCallState<ActionPlanExpenseSummary[]>;
  fetchOne: ApiCallState<ActionPlanExpenseSummary | {}>;
};

export const ActionPlanExpenseSummaryActionTypes = {
  FETCH_ALL_ACTION_PLAN_EXPENSE_SUMMARY: "FETCH_ALL_ACTION_PLAN_EXPENSE_SUMMARY",
  FETCH_ALL_ACTION_PLAN_EXPENSE_SUMMARY_RESET: "FETCH_ALL_ACTION_PLAN_EXPENSE_SUMMARY_RESET",
  FETCH_ALL_ACTION_PLAN_EXPENSE_SUMMARY_FAILURE: "FETCH_ALL_ACTION_PLAN_EXPENSE_SUMMARY_FAILURE",
  FETCH_ALL_ACTION_PLAN_EXPENSE_SUMMARY_SUCCESS: "FETCH_ALL_ACTION_PLAN_EXPENSE_SUMMARY_SUCCESS",

  FETCH_ONE_ACTION_PLAN_EXPENSE_SUMMARY: "FETCH_ONE_ACTION_PLAN_EXPENSE_SUMMARY",
  FETCH_ONE_ACTION_PLAN_EXPENSE_SUMMARY_RESET: "FETCH_ONE_ACTION_PLAN_EXPENSE_SUMMARY_RESET",
  FETCH_ONE_ACTION_PLAN_EXPENSE_SUMMARY_FAILURE: "FETCH_ONE_ACTION_PLAN_EXPENSE_SUMMARY_FAILURE",
  FETCH_ONE_ACTION_PLAN_EXPENSE_SUMMARY_SUCCESS: "FETCH_ONE_ACTION_PLAN_EXPENSE_SUMMARY_SUCCESS",
};
