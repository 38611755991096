import { ApiCallState } from "../Utils";

export type RoadFuelRequest= {
  id: number | null;
  project_id:number | null;
  date:string;
  request_no:string;
  total_consumption_diesel_oil:number;
  total_consumption_benzene:number;
  approval_quantity_for_this_week:number;
  required_amount_in_birr:number;
  previous_week_balance:number;
  this_week_balance:number;
  current_benzene_price:number;
  current_diesel_price:number;
  road_fuel_request_details:RoadFuelRequestDetail[];
};

export type RoadFuelRequestDetail= {
    id: number | null;
    road_fuel_request_id:number | null;
    is_title:boolean;
    boq_id:number;
    work_description:string;
    work_volume:number;
    total_length_of_road:number;
    total_excuting_quantity:number;
    unit:string;
    machine:string;
    fuel_type:string;
    required_no:number;
    total_expected_no_of_trip:number;
    expected_round_trip_distance:number;
    total_distance:number;
    index_daily_output:number;
    index_daily_output_per_m3:number;
    working_days:number;
    total_working_days:number;
    expected_working:number;
    total_expected_working_hr:number;
    fuel_consumption:number;
    total_fuel_consumption_hr:number;
    total_fuel_consumption:number;
    total_fuel_required:number;
    remark:string;
}

export type RoadFuelRequestStateTypes = {
  fetchAll: ApiCallState<RoadFuelRequest[]>;
  fetchOne: ApiCallState<RoadFuelRequest | {}>;
};

export const RoadFuelRequestActionTypes = {
  FETCH_ALL_ROAD_FUEL_REQUEST: "FETCH_ALL_ROAD_FUEL_REQUEST",
  FETCH_ALL_ROAD_FUEL_REQUEST_RESET: "FETCH_ALL_ROAD_FUEL_REQUEST_RESET",
  FETCH_ALL_ROAD_FUEL_REQUEST_FAILURE: "FETCH_ALL_ROAD_FUEL_REQUEST_FAILURE",
  FETCH_ALL_ROAD_FUEL_REQUEST_SUCCESS: "FETCH_ALL_ROAD_FUEL_REQUEST_SUCCESS",

  FETCH_ONE_ROAD_FUEL_REQUEST: "FETCH_ONE_ROAD_FUEL_REQUEST",
  FETCH_ONE_ROAD_FUEL_REQUEST_RESET: "FETCH_ONE_ROAD_FUEL_REQUEST_RESET",
  FETCH_ONE_ROAD_FUEL_REQUEST_FAILURE: "FETCH_ONE_ROAD_FUEL_REQUEST_FAILURE",
  FETCH_ONE_ROAD_FUEL_REQUEST_SUCCESS: "FETCH_ONE_ROAD_FUEL_REQUEST_SUCCESS",
};
