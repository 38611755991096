import { ApiCallState } from "../Utils";

export type MaterialPurchaseFollowUp= {
    id: number | null;
    project_id:number | null;
    mr_number:number;
    description:string;
    unit:string;
    requested_previous_quantity:number;
    requested_current_quantity:number;
    requested_todate:number;
    purchased_previous_quantity:number;
    purchased_current_quantity:number;
    purchased_todate:number;
    balance:number;
    unit_price:number;
    requested_amount:number;
    purchased_amount:number;
    amount_not_purchased:number;

};

export type MaterialPurchaseFollowUpStateTypes = {
  fetchAll: ApiCallState<MaterialPurchaseFollowUp[]>;
  fetchOne: ApiCallState<MaterialPurchaseFollowUp | {}>;
};

export const MaterialPurchaseFollowUpActionTypes = {
  FETCH_ALL_MATERIAL_PURCHASE_FOLLOW_UP: "FETCH_ALL_MATERIAL_PURCHASE_FOLLOW_UP",
  FETCH_ALL_MATERIAL_PURCHASE_FOLLOW_UP_RESET: "FETCH_ALL_MATERIAL_PURCHASE_FOLLOW_UP_RESET",
  FETCH_ALL_MATERIAL_PURCHASE_FOLLOW_UP_FAILURE: "FETCH_ALL_MATERIAL_PURCHASE_FOLLOW_UP_FAILURE",
  FETCH_ALL_MATERIAL_PURCHASE_FOLLOW_UP_SUCCESS: "FETCH_ALL_MATERIAL_PURCHASE_FOLLOW_UP_SUCCESS",

  FETCH_ONE_MATERIAL_PURCHASE_FOLLOW_UP: "FETCH_ONE_MATERIAL_PURCHASE_FOLLOW_UP",
  FETCH_ONE_MATERIAL_PURCHASE_FOLLOW_UP_RESET: "FETCH_ONE_MATERIAL_PURCHASE_FOLLOW_UP_RESET",
  FETCH_ONE_MATERIAL_PURCHASE_FOLLOW_UP_FAILURE: "FETCH_ONE_MATERIAL_PURCHASE_FOLLOW_UP_FAILURE",
  FETCH_ONE_MATERIAL_PURCHASE_FOLLOW_UP_SUCCESS: "FETCH_ONE_MATERIAL_PURCHASE_FOLLOW_UP_SUCCESS",
};
