import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ProjectLetterActionTypes } from "./ProjectLetter.type";

export function* fetchAllProjectLetters(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/project-letter?project_id=${action.payload.project_id}`);
    yield put({
      type: ProjectLetterActionTypes.FETCH_ALL_PROJECT_LETTER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ProjectLetterActionTypes.FETCH_ALL_PROJECT_LETTER_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneProjectLetters(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/project-letter/${action.payload}`
    );
    yield put({
      type: ProjectLetterActionTypes.FETCH_ONE_PROJECT_LETTER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ProjectLetterActionTypes.FETCH_ONE_PROJECT_LETTER_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllProjectLetters() {
  yield takeLatest(ProjectLetterActionTypes.FETCH_ALL_PROJECT_LETTER, fetchAllProjectLetters);
}

export function* watcherFetchOneProjectLetters() {
  yield takeLatest(ProjectLetterActionTypes.FETCH_ONE_PROJECT_LETTER, fetchOneProjectLetters);
}
