import { ApiCallState } from "../Utils";
import { Moment } from "moment";

export type WeatherCondition= {
    project_id: number | null;
    id: number | null;
    date: Moment;
    description:string;
    location:string;
    unit:string;
    quantity:number;
    idle_manpower:number;
    idle_machinaries:number;
    working_hour:number;
    remark:string;
};

export type WeatherConditionStateTypes = {
  fetchAll: ApiCallState<WeatherCondition[]>;
  fetchOne: ApiCallState<WeatherCondition | {}>;
};

export type WeatherConditionActionType = {
    project_id: number;
    date: string;
};

export const WeatherConditionActionTypes = {
  FETCH_ALL_WEATHER_CONDITION: "FETCH_ALL_WEATHER_CONDITION",
  FETCH_ALL_WEATHER_CONDITION_RESET: "FETCH_ALL_WEATHER_CONDITION_RESET",
  FETCH_ALL_WEATHER_CONDITION_FAILURE: "FETCH_ALL_WEATHER_CONDITION_FAILURE",
  FETCH_ALL_WEATHER_CONDITION_SUCCESS: "FETCH_ALL_WEATHER_CONDITION_SUCCESS",

  FETCH_ONE_WEATHER_CONDITION: "FETCH_ONE_WEATHER_CONDITION",
  FETCH_ONE_WEATHER_CONDITION_RESET: "FETCH_ONE_WEATHER_CONDITION_RESET",
  FETCH_ONE_WEATHER_CONDITION_FAILURE: "FETCH_ONE_WEATHER_CONDITION_FAILURE",
  FETCH_ONE_WEATHER_CONDITION_SUCCESS: "FETCH_ONE_WEATHER_CONDITION_SUCCESS",
};
