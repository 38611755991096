import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ProjectOperationReportActionTypes } from "./ProjectOperationReport.type";

export function* fetchAllProjectOperationReports(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/project-operation-report?project_id=${action.payload?.project_id}`);
    yield put({
      type: ProjectOperationReportActionTypes.FETCH_ALL_PROJECT_OPERATION_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ProjectOperationReportActionTypes.FETCH_ALL_PROJECT_OPERATION_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneProjectOperationReports(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/project-operation-report/${action.payload}`
    );
    yield put({
      type: ProjectOperationReportActionTypes.FETCH_ONE_PROJECT_OPERATION_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ProjectOperationReportActionTypes.FETCH_ONE_PROJECT_OPERATION_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllProjectOperationReports() {
  yield takeLatest(ProjectOperationReportActionTypes.FETCH_ALL_PROJECT_OPERATION_REPORT, fetchAllProjectOperationReports);
}

export function* watcherFetchOneProjectOperationReports() {
  yield takeLatest(ProjectOperationReportActionTypes.FETCH_ONE_PROJECT_OPERATION_REPORT, fetchOneProjectOperationReports);
}
