import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { DailyReportActionTypes } from "./DailyReport.type";

export function* fetchAllDailyReports(action: any): any {
  try {
    let query = "";

    if (Object.keys(action.payload).length > 0) {
      let keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }

    const response = yield axios.get(`${API_BASE_URI}/daily_report?${query}`);
    yield put({
      type: DailyReportActionTypes.FETCH_ALL_DAILY_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DailyReportActionTypes.FETCH_ALL_DAILY_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneDailyReports(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/daily_report/${action.payload}`
    );
    yield put({
      type: DailyReportActionTypes.FETCH_ONE_DAILY_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DailyReportActionTypes.FETCH_ONE_DAILY_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllDailyReports() {
  yield takeLatest(
    DailyReportActionTypes.FETCH_ALL_DAILY_REPORT,
    fetchAllDailyReports
  );
}

export function* watcherFetchOneDailyReports() {
  yield takeLatest(
    DailyReportActionTypes.FETCH_ONE_DAILY_REPORT,
    fetchOneDailyReports
  );
}
