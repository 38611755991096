import { ApiCallState } from "../Utils";
import { Moment } from "moment";

export type DailySurveying= {
    project_id: number | null;
    id: number | null;
    date:Moment;
    name:string;
    position:string;
    row_date:Moment;
    station:string;
    work_description:string;
    remark:string;
};

export type DailySurveyingStateTypes = {
  fetchAll: ApiCallState<DailySurveying[]>;
  fetchOne: ApiCallState<DailySurveying | {}>;
};

export type DailySurveyingActionType = {
    project_id: number;
    date: string;
};

export const DailySurveyingActionTypes = {
  FETCH_ALL_DAILY_SURVEYING: "FETCH_ALL_DAILY_SURVEYING",
  FETCH_ALL_DAILY_SURVEYING_RESET: "FETCH_ALL_DAILY_SURVEYING_RESET",
  FETCH_ALL_DAILY_SURVEYING_FAILURE: "FETCH_ALL_DAILY_SURVEYING_FAILURE",
  FETCH_ALL_DAILY_SURVEYING_SUCCESS: "FETCH_ALL_DAILY_SURVEYING_SUCCESS",

  FETCH_ONE_DAILY_SURVEYING: "FETCH_ONE_DAILY_SURVEYING",
  FETCH_ONE_DAILY_SURVEYING_RESET: "FETCH_ONE_DAILY_SURVEYING_RESET",
  FETCH_ONE_DAILY_SURVEYING_FAILURE: "FETCH_ONE_DAILY_SURVEYING_FAILURE",
  FETCH_ONE_DAILY_SURVEYING_SUCCESS: "FETCH_ONE_DAILY_SURVEYING_SUCCESS",
};
