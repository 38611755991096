import { RentContract } from "../RentContract/RentContract.type";
import { ApiCallState } from "../Utils";

export type RentContractProject = {
  id: number;
  name: string;

  contracts: RentContract[];
};

export type RentContractProjectStateTypes = {
  fetchAll: ApiCallState<RentContractProject[]>;
  fetchOne: ApiCallState<RentContractProject | {}>;
};

export const RentContractProjectActionTypes = {
  FETCH_ALL_RENT_CONTRACT_PROJECT: "FETCH_ALL_RENT_CONTRACT_PROJECT",
  FETCH_ALL_RENT_CONTRACT_PROJECT_RESET:
    "FETCH_ALL_RENT_CONTRACT_PROJECT_RESET",
  FETCH_ALL_RENT_CONTRACT_PROJECT_FAILURE:
    "FETCH_ALL_RENT_CONTRACT_PROJECT_FAILURE",
  FETCH_ALL_RENT_CONTRACT_PROJECT_SUCCESS:
    "FETCH_ALL_RENT_CONTRACT_PROJECT_SUCCESS",

  FETCH_ONE_RENT_CONTRACT_PROJECT: "FETCH_ONE_RENT_CONTRACT_PROJECT",
  FETCH_ONE_RENT_CONTRACT_PROJECT_RESET:
    "FETCH_ONE_RENT_CONTRACT_PROJECT_RESET",
  FETCH_ONE_RENT_CONTRACT_PROJECT_FAILURE:
    "FETCH_ONE_RENT_CONTRACT_PROJECT_FAILURE",
  FETCH_ONE_RENT_CONTRACT_PROJECT_SUCCESS:
    "FETCH_ONE_RENT_CONTRACT_PROJECT_SUCCESS",
};
