import { MasterList } from "../MasterList/MasterList.type";
import { ApiCallState } from "../Utils";

export type EquipmentDailyDownIdleReport = {
  id: number;
  user_id: number;

  date: number;

  equipment_daily_down_idle_report_items: EquipmentDailyDownIdleReportItem[];
};

export type EquipmentDailyDownIdleReportItem = {
  id: number;
  equipment_daily_down_idle_report_id: number;
  master_list_id: number;

  time_in: string;
  date_in: string;

  time_out: string;
  to_date: string;

  description: string;
  reason: string;

  master_list: MasterList;

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type EquipmentDailyDownIdleReportStateTypes = {
  fetchAll: ApiCallState<EquipmentDailyDownIdleReport[]>;
  fetchOne: ApiCallState<EquipmentDailyDownIdleReport | {}>;
  fetchPrevious: ApiCallState<EquipmentDailyDownIdleReport | {}>;
};

export const EquipmentDailyDownIdleReportActionTypes = {
  FETCH_ALL_EQUIPMENT_DAILY_BREAKDOWN_IDLE_REPORT:
    "FETCH_ALL_EQUIPMENT_DAILY_BREAKDOWN_IDLE_REPORT",
  FETCH_ALL_EQUIPMENT_DAILY_BREAKDOWN_IDLE_REPORT_RESET:
    "FETCH_ALL_EQUIPMENT_DAILY_BREAKDOWN_IDLE_REPORT_RESET",
  FETCH_ALL_EQUIPMENT_DAILY_BREAKDOWN_IDLE_REPORT_FAILURE:
    "FETCH_ALL_EQUIPMENT_DAILY_BREAKDOWN_IDLE_REPORT_FAILURE",
  FETCH_ALL_EQUIPMENT_DAILY_BREAKDOWN_IDLE_REPORT_SUCCESS:
    "FETCH_ALL_EQUIPMENT_DAILY_BREAKDOWN_IDLE_REPORT_SUCCESS",

  FETCH_ONE_EQUIPMENT_DAILY_BREAKDOWN_IDLE_REPORT:
    "FETCH_ONE_EQUIPMENT_DAILY_BREAKDOWN_IDLE_REPORT",
  FETCH_ONE_EQUIPMENT_DAILY_BREAKDOWN_IDLE_REPORT_RESET:
    "FETCH_ONE_EQUIPMENT_DAILY_BREAKDOWN_IDLE_REPORT_RESET",
  FETCH_ONE_EQUIPMENT_DAILY_BREAKDOWN_IDLE_REPORT_FAILURE:
    "FETCH_ONE_EQUIPMENT_DAILY_BREAKDOWN_IDLE_REPORT_FAILURE",
  FETCH_ONE_EQUIPMENT_DAILY_BREAKDOWN_IDLE_REPORT_SUCCESS:
    "FETCH_ONE_EQUIPMENT_DAILY_BREAKDOWN_IDLE_REPORT_SUCCESS",

  FETCH_PREVIOUS_EQUIPMENT_DAILY_BREAKDOWN_IDLE_REPORT:
    "FETCH_PREVIOUS_EQUIPMENT_DAILY_BREAKDOWN_IDLE_REPORT",
  FETCH_PREVIOUS_EQUIPMENT_DAILY_BREAKDOWN_IDLE_REPORT_RESET:
    "FETCH_PREVIOUS_EQUIPMENT_DAILY_BREAKDOWN_IDLE_REPORT_RESET",
  FETCH_PREVIOUS_EQUIPMENT_DAILY_BREAKDOWN_IDLE_REPORT_FAILURE:
    "FETCH_PREVIOUS_EQUIPMENT_DAILY_BREAKDOWN_IDLE_REPORT_FAILURE",
  FETCH_PREVIOUS_EQUIPMENT_DAILY_BREAKDOWN_IDLE_REPORT_SUCCESS:
    "FETCH_PREVIOUS_EQUIPMENT_DAILY_BREAKDOWN_IDLE_REPORT_SUCCESS",
};
