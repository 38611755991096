import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { EquipmentDailyDownIdleReportActionTypes } from "./EquipmentDailyDownIdleReport.type";

export function* fetchAllEquipmentDailyDownIdleReports(action: any): any {
  try {
    let query = "";

    if (Object.keys(action.payload).length > 0) {
      query = Object.keys(action.payload)
        .map((key) => key + "=" + action.payload[key])
        .join("&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/equipment-daily-breakdown-idle-report?${query}`
    );
    yield put({
      type: EquipmentDailyDownIdleReportActionTypes.FETCH_ALL_EQUIPMENT_DAILY_BREAKDOWN_IDLE_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EquipmentDailyDownIdleReportActionTypes.FETCH_ALL_EQUIPMENT_DAILY_BREAKDOWN_IDLE_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneEquipmentDailyDownIdleReports(action: any): any {
  try {
    let query = "";

    if (Object.keys(action.payload).length > 0) {
      query = Object.keys(action.payload)
        .map((key) => key + "=" + action.payload[key])
        .join("&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/equipment-daily-breakdown-idle-report/one?${query}`
    );
    yield put({
      type: EquipmentDailyDownIdleReportActionTypes.FETCH_ONE_EQUIPMENT_DAILY_BREAKDOWN_IDLE_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EquipmentDailyDownIdleReportActionTypes.FETCH_ONE_EQUIPMENT_DAILY_BREAKDOWN_IDLE_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchPreviousEquipmentDailyDownIdleReports(action: any): any {
  try {
    let query = "";

    if (Object.keys(action.payload).length > 0) {
      query = Object.keys(action.payload)
        .map((key) => key + "=" + action.payload[key])
        .join("&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/equipment-daily-breakdown-idle-report/previous?${query}`
    );
    yield put({
      type: EquipmentDailyDownIdleReportActionTypes.FETCH_PREVIOUS_EQUIPMENT_DAILY_BREAKDOWN_IDLE_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EquipmentDailyDownIdleReportActionTypes.FETCH_PREVIOUS_EQUIPMENT_DAILY_BREAKDOWN_IDLE_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllEquipmentDailyDownIdleReports() {
  yield takeLatest(
    EquipmentDailyDownIdleReportActionTypes.FETCH_ALL_EQUIPMENT_DAILY_BREAKDOWN_IDLE_REPORT,
    fetchAllEquipmentDailyDownIdleReports
  );
}

export function* watcherFetchOneEquipmentDailyDownIdleReports() {
  yield takeLatest(
    EquipmentDailyDownIdleReportActionTypes.FETCH_ONE_EQUIPMENT_DAILY_BREAKDOWN_IDLE_REPORT,
    fetchOneEquipmentDailyDownIdleReports
  );
}

export function* watcherFetchPreviousEquipmentDailyDownIdleReports() {
  yield takeLatest(
    EquipmentDailyDownIdleReportActionTypes.FETCH_PREVIOUS_EQUIPMENT_DAILY_BREAKDOWN_IDLE_REPORT,
    fetchPreviousEquipmentDailyDownIdleReports
  );
}
