import { ApiCallState } from "../Utils";
import { Moment } from "moment";

export type MonthEquipmentSchedule= {
    project_id: number | null;
    id: number | null;
    material_id:number;
    date: Moment;
    rate: number;
    total_required_hr: number;
    total_amount: number;
    remark: string;
    week1: number;
    week2: number;
    week3: number;
    week4: number;
};

export type MonthEquipmentScheduleStateTypes = {
  fetchAll: ApiCallState<MonthEquipmentSchedule[]>;
  fetchOne: ApiCallState<MonthEquipmentSchedule | {}>;
};

export type MonthEquipmentScheduleActionType = {
    project_id: number;
    date: string;
};

export const MonthEquipmentScheduleActionTypes = {
  FETCH_ALL_MONTH_EQUIPMENT_SCHEDULE: "FETCH_ALL_MONTH_EQUIPMENT_SCHEDULE",
  FETCH_ALL_MONTH_EQUIPMENT_SCHEDULE_RESET: "FETCH_ALL_MONTH_EQUIPMENT_SCHEDULE_RESET",
  FETCH_ALL_MONTH_EQUIPMENT_SCHEDULE_FAILURE: "FETCH_ALL_MONTH_EQUIPMENT_SCHEDULE_FAILURE",
  FETCH_ALL_MONTH_EQUIPMENT_SCHEDULE_SUCCESS: "FETCH_ALL_MONTH_EQUIPMENT_SCHEDULE_SUCCESS",

  FETCH_ONE_MONTH_EQUIPMENT_SCHEDULE: "FETCH_ONE_MONTH_EQUIPMENT_SCHEDULE",
  FETCH_ONE_MONTH_EQUIPMENT_SCHEDULE_RESET: "FETCH_ONE_MONTH_EQUIPMENT_SCHEDULE_RESET",
  FETCH_ONE_MONTH_EQUIPMENT_SCHEDULE_FAILURE: "FETCH_ONE_MONTH_EQUIPMENT_SCHEDULE_FAILURE",
  FETCH_ONE_MONTH_EQUIPMENT_SCHEDULE_SUCCESS: "FETCH_ONE_MONTH_EQUIPMENT_SCHEDULE_SUCCESS",
};
