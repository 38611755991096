import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { WithHoldPaymentActionTypes } from "./WithHoldPayment.type";

export function* fetchAllWithHoldPayments(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/with-hold-payment`);
    yield put({
      type: WithHoldPaymentActionTypes.FETCH_ALL_WITH_HOLD_PAYMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: WithHoldPaymentActionTypes.FETCH_ALL_WITH_HOLD_PAYMENT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneWithHoldPayments(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/with-hold-payment/${action.payload}`
    );
    yield put({
      type: WithHoldPaymentActionTypes.FETCH_ONE_WITH_HOLD_PAYMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: WithHoldPaymentActionTypes.FETCH_ONE_WITH_HOLD_PAYMENT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllWithHoldPayments() {
  yield takeLatest(
    WithHoldPaymentActionTypes.FETCH_ALL_WITH_HOLD_PAYMENT,
    fetchAllWithHoldPayments
  );
}

export function* watcherFetchOneWithHoldPayments() {
  yield takeLatest(
    WithHoldPaymentActionTypes.FETCH_ONE_WITH_HOLD_PAYMENT,
    fetchOneWithHoldPayments
  );
}
