import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { DailySummaryActionTypes } from "./DailySummary.type";

export function* fetchAllDailySummarys(action: any): any {
  try {
  const response = yield axios.get(`${API_BASE_URI}/daily-summary?date=${action.payload?.date}&project_id=${action.payload?.project_id}`);
    yield put({
      type: DailySummaryActionTypes.FETCH_ALL_DAILY_SUMMARY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DailySummaryActionTypes.FETCH_ALL_DAILY_SUMMARY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneDailySummarys(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/daily-summary/one?date=${action.payload?.date}&project_id=${action.payload?.project_id}`
    );
    yield put({
      type: DailySummaryActionTypes.FETCH_ONE_DAILY_SUMMARY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DailySummaryActionTypes.FETCH_ONE_DAILY_SUMMARY_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllDailySummarys() {
  yield takeLatest(DailySummaryActionTypes.FETCH_ALL_DAILY_SUMMARY, fetchAllDailySummarys);
}

export function* watcherFetchOneDailySummarys() {
  yield takeLatest(DailySummaryActionTypes.FETCH_ONE_DAILY_SUMMARY, fetchOneDailySummarys);
}
