import { ApiCallState } from "../Utils";

export type SubcontractorPaymentHandover= {
  id: number | null;
  project_id:number | null;
  date:string;
  sub_contractor:string;
   main_office_date:string;
   main_office_time:string;
   main_office_received:string;
   support_office_date:string;
   support_office_time:string;
   support_office_received:string;
   engineer_office_date:string;
   engineer_office_time:string;
   engineer_office_received:string;
   manager_office_date:string;
   manager_office_time:string;
   manager_office_received:string;
   is_received_main:boolean;
   is_received_support:boolean;
   is_received_engineer:boolean;
   is_received_manager:boolean;
   remark:string;
};

export type SubcontractorPaymentHandoverStateTypes = {
  fetchAll: ApiCallState<SubcontractorPaymentHandover[]>;
  fetchOne: ApiCallState<SubcontractorPaymentHandover | {}>;
};

export const SubcontractorPaymentHandoverActionTypes = {
  FETCH_ALL_SUBCONTRACTOR_PAYMENT_HANDOVER: "FETCH_ALL_SUBCONTRACTOR_PAYMENT_HANDOVER",
  FETCH_ALL_SUBCONTRACTOR_PAYMENT_HANDOVER_RESET: "FETCH_ALL_SUBCONTRACTOR_PAYMENT_HANDOVER_RESET",
  FETCH_ALL_SUBCONTRACTOR_PAYMENT_HANDOVER_FAILURE: "FETCH_ALL_SUBCONTRACTOR_PAYMENT_HANDOVER_FAILURE",
  FETCH_ALL_SUBCONTRACTOR_PAYMENT_HANDOVER_SUCCESS: "FETCH_ALL_SUBCONTRACTOR_PAYMENT_HANDOVER_SUCCESS",

  FETCH_ONE_SUBCONTRACTOR_PAYMENT_HANDOVER: "FETCH_ONE_SUBCONTRACTOR_PAYMENT_HANDOVER",
  FETCH_ONE_SUBCONTRACTOR_PAYMENT_HANDOVER_RESET: "FETCH_ONE_SUBCONTRACTOR_PAYMENT_HANDOVER_RESET",
  FETCH_ONE_SUBCONTRACTOR_PAYMENT_HANDOVER_FAILURE: "FETCH_ONE_SUBCONTRACTOR_PAYMENT_HANDOVER_FAILURE",
  FETCH_ONE_SUBCONTRACTOR_PAYMENT_HANDOVER_SUCCESS: "FETCH_ONE_SUBCONTRACTOR_PAYMENT_HANDOVER_SUCCESS",
};
