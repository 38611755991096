import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { SubContractHandOverActionTypes } from "./SubContractHandOver.type";

export function* fetchAllSubContractHandOvers(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/sub-contract-handover?project_id=${action.payload?.project_id}`
    );
    yield put({
      type: SubContractHandOverActionTypes.FETCH_ALL_SUB_CONTRACT_HAND_OVER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SubContractHandOverActionTypes.FETCH_ALL_SUB_CONTRACT_HAND_OVER_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneSubContractHandOvers(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/sub-contract-handover/${action.payload}`
    );
    yield put({
      type: SubContractHandOverActionTypes.FETCH_ONE_SUB_CONTRACT_HAND_OVER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SubContractHandOverActionTypes.FETCH_ONE_SUB_CONTRACT_HAND_OVER_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllSubContractHandOvers() {
  yield takeLatest(
    SubContractHandOverActionTypes.FETCH_ALL_SUB_CONTRACT_HAND_OVER,
    fetchAllSubContractHandOvers
  );
}

export function* watcherFetchOneSubContractHandOvers() {
  yield takeLatest(
    SubContractHandOverActionTypes.FETCH_ONE_SUB_CONTRACT_HAND_OVER,
    fetchOneSubContractHandOvers
  );
}
