import { Material } from "../Material/Material.type";
import { Project } from "../Project/Project.type";
import { Store } from "../Store/Store.type";
import { ApiCallState } from "../Utils";

export interface Inventory extends Material {
  project?: Project;
  store?: Store;
  quantity: number;
  key: number;
  unit_price: number;
}

export type InventoryStateTypes = {
  fetchAll: ApiCallState<Inventory[]>;
  fetchOne: ApiCallState<Inventory | {}>;
};

export const InventoryActionTypes = {
  FETCH_ALL_INVENTORY: "FETCH_ALL_INVENTORY",
  FETCH_ALL_INVENTORY_RESET: "FETCH_ALL_INVENTORY_RESET",
  FETCH_ALL_INVENTORY_FAILURE: "FETCH_ALL_INVENTORY_FAILURE",
  FETCH_ALL_INVENTORY_SUCCESS: "FETCH_ALL_INVENTORY_SUCCESS",

  FETCH_ONE_INVENTORY: "FETCH_ONE_INVENTORY",
  FETCH_ONE_INVENTORY_RESET: "FETCH_ONE_INVENTORY_RESET",
  FETCH_ONE_INVENTORY_FAILURE: "FETCH_ONE_INVENTORY_FAILURE",
  FETCH_ONE_INVENTORY_SUCCESS: "FETCH_ONE_INVENTORY_SUCCESS",
};
