import axios from "axios";
import moment from "moment";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ManpowerScheduleActionTypes } from "./ManpowerSchedule.type";

export function* fetchAllManpowerSchedules(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/manpower-schedule?project_id=${action.payload.project_id}&date=${moment(action.payload?.date).endOf("week").format("DD-MM-YYYY")}`);
    yield put({
      type: ManpowerScheduleActionTypes.FETCH_ALL_MANPOWER_SCHEDULE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ManpowerScheduleActionTypes.FETCH_ALL_MANPOWER_SCHEDULE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneManpowerSchedules(action: any): any {
  try {
    const response = yield axios.get(
      `API_BASE_URI/${action.payload}`
    );
    yield put({
      type: ManpowerScheduleActionTypes.FETCH_ONE_MANPOWER_SCHEDULE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ManpowerScheduleActionTypes.FETCH_ONE_MANPOWER_SCHEDULE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllManpowerSchedules() {
  yield takeLatest(ManpowerScheduleActionTypes.FETCH_ALL_MANPOWER_SCHEDULE, fetchAllManpowerSchedules);
}

export function* watcherFetchOneManpowerSchedules() {
  yield takeLatest(ManpowerScheduleActionTypes.FETCH_ONE_MANPOWER_SCHEDULE, fetchOneManpowerSchedules);
}
