import { Moment } from "moment";
import { ApiCallState } from "../Utils";

export type LabourUtilizationSummary= {
    id: number | null;
    project_id:number | null;
    date:Moment;
    summary_no:string;
    prepared_by:string;
    checked_by:string;
    approved_by:string;
    is_approved:boolean;
    is_checked:boolean;
    total_measurable_amount:number;
    total_non_measureable_amount:number;
    total_payroll:number,
    total_profit:number,
    total_loss:number,
    labour_utilization_summary_details:LabourUtilizationSummaryDetail[];
    measureable_work_summary_details:MeasureableWorkSummaryDetail[];
    non_measureable_work_summaries:NonMeasureableWorkSummaryDetail[];
};

export type LabourUtilizationSummaryDetail ={
    id: number | null;
    labour_utilization_summary_id:number | null;
    description:string;
    unit:string;
    payroll:number;
    standard:number;
    loss:number;
    profit:number;
    remark:string;
}

export type MeasureableWorkSummaryDetail={
    id: number | null;
    labour_utilization_summary_id:number | null;
    item_no:string;
    description:string;
    unit:string;
    unit_price:number;
    quantity:number;
    amount:number;
    subcontract_unit_price:number;
    subcontract_amount:number;
    difference:string;
    remark:string;
}

export type NonMeasureableWorkSummaryDetail={
    id: number | null;
    labour_utilization_summary_id:number | null;
    item_no:string;
    description:string;
    unit:string;
    unit_price:number;
    quantity:number;
    amount:number;
    remark:string;
}

export type LabourUtilizationSummaryStateTypes = {
  fetchAll: ApiCallState<LabourUtilizationSummary[]>;
  fetchOne: ApiCallState<LabourUtilizationSummary | {}>;
};

export const LabourUtilizationSummaryActionTypes = {
  FETCH_ALL_LABOUR_UTILIZATION_SUMMARY: "FETCH_ALL_LABOUR_UTILIZATION_SUMMARY",
  FETCH_ALL_LABOUR_UTILIZATION_SUMMARY_RESET: "FETCH_ALL_LABOUR_UTILIZATION_SUMMARY_RESET",
  FETCH_ALL_LABOUR_UTILIZATION_SUMMARY_FAILURE: "FETCH_ALL_LABOUR_UTILIZATION_SUMMARY_FAILURE",
  FETCH_ALL_LABOUR_UTILIZATION_SUMMARY_SUCCESS: "FETCH_ALL_LABOUR_UTILIZATION_SUMMARY_SUCCESS",

  FETCH_ONE_LABOUR_UTILIZATION_SUMMARY: "FETCH_ONE_LABOUR_UTILIZATION_SUMMARY",
  FETCH_ONE_LABOUR_UTILIZATION_SUMMARY_RESET: "FETCH_ONE_LABOUR_UTILIZATION_SUMMARY_RESET",
  FETCH_ONE_LABOUR_UTILIZATION_SUMMARY_FAILURE: "FETCH_ONE_LABOUR_UTILIZATION_SUMMARY_FAILURE",
  FETCH_ONE_LABOUR_UTILIZATION_SUMMARY_SUCCESS: "FETCH_ONE_LABOUR_UTILIZATION_SUMMARY_SUCCESS",
};
