import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ActionPlanGeneralActionTypes } from "./ActionPlanGeneral.type";

export function* fetchAllActionPlanGenerals(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/action-plan-general`);
    yield put({
      type: ActionPlanGeneralActionTypes.FETCH_ALL_ACTION_PLAN_GENERAL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionPlanGeneralActionTypes.FETCH_ALL_ACTION_PLAN_GENERAL_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneActionPlanGenerals(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/action-plan-general/detail?project_id=${action.payload?.project_id}&date=${action.payload?.date}`
    );
    yield put({
      type: ActionPlanGeneralActionTypes.FETCH_ONE_ACTION_PLAN_GENERAL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionPlanGeneralActionTypes.FETCH_ONE_ACTION_PLAN_GENERAL_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllActionPlanGenerals() {
  yield takeLatest(ActionPlanGeneralActionTypes.FETCH_ALL_ACTION_PLAN_GENERAL, fetchAllActionPlanGenerals);
}

export function* watcherFetchOneActionPlanGenerals() {
  yield takeLatest(ActionPlanGeneralActionTypes.FETCH_ONE_ACTION_PLAN_GENERAL, fetchOneActionPlanGenerals);
}
