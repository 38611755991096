import { ApiCallState } from "../Utils";
import { Boq } from "../Boq/Boq.type";

export type WorkSchedule= {
    project_id: number | null;
    id: number | null;
    location: string | null;
    task_name: string;
    item_no: string;
    unit: string;
    planned_quantity: number;
    unit_price: number;
    planned_amount: number;
    date: string;
    remark:string;
    monday:boolean;
    tuseday:boolean;
    wednesday:boolean;
    thursday:boolean;
    friday:boolean;
    saturday:boolean;
    sunday:boolean;
    boq_id?:number;
    boq?:Boq
};

export type WorkScheduleStateTypes = {
  fetchAll: ApiCallState<WorkSchedule[]>;
  fetchOne: ApiCallState<WorkSchedule | {}>;
};

export type WorkScheduleActionType = {
    project_id: number;
    date: string;
};
  
export const WorkScheduleActionTypes = {
  FETCH_ALL_WORK_SCHEDULE: "FETCH_ALL_WORK_SCHEDULE",
  FETCH_ALL_WORK_SCHEDULE_RESET: "FETCH_ALL_WORK_SCHEDULE_RESET",
  FETCH_ALL_WORK_SCHEDULE_FAILURE: "FETCH_ALL_WORK_SCHEDULE_FAILURE",
  FETCH_ALL_WORK_SCHEDULE_SUCCESS: "FETCH_ALL_WORK_SCHEDULE_SUCCESS",

  FETCH_ONE_WORK_SCHEDULE: "FETCH_ONE_WORK_SCHEDULE",
  FETCH_ONE_WORK_SCHEDULE_RESET: "FETCH_ONE_WORK_SCHEDULE_RESET",
  FETCH_ONE_WORK_SCHEDULE_FAILURE: "FETCH_ONE_WORK_SCHEDULE_FAILURE",
  FETCH_ONE_WORK_SCHEDULE_SUCCESS: "FETCH_ONE_WORK_SCHEDULE_SUCCESS",
};
