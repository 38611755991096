import { ApiCallState } from "../Utils";
import { Moment } from "moment";

export type SubcontractRateApproval= {
    project_id: number | null;
    id: number | null;
    date: Moment;
    rate: number;
    unit:string;
    description_of_work:string;
    scope_of_work:string;
    remark:string
};

export type SubcontractRateApprovalStateTypes = {
  fetchAll: ApiCallState<SubcontractRateApproval[]>;
  fetchOne: ApiCallState<SubcontractRateApproval | {}>;
};

export type SubcontractRateApprovalActionType = {
    project_id: number;
    date: string;
};

export const SubcontractRateApprovalActionTypes = {
  FETCH_ALL_SUBCONTRACT_RATE_APPROVAL: "FETCH_ALL_SUBCONTRACT_RATE_APPROVAL",
  FETCH_ALL_SUBCONTRACT_RATE_APPROVAL_RESET: "FETCH_ALL_SUBCONTRACT_RATE_APPROVAL_RESET",
  FETCH_ALL_SUBCONTRACT_RATE_APPROVAL_FAILURE: "FETCH_ALL_SUBCONTRACT_RATE_APPROVAL_FAILURE",
  FETCH_ALL_SUBCONTRACT_RATE_APPROVAL_SUCCESS: "FETCH_ALL_SUBCONTRACT_RATE_APPROVAL_SUCCESS",

  FETCH_ONE_SUBCONTRACT_RATE_APPROVAL: "FETCH_ONE_SUBCONTRACT_RATE_APPROVAL",
  FETCH_ONE_SUBCONTRACT_RATE_APPROVAL_RESET: "FETCH_ONE_SUBCONTRACT_RATE_APPROVAL_RESET",
  FETCH_ONE_SUBCONTRACT_RATE_APPROVAL_FAILURE: "FETCH_ONE_SUBCONTRACT_RATE_APPROVAL_FAILURE",
  FETCH_ONE_SUBCONTRACT_RATE_APPROVAL_SUCCESS: "FETCH_ONE_SUBCONTRACT_RATE_APPROVAL_SUCCESS",
};
