import { ApiCallState } from "../Utils";
import { Moment } from "moment";

export type EquipmentAndPlantStatus= {
    id: number | null;
    project_id: number | null;
    date:Moment;
    report_no:string;
    to:string;
    from:string;
    note:string;
    reported_by:string;
    approved_by:string;
    is_approved:boolean;
    equipment_and_plant_details:EquipmentAndPlantStatusDetail[];
};

export type EquipmentAndPlantStatusDetail= {
    id: number | null;
    equipment_and_plant_id:number | null;
    type_of_equipment:string;
    idle:boolean;
    down:boolean;
    reason:string;
    reporting_date:Moment;
}

export type EquipmentAndPlantStatusStateTypes = {
  fetchAll: ApiCallState<EquipmentAndPlantStatus[]>;
  fetchOne: ApiCallState<EquipmentAndPlantStatus | {}>;
};

export const EquipmentAndPlantStatusActionTypes = {
  FETCH_ALL_EQUIPMENT_AND_PLANT_STATUS: "FETCH_ALL_EQUIPMENT_AND_PLANT_STATUS",
  FETCH_ALL_EQUIPMENT_AND_PLANT_STATUS_RESET: "FETCH_ALL_EQUIPMENT_AND_PLANT_STATUS_RESET",
  FETCH_ALL_EQUIPMENT_AND_PLANT_STATUS_FAILURE: "FETCH_ALL_EQUIPMENT_AND_PLANT_STATUS_FAILURE",
  FETCH_ALL_EQUIPMENT_AND_PLANT_STATUS_SUCCESS: "FETCH_ALL_EQUIPMENT_AND_PLANT_STATUS_SUCCESS",

  FETCH_ONE_EQUIPMENT_AND_PLANT_STATUS: "FETCH_ONE_EQUIPMENT_AND_PLANT_STATUS",
  FETCH_ONE_EQUIPMENT_AND_PLANT_STATUS_RESET: "FETCH_ONE_EQUIPMENT_AND_PLANT_STATUS_RESET",
  FETCH_ONE_EQUIPMENT_AND_PLANT_STATUS_FAILURE: "FETCH_ONE_EQUIPMENT_AND_PLANT_STATUS_FAILURE",
  FETCH_ONE_EQUIPMENT_AND_PLANT_STATUS_SUCCESS: "FETCH_ONE_EQUIPMENT_AND_PLANT_STATUS_SUCCESS",
};
