import { ApiCallState } from "../Utils";
import { Moment } from "moment";

export type EquipmentRequest= {
    id: number | null;
    project_id: number | null;
    request_date:Moment;
    request_no:string;
    requested_by:string;
    from:string;
    to:string;
    note:string;
    checked_by:string;
    approved_by:string;
    is_checked:boolean;
    is_approved:boolean;
    equipment_request_details:EquipmentRequestDetail[];
};

export type EquipmentRequestDetail= {
    id: number | null;
    equipment_request_id: number | null;
    work_description:string;
    type_of_equipment:string;
    total_quantity:number;
    unit:string;
    required_no:number;
    output_per_day:number;
    duration:number;
    duration_type:string;
    remark:string;
}

export type EquipmentRequestStateTypes = {
  fetchAll: ApiCallState<EquipmentRequest[]>;
  fetchOne: ApiCallState<EquipmentRequest | {}>;
};

export type EquipmentRequestActionType = {
    id: number;
};

export const EquipmentRequestActionTypes = {
  FETCH_ALL_EQUIPMENT_REQUEST: "FETCH_ALL_EQUIPMENT_REQUEST",
  FETCH_ALL_EQUIPMENT_REQUEST_RESET: "FETCH_ALL_EQUIPMENT_REQUEST_RESET",
  FETCH_ALL_EQUIPMENT_REQUEST_FAILURE: "FETCH_ALL_EQUIPMENT_REQUEST_FAILURE",
  FETCH_ALL_EQUIPMENT_REQUEST_SUCCESS: "FETCH_ALL_EQUIPMENT_REQUEST_SUCCESS",

  FETCH_ONE_EQUIPMENT_REQUEST: "FETCH_ONE_EQUIPMENT_REQUEST",
  FETCH_ONE_EQUIPMENT_REQUEST_RESET: "FETCH_ONE_EQUIPMENT_REQUEST_RESET",
  FETCH_ONE_EQUIPMENT_REQUEST_FAILURE: "FETCH_ONE_EQUIPMENT_REQUEST_FAILURE",
  FETCH_ONE_EQUIPMENT_REQUEST_SUCCESS: "FETCH_ONE_EQUIPMENT_REQUEST_SUCCESS",
};
