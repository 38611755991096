import { SiteVisitReportStateTypes, SiteVisitReportActionTypes } from "./SiteVisitReport.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: SiteVisitReportStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const SiteVisitReportReducer = (
  state: SiteVisitReportStateTypes = INITIAL_STATE,
  action: any
): SiteVisitReportStateTypes => {
  switch (action.type) {
    case SiteVisitReportActionTypes.FETCH_ALL_SITE_VISIT_REPORT:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case SiteVisitReportActionTypes.FETCH_ALL_SITE_VISIT_REPORT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case SiteVisitReportActionTypes.FETCH_ALL_SITE_VISIT_REPORT_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case SiteVisitReportActionTypes.FETCH_ALL_SITE_VISIT_REPORT_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case SiteVisitReportActionTypes.FETCH_ONE_SITE_VISIT_REPORT:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case SiteVisitReportActionTypes.FETCH_ONE_SITE_VISIT_REPORT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case SiteVisitReportActionTypes.FETCH_ONE_SITE_VISIT_REPORT_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case SiteVisitReportActionTypes.FETCH_ONE_SITE_VISIT_REPORT_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default SiteVisitReportReducer;
