import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { SiteVisitReportActionTypes } from "./SiteVisitReport.type";

export function* fetchAllSiteVisitReports(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/site-visit-report?project_id=${action.payload.project_id}`);
    yield put({
      type: SiteVisitReportActionTypes.FETCH_ALL_SITE_VISIT_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SiteVisitReportActionTypes.FETCH_ALL_SITE_VISIT_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneSiteVisitReports(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/site-visit-report/${action.payload}`
    );
    yield put({
      type: SiteVisitReportActionTypes.FETCH_ONE_SITE_VISIT_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SiteVisitReportActionTypes.FETCH_ONE_SITE_VISIT_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllSiteVisitReports() {
  yield takeLatest(SiteVisitReportActionTypes.FETCH_ALL_SITE_VISIT_REPORT, fetchAllSiteVisitReports);
}

export function* watcherFetchOneSiteVisitReports() {
  yield takeLatest(SiteVisitReportActionTypes.FETCH_ONE_SITE_VISIT_REPORT, fetchOneSiteVisitReports);
}
