import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { WorkExecutedActionTypes } from "./WorkExecuted.type";

export function* fetchAllWorkExecuteds(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/work-executed?project_id=${action.payload.project_id}`
    );
    yield put({
      type: WorkExecutedActionTypes.FETCH_ALL_WORK_EXECUTED_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: WorkExecutedActionTypes.FETCH_ALL_WORK_EXECUTED_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneWorkExecuteds(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/work-executed/${action.payload}`
    );
    yield put({
      type: WorkExecutedActionTypes.FETCH_ONE_WORK_EXECUTED_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: WorkExecutedActionTypes.FETCH_ONE_WORK_EXECUTED_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllWorkExecuteds() {
  yield takeLatest(
    WorkExecutedActionTypes.FETCH_ALL_WORK_EXECUTED,
    fetchAllWorkExecuteds
  );
}

export function* watcherFetchOneWorkExecuteds() {
  yield takeLatest(
    WorkExecutedActionTypes.FETCH_ONE_WORK_EXECUTED,
    fetchOneWorkExecuteds
  );
}
