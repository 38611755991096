import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { IncomeGeneratedActionTypes } from "./IncomeGenerated.type";

export function* fetchAllIncomeGenerateds(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/income-generated?project_id=${action.payload.project_id}`);
    yield put({
      type: IncomeGeneratedActionTypes.FETCH_ALL_INCOME_GENERATED_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: IncomeGeneratedActionTypes.FETCH_ALL_INCOME_GENERATED_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneIncomeGenerateds(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/income-generated/${action.payload}`
    );
    yield put({
      type: IncomeGeneratedActionTypes.FETCH_ONE_INCOME_GENERATED_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: IncomeGeneratedActionTypes.FETCH_ONE_INCOME_GENERATED_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllIncomeGenerateds() {
  yield takeLatest(IncomeGeneratedActionTypes.FETCH_ALL_INCOME_GENERATED, fetchAllIncomeGenerateds);
}

export function* watcherFetchOneIncomeGenerateds() {
  yield takeLatest(IncomeGeneratedActionTypes.FETCH_ONE_INCOME_GENERATED, fetchOneIncomeGenerateds);
}
