import {
  RentContractProjectStateTypes,
  RentContractProjectActionTypes,
} from "./RentContractProject.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: RentContractProjectStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const RentContractProjectReducer = (
  state: RentContractProjectStateTypes = INITIAL_STATE,
  action: any
): RentContractProjectStateTypes => {
  switch (action.type) {
    case RentContractProjectActionTypes.FETCH_ALL_RENT_CONTRACT_PROJECT:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case RentContractProjectActionTypes.FETCH_ALL_RENT_CONTRACT_PROJECT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case RentContractProjectActionTypes.FETCH_ALL_RENT_CONTRACT_PROJECT_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case RentContractProjectActionTypes.FETCH_ALL_RENT_CONTRACT_PROJECT_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case RentContractProjectActionTypes.FETCH_ONE_RENT_CONTRACT_PROJECT:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case RentContractProjectActionTypes.FETCH_ONE_RENT_CONTRACT_PROJECT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case RentContractProjectActionTypes.FETCH_ONE_RENT_CONTRACT_PROJECT_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case RentContractProjectActionTypes.FETCH_ONE_RENT_CONTRACT_PROJECT_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default RentContractProjectReducer;
