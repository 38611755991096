import { ApiCallState } from "../Utils";

export type SubcontractorsCommitment= {
  id: number | null;
  project_id:number | null;
  sub_contractor_commitment_no: string;
  date: string;
  sub_contractor:string;
  prepared_by: string;
  checked_by: string;
  received_by: string;
  is_checked: boolean;
  is_received: boolean;
  subcontractors_commitment_details:SubcontractorsCommitmentDetail[];
};

export type SubcontractorsCommitmentDetail ={
   id: number | null;
   subcontractors_commitment_id:number | null;
   item:string;
   work_description:string;
   block:string;
   location:string;
   starting_date:string;
   finishing_date:string;
   remark:string;
}

export type SubcontractorsCommitmentStateTypes = {
  fetchAll: ApiCallState<SubcontractorsCommitment[]>;
  fetchOne: ApiCallState<SubcontractorsCommitment | {}>;
};

export const SubcontractorsCommitmentActionTypes = {
  FETCH_ALL_SUBCONTRACTORS_COMMITMENT: "FETCH_ALL_SUBCONTRACTORS_COMMITMENT",
  FETCH_ALL_SUBCONTRACTORS_COMMITMENT_RESET: "FETCH_ALL_SUBCONTRACTORS_COMMITMENT_RESET",
  FETCH_ALL_SUBCONTRACTORS_COMMITMENT_FAILURE: "FETCH_ALL_SUBCONTRACTORS_COMMITMENT_FAILURE",
  FETCH_ALL_SUBCONTRACTORS_COMMITMENT_SUCCESS: "FETCH_ALL_SUBCONTRACTORS_COMMITMENT_SUCCESS",

  FETCH_ONE_SUBCONTRACTORS_COMMITMENT: "FETCH_ONE_SUBCONTRACTORS_COMMITMENT",
  FETCH_ONE_SUBCONTRACTORS_COMMITMENT_RESET: "FETCH_ONE_SUBCONTRACTORS_COMMITMENT_RESET",
  FETCH_ONE_SUBCONTRACTORS_COMMITMENT_FAILURE: "FETCH_ONE_SUBCONTRACTORS_COMMITMENT_FAILURE",
  FETCH_ONE_SUBCONTRACTORS_COMMITMENT_SUCCESS: "FETCH_ONE_SUBCONTRACTORS_COMMITMENT_SUCCESS",
};
