import { TransferDocumentFormatStateTypes, TransferDocumentFormatActionTypes } from "./TransferDocumentFormat.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: TransferDocumentFormatStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const TransferDocumentFormatReducer = (
  state: TransferDocumentFormatStateTypes = INITIAL_STATE,
  action: any
): TransferDocumentFormatStateTypes => {
  switch (action.type) {
    case TransferDocumentFormatActionTypes.FETCH_ALL_TRANSFER_DOCUMENT_FORMAT:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case TransferDocumentFormatActionTypes.FETCH_ALL_TRANSFER_DOCUMENT_FORMAT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case TransferDocumentFormatActionTypes.FETCH_ALL_TRANSFER_DOCUMENT_FORMAT_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case TransferDocumentFormatActionTypes.FETCH_ALL_TRANSFER_DOCUMENT_FORMAT_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case TransferDocumentFormatActionTypes.FETCH_ONE_TRANSFER_DOCUMENT_FORMAT:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case TransferDocumentFormatActionTypes.FETCH_ONE_TRANSFER_DOCUMENT_FORMAT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case TransferDocumentFormatActionTypes.FETCH_ONE_TRANSFER_DOCUMENT_FORMAT_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case TransferDocumentFormatActionTypes.FETCH_ONE_TRANSFER_DOCUMENT_FORMAT_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default TransferDocumentFormatReducer;
