import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MonthlySummaryReportActionTypes } from "./MonthlySummaryReport.type";

export function* fetchAllMonthlySummaryReports(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/monthly-summary-report`);
    yield put({
      type: MonthlySummaryReportActionTypes.FETCH_ALL_MONTHLY_SUMMARY_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MonthlySummaryReportActionTypes.FETCH_ALL_MONTHLY_SUMMARY_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneMonthlySummaryReports(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/monthly-summary-report/one?date=${action.payload?.date}&project_id=${action.payload?.project_id}`
    );
    yield put({
      type: MonthlySummaryReportActionTypes.FETCH_ONE_MONTHLY_SUMMARY_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MonthlySummaryReportActionTypes.FETCH_ONE_MONTHLY_SUMMARY_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllMonthlySummaryReports() {
  yield takeLatest(MonthlySummaryReportActionTypes.FETCH_ALL_MONTHLY_SUMMARY_REPORT, fetchAllMonthlySummaryReports);
}

export function* watcherFetchOneMonthlySummaryReports() {
  yield takeLatest(MonthlySummaryReportActionTypes.FETCH_ONE_MONTHLY_SUMMARY_REPORT, fetchOneMonthlySummaryReports);
}
