import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { LabourUtilizationSummaryActionTypes } from "./LabourUtilizationSummary.type";

export function* fetchAllLabourUtilizationSummarys(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/labour-utilization-summary?project_id=${action.payload.project_id}`);
    yield put({
      type: LabourUtilizationSummaryActionTypes.FETCH_ALL_LABOUR_UTILIZATION_SUMMARY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: LabourUtilizationSummaryActionTypes.FETCH_ALL_LABOUR_UTILIZATION_SUMMARY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneLabourUtilizationSummarys(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/labour-utilization-summary/${action.payload}`
    );
    yield put({
      type: LabourUtilizationSummaryActionTypes.FETCH_ONE_LABOUR_UTILIZATION_SUMMARY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: LabourUtilizationSummaryActionTypes.FETCH_ONE_LABOUR_UTILIZATION_SUMMARY_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllLabourUtilizationSummarys() {
  yield takeLatest(LabourUtilizationSummaryActionTypes.FETCH_ALL_LABOUR_UTILIZATION_SUMMARY, fetchAllLabourUtilizationSummarys);
}

export function* watcherFetchOneLabourUtilizationSummarys() {
  yield takeLatest(LabourUtilizationSummaryActionTypes.FETCH_ONE_LABOUR_UTILIZATION_SUMMARY, fetchOneLabourUtilizationSummarys);
}
