import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MaberetachaActionTypes } from "./Maberetacha.type";

export function* fetchAllMaberetachas(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/maberetacha`);
    yield put({
      type: MaberetachaActionTypes.FETCH_ALL_MABERETACHA_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaberetachaActionTypes.FETCH_ALL_MABERETACHA_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneMaberetachas(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/maberetacha/${action.payload}`
    );
    yield put({
      type: MaberetachaActionTypes.FETCH_ONE_MABERETACHA_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaberetachaActionTypes.FETCH_ONE_MABERETACHA_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllMaberetachas() {
  yield takeLatest(
    MaberetachaActionTypes.FETCH_ALL_MABERETACHA,
    fetchAllMaberetachas
  );
}

export function* watcherFetchOneMaberetachas() {
  yield takeLatest(
    MaberetachaActionTypes.FETCH_ONE_MABERETACHA,
    fetchOneMaberetachas
  );
}
