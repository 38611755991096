import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { DispatchSummaryActionTypes } from "./DispatchSummary.type";

export function* fetchAllDispatchSummarys(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/dispatch-summary?project_id=${action.payload?.project_id}`);
    yield put({
      type: DispatchSummaryActionTypes.FETCH_ALL_DISPATCH_SUMMARY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DispatchSummaryActionTypes.FETCH_ALL_DISPATCH_SUMMARY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneDispatchSummarys(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/dispatch-summary/${action.payload}`
    );
    yield put({
      type: DispatchSummaryActionTypes.FETCH_ONE_DISPATCH_SUMMARY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DispatchSummaryActionTypes.FETCH_ONE_DISPATCH_SUMMARY_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllDispatchSummarys() {
  yield takeLatest(DispatchSummaryActionTypes.FETCH_ALL_DISPATCH_SUMMARY, fetchAllDispatchSummarys);
}

export function* watcherFetchOneDispatchSummarys() {
  yield takeLatest(DispatchSummaryActionTypes.FETCH_ONE_DISPATCH_SUMMARY, fetchOneDispatchSummarys);
}
