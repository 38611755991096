import { LoanExtension } from "../LoanExtension/LoanExtension.type";
import { Staff } from "../Staff/Staff.type";
import { Signature } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type Loan = {
  id: number;
  user_id: number;
  staff_id: number;
  warrantor_signature_id: number;
  lender_signature_id: number;
  spouse_signature_id: number;
  warrantor_spouse_signature_id: number;

  date: string;

  loan_amount: number;
  monthly_repayment_amount: number;

  warrantor_name: string;
  warrantor_phone_number: string;
  warrantor_address: string;
  warrantor_sub_city: string;
  warrantor_woreda: string;
  warrantor_kebele: string;
  warrantor_house_number: string;
  warrantor_id_number: string;
  warrantor_date: string;
  collateral: string;
  warrantor_spouse_name: string;

  spouse_name: string;

  staff: Staff;
  warrantor_signature: Signature;
  lender_signature: Signature;
  spouse_signature: Signature;
  warrantor_spouse_signature: Signature;
  loan_witnesses: LoanWitness[];
};

export type LoanStatus = {
  staff_name: string;
  date: string;
  loan_amount: number;
  paid_amount: number;
  remaining_amount: number;
};

export type LoanWitness = {
  id: number;
  loan_id: number;
  signature_id: number;

  name: string;
  signature: Signature;
};

export type LoanStateTypes = {
  fetchAll: ApiCallState<Loan[]>;
  fetchStatus: ApiCallState<LoanStatus[]>;
  fetchOne: ApiCallState<Loan | {}>;
};

export const LoanActionTypes = {
  FETCH_ALL_LOAN: "FETCH_ALL_LOAN",
  FETCH_ALL_LOAN_RESET: "FETCH_ALL_LOAN_RESET",
  FETCH_ALL_LOAN_FAILURE: "FETCH_ALL_LOAN_FAILURE",
  FETCH_ALL_LOAN_SUCCESS: "FETCH_ALL_LOAN_SUCCESS",

  FETCH_LOAN_STATUS: "FETCH_LOAN_STATUS",
  FETCH_LOAN_STATUS_RESET: "FETCH_LOAN_STATUS_RESET",
  FETCH_LOAN_STATUS_FAILURE: "FETCH_LOAN_STATUS_FAILURE",
  FETCH_LOAN_STATUS_SUCCESS: "FETCH_LOAN_STATUS_SUCCESS",

  FETCH_ONE_LOAN: "FETCH_ONE_LOAN",
  FETCH_ONE_LOAN_RESET: "FETCH_ONE_LOAN_RESET",
  FETCH_ONE_LOAN_FAILURE: "FETCH_ONE_LOAN_FAILURE",
  FETCH_ONE_LOAN_SUCCESS: "FETCH_ONE_LOAN_SUCCESS",
};
