import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { PaymentCertificateTakeoffActionTypes } from "./PaymentCertificateTakeoff.type";

export function* fetchAllPaymentCertificateTakeoffs(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/payment_certificate_takeoff?pid=${action?.payload?.pid}`);
    yield put({
      type: PaymentCertificateTakeoffActionTypes.FETCH_ALL_PAYMENT_CERTIFICATE_TAKEOFF_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PaymentCertificateTakeoffActionTypes.FETCH_ALL_PAYMENT_CERTIFICATE_TAKEOFF_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOnePaymentCertificateTakeoffs(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/payment_certificate_takeoff/${action.payload}`
    );
    yield put({
      type: PaymentCertificateTakeoffActionTypes.FETCH_ONE_PAYMENT_CERTIFICATE_TAKEOFF_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PaymentCertificateTakeoffActionTypes.FETCH_ONE_PAYMENT_CERTIFICATE_TAKEOFF_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllPaymentCertificateTakeoffs() {
  yield takeLatest(PaymentCertificateTakeoffActionTypes.FETCH_ALL_PAYMENT_CERTIFICATE_TAKEOFF, fetchAllPaymentCertificateTakeoffs);
}

export function* watcherFetchOnePaymentCertificateTakeoffs() {
  yield takeLatest(PaymentCertificateTakeoffActionTypes.FETCH_ONE_PAYMENT_CERTIFICATE_TAKEOFF, fetchOnePaymentCertificateTakeoffs);
}
