import { ProjectOperationReportStateTypes, ProjectOperationReportActionTypes } from "./ProjectOperationReport.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: ProjectOperationReportStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const ProjectOperationReportReducer = (
  state: ProjectOperationReportStateTypes = INITIAL_STATE,
  action: any
): ProjectOperationReportStateTypes => {
  switch (action.type) {
    case ProjectOperationReportActionTypes.FETCH_ALL_PROJECT_OPERATION_REPORT:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case ProjectOperationReportActionTypes.FETCH_ALL_PROJECT_OPERATION_REPORT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ProjectOperationReportActionTypes.FETCH_ALL_PROJECT_OPERATION_REPORT_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ProjectOperationReportActionTypes.FETCH_ALL_PROJECT_OPERATION_REPORT_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case ProjectOperationReportActionTypes.FETCH_ONE_PROJECT_OPERATION_REPORT:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case ProjectOperationReportActionTypes.FETCH_ONE_PROJECT_OPERATION_REPORT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ProjectOperationReportActionTypes.FETCH_ONE_PROJECT_OPERATION_REPORT_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ProjectOperationReportActionTypes.FETCH_ONE_PROJECT_OPERATION_REPORT_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default ProjectOperationReportReducer;
