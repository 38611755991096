import { LabourUtilizationStateTypes, LabourUtilizationActionTypes } from "./LabourUtilization.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: LabourUtilizationStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const LabourUtilizationReducer = (
  state: LabourUtilizationStateTypes = INITIAL_STATE,
  action: any
): LabourUtilizationStateTypes => {
  switch (action.type) {
    case LabourUtilizationActionTypes.FETCH_ALL_LABOUR_UTILIZATION:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case LabourUtilizationActionTypes.FETCH_ALL_LABOUR_UTILIZATION_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case LabourUtilizationActionTypes.FETCH_ALL_LABOUR_UTILIZATION_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case LabourUtilizationActionTypes.FETCH_ALL_LABOUR_UTILIZATION_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case LabourUtilizationActionTypes.FETCH_ONE_LABOUR_UTILIZATION:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case LabourUtilizationActionTypes.FETCH_ONE_LABOUR_UTILIZATION_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case LabourUtilizationActionTypes.FETCH_ONE_LABOUR_UTILIZATION_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case LabourUtilizationActionTypes.FETCH_ONE_LABOUR_UTILIZATION_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default LabourUtilizationReducer;
