import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { InspectionRegistryActionTypes } from "./InspectionRegistry.type";

export function* fetchAllInspectionRegistrys(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/inspection-registry?project_id=${action.payload?.project_id}`);
    yield put({
      type: InspectionRegistryActionTypes.FETCH_ALL_INSPECTION_REGISTRY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: InspectionRegistryActionTypes.FETCH_ALL_INSPECTION_REGISTRY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneInspectionRegistrys(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/inspection-registry/${action.payload}`
    );
    yield put({
      type: InspectionRegistryActionTypes.FETCH_ONE_INSPECTION_REGISTRY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: InspectionRegistryActionTypes.FETCH_ONE_INSPECTION_REGISTRY_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllInspectionRegistrys() {
  yield takeLatest(InspectionRegistryActionTypes.FETCH_ALL_INSPECTION_REGISTRY, fetchAllInspectionRegistrys);
}

export function* watcherFetchOneInspectionRegistrys() {
  yield takeLatest(InspectionRegistryActionTypes.FETCH_ONE_INSPECTION_REGISTRY, fetchOneInspectionRegistrys);
}
