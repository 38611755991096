import { MonthlySummaryReportStateTypes, MonthlySummaryReportActionTypes } from "./MonthlySummaryReport.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: MonthlySummaryReportStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const MonthlySummaryReportReducer = (
  state: MonthlySummaryReportStateTypes = INITIAL_STATE,
  action: any
): MonthlySummaryReportStateTypes => {
  switch (action.type) {
    case MonthlySummaryReportActionTypes.FETCH_ALL_MONTHLY_SUMMARY_REPORT:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case MonthlySummaryReportActionTypes.FETCH_ALL_MONTHLY_SUMMARY_REPORT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case MonthlySummaryReportActionTypes.FETCH_ALL_MONTHLY_SUMMARY_REPORT_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MonthlySummaryReportActionTypes.FETCH_ALL_MONTHLY_SUMMARY_REPORT_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case MonthlySummaryReportActionTypes.FETCH_ONE_MONTHLY_SUMMARY_REPORT:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case MonthlySummaryReportActionTypes.FETCH_ONE_MONTHLY_SUMMARY_REPORT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case MonthlySummaryReportActionTypes.FETCH_ONE_MONTHLY_SUMMARY_REPORT_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MonthlySummaryReportActionTypes.FETCH_ONE_MONTHLY_SUMMARY_REPORT_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default MonthlySummaryReportReducer;
