import { isString, isEmpty,isNumber, toNumber } from "lodash";

class BuildingDailyReport {
  data: any[];
  sheet_name: string;
  type: any;
  parsed: any[];

  constructor(data: any, sheet_name: string) {
    this.data = data;
    this.type = "";
    this.parsed = [];
    this.sheet_name = sheet_name;
  }

  parseBoq() {
    let start = -1;
    // Iterate Through Each Row
    this.data.forEach((col: any[], index) => {
      //Check if its The Start of the Excel File and set the index of the Start Row
      if (this.isTableState(col)) start = index;
      // If its the Start of Excel Data
      else if (start !== -1) {
        // Identify the Row Type
        if (col[2] && isNumber(col[1])) {
          this.parsed.push({
            description: "",
            weather: this.WeatherTypes.BRIGHT_SUN,
            quantity_performed: col[5],
            forman: "",
            finance_daily_report_item: {
              description: col[2],
              unit: col[3],
              quantity: toNumber(col[5]),
              unit_price: toNumber(col[4]),
              planned_amount: toNumber(col[6]),
            },
            material: isEmpty(col[8]) ? []:[
              {
                type: col[8],
                unit: col[9],
                rate: col[12] ? toNumber(col[12]): 0,
                quantity: col[11] ? toNumber(col[11]):0,
                isEdited: true,
              },
            ],
            equipment: isEmpty(col[14])
              ? []
              : [
                  {
                    type: col[14],
                    rate: col[15] ? toNumber(col[15]):0,
                    duration: col[16] ? toNumber(col[16]):0,
                    isEdited: true,
                  },
                ],
            labour:isEmpty(col[18])
            ? [] : [
              {
                type: col[18],
                rate: col[19] ? toNumber(col[19]):0,
                duration: 1,
                quantity: col[20] ? toNumber(col[20]):0,
                normal_overtime: 0,
                holiday_overtime: 0,
                isEdited: true,
              },
            ],
            contract:isEmpty(col[22])
            ? [] :[
              {
                description: col[22],
                rate: col[23] ? toNumber(col[23]): 0,
                quantity:col[24] ? toNumber(col[24]):0,
                type: "",
                isEdited: true,
              },
            ],
            isEdited: false,
            key: this.parsed.length,
            boq: null,
          });
        } else {
          if (isEmpty(col[2]) && col[8] && col[9]) {
            let lastElement = this.parsed.pop();
            this.parsed.push({
              description: lastElement.description,
              weather: lastElement.weather,
              quantity_performed: lastElement.quantity_performed,
              forman: lastElement.forman,
              finance_daily_report_item: lastElement.finance_daily_report_item,
              material: [
                ...lastElement.material,
                {
                  type: col[8],
                  unit: col[9],
                  rate: toNumber(col[12]),
                  quantity: toNumber(col[11]),
                  isEdited: true,
                },
              ],
              equipment: [...lastElement.equipment],
              labour: [...lastElement.labour],
              contract: [...lastElement.contract],
              isEdited: false,
              key: this.parsed.length + 1375,
              boq: null,
            });
          }
          if (isEmpty(col[2]) && col[14] && col[15]) {
            let lastElement = this.parsed.pop();
            this.parsed.push({
              description: lastElement.description,
              weather: lastElement.weather,
              quantity_performed: lastElement.quantity_performed,
              forman: lastElement.forman,
              finance_daily_report_item: lastElement.finance_daily_report_item,
              material: [...lastElement.material],
              equipment: [
                ...lastElement.equipment,
                {
                  type: col[14],
                  rate: toNumber(col[15]),
                  duration: toNumber(col[16]),
                  isEdited: true,
                },
              ],
              labour: [...lastElement.labour],
              contract: [...lastElement.contract],
              isEdited: false,
              key: this.parsed.length + 26532,
              boq: null,
            });
          }
          if (isEmpty(col[2]) && col[18] && col[19]) {
            let lastElement = this.parsed.pop();
            this.parsed.push({
              description: lastElement.description,
              weather: lastElement.weather,
              quantity_performed: lastElement.quantity_performed,
              forman: lastElement.forman,
              finance_daily_report_item: lastElement.finance_daily_report_item,
              material: [...lastElement.material],
              equipment: [...lastElement.equipment],
              labour: [
                ...lastElement.labour,
                {
                  type: col[18],
                  rate: toNumber(col[19]),
                  duration: 1,
                  quantity: toNumber(col[20]),
                  normal_overtime: 0,
                  holiday_overtime: 0,
                  isEdited: true,
                },
              ],
              contract: [...lastElement.contract],
              isEdited: false,
              key: this.parsed.length + 30976432,
              boq: null,
            });
          }
          if (isEmpty(col[2]) && col[22] && col[23]) {
            let lastElement = this.parsed.pop();
            this.parsed.push({
              description: lastElement.description,
              weather: lastElement.weather,
              quantity_performed: lastElement.quantity_performed,
              forman: lastElement.forman,
              finance_daily_report_item: lastElement.finance_daily_report_item,
              material: [...lastElement.material],
              equipment: [...lastElement.equipment],
              labour: [...lastElement.labour],
              contract: [
                ...lastElement.contract,
                {
                  description: col[22],
                  rate: toNumber(col[23]),
                  quantity: toNumber(col[24]),
                  type: "",
                  isEdited: true,
                },
              ],
              isEdited: false,
              key: this.parsed.length + 58654322,
              boq: null,
            });
          }
        }
      }
    });
    return this.parsed;
  }

  private isTableState(col: any[]) {
    //check if col 0 to col 5 is a string
    return (
      isString(col[2]) &&
      isString(col[3]) &&
      isString(col[5]) &&
      isString(col[6]) &&
      isString(col[7]) &&
      isString(col[8]) &&
      isString(col[9]) &&
      isString(col[10])
    );
  }

  private WeatherTypes = {
    BRIGHT_SUN: "Bright sun",
    RAINY: "Rainy",
    FOGY: "Fogy",
  };
}
export default BuildingDailyReport;
