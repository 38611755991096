import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { PostCheckActionTypes } from "./PostCheck.type";

export function* fetchAllPostChecks(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }

    const response = yield axios.get(`${API_BASE_URI}/post_check?${query}`);
    yield put({
      type: PostCheckActionTypes.FETCH_ALL_POST_CHECK_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PostCheckActionTypes.FETCH_ALL_POST_CHECK_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOnePostChecks(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/post_check/${action.payload}`
    );
    yield put({
      type: PostCheckActionTypes.FETCH_ONE_POST_CHECK_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PostCheckActionTypes.FETCH_ONE_POST_CHECK_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllPostChecks() {
  yield takeLatest(
    PostCheckActionTypes.FETCH_ALL_POST_CHECK,
    fetchAllPostChecks
  );
}

export function* watcherFetchOnePostChecks() {
  yield takeLatest(
    PostCheckActionTypes.FETCH_ONE_POST_CHECK,
    fetchOnePostChecks
  );
}
