import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ActionPlanMaterialActionTypes } from "./ActionPlanMaterial.type";

export function* fetchAllActionPlanMaterials(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/action-plan-material?date=${action.payload.date}`);
    yield put({
      type: ActionPlanMaterialActionTypes.FETCH_ALL_ACTION_PLAN_MATERIAL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionPlanMaterialActionTypes.FETCH_ALL_ACTION_PLAN_MATERIAL_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneActionPlanMaterials(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/action-plan-material/${action.payload}`
    );
    yield put({
      type: ActionPlanMaterialActionTypes.FETCH_ONE_ACTION_PLAN_MATERIAL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionPlanMaterialActionTypes.FETCH_ONE_ACTION_PLAN_MATERIAL_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllActionPlanMaterials() {
  yield takeLatest(ActionPlanMaterialActionTypes.FETCH_ALL_ACTION_PLAN_MATERIAL, fetchAllActionPlanMaterials);
}

export function* watcherFetchOneActionPlanMaterials() {
  yield takeLatest(ActionPlanMaterialActionTypes.FETCH_ONE_ACTION_PLAN_MATERIAL, fetchOneActionPlanMaterials);
}
