import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";
import { API_BASE_URI } from "../ApiCall";
import { EquipmentDailyOperationReportActionTypes } from "./EquipmentDailyOperationReport.type";

export function* fetchAllEquipmentDailyOperationReports(action: any): any {
  try {
    let query = "";

    if (Object.keys(action.payload).length > 0) {
      query = Object.keys(action.payload)
        .map((key) => key + "=" + action.payload[key])
        .join("&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/equipment-daily-operation-report?${query}`
    );
    yield put({
      type: EquipmentDailyOperationReportActionTypes.FETCH_ALL_EQUIPMENT_DAILY_OPERATION_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EquipmentDailyOperationReportActionTypes.FETCH_ALL_EQUIPMENT_DAILY_OPERATION_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneEquipmentDailyOperationReports(action: any): any {
  try {
    let query = "";

    if (Object.keys(action.payload).length > 0) {
      query = Object.keys(action.payload)
        .map((key) => key + "=" + action.payload[key])
        .join("&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/equipment-daily-operation-report/one?${query}`
    );
    yield put({
      type: EquipmentDailyOperationReportActionTypes.FETCH_ONE_EQUIPMENT_DAILY_OPERATION_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EquipmentDailyOperationReportActionTypes.FETCH_ONE_EQUIPMENT_DAILY_OPERATION_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchPreviousEquipmentDailyOperationReports(action: any): any {
  try {
    let query = "";

    if (Object.keys(action.payload).length > 0) {
      query = Object.keys(action.payload)
        .map((key) => key + "=" + action.payload[key])
        .join("&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/equipment-daily-operation-report/previous?${query}`
    );
    yield put({
      type: EquipmentDailyOperationReportActionTypes.FETCH_PREVIOUS_EQUIPMENT_DAILY_OPERATION_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EquipmentDailyOperationReportActionTypes.FETCH_PREVIOUS_EQUIPMENT_DAILY_OPERATION_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchGeneratedEquipmentDailyOperationReports(
  action: any
): any {
  try {
    let query = "";

    if (Object.keys(action.payload).length > 0) {
      query = Object.keys(action.payload)
        .map((key) => key + "=" + action.payload[key])
        .join("&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/equipment-daily-operation-report/operation-report?${query}`
    );
    yield put({
      type: EquipmentDailyOperationReportActionTypes.FETCH_GENERATED_EQUIPMENT_DAILY_OPERATION_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EquipmentDailyOperationReportActionTypes.FETCH_GENERATED_EQUIPMENT_DAILY_OPERATION_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllEquipmentDailyOperationReports() {
  yield takeLatest(
    EquipmentDailyOperationReportActionTypes.FETCH_ALL_EQUIPMENT_DAILY_OPERATION_REPORT,
    fetchAllEquipmentDailyOperationReports
  );
}

export function* watcherFetchOneEquipmentDailyOperationReports() {
  yield takeLatest(
    EquipmentDailyOperationReportActionTypes.FETCH_ONE_EQUIPMENT_DAILY_OPERATION_REPORT,
    fetchOneEquipmentDailyOperationReports
  );
}

export function* watcherFetchPreviousEquipmentDailyOperationReports() {
  yield takeLatest(
    EquipmentDailyOperationReportActionTypes.FETCH_PREVIOUS_EQUIPMENT_DAILY_OPERATION_REPORT,
    fetchPreviousEquipmentDailyOperationReports
  );
}

export function* watcherFetchGeneratedEquipmentDailyOperationReports() {
  yield takeLatest(
    EquipmentDailyOperationReportActionTypes.FETCH_GENERATED_EQUIPMENT_DAILY_OPERATION_REPORT,
    fetchGeneratedEquipmentDailyOperationReports
  );
}
