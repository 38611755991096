import { ApiCallState } from "../Utils";
import { Moment } from "moment";

export type SiteRAFReading= {
    project_id: number | null;
    id: number | null;
    date:Moment;
    disk_work:string;
    station:string;
    lhs_diff:number;
    lhs_ratio:number;
    lhs_dist:number;
    rhs_diff:number;
    rhs_ratio:number;
    rhs_dist:number;
    remark:string;
};

export type SiteRAFReadingStateTypes = {
  fetchAll: ApiCallState<SiteRAFReading[]>;
  fetchOne: ApiCallState<SiteRAFReading | {}>;
};

export type SiteRAFReadingActionType = {
    project_id: number;
    date: string;
};

export const SiteRAFReadingActionTypes = {
  FETCH_ALL_SITE_RAF_READING: "FETCH_ALL_SITE_RAF_READING",
  FETCH_ALL_SITE_RAF_READING_RESET: "FETCH_ALL_SITE_RAF_READING_RESET",
  FETCH_ALL_SITE_RAF_READING_FAILURE: "FETCH_ALL_SITE_RAF_READING_FAILURE",
  FETCH_ALL_SITE_RAF_READING_SUCCESS: "FETCH_ALL_SITE_RAF_READING_SUCCESS",

  FETCH_ONE_SITE_RAF_READING: "FETCH_ONE_SITE_RAF_READING",
  FETCH_ONE_SITE_RAF_READING_RESET: "FETCH_ONE_SITE_RAF_READING_RESET",
  FETCH_ONE_SITE_RAF_READING_FAILURE: "FETCH_ONE_SITE_RAF_READING_FAILURE",
  FETCH_ONE_SITE_RAF_READING_SUCCESS: "FETCH_ONE_SITE_RAF_READING_SUCCESS",
};
