import { ActionPlanGeneralStateTypes, ActionPlanGeneralActionTypes } from "./ActionPlanGeneral.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: ActionPlanGeneralStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const ActionPlanGeneralReducer = (
  state: ActionPlanGeneralStateTypes = INITIAL_STATE,
  action: any
): ActionPlanGeneralStateTypes => {
  switch (action.type) {
    case ActionPlanGeneralActionTypes.FETCH_ALL_ACTION_PLAN_GENERAL:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case ActionPlanGeneralActionTypes.FETCH_ALL_ACTION_PLAN_GENERAL_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ActionPlanGeneralActionTypes.FETCH_ALL_ACTION_PLAN_GENERAL_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ActionPlanGeneralActionTypes.FETCH_ALL_ACTION_PLAN_GENERAL_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case ActionPlanGeneralActionTypes.FETCH_ONE_ACTION_PLAN_GENERAL:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case ActionPlanGeneralActionTypes.FETCH_ONE_ACTION_PLAN_GENERAL_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ActionPlanGeneralActionTypes.FETCH_ONE_ACTION_PLAN_GENERAL_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ActionPlanGeneralActionTypes.FETCH_ONE_ACTION_PLAN_GENERAL_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default ActionPlanGeneralReducer;
