import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MonthMaterialScheduleActionTypes } from "./MonthMaterialSchedule.type";

export function* fetchAllMonthMaterialSchedules(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/month-material-schedule?project_id=${action.payload.project_id}`);
    yield put({
      type: MonthMaterialScheduleActionTypes.FETCH_ALL_MONTH_MATERIAL_SCHEDULE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MonthMaterialScheduleActionTypes.FETCH_ALL_MONTH_MATERIAL_SCHEDULE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneMonthMaterialSchedules(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/month-material-schedule/${action.payload}`
    );
    yield put({
      type: MonthMaterialScheduleActionTypes.FETCH_ONE_MONTH_MATERIAL_SCHEDULE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MonthMaterialScheduleActionTypes.FETCH_ONE_MONTH_MATERIAL_SCHEDULE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllMonthMaterialSchedules() {
  yield takeLatest(MonthMaterialScheduleActionTypes.FETCH_ALL_MONTH_MATERIAL_SCHEDULE, fetchAllMonthMaterialSchedules);
}

export function* watcherFetchOneMonthMaterialSchedules() {
  yield takeLatest(MonthMaterialScheduleActionTypes.FETCH_ONE_MONTH_MATERIAL_SCHEDULE, fetchOneMonthMaterialSchedules);
}
