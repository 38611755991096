import {
  GeneralDailyOperationReportStateTypes,
  GeneralDailyOperationReportActionTypes,
} from "./GeneralDailyOperationReport.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: GeneralDailyOperationReportStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
  fetchPrevious: resetApiCallState({}),
  fetchReport: resetApiCallState({}),
};

const GeneralDailyOperationReportReducer = (
  state: GeneralDailyOperationReportStateTypes = INITIAL_STATE,
  action: any
): GeneralDailyOperationReportStateTypes => {
  switch (action.type) {
    case GeneralDailyOperationReportActionTypes.FETCH_ALL_GENERAL_DAILY_OPERATION_REPORT:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case GeneralDailyOperationReportActionTypes.FETCH_ALL_GENERAL_DAILY_OPERATION_REPORT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState({}),
      };
    case GeneralDailyOperationReportActionTypes.FETCH_ALL_GENERAL_DAILY_OPERATION_REPORT_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case GeneralDailyOperationReportActionTypes.FETCH_ALL_GENERAL_DAILY_OPERATION_REPORT_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case GeneralDailyOperationReportActionTypes.FETCH_ONE_GENERAL_DAILY_OPERATION_REPORT:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case GeneralDailyOperationReportActionTypes.FETCH_ONE_GENERAL_DAILY_OPERATION_REPORT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case GeneralDailyOperationReportActionTypes.FETCH_ONE_GENERAL_DAILY_OPERATION_REPORT_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case GeneralDailyOperationReportActionTypes.FETCH_ONE_GENERAL_DAILY_OPERATION_REPORT_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case GeneralDailyOperationReportActionTypes.FETCH_PREVIOUS_GENERAL_DAILY_OPERATION_REPORT:
      return {
        ...state,
        fetchPrevious: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case GeneralDailyOperationReportActionTypes.FETCH_PREVIOUS_GENERAL_DAILY_OPERATION_REPORT_RESET:
      return {
        ...state,
        fetchPrevious: resetApiCallState({}),
      };
    case GeneralDailyOperationReportActionTypes.FETCH_PREVIOUS_GENERAL_DAILY_OPERATION_REPORT_FAILURE:
      return {
        ...state,
        fetchPrevious: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case GeneralDailyOperationReportActionTypes.FETCH_PREVIOUS_GENERAL_DAILY_OPERATION_REPORT_SUCCESS:
      return {
        ...state,
        fetchPrevious: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    //

    case GeneralDailyOperationReportActionTypes.FETCH_GENERATED_GENERAL_DAILY_OPERATION_REPORT:
      return {
        ...state,
        fetchReport: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case GeneralDailyOperationReportActionTypes.FETCH_GENERATED_GENERAL_DAILY_OPERATION_REPORT_RESET:
      return {
        ...state,
        fetchReport: resetApiCallState({}),
      };
    case GeneralDailyOperationReportActionTypes.FETCH_GENERATED_GENERAL_DAILY_OPERATION_REPORT_FAILURE:
      return {
        ...state,
        fetchReport: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case GeneralDailyOperationReportActionTypes.FETCH_GENERATED_GENERAL_DAILY_OPERATION_REPORT_SUCCESS:
      return {
        ...state,
        fetchReport: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default GeneralDailyOperationReportReducer;
