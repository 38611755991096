import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { SubContractComparisonActionTypes } from "./SubContractComparison.type";

export function* fetchAllSubContractComparisons(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/sub-contract-comparison?project_id=${action.payload.project_id}`
    );
    yield put({
      type: SubContractComparisonActionTypes.FETCH_ALL_SUB_CONTRACT_COMPARISON_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SubContractComparisonActionTypes.FETCH_ALL_SUB_CONTRACT_COMPARISON_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneSubContractComparisons(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/sub-contract-comparison/${action.payload}`
    );
    yield put({
      type: SubContractComparisonActionTypes.FETCH_ONE_SUB_CONTRACT_COMPARISON_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SubContractComparisonActionTypes.FETCH_ONE_SUB_CONTRACT_COMPARISON_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllSubContractComparisons() {
  yield takeLatest(
    SubContractComparisonActionTypes.FETCH_ALL_SUB_CONTRACT_COMPARISON,
    fetchAllSubContractComparisons
  );
}

export function* watcherFetchOneSubContractComparisons() {
  yield takeLatest(
    SubContractComparisonActionTypes.FETCH_ONE_SUB_CONTRACT_COMPARISON,
    fetchOneSubContractComparisons
  );
}
