import { MonthEquipmentScheduleStateTypes, MonthEquipmentScheduleActionTypes } from "./MonthEquipmentSchedule.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: MonthEquipmentScheduleStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const MonthEquipmentScheduleReducer = (
  state: MonthEquipmentScheduleStateTypes = INITIAL_STATE,
  action: any
): MonthEquipmentScheduleStateTypes => {
  switch (action.type) {
    case MonthEquipmentScheduleActionTypes.FETCH_ALL_MONTH_EQUIPMENT_SCHEDULE:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case MonthEquipmentScheduleActionTypes.FETCH_ALL_MONTH_EQUIPMENT_SCHEDULE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case MonthEquipmentScheduleActionTypes.FETCH_ALL_MONTH_EQUIPMENT_SCHEDULE_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MonthEquipmentScheduleActionTypes.FETCH_ALL_MONTH_EQUIPMENT_SCHEDULE_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case MonthEquipmentScheduleActionTypes.FETCH_ONE_MONTH_EQUIPMENT_SCHEDULE:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case MonthEquipmentScheduleActionTypes.FETCH_ONE_MONTH_EQUIPMENT_SCHEDULE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case MonthEquipmentScheduleActionTypes.FETCH_ONE_MONTH_EQUIPMENT_SCHEDULE_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MonthEquipmentScheduleActionTypes.FETCH_ONE_MONTH_EQUIPMENT_SCHEDULE_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default MonthEquipmentScheduleReducer;
