import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { WeatherConditionActionTypes } from "./WeatherCondition.type";

export function* fetchAllWeatherConditions(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/weather-condition?project_id=${action.payload.project_id}&date=${action.payload.date}`);
    yield put({
      type: WeatherConditionActionTypes.FETCH_ALL_WEATHER_CONDITION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: WeatherConditionActionTypes.FETCH_ALL_WEATHER_CONDITION_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneWeatherConditions(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/weather-condition/${action.payload}`
    );
    yield put({
      type: WeatherConditionActionTypes.FETCH_ONE_WEATHER_CONDITION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: WeatherConditionActionTypes.FETCH_ONE_WEATHER_CONDITION_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllWeatherConditions() {
  yield takeLatest(WeatherConditionActionTypes.FETCH_ALL_WEATHER_CONDITION, fetchAllWeatherConditions);
}

export function* watcherFetchOneWeatherConditions() {
  yield takeLatest(WeatherConditionActionTypes.FETCH_ONE_WEATHER_CONDITION, fetchOneWeatherConditions);
}
