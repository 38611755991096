import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ReplenishmentActionTypes } from "./Replenishment.type";

export function* fetchAllReplenishments(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }

    const response = yield axios.get(`${API_BASE_URI}/replenishment?${query}`);
    yield put({
      type: ReplenishmentActionTypes.FETCH_ALL_REPLENISHMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ReplenishmentActionTypes.FETCH_ALL_REPLENISHMENT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneReplenishments(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/replenishment/one?${query}`
    );
    yield put({
      type: ReplenishmentActionTypes.FETCH_ONE_REPLENISHMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ReplenishmentActionTypes.FETCH_ONE_REPLENISHMENT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllReplenishments() {
  yield takeLatest(
    ReplenishmentActionTypes.FETCH_ALL_REPLENISHMENT,
    fetchAllReplenishments
  );
}

export function* watcherFetchOneReplenishments() {
  yield takeLatest(
    ReplenishmentActionTypes.FETCH_ONE_REPLENISHMENT,
    fetchOneReplenishments
  );
}
