import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MaterialPurchaseFollowUpActionTypes } from "./MaterialPurchaseFollowUp.type";

export function* fetchAllMaterialPurchaseFollowUps(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/material-purchase-follow-up?project_id=${action.payload.project_id}`);
    yield put({
      type: MaterialPurchaseFollowUpActionTypes.FETCH_ALL_MATERIAL_PURCHASE_FOLLOW_UP_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialPurchaseFollowUpActionTypes.FETCH_ALL_MATERIAL_PURCHASE_FOLLOW_UP_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneMaterialPurchaseFollowUps(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/material-purchase-follow-up/${action.payload}`
    );
    yield put({
      type: MaterialPurchaseFollowUpActionTypes.FETCH_ONE_MATERIAL_PURCHASE_FOLLOW_UP_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialPurchaseFollowUpActionTypes.FETCH_ONE_MATERIAL_PURCHASE_FOLLOW_UP_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllMaterialPurchaseFollowUps() {
  yield takeLatest(MaterialPurchaseFollowUpActionTypes.FETCH_ALL_MATERIAL_PURCHASE_FOLLOW_UP, fetchAllMaterialPurchaseFollowUps);
}

export function* watcherFetchOneMaterialPurchaseFollowUps() {
  yield takeLatest(MaterialPurchaseFollowUpActionTypes.FETCH_ONE_MATERIAL_PURCHASE_FOLLOW_UP, fetchOneMaterialPurchaseFollowUps);
}
