import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { PaymentCertificateActionTypes } from "./PaymentCertificate.type";

export function* fetchAllPaymentCertificates(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/payment_certificate`);
    yield put({
      type: PaymentCertificateActionTypes.FETCH_ALL_PAYMENT_CERTIFICATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PaymentCertificateActionTypes.FETCH_ALL_PAYMENT_CERTIFICATE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOnePaymentCertificates(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/payment_certificate/${action.payload}`
    );
    yield put({
      type: PaymentCertificateActionTypes.FETCH_ONE_PAYMENT_CERTIFICATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PaymentCertificateActionTypes.FETCH_ONE_PAYMENT_CERTIFICATE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllPaymentCertificates() {
  yield takeLatest(PaymentCertificateActionTypes.FETCH_ALL_PAYMENT_CERTIFICATE, fetchAllPaymentCertificates);
}

export function* watcherFetchOnePaymentCertificates() {
  yield takeLatest(PaymentCertificateActionTypes.FETCH_ONE_PAYMENT_CERTIFICATE, fetchOnePaymentCertificates);
}
