import {
  AccountTransactionStateTypes,
  AccountTransactionActionTypes,
} from "./AccountTransaction.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: AccountTransactionStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchAllAll: resetApiCallState([]),
};

const AccountTransactionReducer = (
  state: AccountTransactionStateTypes = INITIAL_STATE,
  action: any
): AccountTransactionStateTypes => {
  switch (action.type) {
    case AccountTransactionActionTypes.FETCH_ALL_ACCOUNT_TRANSACTION:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case AccountTransactionActionTypes.FETCH_ALL_ACCOUNT_TRANSACTION_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case AccountTransactionActionTypes.FETCH_ALL_ACCOUNT_TRANSACTION_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case AccountTransactionActionTypes.FETCH_ALL_ACCOUNT_TRANSACTION_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    // ----------------
    case AccountTransactionActionTypes.FETCH_ALL_ALL_ACCOUNT_TRANSACTION:
      return {
        ...state,
        fetchAllAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case AccountTransactionActionTypes.FETCH_ALL_ALL_ACCOUNT_TRANSACTION_RESET:
      return {
        ...state,
        fetchAllAll: resetApiCallState([]),
      };
    case AccountTransactionActionTypes.FETCH_ALL_ALL_ACCOUNT_TRANSACTION_FAILURE:
      return {
        ...state,
        fetchAllAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case AccountTransactionActionTypes.FETCH_ALL_ALL_ACCOUNT_TRANSACTION_SUCCESS:
      return {
        ...state,
        fetchAllAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default AccountTransactionReducer;
