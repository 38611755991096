import { ApiCallState } from "../Utils";

export type PlanSummary = {
  id: number;
  date: string;
  remark: string;
  project_id: number;
  plan_summary_equipment_utilizeds: PlanSummaryEquipmentUtilized[];
  plan_summary_fuel_utilizeds: PlanSummaryFuelUtilized[];
  plan_summary_material_balances: PlanSummaryMaterialBalances[];
  plan_summary_permanent_man_powers: PlanSummaryPermanentManPower[];
  plan_summary_next_days: PlanSummaryNextDay[];
};

export type PlanSummaryEquipmentUtilized = {
  id?: number;
  description: string;
  ownership: string;
  total_working_hour: number;
  down: number;
  no_of_equipment: number;
  rate: number;
};

export type PlanSummaryFuelUtilized = {
  id?: number;
  description: string;
  unit: string;
  quantity: number;
  rate: number;
};

export type PlanSummaryMaterialBalances = {
  id?: number;
  description: string;
  unit: string;
  quantity: number;
  rate: number;
};

export type PlanSummaryPermanentManPower = {
  id?: number;
  description: string;
  no: number;
  rate: number;
};

export type PlanSummaryStateTypes = {
  fetchAll: ApiCallState<PlanSummary[]>;
  fetchOne: ApiCallState<PlanSummary | {}>;
};

export type PlanSummaryNextDay = {
  id?: number;
  description: string;
  unit: string;
  quantity: number;
  rate: number;
  remark: string;
}

export const PlanSummaryActionTypes = {
  FETCH_ALL_PLAN_SUMMARY: "FETCH_ALL_PLAN_SUMMARY",
  FETCH_ALL_PLAN_SUMMARY_RESET: "FETCH_ALL_PLAN_SUMMARY_RESET",
  FETCH_ALL_PLAN_SUMMARY_FAILURE: "FETCH_ALL_PLAN_SUMMARY_FAILURE",
  FETCH_ALL_PLAN_SUMMARY_SUCCESS: "FETCH_ALL_PLAN_SUMMARY_SUCCESS",

  FETCH_ONE_PLAN_SUMMARY: "FETCH_ONE_PLAN_SUMMARY",
  FETCH_ONE_PLAN_SUMMARY_RESET: "FETCH_ONE_PLAN_SUMMARY_RESET",
  FETCH_ONE_PLAN_SUMMARY_FAILURE: "FETCH_ONE_PLAN_SUMMARY_FAILURE",
  FETCH_ONE_PLAN_SUMMARY_SUCCESS: "FETCH_ONE_PLAN_SUMMARY_SUCCESS",
};
