import { ApiCallState } from "../Utils";
import { Document } from "../Document/Document.type";

export type MasterScheduleUpdated= {
  id: number;
  project_id:number;
  type:string;
  description:string;
  status:string;
  document:Document;
  date_of_submission:string;
  date_of_commented:string;
  date_of_resubmission:string;
};

export type MasterScheduleUpdatedStateTypes = {
  fetchAll: ApiCallState<MasterScheduleUpdated[]>;
  fetchOne: ApiCallState<MasterScheduleUpdated | {}>;
};

export const MasterScheduleUpdatedActionTypes = {
  FETCH_ALL_MASTER_SCHEDULE_UPDATED: "FETCH_ALL_MASTER_SCHEDULE_UPDATED",
  FETCH_ALL_MASTER_SCHEDULE_UPDATED_RESET: "FETCH_ALL_MASTER_SCHEDULE_UPDATED_RESET",
  FETCH_ALL_MASTER_SCHEDULE_UPDATED_FAILURE: "FETCH_ALL_MASTER_SCHEDULE_UPDATED_FAILURE",
  FETCH_ALL_MASTER_SCHEDULE_UPDATED_SUCCESS: "FETCH_ALL_MASTER_SCHEDULE_UPDATED_SUCCESS",

  FETCH_ONE_MASTER_SCHEDULE_UPDATED: "FETCH_ONE_MASTER_SCHEDULE_UPDATED",
  FETCH_ONE_MASTER_SCHEDULE_UPDATED_RESET: "FETCH_ONE_MASTER_SCHEDULE_UPDATED_RESET",
  FETCH_ONE_MASTER_SCHEDULE_UPDATED_FAILURE: "FETCH_ONE_MASTER_SCHEDULE_UPDATED_FAILURE",
  FETCH_ONE_MASTER_SCHEDULE_UPDATED_SUCCESS: "FETCH_ONE_MASTER_SCHEDULE_UPDATED_SUCCESS",
};
