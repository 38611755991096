import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { SiteBookOrderActionTypes } from "./SiteBookOrder.type";

export function* fetchAllSiteBookOrders(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/site-book-order?project_id=${action.payload?.project_id}`);
    yield put({
      type: SiteBookOrderActionTypes.FETCH_ALL_SITE_BOOK_ORDER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SiteBookOrderActionTypes.FETCH_ALL_SITE_BOOK_ORDER_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneSiteBookOrders(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/site-book-order/${action.payload}`
    );
    yield put({
      type: SiteBookOrderActionTypes.FETCH_ONE_SITE_BOOK_ORDER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SiteBookOrderActionTypes.FETCH_ONE_SITE_BOOK_ORDER_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllSiteBookOrders() {
  yield takeLatest(SiteBookOrderActionTypes.FETCH_ALL_SITE_BOOK_ORDER, fetchAllSiteBookOrders);
}

export function* watcherFetchOneSiteBookOrders() {
  yield takeLatest(SiteBookOrderActionTypes.FETCH_ONE_SITE_BOOK_ORDER, fetchOneSiteBookOrders);
}
