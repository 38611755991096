export type ApiCallState<T> = {
  error: any;
  payload: T;
  isPending: boolean;
  isSuccessful: boolean;
};

export type PagedData<T> = {
  page_size: number;
  rows: T;
  count: number;
  current_page: number;
};

export const resetApiCallState = (payload: any) => ({
  error: null,
  payload: payload,
  isPending: false,
  isSuccessful: false,
});
