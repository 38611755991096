import { SubcontractRateApprovalStateTypes, SubcontractRateApprovalActionTypes } from "./SubcontractRateApproval.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: SubcontractRateApprovalStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const SubcontractRateApprovalReducer = (
  state: SubcontractRateApprovalStateTypes = INITIAL_STATE,
  action: any
): SubcontractRateApprovalStateTypes => {
  switch (action.type) {
    case SubcontractRateApprovalActionTypes.FETCH_ALL_SUBCONTRACT_RATE_APPROVAL:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case SubcontractRateApprovalActionTypes.FETCH_ALL_SUBCONTRACT_RATE_APPROVAL_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case SubcontractRateApprovalActionTypes.FETCH_ALL_SUBCONTRACT_RATE_APPROVAL_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case SubcontractRateApprovalActionTypes.FETCH_ALL_SUBCONTRACT_RATE_APPROVAL_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case SubcontractRateApprovalActionTypes.FETCH_ONE_SUBCONTRACT_RATE_APPROVAL:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case SubcontractRateApprovalActionTypes.FETCH_ONE_SUBCONTRACT_RATE_APPROVAL_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case SubcontractRateApprovalActionTypes.FETCH_ONE_SUBCONTRACT_RATE_APPROVAL_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case SubcontractRateApprovalActionTypes.FETCH_ONE_SUBCONTRACT_RATE_APPROVAL_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default SubcontractRateApprovalReducer;
