import { ApiCallState } from "../Utils";

export type JustificationSummary= {
  id: number;
  date: string;
  type: string;
  major_problems: string;
  measure_taken_by_project: string;
  project_id: number;
  justification_summary_items: JustificationSummaryItem[]
};

export type JustificationSummaryItem = {
  id: number;
  description: string;
  unexpected_amount: number;
  total_daily_plan_percent: number;
  problem_encountered_description: string;
  remark: string;
}

export type JustificationSummaryStateTypes = {
  fetchAll: ApiCallState<JustificationSummary[]>;
  fetchOne: ApiCallState<JustificationSummary | {}>;
};

export const JustificationSummaryActionTypes = {
  FETCH_ALL_JUSTIFICATION_SUMMARY: "FETCH_ALL_JUSTIFICATION_SUMMARY",
  FETCH_ALL_JUSTIFICATION_SUMMARY_RESET: "FETCH_ALL_JUSTIFICATION_SUMMARY_RESET",
  FETCH_ALL_JUSTIFICATION_SUMMARY_FAILURE: "FETCH_ALL_JUSTIFICATION_SUMMARY_FAILURE",
  FETCH_ALL_JUSTIFICATION_SUMMARY_SUCCESS: "FETCH_ALL_JUSTIFICATION_SUMMARY_SUCCESS",

  FETCH_ONE_JUSTIFICATION_SUMMARY: "FETCH_ONE_JUSTIFICATION_SUMMARY",
  FETCH_ONE_JUSTIFICATION_SUMMARY_RESET: "FETCH_ONE_JUSTIFICATION_SUMMARY_RESET",
  FETCH_ONE_JUSTIFICATION_SUMMARY_FAILURE: "FETCH_ONE_JUSTIFICATION_SUMMARY_FAILURE",
  FETCH_ONE_JUSTIFICATION_SUMMARY_SUCCESS: "FETCH_ONE_JUSTIFICATION_SUMMARY_SUCCESS",
};
