import { RentContractItem } from "../RentContract/RentContract.type";
import { ApiCallState } from "../Utils";

export type MasterList = {
  id: number;
  user_id: number;

  equipment_type: string;
  plate_number: string;
  make: string;
  model: string;
  engine_number: string;
  serial_chassis_number: string;
  hr_power: string;
  capacity: string;
  manufacture_year: string;
  purchase_year: string;
  purchase_price: number;
  purchase_currency: string;
  current_location: string;
  status: string;
  driver_name: string;
  tel_phone_number: string;

  contract_items: RentContractItem[];

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type MasterListStateTypes = {
  fetchAll: ApiCallState<MasterList[]>;
  fetchOne: ApiCallState<MasterList | {}>;
};

export const MasterListActionTypes = {
  FETCH_ALL_MASTER_LIST: "FETCH_ALL_MASTER_LIST",
  FETCH_ALL_MASTER_LIST_RESET: "FETCH_ALL_MASTER_LIST_RESET",
  FETCH_ALL_MASTER_LIST_FAILURE: "FETCH_ALL_MASTER_LIST_FAILURE",
  FETCH_ALL_MASTER_LIST_SUCCESS: "FETCH_ALL_MASTER_LIST_SUCCESS",

  FETCH_ONE_MASTER_LIST: "FETCH_ONE_MASTER_LIST",
  FETCH_ONE_MASTER_LIST_RESET: "FETCH_ONE_MASTER_LIST_RESET",
  FETCH_ONE_MASTER_LIST_FAILURE: "FETCH_ONE_MASTER_LIST_FAILURE",
  FETCH_ONE_MASTER_LIST_SUCCESS: "FETCH_ONE_MASTER_LIST_SUCCESS",
};
