import { BenchmarkStateTypes, BenchmarkActionTypes } from "./Benchmark.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: BenchmarkStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const BenchmarkReducer = (
  state: BenchmarkStateTypes = INITIAL_STATE,
  action: any
): BenchmarkStateTypes => {
  switch (action.type) {
    case BenchmarkActionTypes.FETCH_ALL_BENCHMARK:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case BenchmarkActionTypes.FETCH_ALL_BENCHMARK_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case BenchmarkActionTypes.FETCH_ALL_BENCHMARK_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case BenchmarkActionTypes.FETCH_ALL_BENCHMARK_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case BenchmarkActionTypes.FETCH_ONE_BENCHMARK:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case BenchmarkActionTypes.FETCH_ONE_BENCHMARK_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case BenchmarkActionTypes.FETCH_ONE_BENCHMARK_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case BenchmarkActionTypes.FETCH_ONE_BENCHMARK_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default BenchmarkReducer;
