import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { SiteRAFReadingActionTypes } from "./SiteRAFReading.type";

export function* fetchAllSiteRAFReadings(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/site-raf-reading?project_id=${action.payload.project_id}&date=${action.payload.date}`);
    yield put({
      type: SiteRAFReadingActionTypes.FETCH_ALL_SITE_RAF_READING_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SiteRAFReadingActionTypes.FETCH_ALL_SITE_RAF_READING_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneSiteRAFReadings(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/site-raf-reading/${action.payload}`
    );
    yield put({
      type: SiteRAFReadingActionTypes.FETCH_ONE_SITE_RAF_READING_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SiteRAFReadingActionTypes.FETCH_ONE_SITE_RAF_READING_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllSiteRAFReadings() {
  yield takeLatest(SiteRAFReadingActionTypes.FETCH_ALL_SITE_RAF_READING, fetchAllSiteRAFReadings);
}

export function* watcherFetchOneSiteRAFReadings() {
  yield takeLatest(SiteRAFReadingActionTypes.FETCH_ONE_SITE_RAF_READING, fetchOneSiteRAFReadings);
}
