import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { AllLabourUtilizationSummaryActionTypes } from "./AllLabourUtilizationSummary.type";

export function* fetchAllAllLabourUtilizationSummarys(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/all-labour-utilization-summary?reporting_period=${action.payload.reporting_period}`);
    yield put({
      type: AllLabourUtilizationSummaryActionTypes.FETCH_ALL_ALL_LABOUR_UTILIZATION_SUMMARY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AllLabourUtilizationSummaryActionTypes.FETCH_ALL_ALL_LABOUR_UTILIZATION_SUMMARY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneAllLabourUtilizationSummarys(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/all-labour-utilization-summary/${action.payload}`
    );
    yield put({
      type: AllLabourUtilizationSummaryActionTypes.FETCH_ONE_ALL_LABOUR_UTILIZATION_SUMMARY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AllLabourUtilizationSummaryActionTypes.FETCH_ONE_ALL_LABOUR_UTILIZATION_SUMMARY_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllAllLabourUtilizationSummarys() {
  yield takeLatest(AllLabourUtilizationSummaryActionTypes.FETCH_ALL_ALL_LABOUR_UTILIZATION_SUMMARY, fetchAllAllLabourUtilizationSummarys);
}

export function* watcherFetchOneAllLabourUtilizationSummarys() {
  yield takeLatest(AllLabourUtilizationSummaryActionTypes.FETCH_ONE_ALL_LABOUR_UTILIZATION_SUMMARY, fetchOneAllLabourUtilizationSummarys);
}
