import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type InspectionRegistry= {
    id: number;
    type: string;
    date: string;
    time: string;
    location: string;
    inspection: string;
    purpose_of_inspection: string;
    particular_details: string;
    submitted_by_id: string;
    surveyor_remark: string;
    quantity_surveyor_remark: string;
    structural_inspector_remark: string;
    material_inspector_remark: string;
    others_remark: string;
    inspection_date_time: string;
    representative_comments: string;
    works_are: string;
    inspection_registry_work_items: InspectionRegistryWorkItems[],
    user: User
};

type InspectionRegistryWorkItems = {
  description: string
}

export type InspectionRegistryStateTypes = {
  fetchAll: ApiCallState<InspectionRegistry[]>;
  fetchOne: ApiCallState<InspectionRegistry | {}>;
};

export const InspectionRegistryActionTypes = {
  FETCH_ALL_INSPECTION_REGISTRY: "FETCH_ALL_INSPECTION_REGISTRY",
  FETCH_ALL_INSPECTION_REGISTRY_RESET: "FETCH_ALL_INSPECTION_REGISTRY_RESET",
  FETCH_ALL_INSPECTION_REGISTRY_FAILURE: "FETCH_ALL_INSPECTION_REGISTRY_FAILURE",
  FETCH_ALL_INSPECTION_REGISTRY_SUCCESS: "FETCH_ALL_INSPECTION_REGISTRY_SUCCESS",

  FETCH_ONE_INSPECTION_REGISTRY: "FETCH_ONE_INSPECTION_REGISTRY",
  FETCH_ONE_INSPECTION_REGISTRY_RESET: "FETCH_ONE_INSPECTION_REGISTRY_RESET",
  FETCH_ONE_INSPECTION_REGISTRY_FAILURE: "FETCH_ONE_INSPECTION_REGISTRY_FAILURE",
  FETCH_ONE_INSPECTION_REGISTRY_SUCCESS: "FETCH_ONE_INSPECTION_REGISTRY_SUCCESS",
};
