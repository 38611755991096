import { MasterList } from "../MasterList/MasterList.type";
import {
  ContractSubItem,
  RentContractItem,
} from "../RentContract/RentContract.type";
import { ApiCallState } from "../Utils";

export type GeneralDailyOperationReport = {
  id: number;
  user_id: number;

  date: string;

  name1: string;
  name2: string;
  name3: string;
  name4: string;

  general_daily_operation_report_items: GeneralDailyOperationReportItem[];

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type GeneralDailyOperationReportItem = {
  id: number;
  general_daily_operation_report_id: number;
  master_list_id: number;

  actual_from_hr: string;
  actual_to_hr: string;

  idle_from_hr: string;
  idle_to_hr: string;

  down_from_hr: string;
  down_to_hr: string;

  equipment_service_initial: number;
  equipment_service_final: number;

  fuel_filled: number;
  prev_km_hr: number;
  to_date_km_hr: number;

  idle_reason: string;

  master_list: MasterList;

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type GeneralDailyOperationReportStateTypes = {
  fetchAll: ApiCallState<GeneralDailyOperationReport[]>;
  fetchOne: ApiCallState<GeneralDailyOperationReport | {}>;
  fetchPrevious: ApiCallState<GeneralDailyOperationReport | {}>;
  fetchReport: ApiCallState<GeneralDailyOperationReport | {}>;
};

export const GeneralDailyOperationReportActionTypes = {
  FETCH_ALL_GENERAL_DAILY_OPERATION_REPORT:
    "FETCH_ALL_GENERAL_DAILY_OPERATION_REPORT",
  FETCH_ALL_GENERAL_DAILY_OPERATION_REPORT_RESET:
    "FETCH_ALL_GENERAL_DAILY_OPERATION_REPORT_RESET",
  FETCH_ALL_GENERAL_DAILY_OPERATION_REPORT_FAILURE:
    "FETCH_ALL_GENERAL_DAILY_OPERATION_REPORT_FAILURE",
  FETCH_ALL_GENERAL_DAILY_OPERATION_REPORT_SUCCESS:
    "FETCH_ALL_GENERAL_DAILY_OPERATION_REPORT_SUCCESS",

  FETCH_ONE_GENERAL_DAILY_OPERATION_REPORT:
    "FETCH_ONE_GENERAL_DAILY_OPERATION_REPORT",
  FETCH_ONE_GENERAL_DAILY_OPERATION_REPORT_RESET:
    "FETCH_ONE_GENERAL_DAILY_OPERATION_REPORT_RESET",
  FETCH_ONE_GENERAL_DAILY_OPERATION_REPORT_FAILURE:
    "FETCH_ONE_GENERAL_DAILY_OPERATION_REPORT_FAILURE",
  FETCH_ONE_GENERAL_DAILY_OPERATION_REPORT_SUCCESS:
    "FETCH_ONE_GENERAL_DAILY_OPERATION_REPORT_SUCCESS",

  FETCH_PREVIOUS_GENERAL_DAILY_OPERATION_REPORT:
    "FETCH_PREVIOUS_GENERAL_DAILY_OPERATION_REPORT",
  FETCH_PREVIOUS_GENERAL_DAILY_OPERATION_REPORT_RESET:
    "FETCH_PREVIOUS_GENERAL_DAILY_OPERATION_REPORT_RESET",
  FETCH_PREVIOUS_GENERAL_DAILY_OPERATION_REPORT_FAILURE:
    "FETCH_PREVIOUS_GENERAL_DAILY_OPERATION_REPORT_FAILURE",
  FETCH_PREVIOUS_GENERAL_DAILY_OPERATION_REPORT_SUCCESS:
    "FETCH_PREVIOUS_GENERAL_DAILY_OPERATION_REPORT_SUCCESS",

  FETCH_GENERATED_GENERAL_DAILY_OPERATION_REPORT:
    "FETCH_GENERATED_GENERAL_DAILY_OPERATION_REPORT",
  FETCH_GENERATED_GENERAL_DAILY_OPERATION_REPORT_RESET:
    "FETCH_GENERATED_GENERAL_DAILY_OPERATION_REPORT_RESET",
  FETCH_GENERATED_GENERAL_DAILY_OPERATION_REPORT_FAILURE:
    "FETCH_GENERATED_GENERAL_DAILY_OPERATION_REPORT_FAILURE",
  FETCH_GENERATED_GENERAL_DAILY_OPERATION_REPORT_SUCCESS:
    "FETCH_GENERATED_GENERAL_DAILY_OPERATION_REPORT_SUCCESS",
};
