import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { DailyStatusReportActionTypes } from "./DailyStatusReport.type";

export function* fetchAllDailyStatusReports(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/daily-status-report`);
    yield put({
      type: DailyStatusReportActionTypes.FETCH_ALL_DAILY_STATUS_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DailyStatusReportActionTypes.FETCH_ALL_DAILY_STATUS_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneDailyStatusReports(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/daily-status-report/${action.payload}`
    );
    yield put({
      type: DailyStatusReportActionTypes.FETCH_ONE_DAILY_STATUS_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DailyStatusReportActionTypes.FETCH_ONE_DAILY_STATUS_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllDailyStatusReports() {
  yield takeLatest(
    DailyStatusReportActionTypes.FETCH_ALL_DAILY_STATUS_REPORT,
    fetchAllDailyStatusReports
  );
}

export function* watcherFetchOneDailyStatusReports() {
  yield takeLatest(
    DailyStatusReportActionTypes.FETCH_ONE_DAILY_STATUS_REPORT,
    fetchOneDailyStatusReports
  );
}
