import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ProformaComparisonActionTypes } from "./ProformaComparison.type";

export function* fetchAllProformaComparisons(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/proforma-comparison?project_id=${action.payload?.project_id}`);
    yield put({
      type: ProformaComparisonActionTypes.FETCH_ALL_PROFORMA_COMPARISON_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ProformaComparisonActionTypes.FETCH_ALL_PROFORMA_COMPARISON_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneProformaComparisons(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/proforma-comparison/${action.payload}`
    );
    yield put({
      type: ProformaComparisonActionTypes.FETCH_ONE_PROFORMA_COMPARISON_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ProformaComparisonActionTypes.FETCH_ONE_PROFORMA_COMPARISON_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllProformaComparisons() {
  yield takeLatest(
    ProformaComparisonActionTypes.FETCH_ALL_PROFORMA_COMPARISON,
    fetchAllProformaComparisons
  );
}

export function* watcherFetchOneProformaComparisons() {
  yield takeLatest(
    ProformaComparisonActionTypes.FETCH_ONE_PROFORMA_COMPARISON,
    fetchOneProformaComparisons
  );
}
