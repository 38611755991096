import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { SubcontractorsCommitmentActionTypes } from "./SubcontractorsCommitment.type";

export function* fetchAllSubcontractorsCommitments(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/subcontractors-commitment?project_id=${action.payload.project_id}`);
    yield put({
      type: SubcontractorsCommitmentActionTypes.FETCH_ALL_SUBCONTRACTORS_COMMITMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SubcontractorsCommitmentActionTypes.FETCH_ALL_SUBCONTRACTORS_COMMITMENT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneSubcontractorsCommitments(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/subcontractors-commitment/${action.payload}`
    );
    yield put({
      type: SubcontractorsCommitmentActionTypes.FETCH_ONE_SUBCONTRACTORS_COMMITMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SubcontractorsCommitmentActionTypes.FETCH_ONE_SUBCONTRACTORS_COMMITMENT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllSubcontractorsCommitments() {
  yield takeLatest(SubcontractorsCommitmentActionTypes.FETCH_ALL_SUBCONTRACTORS_COMMITMENT, fetchAllSubcontractorsCommitments);
}

export function* watcherFetchOneSubcontractorsCommitments() {
  yield takeLatest(SubcontractorsCommitmentActionTypes.FETCH_ONE_SUBCONTRACTORS_COMMITMENT, fetchOneSubcontractorsCommitments);
}
