import { ApiCallState } from "../Utils";

export type AssignmentRegistry= {
    project_id: number | null;
    id: number | null;
    division:string;
    description:string;
    resposible_person:string;
    due_date:string;
    response:string;
    assigned_by:string;
};

export type AssignmentRegistryStateTypes = {
  fetchAll: ApiCallState<AssignmentRegistry[]>;
  fetchOne: ApiCallState<AssignmentRegistry | {}>;
};

export type AssignmentRegistryActionType = {
    project_id: number;
};

export type AssignmentRegistryOneActionType = {
  project_id: number;
  id:number;
}

export const AssignmentRegistryActionTypes = {
  FETCH_ALL_ASSIGNMENT_REGISTRY: "FETCH_ALL_ASSIGNMENT_REGISTRY",
  FETCH_ALL_ASSIGNMENT_REGISTRY_RESET: "FETCH_ALL_ASSIGNMENT_REGISTRY_RESET",
  FETCH_ALL_ASSIGNMENT_REGISTRY_FAILURE: "FETCH_ALL_ASSIGNMENT_REGISTRY_FAILURE",
  FETCH_ALL_ASSIGNMENT_REGISTRY_SUCCESS: "FETCH_ALL_ASSIGNMENT_REGISTRY_SUCCESS",

  FETCH_ONE_ASSIGNMENT_REGISTRY: "FETCH_ONE_ASSIGNMENT_REGISTRY",
  FETCH_ONE_ASSIGNMENT_REGISTRY_RESET: "FETCH_ONE_ASSIGNMENT_REGISTRY_RESET",
  FETCH_ONE_ASSIGNMENT_REGISTRY_FAILURE: "FETCH_ONE_ASSIGNMENT_REGISTRY_FAILURE",
  FETCH_ONE_ASSIGNMENT_REGISTRY_SUCCESS: "FETCH_ONE_ASSIGNMENT_REGISTRY_SUCCESS",
};
