import { ApiCallState } from "../Utils";
import { Moment } from "moment";

export type MaterialRequiredSummary= {
    id: number | null;
    project_id:number | null;
    date:Moment;
    summary_no:string;
    material_required_summary_details:MaterialRequiredSummaryDetail[];
};

export type MaterialRequiredSummaryDetail ={
    id: number | null;
    material_required_summary_id:number | null;
    item_no:string;
    description:string;
    unit:string;
    quantity:number;
    cement_required:number;
    sand_required:number;
    aggregate_required:number;
    stone_required:number;
    hcp:number;
    brick:number;
    remark:string;
}

export type MaterialRequiredSummaryStateTypes = {
  fetchAll: ApiCallState<MaterialRequiredSummary[]>;
  fetchOne: ApiCallState<MaterialRequiredSummary | {}>;
};

export const MaterialRequiredSummaryActionTypes = {
  FETCH_ALL_MATERIAL_REQUIRED_SUMMARY: "FETCH_ALL_MATERIAL_REQUIRED_SUMMARY",
  FETCH_ALL_MATERIAL_REQUIRED_SUMMARY_RESET: "FETCH_ALL_MATERIAL_REQUIRED_SUMMARY_RESET",
  FETCH_ALL_MATERIAL_REQUIRED_SUMMARY_FAILURE: "FETCH_ALL_MATERIAL_REQUIRED_SUMMARY_FAILURE",
  FETCH_ALL_MATERIAL_REQUIRED_SUMMARY_SUCCESS: "FETCH_ALL_MATERIAL_REQUIRED_SUMMARY_SUCCESS",

  FETCH_ONE_MATERIAL_REQUIRED_SUMMARY: "FETCH_ONE_MATERIAL_REQUIRED_SUMMARY",
  FETCH_ONE_MATERIAL_REQUIRED_SUMMARY_RESET: "FETCH_ONE_MATERIAL_REQUIRED_SUMMARY_RESET",
  FETCH_ONE_MATERIAL_REQUIRED_SUMMARY_FAILURE: "FETCH_ONE_MATERIAL_REQUIRED_SUMMARY_FAILURE",
  FETCH_ONE_MATERIAL_REQUIRED_SUMMARY_SUCCESS: "FETCH_ONE_MATERIAL_REQUIRED_SUMMARY_SUCCESS",
};
