import { MonthMaterialScheduleStateTypes, MonthMaterialScheduleActionTypes } from "./MonthMaterialSchedule.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: MonthMaterialScheduleStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const MonthMaterialScheduleReducer = (
  state: MonthMaterialScheduleStateTypes = INITIAL_STATE,
  action: any
): MonthMaterialScheduleStateTypes => {
  switch (action.type) {
    case MonthMaterialScheduleActionTypes.FETCH_ALL_MONTH_MATERIAL_SCHEDULE:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case MonthMaterialScheduleActionTypes.FETCH_ALL_MONTH_MATERIAL_SCHEDULE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case MonthMaterialScheduleActionTypes.FETCH_ALL_MONTH_MATERIAL_SCHEDULE_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MonthMaterialScheduleActionTypes.FETCH_ALL_MONTH_MATERIAL_SCHEDULE_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case MonthMaterialScheduleActionTypes.FETCH_ONE_MONTH_MATERIAL_SCHEDULE:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case MonthMaterialScheduleActionTypes.FETCH_ONE_MONTH_MATERIAL_SCHEDULE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case MonthMaterialScheduleActionTypes.FETCH_ONE_MONTH_MATERIAL_SCHEDULE_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MonthMaterialScheduleActionTypes.FETCH_ONE_MONTH_MATERIAL_SCHEDULE_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default MonthMaterialScheduleReducer;
