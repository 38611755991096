import { RentContractItem } from "../RentContract/RentContract.type";
import { ApiCallState } from "../Utils";

export type DailyReport = {
  id: number;
  contract_item_id: number;

  operator: string;
  foreman: string;

  date: string;
  type: string;

  oh_morning_from: string;
  oh_morning_to: string;
  oh_morning_diff: number;
  oh_morning_end_reason: string;

  oh_afternoon_from: string;
  oh_afternoon_to: string;
  oh_afternoon_diff: number;
  oh_afternoon_end_reason: string;

  nh_from: string;
  nh_to: string;
  nh_diff: number;
  nh_end_reason: string;

  sh_from: string;
  sh_to: string;
  sh_diff: number;

  dh_from: string;
  dh_to: string;
  dh_diff: number;

  remark: string;

  km_reading_start: number;
  km_reading_end: number;

  fuel_consumption: number;
  fuel_price: number;

  contract_item: RentContractItem;
  daily_report_oil_and_lubricants: DailyReportItem[];
  daily_report_maintenances: DailyReportItem[];
  daily_report_others: DailyReportItem[];

  readonly createdAt: Date;
};

export type DailyReportItem = {
  id: number;
  daily_report_id: number;

  description: string;
  quantity: number;
  unit: string;
  unit_price: number;
  amount: number;

  readonly createdAt: Date;
};

export type DailyReportStateTypes = {
  fetchAll: ApiCallState<DailyReport[]>;
  fetchOne: ApiCallState<DailyReport | {}>;
};

export const DailyReportActionTypes = {
  FETCH_ALL_DAILY_REPORT: "FETCH_ALL_DAILY_REPORT",
  FETCH_ALL_DAILY_REPORT_RESET: "FETCH_ALL_DAILY_REPORT_RESET",
  FETCH_ALL_DAILY_REPORT_FAILURE: "FETCH_ALL_DAILY_REPORT_FAILURE",
  FETCH_ALL_DAILY_REPORT_SUCCESS: "FETCH_ALL_DAILY_REPORT_SUCCESS",

  FETCH_ONE_DAILY_REPORT: "FETCH_ONE_DAILY_REPORT",
  FETCH_ONE_DAILY_REPORT_RESET: "FETCH_ONE_DAILY_REPORT_RESET",
  FETCH_ONE_DAILY_REPORT_FAILURE: "FETCH_ONE_DAILY_REPORT_FAILURE",
  FETCH_ONE_DAILY_REPORT_SUCCESS: "FETCH_ONE_DAILY_REPORT_SUCCESS",
};
