import { ProjectUploadDocumentStateTypes, ProjectUploadDocumentActionTypes } from "./ProjectUploadDocument.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: ProjectUploadDocumentStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const ProjectUploadDocumentReducer = (
  state: ProjectUploadDocumentStateTypes = INITIAL_STATE,
  action: any
): ProjectUploadDocumentStateTypes => {
  switch (action.type) {
    case ProjectUploadDocumentActionTypes.FETCH_ALL_PROJECT_UPLOAD_DOCUMENT:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case ProjectUploadDocumentActionTypes.FETCH_ALL_PROJECT_UPLOAD_DOCUMENT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ProjectUploadDocumentActionTypes.FETCH_ALL_PROJECT_UPLOAD_DOCUMENT_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ProjectUploadDocumentActionTypes.FETCH_ALL_PROJECT_UPLOAD_DOCUMENT_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case ProjectUploadDocumentActionTypes.FETCH_ONE_PROJECT_UPLOAD_DOCUMENT:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case ProjectUploadDocumentActionTypes.FETCH_ONE_PROJECT_UPLOAD_DOCUMENT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ProjectUploadDocumentActionTypes.FETCH_ONE_PROJECT_UPLOAD_DOCUMENT_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ProjectUploadDocumentActionTypes.FETCH_ONE_PROJECT_UPLOAD_DOCUMENT_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default ProjectUploadDocumentReducer;
