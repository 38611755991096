import { AggregateTakeoffItem } from './../Boq/Boq.type';

import { ApiCallState } from "../Utils";

export type PaymentCertificateAggregate= {
  id: number;
  pid:number
  item_id:number
  aggregate_take_off_item:AggregateTakeoffItem
  approval: number,
  remark: string,
  quantity:number
};

export type PaymentCertificateAggregateStateTypes = {
  fetchAll: ApiCallState<PaymentCertificateAggregate[]>;
  fetchOne: ApiCallState<PaymentCertificateAggregate | {}>;
};

export const PaymentCertificateAggregateActionTypes = {
  FETCH_ALL_PAYMENT_CERTIFICATE_AGGREGATE: "FETCH_ALL_PAYMENT_CERTIFICATE_AGGREGATE",
  FETCH_ALL_PAYMENT_CERTIFICATE_AGGREGATE_RESET: "FETCH_ALL_PAYMENT_CERTIFICATE_AGGREGATE_RESET",
  FETCH_ALL_PAYMENT_CERTIFICATE_AGGREGATE_FAILURE: "FETCH_ALL_PAYMENT_CERTIFICATE_AGGREGATE_FAILURE",
  FETCH_ALL_PAYMENT_CERTIFICATE_AGGREGATE_SUCCESS: "FETCH_ALL_PAYMENT_CERTIFICATE_AGGREGATE_SUCCESS",

  FETCH_ONE_PAYMENT_CERTIFICATE_AGGREGATE: "FETCH_ONE_PAYMENT_CERTIFICATE_AGGREGATE",
  FETCH_ONE_PAYMENT_CERTIFICATE_AGGREGATE_RESET: "FETCH_ONE_PAYMENT_CERTIFICATE_AGGREGATE_RESET",
  FETCH_ONE_PAYMENT_CERTIFICATE_AGGREGATE_FAILURE: "FETCH_ONE_PAYMENT_CERTIFICATE_AGGREGATE_FAILURE",
  FETCH_ONE_PAYMENT_CERTIFICATE_AGGREGATE_SUCCESS: "FETCH_ONE_PAYMENT_CERTIFICATE_AGGREGATE_SUCCESS",
};
