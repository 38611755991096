import { Moment } from "moment";
import { ApiCallState } from "../Utils";

export type LabourUtilization= {
    id: number | null;
    project_id:number | null;
    date:Moment;
    labour_no:string;
    total_measurable_amount:number;
    total_manhour:number;
    measureable_activities:MeasureableActivity[];
    non_measureable_activities:NonMeasureableActivity[];
};

export type MeasureableActivity ={
    id: number | null;
    labour_utilization_id:number | null;
    item_no:string;
    description:string;
    quantity:number;
    unit:string;
    unit_price:number;
    amount:number;
}

export type NonMeasureableActivity ={
    id: number | null;
    labour_utilization_id:number | null;
    activity:string;
    dl_m:number;
    dl_f:number;
    bar_bendor:number;
    ass_store:number;
    carpenter:number;
    ass_carpenter:number;
    data_collector:number;
    masson:number;
    ass_masson:number;
    plasterer:number;
    ass_plasterer:number;
    sub_store:number;
    mixer_operator:number;
    ass_mixer_operator:number;
    chiseler:number;
    ass_chiseler:number;
    gang_chief:number;
    ass_loader_operator:number;
    office_cleaner:number;
    ass_plumber:number;
    electrician:number;
    total_amount_activity:number;
    type:string;
}

export type LabourUtilizationStateTypes = {
  fetchAll: ApiCallState<LabourUtilization[]>;
  fetchOne: ApiCallState<LabourUtilization | {}>;
};

export const LabourUtilizationActionTypes = {
  FETCH_ALL_LABOUR_UTILIZATION: "FETCH_ALL_LABOUR_UTILIZATION",
  FETCH_ALL_LABOUR_UTILIZATION_RESET: "FETCH_ALL_LABOUR_UTILIZATION_RESET",
  FETCH_ALL_LABOUR_UTILIZATION_FAILURE: "FETCH_ALL_LABOUR_UTILIZATION_FAILURE",
  FETCH_ALL_LABOUR_UTILIZATION_SUCCESS: "FETCH_ALL_LABOUR_UTILIZATION_SUCCESS",

  FETCH_ONE_LABOUR_UTILIZATION: "FETCH_ONE_LABOUR_UTILIZATION",
  FETCH_ONE_LABOUR_UTILIZATION_RESET: "FETCH_ONE_LABOUR_UTILIZATION_RESET",
  FETCH_ONE_LABOUR_UTILIZATION_FAILURE: "FETCH_ONE_LABOUR_UTILIZATION_FAILURE",
  FETCH_ONE_LABOUR_UTILIZATION_SUCCESS: "FETCH_ONE_LABOUR_UTILIZATION_SUCCESS",
};
