import { Moment } from "moment";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type WorkerTimeSheet = {
  id: number;
  date: Moment[];
  checked_by: number | null;
  authorized_by: number | null;
  is_approved: boolean;
  is_checked: boolean;
  project_id: number;
  worker_time_sheet_items: WorkerTimeSheetItem[];
  work_time_sheet_approved_by: User | null;
  work_time_sheet_checked_by: User | null;
  work_time_sheet_prepared_by: User | null;
  user_id: number;
};

export type WorkerTimeSheetItem = {
  id: any;
  key: number;
  name: string;
  salary: number;
  position: string;
  staff_id: any;
  working_day: number;
};

export type WorkerTimeSheetStateTypes = {
  fetchAll: ApiCallState<WorkerTimeSheet[]>;
  fetchOne: ApiCallState<WorkerTimeSheet | {}>;
};

export const WorkerTimeSheetActionTypes = {
  FETCH_ALL_WORKER_TIME_SHEET: "FETCH_ALL_WORKER_TIME_SHEET",
  FETCH_ALL_WORKER_TIME_SHEET_RESET: "FETCH_ALL_WORKER_TIME_SHEET_RESET",
  FETCH_ALL_WORKER_TIME_SHEET_FAILURE: "FETCH_ALL_WORKER_TIME_SHEET_FAILURE",
  FETCH_ALL_WORKER_TIME_SHEET_SUCCESS: "FETCH_ALL_WORKER_TIME_SHEET_SUCCESS",

  FETCH_ONE_WORKER_TIME_SHEET: "FETCH_ONE_WORKER_TIME_SHEET",
  FETCH_ONE_WORKER_TIME_SHEET_RESET: "FETCH_ONE_WORKER_TIME_SHEET_RESET",
  FETCH_ONE_WORKER_TIME_SHEET_FAILURE: "FETCH_ONE_WORKER_TIME_SHEET_FAILURE",
  FETCH_ONE_WORKER_TIME_SHEET_SUCCESS: "FETCH_ONE_WORKER_TIME_SHEET_SUCCESS",
};
