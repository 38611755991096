import axios from "axios";
import moment from "moment";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { WorkScheduleActionTypes } from "./WorkSchedule.type";

export function* fetchAllWorkSchedules(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/work-schedule?project_id=${action.payload?.project_id}&date=${action.payload?.date}`
    );
    yield put({
      type: WorkScheduleActionTypes.FETCH_ALL_WORK_SCHEDULE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: WorkScheduleActionTypes.FETCH_ALL_WORK_SCHEDULE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneWorkSchedules(action: any): any {
  try {
    const response = yield axios.get(
      `API_BASE_URI/work-schedule/${action.payload}`
    );
    yield put({
      type: WorkScheduleActionTypes.FETCH_ONE_WORK_SCHEDULE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: WorkScheduleActionTypes.FETCH_ONE_WORK_SCHEDULE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllWorkSchedules() {
  yield takeLatest(
    WorkScheduleActionTypes.FETCH_ALL_WORK_SCHEDULE,
    fetchAllWorkSchedules
  );
}

export function* watcherFetchOneWorkSchedules() {
  yield takeLatest(
    WorkScheduleActionTypes.FETCH_ONE_WORK_SCHEDULE,
    fetchOneWorkSchedules
  );
}
