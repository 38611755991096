import { ApiCallState } from "../Utils";
import { User } from "../User/User.type";

export type DailyActivity = {
  id: number;
  employee_name: string;
  position: string;
  work_place: string;
  department: string;
  date: string;
  user: User;
  prepared_by_id: number;
  daily_work_reports: DailyWorkReport[];
  next_day_work_plans: NextDayWorkPlan[];
};

export type DailyWorkReport = {
  id?: number;
  project_name: string;
  type: string;
  activity: string;
  remark: string;
};

export type NextDayWorkPlan = {
  id?: number;
  project_name: string;
  type: string;
  activity: string;
  remark: string;
};

export type DailyActivityStateTypes = {
  fetchAll: ApiCallState<DailyActivity[]>;
  fetchOne: ApiCallState<DailyActivity | {}>;
};

export const DailyActivityActionTypes = {
  FETCH_ALL_DAILY_ACTIVITY: "FETCH_ALL_DAILY_ACTIVITY",
  FETCH_ALL_DAILY_ACTIVITY_RESET: "FETCH_ALL_DAILY_ACTIVITY_RESET",
  FETCH_ALL_DAILY_ACTIVITY_FAILURE: "FETCH_ALL_DAILY_ACTIVITY_FAILURE",
  FETCH_ALL_DAILY_ACTIVITY_SUCCESS: "FETCH_ALL_DAILY_ACTIVITY_SUCCESS",

  FETCH_ONE_DAILY_ACTIVITY: "FETCH_ONE_DAILY_ACTIVITY",
  FETCH_ONE_DAILY_ACTIVITY_RESET: "FETCH_ONE_DAILY_ACTIVITY_RESET",
  FETCH_ONE_DAILY_ACTIVITY_FAILURE: "FETCH_ONE_DAILY_ACTIVITY_FAILURE",
  FETCH_ONE_DAILY_ACTIVITY_SUCCESS: "FETCH_ONE_DAILY_ACTIVITY_SUCCESS",
};
