import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ApprovalScheduleActionTypes } from "./ApprovalSchedule.type";

export function* fetchAllApprovalSchedules(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/approval-schedule?project_id=${action.payload.project_id}`);
    yield put({
      type: ApprovalScheduleActionTypes.FETCH_ALL_APPROVAL_SCHEDULE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ApprovalScheduleActionTypes.FETCH_ALL_APPROVAL_SCHEDULE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneApprovalSchedules(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/approval-schedule/${action.payload}`
    );
    yield put({
      type: ApprovalScheduleActionTypes.FETCH_ONE_APPROVAL_SCHEDULE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ApprovalScheduleActionTypes.FETCH_ONE_APPROVAL_SCHEDULE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllApprovalSchedules() {
  yield takeLatest(ApprovalScheduleActionTypes.FETCH_ALL_APPROVAL_SCHEDULE, fetchAllApprovalSchedules);
}

export function* watcherFetchOneApprovalSchedules() {
  yield takeLatest(ApprovalScheduleActionTypes.FETCH_ONE_APPROVAL_SCHEDULE, fetchOneApprovalSchedules);
}
