import { ApiCallState } from "../Utils";

export type DailyStatusReport = {
  id: number;
  user_id: number;
  contract_project_id: number;

  date: string;

  daily_status_report_items: DailyStatusReportItem[];
};

export type DailyStatusReportItem = {
  id: number;
  daily_status_report_id: number;

  vehicle_type: string;
  active: number;
  down: number;
  idle: number;
  remark: string;
};

export type DailyStatusReportStateTypes = {
  fetchAll: ApiCallState<DailyStatusReport[]>;
  fetchOne: ApiCallState<DailyStatusReport | {}>;
};

export const DailyStatusReportActionTypes = {
  FETCH_ALL_DAILY_STATUS_REPORT: "FETCH_ALL_DAILY_STATUS_REPORT",
  FETCH_ALL_DAILY_STATUS_REPORT_RESET: "FETCH_ALL_DAILY_STATUS_REPORT_RESET",
  FETCH_ALL_DAILY_STATUS_REPORT_FAILURE:
    "FETCH_ALL_DAILY_STATUS_REPORT_FAILURE",
  FETCH_ALL_DAILY_STATUS_REPORT_SUCCESS:
    "FETCH_ALL_DAILY_STATUS_REPORT_SUCCESS",

  FETCH_ONE_DAILY_STATUS_REPORT: "FETCH_ONE_DAILY_STATUS_REPORT",
  FETCH_ONE_DAILY_STATUS_REPORT_RESET: "FETCH_ONE_DAILY_STATUS_REPORT_RESET",
  FETCH_ONE_DAILY_STATUS_REPORT_FAILURE:
    "FETCH_ONE_DAILY_STATUS_REPORT_FAILURE",
  FETCH_ONE_DAILY_STATUS_REPORT_SUCCESS:
    "FETCH_ONE_DAILY_STATUS_REPORT_SUCCESS",
};
