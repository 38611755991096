import { Rebar } from "../Rebar/Rebar.type";
import { ApiCallState } from "../Utils";

export type PaymentCertificateRebar= {
  id:number
  pid:number
  rebar_id:number
  location:string
  structural_member_axis:string
  length:number
  bar_diameter:number
  no_of_member:number
  no_of_bar:number
  total:number
  remark:string
  approval:string
  rebar:Rebar


};

export type PaymentCertificateRebarStateTypes = {
  fetchAll: ApiCallState<PaymentCertificateRebar[]>;
  fetchOne: ApiCallState<PaymentCertificateRebar | {}>;
};

export const PaymentCertificateRebarActionTypes = {
  FETCH_ALL_PAYMENT_CERTIFICATE_REBAR: "FETCH_ALL_PAYMENT_CERTIFICATE_REBAR",
  FETCH_ALL_PAYMENT_CERTIFICATE_REBAR_RESET: "FETCH_ALL_PAYMENT_CERTIFICATE_REBAR_RESET",
  FETCH_ALL_PAYMENT_CERTIFICATE_REBAR_FAILURE: "FETCH_ALL_PAYMENT_CERTIFICATE_REBAR_FAILURE",
  FETCH_ALL_PAYMENT_CERTIFICATE_REBAR_SUCCESS: "FETCH_ALL_PAYMENT_CERTIFICATE_REBAR_SUCCESS",

  FETCH_ONE_PAYMENT_CERTIFICATE_REBAR: "FETCH_ONE_PAYMENT_CERTIFICATE_REBAR",
  FETCH_ONE_PAYMENT_CERTIFICATE_REBAR_RESET: "FETCH_ONE_PAYMENT_CERTIFICATE_REBAR_RESET",
  FETCH_ONE_PAYMENT_CERTIFICATE_REBAR_FAILURE: "FETCH_ONE_PAYMENT_CERTIFICATE_REBAR_FAILURE",
  FETCH_ONE_PAYMENT_CERTIFICATE_REBAR_SUCCESS: "FETCH_ONE_PAYMENT_CERTIFICATE_REBAR_SUCCESS",
};
