import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { SupplyFixActionTypes } from "./SupplyFix.type";

export function* fetchAllSupplyFixes(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/supply-fix?project_id=${action.payload?.project_id}`
    );
    yield put({
      type: SupplyFixActionTypes.FETCH_ALL_SUPPLY_FIX_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SupplyFixActionTypes.FETCH_ALL_SUPPLY_FIX_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneSupplyFixes(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/supply-fix/detail?project_id=${action.payload?.project_id}`
    );
    yield put({
      type: SupplyFixActionTypes.FETCH_ONE_SUPPLY_FIX_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SupplyFixActionTypes.FETCH_ONE_SUPPLY_FIX_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllSupplyFixes() {
  yield takeLatest(
    SupplyFixActionTypes.FETCH_ALL_SUPPLY_FIX,
    fetchAllSupplyFixes
  );
}

export function* watcherFetchOneSupplyFixes() {
  yield takeLatest(
    SupplyFixActionTypes.FETCH_ONE_SUPPLY_FIX,
    fetchOneSupplyFixes
  );
}
