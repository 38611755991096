import { Project } from "../Project/Project.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type Meeting = {
  id: any;
  date: string;
  type: string;
  location: string;
  project: Project;
  project_id: number;
  meeting_documents: MeetingDocuments[];
  meeting_attendances: MeetingAttendance[];
  meeting_agendas: MeetingAgenda[];
  meeting_general_work_progresses: MeetingGeneralWorkProgress[];
  meeting_agenda_discussions: MeetingAgendaDiscussion[];
  meeting_action_plans: MeetingActionPlan[];
  meeting_additional_issues: MeetingAdditionalIssue[];
  meeting_incoming_documents: MeetingIncomingDocument[];
  meeting_opening_remarks: MeetingOpeningRemarks[];
  meeting_previous_corrections: MeetingPreviousCorrection[];
  meeting_participants: MeetingParticipant[];
  next_location: string;
  next_date: string;
  next_time: string;
};

export type MeetingStateTypes = {
  fetchAll: ApiCallState<Meeting[]>;
  fetchOne: ApiCallState<Meeting | {}>;
};

export const MeetingActionTypes = {
  FETCH_ALL_MEETING: "FETCH_ALL_MEETING",
  FETCH_ALL_MEETING_RESET: "FETCH_ALL_MEETING_RESET",
  FETCH_ALL_MEETING_FAILURE: "FETCH_ALL_MEETING_FAILURE",
  FETCH_ALL_MEETING_SUCCESS: "FETCH_ALL_MEETING_SUCCESS",

  FETCH_ONE_MEETING: "FETCH_ONE_MEETING",
  FETCH_ONE_MEETING_RESET: "FETCH_ONE_MEETING_RESET",
  FETCH_ONE_MEETING_FAILURE: "FETCH_ONE_MEETING_FAILURE",
  FETCH_ONE_MEETING_SUCCESS: "FETCH_ONE_MEETING_SUCCESS",
};

type MeetingDocuments = {
  id: any;
  description: string;
};

type MeetingAttendance = {
  id: number;
  full_name: string;
  role: string;
  meeting_attendance: {
    is_approved: boolean;
    id: number;
    user_id: number;
  };
};

export type MeetingParticipant = {
  id: number;
  name: string;
  position: string;
};

type MeetingActionPlan = {
  id: any;
  schedule_by: string;
  assigned_to: string;
  task: string;
};

type MeetingAgenda = {
  id: any;
  description: string;
};

type MeetingAgendaDiscussion = {
  id: any;
  description: string;
};

type MeetingGeneralWorkProgress = {
  id: any;
  description: string;
  status: string;
};

type MeetingAdditionalIssue = {
  id: any;
  description: string;
};

type MeetingIncomingDocument = {
  id: any;
  description: string;
};

type MeetingOpeningRemarks = {
  id: any;
  description: string;
};

type MeetingPreviousCorrection = {
  id: any;
  description: string;
};
