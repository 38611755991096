import { ApiCallState } from "../Utils";

export type DailyActivityLog = {
  id: any;
  project_id: number;
  date: string;
  description: string;
  start_time: string;
  end_time: string;
  duration: number;
  key: number;
};

export type DailyActivityLogStateTypes = {
  fetchAll: ApiCallState<DailyActivityLog[]>;
  fetchOne: ApiCallState<DailyActivityLog | {}>;
};

export const DailyActivityLogActionTypes = {
  FETCH_ALL_DAILY_ACTIVITY_LOG: "FETCH_ALL_DAILY_ACTIVITY_LOG",
  FETCH_ALL_DAILY_ACTIVITY_LOG_RESET: "FETCH_ALL_DAILY_ACTIVITY_LOG_RESET",
  FETCH_ALL_DAILY_ACTIVITY_LOG_FAILURE: "FETCH_ALL_DAILY_ACTIVITY_LOG_FAILURE",
  FETCH_ALL_DAILY_ACTIVITY_LOG_SUCCESS: "FETCH_ALL_DAILY_ACTIVITY_LOG_SUCCESS",

  FETCH_ONE_DAILY_ACTIVITY_LOG: "FETCH_ONE_DAILY_ACTIVITY_LOG",
  FETCH_ONE_DAILY_ACTIVITY_LOG_RESET: "FETCH_ONE_DAILY_ACTIVITY_LOG_RESET",
  FETCH_ONE_DAILY_ACTIVITY_LOG_FAILURE: "FETCH_ONE_DAILY_ACTIVITY_LOG_FAILURE",
  FETCH_ONE_DAILY_ACTIVITY_LOG_SUCCESS: "FETCH_ONE_DAILY_ACTIVITY_LOG_SUCCESS",
};
