import { Project } from "./../Project/Project.type";
import { User } from "./../User/User.type";
import { ApiCallState } from "../Utils";

export type SubContractHandOver = {
  id: number;
  date: string;
  no: number;
  total: number;
  project_id: number;
  prepared_by_engineering_department_id: number;
  received_by_engineering_department_id: number;
  received_by_technical_audit_id: number;
  returned_by_technical_audit_id: number;
  user_id: number;
  createdAt: string;
  updatedAt: string;
  status: number[];
  prepared_by_engineering_department: User;
  received_by_engineering_department: User;
  returned_by_technical_audit: User;
  sub_contract_handover_items: SubContractHandOverItem[];
};

export type SubContractHandOverItem = {
  key: number;
  id: any;
  sub_contract_id: any;
  pyt_no: string;
  amount: number;
  remark?: string;
  sub_contract_handover_id: any;

  sub_contract?: Project;
};

export type SubContractHandOverStateTypes = {
  fetchAll: ApiCallState<SubContractHandOver[]>;
  fetchOne: ApiCallState<SubContractHandOver | {}>;
};

export const SubContractHandOverActionTypes = {
  FETCH_ALL_SUB_CONTRACT_HAND_OVER: "FETCH_ALL_SUB_CONTRACT_HAND_OVER",
  FETCH_ALL_SUB_CONTRACT_HAND_OVER_RESET:
    "FETCH_ALL_SUB_CONTRACT_HAND_OVER_RESET",
  FETCH_ALL_SUB_CONTRACT_HAND_OVER_FAILURE:
    "FETCH_ALL_SUB_CONTRACT_HAND_OVER_FAILURE",
  FETCH_ALL_SUB_CONTRACT_HAND_OVER_SUCCESS:
    "FETCH_ALL_SUB_CONTRACT_HAND_OVER_SUCCESS",

  FETCH_ONE_SUB_CONTRACT_HAND_OVER: "FETCH_ONE_SUB_CONTRACT_HAND_OVER",
  FETCH_ONE_SUB_CONTRACT_HAND_OVER_RESET:
    "FETCH_ONE_SUB_CONTRACT_HAND_OVER_RESET",
  FETCH_ONE_SUB_CONTRACT_HAND_OVER_FAILURE:
    "FETCH_ONE_SUB_CONTRACT_HAND_OVER_FAILURE",
  FETCH_ONE_SUB_CONTRACT_HAND_OVER_SUCCESS:
    "FETCH_ONE_SUB_CONTRACT_HAND_OVER_SUCCESS",
};
