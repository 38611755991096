import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { RiskMitigationActionTypes } from "./RiskMitigation.type";

export function* fetchAllRiskMitigations(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/risk-mitigation?project_id=${action.payload.project_id}`);
    yield put({
      type: RiskMitigationActionTypes.FETCH_ALL_RISK_MITIGATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: RiskMitigationActionTypes.FETCH_ALL_RISK_MITIGATION_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneRiskMitigations(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/risk-mitigation/${action.payload}`
    );
    yield put({
      type: RiskMitigationActionTypes.FETCH_ONE_RISK_MITIGATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: RiskMitigationActionTypes.FETCH_ONE_RISK_MITIGATION_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllRiskMitigations() {
  yield takeLatest(RiskMitigationActionTypes.FETCH_ALL_RISK_MITIGATION, fetchAllRiskMitigations);
}

export function* watcherFetchOneRiskMitigations() {
  yield takeLatest(RiskMitigationActionTypes.FETCH_ONE_RISK_MITIGATION, fetchOneRiskMitigations);
}
