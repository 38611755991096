import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { PriceOfferActionTypes } from "./PriceOffer.type";

export function* fetchAllPriceOffers(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/price-offer?project_id=${action.payload?.project_id}`
    );
    yield put({
      type: PriceOfferActionTypes.FETCH_ALL_PRICE_OFFER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PriceOfferActionTypes.FETCH_ALL_PRICE_OFFER_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOnePriceOffers(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/price-offer/${action.payload}`
    );
    yield put({
      type: PriceOfferActionTypes.FETCH_ONE_PRICE_OFFER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PriceOfferActionTypes.FETCH_ONE_PRICE_OFFER_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllPriceOffers() {
  yield takeLatest(
    PriceOfferActionTypes.FETCH_ALL_PRICE_OFFER,
    fetchAllPriceOffers
  );
}

export function* watcherFetchOnePriceOffers() {
  yield takeLatest(
    PriceOfferActionTypes.FETCH_ONE_PRICE_OFFER,
    fetchOnePriceOffers
  );
}
