import { ApiCallState } from "../Utils";

export type DispatchSummary= {
  id: number;
  date: Date;
  unit: string;
  quantity: number;
  dispatch_no: number
};

export type DispatchSummaryStateTypes = {
  fetchAll: ApiCallState<DispatchSummary[]>;
  fetchOne: ApiCallState<DispatchSummary | {}>;
};

export const DispatchSummaryActionTypes = {
  FETCH_ALL_DISPATCH_SUMMARY: "FETCH_ALL_DISPATCH_SUMMARY",
  FETCH_ALL_DISPATCH_SUMMARY_RESET: "FETCH_ALL_DISPATCH_SUMMARY_RESET",
  FETCH_ALL_DISPATCH_SUMMARY_FAILURE: "FETCH_ALL_DISPATCH_SUMMARY_FAILURE",
  FETCH_ALL_DISPATCH_SUMMARY_SUCCESS: "FETCH_ALL_DISPATCH_SUMMARY_SUCCESS",

  FETCH_ONE_DISPATCH_SUMMARY: "FETCH_ONE_DISPATCH_SUMMARY",
  FETCH_ONE_DISPATCH_SUMMARY_RESET: "FETCH_ONE_DISPATCH_SUMMARY_RESET",
  FETCH_ONE_DISPATCH_SUMMARY_FAILURE: "FETCH_ONE_DISPATCH_SUMMARY_FAILURE",
  FETCH_ONE_DISPATCH_SUMMARY_SUCCESS: "FETCH_ONE_DISPATCH_SUMMARY_SUCCESS",
};
