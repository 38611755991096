import { ActionPlanNarrativeStateTypes, ActionPlanNarrativeActionTypes } from "./ActionPlanNarrative.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: ActionPlanNarrativeStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const ActionPlanNarrativeReducer = (
  state: ActionPlanNarrativeStateTypes = INITIAL_STATE,
  action: any
): ActionPlanNarrativeStateTypes => {
  switch (action.type) {
    case ActionPlanNarrativeActionTypes.FETCH_ALL_ACTION_PLAN_NARRATIVE:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case ActionPlanNarrativeActionTypes.FETCH_ALL_ACTION_PLAN_NARRATIVE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ActionPlanNarrativeActionTypes.FETCH_ALL_ACTION_PLAN_NARRATIVE_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ActionPlanNarrativeActionTypes.FETCH_ALL_ACTION_PLAN_NARRATIVE_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case ActionPlanNarrativeActionTypes.FETCH_ONE_ACTION_PLAN_NARRATIVE:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case ActionPlanNarrativeActionTypes.FETCH_ONE_ACTION_PLAN_NARRATIVE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ActionPlanNarrativeActionTypes.FETCH_ONE_ACTION_PLAN_NARRATIVE_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ActionPlanNarrativeActionTypes.FETCH_ONE_ACTION_PLAN_NARRATIVE_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default ActionPlanNarrativeReducer;
