import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ActionPlanSubcontractorActionTypes } from "./ActionPlanSubcontractor.type";

export function* fetchAllActionPlanSubcontractors(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/action-plan-subcontractor?date=${action.payload.date}`);
    yield put({
      type: ActionPlanSubcontractorActionTypes.FETCH_ALL_ACTION_PLAN_SUBCONTRACTOR_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionPlanSubcontractorActionTypes.FETCH_ALL_ACTION_PLAN_SUBCONTRACTOR_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneActionPlanSubcontractors(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/action-plan-subcontractor/${action.payload}`
    );
    yield put({
      type: ActionPlanSubcontractorActionTypes.FETCH_ONE_ACTION_PLAN_SUBCONTRACTOR_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionPlanSubcontractorActionTypes.FETCH_ONE_ACTION_PLAN_SUBCONTRACTOR_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllActionPlanSubcontractors() {
  yield takeLatest(ActionPlanSubcontractorActionTypes.FETCH_ALL_ACTION_PLAN_SUBCONTRACTOR, fetchAllActionPlanSubcontractors);
}

export function* watcherFetchOneActionPlanSubcontractors() {
  yield takeLatest(ActionPlanSubcontractorActionTypes.FETCH_ONE_ACTION_PLAN_SUBCONTRACTOR, fetchOneActionPlanSubcontractors);
}
