import {
  SubContractHandOverStateTypes,
  SubContractHandOverActionTypes,
} from "./SubContractHandOver.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: SubContractHandOverStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const SubContractHandOverReducer = (
  state: SubContractHandOverStateTypes = INITIAL_STATE,
  action: any
): SubContractHandOverStateTypes => {
  switch (action.type) {
    case SubContractHandOverActionTypes.FETCH_ALL_SUB_CONTRACT_HAND_OVER:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case SubContractHandOverActionTypes.FETCH_ALL_SUB_CONTRACT_HAND_OVER_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case SubContractHandOverActionTypes.FETCH_ALL_SUB_CONTRACT_HAND_OVER_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case SubContractHandOverActionTypes.FETCH_ALL_SUB_CONTRACT_HAND_OVER_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case SubContractHandOverActionTypes.FETCH_ONE_SUB_CONTRACT_HAND_OVER:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case SubContractHandOverActionTypes.FETCH_ONE_SUB_CONTRACT_HAND_OVER_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case SubContractHandOverActionTypes.FETCH_ONE_SUB_CONTRACT_HAND_OVER_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case SubContractHandOverActionTypes.FETCH_ONE_SUB_CONTRACT_HAND_OVER_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default SubContractHandOverReducer;
