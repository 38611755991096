import { Moment } from "moment";
import { Boq } from "../Boq/Boq.type";
import { ApiCallState } from "../Utils";

export type WorkExecuted = {
  id: number;
  ordered_to: string;
  date: Moment[];
  project_id: number;
  work_executed_items: WorkExecutedItem[];
  forman: string;
  manager: string;
};

export type WorkExecutedItem = {
  id: any;
  boq: Boq | undefined;
  boq_id: any;
  length: number;
  width: number;
  depth: number;
  timesing: number;
  total_executed: number;
  work_executed_id: any;
  remark: string;
  key: number;
};

export type WorkExecutedStateTypes = {
  fetchAll: ApiCallState<WorkExecuted[]>;
  fetchOne: ApiCallState<WorkExecuted | {}>;
};

export const WorkExecutedActionTypes = {
  FETCH_ALL_WORK_EXECUTED: "FETCH_ALL_WORK_EXECUTED",
  FETCH_ALL_WORK_EXECUTED_RESET: "FETCH_ALL_WORK_EXECUTED_RESET",
  FETCH_ALL_WORK_EXECUTED_FAILURE: "FETCH_ALL_WORK_EXECUTED_FAILURE",
  FETCH_ALL_WORK_EXECUTED_SUCCESS: "FETCH_ALL_WORK_EXECUTED_SUCCESS",

  FETCH_ONE_WORK_EXECUTED: "FETCH_ONE_WORK_EXECUTED",
  FETCH_ONE_WORK_EXECUTED_RESET: "FETCH_ONE_WORK_EXECUTED_RESET",
  FETCH_ONE_WORK_EXECUTED_FAILURE: "FETCH_ONE_WORK_EXECUTED_FAILURE",
  FETCH_ONE_WORK_EXECUTED_SUCCESS: "FETCH_ONE_WORK_EXECUTED_SUCCESS",
};
