import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ProjectIpcFollowUpActionTypes } from "./ProjectIpcFollowUp.type";

export function* fetchAllProjectIpcFollowUps(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/project-ipc-follow-up?date=${action.payload?.date}`);
    yield put({
      type: ProjectIpcFollowUpActionTypes.FETCH_ALL_PROJECT_IPC_FOLLOW_UP_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ProjectIpcFollowUpActionTypes.FETCH_ALL_PROJECT_IPC_FOLLOW_UP_FAILURE,
      payload: error,
    });
  }
}

export function* fetchProjectIpcFollowUps(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/project-ipc-follow-up`
    );
    yield put({
      type: ProjectIpcFollowUpActionTypes.FETCH_PROJECT_IPC_FOLLOW_UP_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ProjectIpcFollowUpActionTypes.FETCH_PROJECT_IPC_FOLLOW_UP_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllProjectIpcFollowUps() {
  yield takeLatest(ProjectIpcFollowUpActionTypes.FETCH_ALL_PROJECT_IPC_FOLLOW_UP, fetchAllProjectIpcFollowUps);
}

export function* watcherFetchProjectIpcFollowUps() {
  yield takeLatest(ProjectIpcFollowUpActionTypes.FETCH_PROJECT_IPC_FOLLOW_UP, fetchProjectIpcFollowUps);
}
