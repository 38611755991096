import { ApiCallState } from "../Utils";

export type WeeklySummaryReport = {
  id: number;
  start_date: string;
  end_date: string;
  weekly_fuel_lubricants: WeeklyFuelLubricant[];
  weekly_equipment_utilizeds: WeeklyEquipmentUtilized[];
  weekly_income_executeds: WeeklyIncomeExecuted[];
  weekly_justifications: WeeklyJustifications[];
  weekly_labour_utilized_expenses: WeeklyLabourUtilizedExpense[];
  weekly_material_consumptions: WeeklyMaterialConsumption[];
  weekly_petty_cash_expenses: WeeklyPettyCashExpense[];
  weekly_sub_contract_expenses: WeeklySubContractExpense[];
  weekly_temporary_employees: weeklyTemporaryEmployee[];
  weekly_machinary_activities: WeeklyMachinaryActivity[];
  weekly_activity_onlies: WeeklyActivityOnly[];
  weekly_material_deliveries: WeeklyMaterialDelivery[];
  planned_executeds: PlannedExecuted[];
  weekly_man_powers: WeeklyManPower[];
  weekly_construction_activities: WeeklyConstructionActivities[];
  weekly_problems_encountereds: WeeklyProblemsEncountered[]
};

export type WeeklyProblemsEncountered = {
  id?: number;
  problem: string;
  responsibility: string;
}

export type WeeklyConstructionActivities = {
  id?: number;
  description: string;
}

export type WeeklyManPower = {
  id?: number;
  description: string;
  schedule: number;
  actual: number;
}

export type PlannedExecuted = {
  id?: number;
  srNo: string;
  description: string;
  masterWeeklyPlanned: number;
  internalWeeklyPlanned: number;
  toDatePlanned: number;
  weeklyExecuted: number;
  toDateExecuted: number;
  masterWeeklyProgress: number;
  internalWeeklyProgress: number;
  toDateProgress: number;
  nextWeekPlanned: number;
  total: number;
  profitLossAmount: number;
  profitLosttPercent: number;
  remark: string;
  isEdited?:boolean;
  isDeleted?:boolean;
}

export type WeeklyMaterialDelivery = {
  id?: number;
  description: string;
  unit: string;
  quantity: number;
  rate: number;
  remark: string;
  isEdited?:boolean;
  isDeleted?:boolean;
}

export type WeeklyActivityOnly = {
  id?: number;
  description: string;
  unit: string;
  quantity: number;
  rate: number;
  remark: string;
  isEdited?:boolean;
  isDeleted?:boolean;
}

export type WeeklyMachinaryActivity = {
  id?: number;
  description: string;
  unit: string;
  quantity: number;
  rate: number;
  remark: string;
  isEdited?:boolean;
  isDeleted?:boolean;
}

export type weeklyTemporaryEmployee = {
  id?: number;
  position: string;
  no: number;
  rate: number;
  type: string;
  isEdited?:boolean;
  isDeleted?:boolean;
};

export type WeeklySubContractExpense = {
  id?: number;
  description: string;
  unit: string;
  quantity: number;
  rate: number;
  isEdited?:boolean;
  isDeleted?:boolean;
};

export type WeeklyPettyCashExpense = {
  id?: number;
  description: string;
  unit: string;
  quantity: number;
  rate: number;
  isEdited?:boolean;
  isDeleted?:boolean;
};

export type WeeklyMaterialConsumption = {
  id?: number;
  description: string;
  unit: string;
  quantity: number;
  rate: number;
  isEdited?:boolean;
  isDeleted?:boolean;
};

export type WeeklyLabourUtilizedExpense = {
  id?: number;
  position: string;
  type: string;
  no: number;
  rate: number;
  isEdited?:boolean;
  isDeleted?:boolean;
};

export type WeeklyEquipmentUtilized = {
  id?: number;
  description: string;
  tw_hour: number;
  idle_hour: number;
  quantity: number;
  rate: number;
  isEdited?:boolean;
  isDeleted?:boolean;
};

export type WeeklyFuelLubricant = {
  id?: number;
  description: string;
  unit: string;
  quantity: number;
  rate: number;
  km: number;
  eng_hr: number;
  isEdited?:boolean;
  isDeleted?:boolean;
};

export type WeeklyIncomeExecuted = {
  id?: number;
  description: string;
  unit: string;
  quantity: number;
  rate: number;
  remark: string;
  isEdited?:boolean;
  isDeleted?:boolean;
};

export type WeeklyJustifications = {
  id?: number;
  description_not_executed: string;
  unexpected_amount: string;
  problems_encountered: string;
  remedial_measure_taken: string;
  remark: string;
  isEdited?:boolean;
  isDeleted?:boolean;
};

export type WeeklySummaryReportStateTypes = {
  fetchAll: ApiCallState<WeeklySummaryReport[]>;
  fetchOne: ApiCallState<WeeklySummaryReport | {}>;
};

export const WeeklySummaryReportActionTypes = {
  FETCH_ALL_WEEKLY_SUMMARY_REPORT: "FETCH_ALL_WEEKLY_SUMMARY_REPORT",
  FETCH_ALL_WEEKLY_SUMMARY_REPORT_RESET:
    "FETCH_ALL_WEEKLY_SUMMARY_REPORT_RESET",
  FETCH_ALL_WEEKLY_SUMMARY_REPORT_FAILURE:
    "FETCH_ALL_WEEKLY_SUMMARY_REPORT_FAILURE",
  FETCH_ALL_WEEKLY_SUMMARY_REPORT_SUCCESS:
    "FETCH_ALL_WEEKLY_SUMMARY_REPORT_SUCCESS",

  FETCH_ONE_WEEKLY_SUMMARY_REPORT: "FETCH_ONE_WEEKLY_SUMMARY_REPORT",
  FETCH_ONE_WEEKLY_SUMMARY_REPORT_RESET:
    "FETCH_ONE_WEEKLY_SUMMARY_REPORT_RESET",
  FETCH_ONE_WEEKLY_SUMMARY_REPORT_FAILURE:
    "FETCH_ONE_WEEKLY_SUMMARY_REPORT_FAILURE",
  FETCH_ONE_WEEKLY_SUMMARY_REPORT_SUCCESS:
    "FETCH_ONE_WEEKLY_SUMMARY_REPORT_SUCCESS",
};
