import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { EquipmentAndPlantStatusActionTypes } from "./EquipmentAndPlantStatus.type";

export function* fetchAllEquipmentAndPlantStatuss(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/equipment-and-plant-status?project_id=${action.payload.project_id}`);
    yield put({
      type: EquipmentAndPlantStatusActionTypes.FETCH_ALL_EQUIPMENT_AND_PLANT_STATUS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EquipmentAndPlantStatusActionTypes.FETCH_ALL_EQUIPMENT_AND_PLANT_STATUS_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneEquipmentAndPlantStatuss(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/equipment-and-plant-status/${action.payload}`
    );
    yield put({
      type: EquipmentAndPlantStatusActionTypes.FETCH_ONE_EQUIPMENT_AND_PLANT_STATUS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EquipmentAndPlantStatusActionTypes.FETCH_ONE_EQUIPMENT_AND_PLANT_STATUS_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllEquipmentAndPlantStatuss() {
  yield takeLatest(EquipmentAndPlantStatusActionTypes.FETCH_ALL_EQUIPMENT_AND_PLANT_STATUS, fetchAllEquipmentAndPlantStatuss);
}

export function* watcherFetchOneEquipmentAndPlantStatuss() {
  yield takeLatest(EquipmentAndPlantStatusActionTypes.FETCH_ONE_EQUIPMENT_AND_PLANT_STATUS, fetchOneEquipmentAndPlantStatuss);
}
