import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { JustificationSummaryActionTypes } from "./JustificationSummary.type";

export function* fetchAllJustificationSummarys(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/justification-summary?project_id=${action.payload?.project_id}&date=${action.payload?.date}`);
    yield put({
      type: JustificationSummaryActionTypes.FETCH_ALL_JUSTIFICATION_SUMMARY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: JustificationSummaryActionTypes.FETCH_ALL_JUSTIFICATION_SUMMARY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneJustificationSummarys(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/justification-summary/one?project_id=${action.payload?.project_id}&date=${action.payload?.date}`
    );
    yield put({
      type: JustificationSummaryActionTypes.FETCH_ONE_JUSTIFICATION_SUMMARY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: JustificationSummaryActionTypes.FETCH_ONE_JUSTIFICATION_SUMMARY_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllJustificationSummarys() {
  yield takeLatest(JustificationSummaryActionTypes.FETCH_ALL_JUSTIFICATION_SUMMARY, fetchAllJustificationSummarys);
}

export function* watcherFetchOneJustificationSummarys() {
  yield takeLatest(JustificationSummaryActionTypes.FETCH_ONE_JUSTIFICATION_SUMMARY, fetchOneJustificationSummarys);
}
