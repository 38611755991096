import { MonthManpowerScheduleStateTypes, MonthManpowerScheduleActionTypes } from "./MonthManpowerSchedule.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: MonthManpowerScheduleStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const MonthManpowerScheduleReducer = (
  state: MonthManpowerScheduleStateTypes = INITIAL_STATE,
  action: any
): MonthManpowerScheduleStateTypes => {
  switch (action.type) {
    case MonthManpowerScheduleActionTypes.FETCH_ALL_MONTH_MANPOWER_SCHEDULE:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case MonthManpowerScheduleActionTypes.FETCH_ALL_MONTH_MANPOWER_SCHEDULE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case MonthManpowerScheduleActionTypes.FETCH_ALL_MONTH_MANPOWER_SCHEDULE_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MonthManpowerScheduleActionTypes.FETCH_ALL_MONTH_MANPOWER_SCHEDULE_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case MonthManpowerScheduleActionTypes.FETCH_ONE_MONTH_MANPOWER_SCHEDULE:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case MonthManpowerScheduleActionTypes.FETCH_ONE_MONTH_MANPOWER_SCHEDULE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case MonthManpowerScheduleActionTypes.FETCH_ONE_MONTH_MANPOWER_SCHEDULE_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MonthManpowerScheduleActionTypes.FETCH_ONE_MONTH_MANPOWER_SCHEDULE_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default MonthManpowerScheduleReducer;
