import { ApiCallState } from "../Utils";
import { Document } from "../Document/Document.type";

export type ProjectContract= {
  id: number;
  project_id:number;
  date:string;
  document:Document;
};

export type ProjectContractStateTypes = {
  fetchAll: ApiCallState<ProjectContract[]>;
  fetchOne: ApiCallState<ProjectContract | {}>;
};

export const ProjectContractActionTypes = {
  FETCH_ALL_PROJECT_CONTRACT: "FETCH_ALL_PROJECT_CONTRACT",
  FETCH_ALL_PROJECT_CONTRACT_RESET: "FETCH_ALL_PROJECT_CONTRACT_RESET",
  FETCH_ALL_PROJECT_CONTRACT_FAILURE: "FETCH_ALL_PROJECT_CONTRACT_FAILURE",
  FETCH_ALL_PROJECT_CONTRACT_SUCCESS: "FETCH_ALL_PROJECT_CONTRACT_SUCCESS",

  FETCH_ONE_PROJECT_CONTRACT: "FETCH_ONE_PROJECT_CONTRACT",
  FETCH_ONE_PROJECT_CONTRACT_RESET: "FETCH_ONE_PROJECT_CONTRACT_RESET",
  FETCH_ONE_PROJECT_CONTRACT_FAILURE: "FETCH_ONE_PROJECT_CONTRACT_FAILURE",
  FETCH_ONE_PROJECT_CONTRACT_SUCCESS: "FETCH_ONE_PROJECT_CONTRACT_SUCCESS",
};
