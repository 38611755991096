import { Staff } from "../Staff/Staff.type";
import { ApiCallState } from "../Utils";

export type WithHoldPayment = {
  id: number;
  staff_id: number;

  date: string;
  type: string;
  reason: string;

  staff: Staff;
};

export type WithHoldPaymentStateTypes = {
  fetchAll: ApiCallState<WithHoldPayment[]>;
  fetchOne: ApiCallState<WithHoldPayment | {}>;
};

export const WithHoldPaymentActionTypes = {
  FETCH_ALL_WITH_HOLD_PAYMENT: "FETCH_ALL_WITH_HOLD_PAYMENT",
  FETCH_ALL_WITH_HOLD_PAYMENT_RESET: "FETCH_ALL_WITH_HOLD_PAYMENT_RESET",
  FETCH_ALL_WITH_HOLD_PAYMENT_FAILURE: "FETCH_ALL_WITH_HOLD_PAYMENT_FAILURE",
  FETCH_ALL_WITH_HOLD_PAYMENT_SUCCESS: "FETCH_ALL_WITH_HOLD_PAYMENT_SUCCESS",

  FETCH_ONE_WITH_HOLD_PAYMENT: "FETCH_ONE_WITH_HOLD_PAYMENT",
  FETCH_ONE_WITH_HOLD_PAYMENT_RESET: "FETCH_ONE_WITH_HOLD_PAYMENT_RESET",
  FETCH_ONE_WITH_HOLD_PAYMENT_FAILURE: "FETCH_ONE_WITH_HOLD_PAYMENT_FAILURE",
  FETCH_ONE_WITH_HOLD_PAYMENT_SUCCESS: "FETCH_ONE_WITH_HOLD_PAYMENT_SUCCESS",
};
