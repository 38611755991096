import {
  EquipmentDailyDownIdleReportStateTypes,
  EquipmentDailyDownIdleReportActionTypes,
} from "./EquipmentDailyDownIdleReport.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: EquipmentDailyDownIdleReportStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
  fetchPrevious: resetApiCallState({}),
};

const EquipmentDailyDownIdleReportReducer = (
  state: EquipmentDailyDownIdleReportStateTypes = INITIAL_STATE,
  action: any
): EquipmentDailyDownIdleReportStateTypes => {
  switch (action.type) {
    case EquipmentDailyDownIdleReportActionTypes.FETCH_ALL_EQUIPMENT_DAILY_BREAKDOWN_IDLE_REPORT:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case EquipmentDailyDownIdleReportActionTypes.FETCH_ALL_EQUIPMENT_DAILY_BREAKDOWN_IDLE_REPORT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case EquipmentDailyDownIdleReportActionTypes.FETCH_ALL_EQUIPMENT_DAILY_BREAKDOWN_IDLE_REPORT_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case EquipmentDailyDownIdleReportActionTypes.FETCH_ALL_EQUIPMENT_DAILY_BREAKDOWN_IDLE_REPORT_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case EquipmentDailyDownIdleReportActionTypes.FETCH_ONE_EQUIPMENT_DAILY_BREAKDOWN_IDLE_REPORT:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case EquipmentDailyDownIdleReportActionTypes.FETCH_ONE_EQUIPMENT_DAILY_BREAKDOWN_IDLE_REPORT_RESET:
      return {
        ...state,
        fetchOne: resetApiCallState({}),
      };
    case EquipmentDailyDownIdleReportActionTypes.FETCH_ONE_EQUIPMENT_DAILY_BREAKDOWN_IDLE_REPORT_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case EquipmentDailyDownIdleReportActionTypes.FETCH_ONE_EQUIPMENT_DAILY_BREAKDOWN_IDLE_REPORT_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case EquipmentDailyDownIdleReportActionTypes.FETCH_ONE_EQUIPMENT_DAILY_BREAKDOWN_IDLE_REPORT:
      return {
        ...state,
        fetchPrevious: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case EquipmentDailyDownIdleReportActionTypes.FETCH_PREVIOUS_EQUIPMENT_DAILY_BREAKDOWN_IDLE_REPORT_RESET:
      return {
        ...state,
        fetchPrevious: resetApiCallState({}),
      };
    case EquipmentDailyDownIdleReportActionTypes.FETCH_PREVIOUS_EQUIPMENT_DAILY_BREAKDOWN_IDLE_REPORT_FAILURE:
      return {
        ...state,
        fetchPrevious: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case EquipmentDailyDownIdleReportActionTypes.FETCH_PREVIOUS_EQUIPMENT_DAILY_BREAKDOWN_IDLE_REPORT_SUCCESS:
      return {
        ...state,
        fetchPrevious: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default EquipmentDailyDownIdleReportReducer;
