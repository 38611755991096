import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { WeeklySummaryReportActionTypes } from "./WeeklySummaryReport.type";

export function* fetchAllWeeklySummaryReports(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/weekly-summary-report?project_id=${action.payload?.project_id}&start_date=${action.payload?.start_date}&end_date=${action.payload?.end_date}`);
    yield put({
      type: WeeklySummaryReportActionTypes.FETCH_ALL_WEEKLY_SUMMARY_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: WeeklySummaryReportActionTypes.FETCH_ALL_WEEKLY_SUMMARY_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneWeeklySummaryReports(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/weekly-summary-report/one?project_id=${action.payload?.project_id}&start_date=${action.payload?.start_date}&end_date=${action.payload?.end_date}`
    );
    yield put({
      type: WeeklySummaryReportActionTypes.FETCH_ONE_WEEKLY_SUMMARY_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: WeeklySummaryReportActionTypes.FETCH_ONE_WEEKLY_SUMMARY_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllWeeklySummaryReports() {
  yield takeLatest(WeeklySummaryReportActionTypes.FETCH_ALL_WEEKLY_SUMMARY_REPORT, fetchAllWeeklySummaryReports);
}

export function* watcherFetchOneWeeklySummaryReports() {
  yield takeLatest(WeeklySummaryReportActionTypes.FETCH_ONE_WEEKLY_SUMMARY_REPORT, fetchOneWeeklySummaryReports);
}
