import {
  ContractSubItem,
  RentContractItem,
} from "../RentContract/RentContract.type";
import { ApiCallState } from "../Utils";

export type EquipmentDailyOperationReport = {
  id: number;
  user_id: number;

  contract_type: string;
  date: string;

  name1: string;
  name2: string;
  name3: string;
  name4: string;

  equipment_daily_reports: EquipmentDailyReport[];
  equipment_daily_trip_reports: EquipmentDailyTripReport[];

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type EquipmentDailyReport = {
  id: number;
  equipment_daily_operation_report_id: number;
  contract_item_id: number;

  actual_from_hr: string;
  actual_to_hr: string;

  idle_from_hr: string;
  idle_to_hr: string;

  down_from_hr: string;
  down_to_hr: string;

  equipment_service_initial: number;
  equipment_service_final: number;

  fuel_filled: number;
  prev_km_hr: number;
  to_date_km_hr: number;

  idle_reason: string;

  contract_item: RentContractItem;

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type EquipmentDailyTripReport = {
  id: number;
  equipment_daily_operation_report_id: number;
  contract_item_id: number;

  fuel_filled: number;
  equipment_service_initial: number;
  equipment_service_final: number;

  idle_reason: string;

  equipment_daily_trip_report_items: EquipmentDailyTripReportItem[];
  contract_item: RentContractItem;

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type EquipmentDailyTripReportItem = {
  id: number;
  equipment_daily_trip_report_id: number;
  contract_sub_item_id: number;

  value: number;

  contract_sub_item: ContractSubItem;

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type EquipmentDailyOperationReportStateTypes = {
  fetchAll: ApiCallState<EquipmentDailyOperationReport[]>;
  fetchOne: ApiCallState<EquipmentDailyOperationReport | {}>;
  fetchPrevious: ApiCallState<EquipmentDailyOperationReport | {}>;
  fetchReport: ApiCallState<EquipmentDailyOperationReport | {}>;
};

export const EquipmentDailyOperationReportActionTypes = {
  FETCH_ALL_EQUIPMENT_DAILY_OPERATION_REPORT:
    "FETCH_ALL_EQUIPMENT_DAILY_OPERATION_REPORT",
  FETCH_ALL_EQUIPMENT_DAILY_OPERATION_REPORT_RESET:
    "FETCH_ALL_EQUIPMENT_DAILY_OPERATION_REPORT_RESET",
  FETCH_ALL_EQUIPMENT_DAILY_OPERATION_REPORT_FAILURE:
    "FETCH_ALL_EQUIPMENT_DAILY_OPERATION_REPORT_FAILURE",
  FETCH_ALL_EQUIPMENT_DAILY_OPERATION_REPORT_SUCCESS:
    "FETCH_ALL_EQUIPMENT_DAILY_OPERATION_REPORT_SUCCESS",

  FETCH_ONE_EQUIPMENT_DAILY_OPERATION_REPORT:
    "FETCH_ONE_EQUIPMENT_DAILY_OPERATION_REPORT",
  FETCH_ONE_EQUIPMENT_DAILY_OPERATION_REPORT_RESET:
    "FETCH_ONE_EQUIPMENT_DAILY_OPERATION_REPORT_RESET",
  FETCH_ONE_EQUIPMENT_DAILY_OPERATION_REPORT_FAILURE:
    "FETCH_ONE_EQUIPMENT_DAILY_OPERATION_REPORT_FAILURE",
  FETCH_ONE_EQUIPMENT_DAILY_OPERATION_REPORT_SUCCESS:
    "FETCH_ONE_EQUIPMENT_DAILY_OPERATION_REPORT_SUCCESS",

  FETCH_PREVIOUS_EQUIPMENT_DAILY_OPERATION_REPORT:
    "FETCH_PREVIOUS_EQUIPMENT_DAILY_OPERATION_REPORT",
  FETCH_PREVIOUS_EQUIPMENT_DAILY_OPERATION_REPORT_RESET:
    "FETCH_PREVIOUS_EQUIPMENT_DAILY_OPERATION_REPORT_RESET",
  FETCH_PREVIOUS_EQUIPMENT_DAILY_OPERATION_REPORT_FAILURE:
    "FETCH_PREVIOUS_EQUIPMENT_DAILY_OPERATION_REPORT_FAILURE",
  FETCH_PREVIOUS_EQUIPMENT_DAILY_OPERATION_REPORT_SUCCESS:
    "FETCH_PREVIOUS_EQUIPMENT_DAILY_OPERATION_REPORT_SUCCESS",

  FETCH_GENERATED_EQUIPMENT_DAILY_OPERATION_REPORT:
    "FETCH_GENERATED_EQUIPMENT_DAILY_OPERATION_REPORT",
  FETCH_GENERATED_EQUIPMENT_DAILY_OPERATION_REPORT_RESET:
    "FETCH_GENERATED_EQUIPMENT_DAILY_OPERATION_REPORT_RESET",
  FETCH_GENERATED_EQUIPMENT_DAILY_OPERATION_REPORT_FAILURE:
    "FETCH_GENERATED_EQUIPMENT_DAILY_OPERATION_REPORT_FAILURE",
  FETCH_GENERATED_EQUIPMENT_DAILY_OPERATION_REPORT_SUCCESS:
    "FETCH_GENERATED_EQUIPMENT_DAILY_OPERATION_REPORT_SUCCESS",
};
