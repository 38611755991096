import { BankAccount } from "../BankAccount/BankAccount.type";
import { Project } from "../Project/Project.type";
import { ApiCallState } from "../Utils";

export type CRVPayment = {
  id: number;
  user_id: number;
  bank_account_id: number;
  project_id: number;

  customer: string;
  description: string;
  crv_number: string;
  amount: number;

  date: Date;

  bank_account: BankAccount;
  project: Project;
};

export type CRVPaymentStateTypes = {
  fetchAll: ApiCallState<CRVPayment[]>;
  fetchOne: ApiCallState<CRVPayment | {}>;
};

export const CRVPaymentActionTypes = {
  FETCH_ALL_CRV_PAYMENT: "FETCH_ALL_CRV_PAYMENT",
  FETCH_ALL_CRV_PAYMENT_RESET: "FETCH_ALL_CRV_PAYMENT_RESET",
  FETCH_ALL_CRV_PAYMENT_FAILURE: "FETCH_ALL_CRV_PAYMENT_FAILURE",
  FETCH_ALL_CRV_PAYMENT_SUCCESS: "FETCH_ALL_CRV_PAYMENT_SUCCESS",

  FETCH_ONE_CRV_PAYMENT: "FETCH_ONE_CRV_PAYMENT",
  FETCH_ONE_CRV_PAYMENT_RESET: "FETCH_ONE_CRV_PAYMENT_RESET",
  FETCH_ONE_CRV_PAYMENT_FAILURE: "FETCH_ONE_CRV_PAYMENT_FAILURE",
  FETCH_ONE_CRV_PAYMENT_SUCCESS: "FETCH_ONE_CRV_PAYMENT_SUCCESS",
};
