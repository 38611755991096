import { Moment } from "moment";
import { ApiCallState } from "../Utils";

export type SiteBookOrder= {
    id: number | null;
    project_id:number | null;
    date:Moment;
    order_no:string;
    project_location:string;
    order_type:number;
    work_order:string;
};

export type SiteBookOrderStateTypes = {
  fetchAll: ApiCallState<SiteBookOrder[]>;
  fetchOne: ApiCallState<SiteBookOrder | {}>;
};

export const SiteBookOrderActionTypes = {
  FETCH_ALL_SITE_BOOK_ORDER: "FETCH_ALL_SITE_BOOK_ORDER",
  FETCH_ALL_SITE_BOOK_ORDER_RESET: "FETCH_ALL_SITE_BOOK_ORDER_RESET",
  FETCH_ALL_SITE_BOOK_ORDER_FAILURE: "FETCH_ALL_SITE_BOOK_ORDER_FAILURE",
  FETCH_ALL_SITE_BOOK_ORDER_SUCCESS: "FETCH_ALL_SITE_BOOK_ORDER_SUCCESS",

  FETCH_ONE_SITE_BOOK_ORDER: "FETCH_ONE_SITE_BOOK_ORDER",
  FETCH_ONE_SITE_BOOK_ORDER_RESET: "FETCH_ONE_SITE_BOOK_ORDER_RESET",
  FETCH_ONE_SITE_BOOK_ORDER_FAILURE: "FETCH_ONE_SITE_BOOK_ORDER_FAILURE",
  FETCH_ONE_SITE_BOOK_ORDER_SUCCESS: "FETCH_ONE_SITE_BOOK_ORDER_SUCCESS",
};
