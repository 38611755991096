import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { BenchmarkActionTypes } from "./Benchmark.type";

export function* fetchAllBenchmarks(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/benchmark?project_id=${action.payload.project_id}`);
    yield put({
      type: BenchmarkActionTypes.FETCH_ALL_BENCHMARK_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BenchmarkActionTypes.FETCH_ALL_BENCHMARK_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneBenchmarks(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/banchmark/${action.payload}`
    );
    yield put({
      type: BenchmarkActionTypes.FETCH_ONE_BENCHMARK_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BenchmarkActionTypes.FETCH_ONE_BENCHMARK_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllBenchmarks() {
  yield takeLatest(BenchmarkActionTypes.FETCH_ALL_BENCHMARK, fetchAllBenchmarks);
}

export function* watcherFetchOneBenchmarks() {
  yield takeLatest(BenchmarkActionTypes.FETCH_ONE_BENCHMARK, fetchOneBenchmarks);
}
