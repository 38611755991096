import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ProjectContractActionTypes } from "./ProjectContract.type";

export function* fetchAllProjectContracts(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/project-contract?project_id=${action.payload.project_id}`);
    yield put({
      type: ProjectContractActionTypes.FETCH_ALL_PROJECT_CONTRACT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ProjectContractActionTypes.FETCH_ALL_PROJECT_CONTRACT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneProjectContracts(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/project-contract/${action.payload}`
    );
    yield put({
      type: ProjectContractActionTypes.FETCH_ONE_PROJECT_CONTRACT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ProjectContractActionTypes.FETCH_ONE_PROJECT_CONTRACT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllProjectContracts() {
  yield takeLatest(ProjectContractActionTypes.FETCH_ALL_PROJECT_CONTRACT, fetchAllProjectContracts);
}

export function* watcherFetchOneProjectContracts() {
  yield takeLatest(ProjectContractActionTypes.FETCH_ONE_PROJECT_CONTRACT, fetchOneProjectContracts);
}
