import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MaterialUtilizationSummaryActionTypes } from "./MaterialUtilizationSummary.type";

export function* fetchAllMaterialUtilizationSummarys(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/material-utilization-summary?project_id=${action.payload.project_id}`);
    yield put({
      type: MaterialUtilizationSummaryActionTypes.FETCH_ALL_MATERIAL_UTILIZATION_SUMMARY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialUtilizationSummaryActionTypes.FETCH_ALL_MATERIAL_UTILIZATION_SUMMARY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneMaterialUtilizationSummarys(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/material-utilization-summary/${action.payload}`
    );
    yield put({
      type: MaterialUtilizationSummaryActionTypes.FETCH_ONE_MATERIAL_UTILIZATION_SUMMARY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialUtilizationSummaryActionTypes.FETCH_ONE_MATERIAL_UTILIZATION_SUMMARY_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllMaterialUtilizationSummarys() {
  yield takeLatest(MaterialUtilizationSummaryActionTypes.FETCH_ALL_MATERIAL_UTILIZATION_SUMMARY, fetchAllMaterialUtilizationSummarys);
}

export function* watcherFetchOneMaterialUtilizationSummarys() {
  yield takeLatest(MaterialUtilizationSummaryActionTypes.FETCH_ONE_MATERIAL_UTILIZATION_SUMMARY, fetchOneMaterialUtilizationSummarys);
}
