import { ApiCallState } from "../Utils";

export type ActionPlanNarrative= {
  id: number | null;
  project_id:number;
  date:string;
  remark:string;
};

export type ActionPlanNarrativeStateTypes = {
  fetchAll: ApiCallState<ActionPlanNarrative[]>;
  fetchOne: ApiCallState<ActionPlanNarrative | {}>;
};

export const ActionPlanNarrativeActionTypes = {
  FETCH_ALL_ACTION_PLAN_NARRATIVE: "FETCH_ALL_ACTION_PLAN_NARRATIVE",
  FETCH_ALL_ACTION_PLAN_NARRATIVE_RESET: "FETCH_ALL_ACTION_PLAN_NARRATIVE_RESET",
  FETCH_ALL_ACTION_PLAN_NARRATIVE_FAILURE: "FETCH_ALL_ACTION_PLAN_NARRATIVE_FAILURE",
  FETCH_ALL_ACTION_PLAN_NARRATIVE_SUCCESS: "FETCH_ALL_ACTION_PLAN_NARRATIVE_SUCCESS",

  FETCH_ONE_ACTION_PLAN_NARRATIVE: "FETCH_ONE_ACTION_PLAN_NARRATIVE",
  FETCH_ONE_ACTION_PLAN_NARRATIVE_RESET: "FETCH_ONE_ACTION_PLAN_NARRATIVE_RESET",
  FETCH_ONE_ACTION_PLAN_NARRATIVE_FAILURE: "FETCH_ONE_ACTION_PLAN_NARRATIVE_FAILURE",
  FETCH_ONE_ACTION_PLAN_NARRATIVE_SUCCESS: "FETCH_ONE_ACTION_PLAN_NARRATIVE_SUCCESS",
};
