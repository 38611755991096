import { ApiCallState } from "../Utils";
import { Document } from "../Document/Document.type";

export type Benchmark= {
  id: number;
  project_id:number;
  date:string;
  location:string;
  document:Document;
  user_id: number;
};

export type BenchmarkStateTypes = {
  fetchAll: ApiCallState<Benchmark[]>;
  fetchOne: ApiCallState<Benchmark | {}>;
};

export const BenchmarkActionTypes = {
  FETCH_ALL_BENCHMARK: "FETCH_ALL_BENCHMARK",
  FETCH_ALL_BENCHMARK_RESET: "FETCH_ALL_BENCHMARK_RESET",
  FETCH_ALL_BENCHMARK_FAILURE: "FETCH_ALL_BENCHMARK_FAILURE",
  FETCH_ALL_BENCHMARK_SUCCESS: "FETCH_ALL_BENCHMARK_SUCCESS",

  FETCH_ONE_BENCHMARK: "FETCH_ONE_BENCHMARK",
  FETCH_ONE_BENCHMARK_RESET: "FETCH_ONE_BENCHMARK_RESET",
  FETCH_ONE_BENCHMARK_FAILURE: "FETCH_ONE_BENCHMARK_FAILURE",
  FETCH_ONE_BENCHMARK_SUCCESS: "FETCH_ONE_BENCHMARK_SUCCESS",
};
