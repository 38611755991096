import { ApiCallState } from "../Utils";

export type ProjectOperationReport = {
  id: number;
  start_date: string;
  end_date: string;
  project_operation_report_items: ProjectOperationReportItems[];
};

export type ProjectOperationReportItems = {
  id?: number;
  operation_issue_follow_up: string;
  operation_isssue_follow_up_status: string;
  sub_contract_management_issues: string;
  sub_contract_management_issues_status: string;
  payment_related_issues: string;
  payment_related_issues_status: string;
  material_supply_related_issues: string;
  material_supply_related_issues_status: string;
  machinary_supply_related_issues: string;
  machinary_supply_related_issues_status: string;
  finance_related_issues: string;
  finance_related_issues_status: string;
  project_follow_up_and_monitoring: string;
  project_follow_up_and_monitoring_status: string;
};

export type ProjectOperationReportStateTypes = {
  fetchAll: ApiCallState<ProjectOperationReport[]>;
  fetchOne: ApiCallState<ProjectOperationReport | {}>;
};

export const ProjectOperationReportActionTypes = {
  FETCH_ALL_PROJECT_OPERATION_REPORT: "FETCH_ALL_PROJECT_OPERATION_REPORT",
  FETCH_ALL_PROJECT_OPERATION_REPORT_RESET:
    "FETCH_ALL_PROJECT_OPERATION_REPORT_RESET",
  FETCH_ALL_PROJECT_OPERATION_REPORT_FAILURE:
    "FETCH_ALL_PROJECT_OPERATION_REPORT_FAILURE",
  FETCH_ALL_PROJECT_OPERATION_REPORT_SUCCESS:
    "FETCH_ALL_PROJECT_OPERATION_REPORT_SUCCESS",

  FETCH_ONE_PROJECT_OPERATION_REPORT: "FETCH_ONE_PROJECT_OPERATION_REPORT",
  FETCH_ONE_PROJECT_OPERATION_REPORT_RESET:
    "FETCH_ONE_PROJECT_OPERATION_REPORT_RESET",
  FETCH_ONE_PROJECT_OPERATION_REPORT_FAILURE:
    "FETCH_ONE_PROJECT_OPERATION_REPORT_FAILURE",
  FETCH_ONE_PROJECT_OPERATION_REPORT_SUCCESS:
    "FETCH_ONE_PROJECT_OPERATION_REPORT_SUCCESS",
};
